import React from "react"
import { copyToClipboard } from "utilities/clipboard"
import Tooltip from "@material-ui/core/Tooltip"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { isNullOrUndefined } from "../../../../utilities/isNullOrUndefined"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyButton } from "@parachutehealth/canopy-button"
import * as styles from "./index.module.scss"

type Props = {
  text: string
  dataTestId: string
}

const CopyableCell: React.FC<Props> = (props: Props) => {
  const { text, dataTestId } = props
  const textPresent = !isNullOrUndefined(text) && text.length > 0
  const [open, setOpen] = React.useState<boolean>(false)
  const handleTooltipClose = () => {
    setOpen(false)
  }
  const onClick = () => {
    copyToClipboard(text)
    setOpen(true)
    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }

  return (
    <CanopyFlex justifyContent="flex-start" alignItems="center">
      {textPresent && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied"
          >
            <CanopyFlex alignItems="center">
              <CanopyButton
                className={styles.minimalButton}
                variant="tertiary"
                accessibleText="Click to copy"
                size="small"
                iconStart="copy"
                title="Click to copy"
                data-testid={`${dataTestId}`}
                onClick={onClick}
              />
            </CanopyFlex>
          </Tooltip>
        </ClickAwayListener>
      )}
      <span className="canopy-typography-body-small">{text}</span>
    </CanopyFlex>
  )
}
export default CopyableCell
