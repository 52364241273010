import React from "react"
import CartCard from "./CartCard"
import DiagnosesCard from "./DiagnosesCard"
import DocumentationCard from "./DocumentationCard"
import PatientCard from "./PatientCard"
import PaymentCard from "./PaymentCard"
import DocumentsBrowserCard from "./DocumentsBrowserCard"
import { Grid } from "@material-ui/core"
import { DmeOrder, Document, RxDetailsOutput } from "sharedTypes"
import { Document as DocumentsBrowserDocument } from "components/DocumentsBrowser/sharedTypes"
import { isMobile } from "react-device-detect"
import { documentsDownloadUrl } from "./urls"
import MedicalNecessityReviewCard from "applications/Workflow/containers/Review/components/ReviewCard/variants/MedicalNecessityReviewCard/MedicalNecessityReviewCard"
import { useFeatureFlags } from "components/FeatureFlagContext"

type Props = {
  dmeOrder: DmeOrder
  downloadableDocuments: Document[]
  documents: DocumentsBrowserDocument[]
  rxDetailsOutputs: RxDetailsOutput[]
}

const Cards = (props: Props) => {
  const { dmeOrder, downloadableDocuments, documents, rxDetailsOutputs } = props
  const { isFeatureEnabled } = useFeatureFlags()
  dmeOrder.questionnaireResponses ||= []

  const isPayorEnhanced =
    dmeOrder.payorEnhancedWorkflowEnabled &&
    isFeatureEnabled("payorEnhancedClinicalGuidelines")

  return (
    <div className="workflow-row">
      <Grid container spacing={2}>
        <Grid item xs={isMobile ? 12 : 6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PatientCard dmeOrder={dmeOrder} />
            </Grid>
            <Grid item xs={12}>
              <PaymentCard dmeOrder={dmeOrder} />
            </Grid>
            <Grid item xs={12}>
              <CartCard
                dmeOrder={dmeOrder}
                rxDetailsOutputs={rxDetailsOutputs}
              />
            </Grid>
            <Grid item xs={12}>
              <DiagnosesCard icd10Codes={dmeOrder.icd10Codes} />
            </Grid>
            <Grid item xs={12}>
              <DocumentationCard
                dmeOrder={dmeOrder}
                downloadableDocuments={downloadableDocuments}
              />
            </Grid>
            {isPayorEnhanced && (
              <Grid item xs={12}>
                <MedicalNecessityReviewCard
                  dmeOrder={dmeOrder}
                  expanded={true}
                  questionnaireResponses={dmeOrder.questionnaireResponses}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item xs={6}>
            <DocumentsBrowserCard
              documents={documents.map((doc) => ({
                ...doc,
                downloadUrl: documentsDownloadUrl(doc.id),
              }))}
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default Cards
