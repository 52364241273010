// @ts-strict-ignore
import React from "react"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import ConfirmButton from "./ConfirmButton"
import ChartNoteHelperAlert from "./ChartNoteHelperAlert"
import ProgressDonut from "components/ProgressDonut"
import Survey from "applications/Workflow/components/Survey"
import { Link } from "react-router-dom"
import * as routes from "applications/Workflow/routes"
import { SurveyQuestion, SurveyAnswerType } from "sharedTypes"
import { ChartNoteHelperType } from "../sharedTypes"
import {
  LEGAL_WARNING,
  CRITERIA_NOT_MET,
  CRITERIA_MET,
  SUPPLIER_CRITERIA_MET,
  SUPPLIER_CRITERIA_NOT_MET,
} from "applications/Workflow/containers/ClinicalFacilityClinical/utilities"
import WorkflowHeader from "applications/Workflow/components/WorkflowHeader"
import cx from "classnames"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { canopySpace8X } from "@parachutehealth/canopy-tokens-space"
import { Button } from "components/form"
import ErrorMessageContainer from "../../ClinicalFacilityClinical/components/OptumDocumentationRequirements/ErrorMessageContainer"

type Props = {
  answerQuestion(
    question: SurveyQuestion,
    answerType: SurveyAnswerType,
    answerValue: string
  ): Promise<void>
  confirmPrefilledAnswers(): Promise<void>
  name: string
  questions: SurveyQuestion[]
  requiresConfirmation: boolean
  disqualifiedMessage?: string
  qualificationStatus: string
  percentageComplete?: number
  chartNoteHelperType: ChartNoteHelperType
  hasDefaultChoices: boolean
  nextPagePath?: string
  isPayorEnhanced?: boolean
  optumError?: string
  isSupplier?: boolean
}

const InternalChartNotes: React.SFC<Props> = ({
  answerQuestion,
  confirmPrefilledAnswers,
  name,
  questions,
  requiresConfirmation,
  qualificationStatus,
  disqualifiedMessage = "",
  percentageComplete,
  hasDefaultChoices,
  chartNoteHelperType,
  nextPagePath,
  isPayorEnhanced = false,
  optumError,
  isSupplier = false,
}) => {
  const isDisqualified = ["disqualified", "not_recommended"].includes(
    qualificationStatus
  )
  const isQualified = ["qualified", "recommended"].includes(qualificationStatus)
  const isComplete = isDisqualified || isQualified

  const PayorEnhancedSupplierQualified =
    isPayorEnhanced && isSupplier && isQualified
  const PayorEnhancedSupplierDisqualified =
    isPayorEnhanced && isSupplier && isDisqualified
  const PayorEnhancedNotSupplierQualified =
    isPayorEnhanced && !isSupplier && isQualified
  const PayorEnhancedNotSupplierDisqualified =
    isPayorEnhanced && !isSupplier && isDisqualified
  const GenericQualified = !isPayorEnhanced && isQualified
  const GenericDisqualified = !isPayorEnhanced && isDisqualified

  const questionnaireStatus = () => {
    switch (true) {
      case PayorEnhancedSupplierQualified:
        return {
          title: "Expect your auth to be quickly approved",
          messageHtml: `${SUPPLIER_CRITERIA_MET}<br /><br /><em>${LEGAL_WARNING}</em>`,
          variant: "success",
        }
      case PayorEnhancedSupplierDisqualified:
        return {
          title: "Health plan criteria has not been met",
          messageHtml: `${SUPPLIER_CRITERIA_NOT_MET}<br /><br /><em>${LEGAL_WARNING}</em>`,
          variant: "warning",
        }
      case PayorEnhancedNotSupplierQualified:
        return {
          title: "Health plan criteria has been met",
          messageHtml: `${CRITERIA_MET}<br /><br /><em>${LEGAL_WARNING}</em>`,
          variant: "success",
        }
      case PayorEnhancedNotSupplierDisqualified:
        return {
          title: "Health plan criteria has not been met",
          messageHtml: `${CRITERIA_NOT_MET}<br /><br /><em>${LEGAL_WARNING}</em>`,
          variant: "warning",
        }
      case GenericQualified:
        return {
          title: "",
          messageHtml: `<strong>Documentation completed.</strong><br /><br /><em>${LEGAL_WARNING}</em>`,
          variant: "success",
        }
      case GenericDisqualified:
        return {
          title: "",
          messageHtml: `<strong>Documentation not supported.</strong> ${disqualifiedMessage}<br /><br /><em>${LEGAL_WARNING}</em>`,
          variant: "error",
        }
    }
  }

  const renderNotice = (notice) => {
    if (!isComplete) {
      return null
    }

    return (
      <CanopyNotice
        title={notice.title}
        // @ts-ignore
        message={
          <span
            dangerouslySetInnerHTML={{
              __html: `${notice.messageHtml}`,
            }}
          />
        }
        // @ts-ignore
        variant={notice.variant}
      ></CanopyNotice>
    )
  }

  const handleGoToMissingInformation = () => {
    const error = document.querySelector(".form-group .has-error")

    if (error) {
      error.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })

      setTimeout(
        () =>
          (error.querySelector(
            "input[name='answerValue'], textarea[name='answerValue']"
          ) as HTMLElement).focus(),
        1000
      )
    }
  }

  const missingRequiredInformation =
    disqualifiedMessage === "Missing required information"

  const errorFetchingFirstQuestion = optumError && questions.length === 0
  return (
    <div className={cx("wide-fixed-row")}>
      <WorkflowHeader title={name} />
      {errorFetchingFirstQuestion && (
        <div className="well clearfix">
          <ErrorMessageContainer />
        </div>
      )}
      {!!questions.length && (
        <>
          <ChartNoteHelperAlert
            type={chartNoteHelperType}
            hasDefaultChoices={hasDefaultChoices}
            isPayorEnhanced={isPayorEnhanced}
          />
          <div className="well clearfix">
            <Survey
              questions={questions}
              answerQuestion={answerQuestion}
              showMostCommon={
                chartNoteHelperType === ChartNoteHelperType.Common
              }
              missingRequiredInformation={missingRequiredInformation}
            />
            {!isComplete && percentageComplete !== undefined && (
              <div className="float-right">
                <ProgressDonut percentage={percentageComplete} />
              </div>
            )}
            {optumError && <ErrorMessageContainer />}
            <ConfirmButton
              requiresConfirmation={requiresConfirmation}
              confirmPrefilledAnswers={confirmPrefilledAnswers}
            />
          </div>
        </>
      )}

      <div className="well-wide">
        <div style={{ marginBottom: canopySpace8X }}>
          {renderNotice(questionnaireStatus())}
        </div>
        <div className="row">
          <div className="col-md-6 col-12 canopy-mbe-12x order-md-6">
            <div className="text-right">
              <ContinueButton
                disabled={isPayorEnhanced ? !isComplete : !nextPagePath}
                path={nextPagePath}
              />
            </div>
          </div>
          <div className="col-md-6 col-12">
            {!!isComplete &&
              (missingRequiredInformation ? (
                <Button
                  className="btn btn-brand-o btn-xs-block"
                  onClick={handleGoToMissingInformation}
                >
                  Go to missing information
                </Button>
              ) : (
                <Link
                  className="btn btn-brand-o btn-xs-block"
                  to={routes.clinicalPath()}
                >
                  Back To Clinical Info
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InternalChartNotes
