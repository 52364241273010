import React, { useState, useCallback, useEffect } from "react"
import { useHistory } from "react-router-dom"

import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { canopyColorTextSecondary } from "./index.module.scss"
import { actionColumnPadding } from "../../../index.module.scss"
import { ExternalPatient, ParachutePatient, searchPatients } from "./api"
import { usePatientQuery } from "../../../queries"
import { ExternalPatientCard, ParachutePatientCard } from "./PatientCard"
import { ROUTES } from "../../../routes"
import { PatientDemographicsForm } from "../PatientDemographicsForm"
import { usePatientMutation } from "../usePatientMutation"
import { Patient } from "applications/SupplierOrganizationInboxDocument/sharedTypes"
import {
  CategoryEditorProps,
  CsrInboxStateCategoryEditor,
} from "applications/CsrInboxStateCategoryEditor"

type PatientSearchProps = {
  supplier: {
    id: string
    supplierSystemInstanceId?: string
  }
  supplierOrganizationId: string
  csrInboxStateId: string
} & Pick<CategoryEditorProps, "initialSelectedCategories" | "categories">

export const PatientSearch = (props: PatientSearchProps) => {
  const history = useHistory()
  const { data: patient } = usePatientQuery()
  const [formData, setFormData] = useState(
    patient || {
      dateOfBirth: "",
      firstName: "",
      lastName: "",
    }
  )
  const [searchHasRun, setSearchHasRun] = useState(false)
  const [parachutePatients, setParachutePatients] = useState<
    ParachutePatient[]
  >([])
  const [externalPatients, setExternalPatients] = useState<ExternalPatient[]>(
    []
  )

  const {
    mutateAsync: updatePatientAndSearch,
    isLoading,
    errors,
  } = usePatientMutation({
    supplierOrganizationId: props.supplierOrganizationId,
    csrInboxStateId: props.csrInboxStateId,
    onSuccess: async (updatedPatient: Patient) => {
      const response = await searchPatients(
        {
          dateOfBirth: updatedPatient.dateOfBirth,
          firstName: updatedPatient.firstName,
          lastName: updatedPatient.lastName,
          sex: updatedPatient.sex,
          supplierId: props.supplier.id,
          supplierSystemInstanceId: props.supplier.supplierSystemInstanceId,
        },
        props.supplierOrganizationId
      )
      setSearchHasRun(true)
      setParachutePatients(response.parachute)
      setExternalPatients(response.external)
    },
  })

  const handleInputChange = useCallback((name: string, value: string) => {
    setFormData((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleSearch = () => {
    updatePatientAndSearch({
      dateOfBirth: formData.dateOfBirth,
      firstName: formData.firstName,
      lastName: formData.lastName,
      sex: formData.sex,
    })
  }

  const handleNewPatient = () => {
    history.push(ROUTES.NEW_PATIENT)
  }

  const autoSearchFieldsFilled =
    !!formData.dateOfBirth && !!formData.firstName && !!formData.lastName

  useEffect(() => {
    if (autoSearchFieldsFilled) {
      handleSearch()
    }
    // Ignoring deps warning because this only should run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={actionColumnPadding}>
      <div className="canopy-mb-10x">
        <CsrInboxStateCategoryEditor
          csrInboxStateId={props.csrInboxStateId}
          supplierOrganizationId={props.supplierOrganizationId}
          initialSelectedCategories={props.initialSelectedCategories}
          categories={props.categories}
        />
      </div>

      <h2 className="canopy-typography-heading-xlarge">Patient search</h2>
      <p
        className={`canopy-typography-body-medium ${canopyColorTextSecondary}`}
      >
        Enter the information below to search for a matching patient record.
      </p>

      <PatientDemographicsForm
        formData={formData}
        onSubmit={handleSearch}
        onInputChange={handleInputChange}
        isLoading={isLoading}
        submitButtonText="Search"
        errors={errors}
      />

      {searchHasRun && (
        <p
          className={`canopy-typography-body-medium ${canopyColorTextSecondary}`}
        >
          Select a patient to view their order history, or create a new one if
          not listed.
        </p>
      )}
      <div aria-live="polite">
        {searchHasRun && (
          <CanopyFlex alignItems="center" justifyContent="space-between">
            <h2 className="canopy-typography-heading-medium canopy-mt-10x">
              {`Results (${
                parachutePatients.length + externalPatients.length
              })`}
            </h2>
            <CanopyButton
              variant="secondary"
              iconStart="plus"
              type="button"
              onClick={handleNewPatient}
            >
              New patient
            </CanopyButton>
          </CanopyFlex>
        )}
      </div>

      {searchHasRun && (
        <div role="list" aria-label="Patient search results">
          {parachutePatients.map((p) => (
            <ParachutePatientCard
              key={`mpid:${p.masterPatientId}`}
              patient={p}
              supplierOrganizationId={props.supplierOrganizationId}
              csrInboxStateId={props.csrInboxStateId}
            />
          ))}

          {externalPatients.map((p) => (
            <ExternalPatientCard
              key={`supplierSystemPatientKey:${p.supplierSystemPatientKey}`}
              patient={p}
              supplierOrganizationId={props.supplierOrganizationId}
              csrInboxStateId={props.csrInboxStateId}
              supplierId={props.supplier.id}
            />
          ))}
        </div>
      )}
    </div>
  )
}
