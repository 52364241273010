import React from "react"
import LoadMoreLink from "../../../components/LoadMoreLink"
import { documentationOrderTableColumnDefinitions } from "../documentationOrderTableColumnDefinitions"
import Table from "../../SupplierOrganizationDashboard/components/Table"
import TableOverviewPresentation from "../../../components/TableOverviewPresentation"
import { OrderStatusFilter } from "../OrderStatusFilter"
import { CreationMethodFilter } from "../CreationMethodFilter"
import { CliniciansSelectFilter } from "../CliniciansSelectFilter"
import SearchFilter from "../SearchFilter"
import { useOrderFetcher } from "../fetchOrders"
import { OrderCancellationProvider } from "../CancelButton"
import { DownloadReportButton } from "./DownloadReportButton"
import { SupplierSelectFilter } from "../SupplierSelectFilter"
import { PatientActivityFilter } from "../PatientActivityFilter"
import { PatientLastAppointmentFilter } from "../PatientLastAppointmentFilter"
import withInitialData from "../../../components/withInitialData"
import { fetchInitialFilters, InitialData } from "./fetchInitialFilters"
import DateRangeFilter from "components/input/DateRangeFilter"
import { LastNotificationFilter } from "../LastNotificationFilter"
import { Record } from "../../../components/SelectFilter"
import { ToastProvider } from "../../../components/Toaster"

const sortingByText = (sortValue, tableColumnDefinitions) => {
  if (sortValue) {
    const tableColumn = tableColumnDefinitions.find(
      (tc) => tc.value === sortValue
    )
    return tableColumn?.title
  }
}

interface Props {
  supplierOrganizationId: string
  initialData?: InitialData
  notificationMethodOptions: Record[]
}

const DashboardTabPanel = (props: Props) => {
  const {
    updateSearchParameters,
    orders,
    searchParameters,
    totalCount,
    loadMore,
    loading,
    removeOrder,
    filters: { suppliers },
    patientActivityEnabled,
  } = useOrderFetcher(
    {
      supplierOrganizationId: props.supplierOrganizationId,
      suppliers: props.initialData?.filters.suppliers,
    },
    !!props.initialData?.patientActivityEnabled
  )
  const tableColumnDefinitions = documentationOrderTableColumnDefinitions(
    patientActivityEnabled
  )

  return (
    <ToastProvider>
      <section role="tabpanel">
        <SearchFilter
          value={searchParameters.searchText}
          updateSearchParameters={updateSearchParameters}
        />
        <OrderStatusFilter updateSearchParameters={updateSearchParameters} />
        <CliniciansSelectFilter
          clinicians={searchParameters.clinicians}
          updateSearchParameters={updateSearchParameters}
        />
        <SupplierSelectFilter
          selectedSupplierIds={suppliers}
          supplierOrganizationId={props.supplierOrganizationId}
          onChange={updateSearchParameters}
        />
        {patientActivityEnabled && (
          <>
            <PatientActivityFilter
              onChange={updateSearchParameters}
              patientActivity={searchParameters.patientActivity}
            />
            <PatientLastAppointmentFilter
              onChange={updateSearchParameters}
              patientLastAppointment={searchParameters.patientLastAppointment}
            />
          </>
        )}
        <DateRangeFilter
          key="createdAtFilter"
          name="createdAt"
          title="Date Created"
          onChange={updateSearchParameters}
          initialStartDate={searchParameters.createdAtStartAt}
          initialEndDate={searchParameters.createdAtEndAt}
          minDateLabel="Start Date"
          maxDateLabel="End Date"
        />
        <DateRangeFilter
          key="dateReturnedFilter"
          name="dateReturned"
          title="Date Returned"
          onChange={updateSearchParameters}
          initialStartDate={searchParameters.dateReturnedStartAt}
          initialEndDate={searchParameters.dateReturnedEndAt}
          minDateLabel="Start Date"
          maxDateLabel="End Date"
        />
        <LastNotificationFilter
          onChange={updateSearchParameters}
          options={props.notificationMethodOptions}
        />
        <CreationMethodFilter updateSearchParameters={updateSearchParameters} />
        <DownloadReportButton
          supplierOrganizationId={props.supplierOrganizationId}
          searchText={searchParameters.searchText}
          createdAtStartAt={searchParameters.createdAtStartAt}
          createdAtEndAt={searchParameters.createdAtEndAt}
          clinicians={searchParameters.clinicians}
          suppliers={searchParameters.suppliers}
          signatureStatuses={searchParameters.signatureStatuses}
          lastNotificationMethod={searchParameters.lastNotificationMethod}
          patientActivity={searchParameters.patientActivity}
        />
        <TableOverviewPresentation
          results={`${loading ? "x" : totalCount} orders`}
          sortingBy={sortingByText(
            searchParameters.sortColumn,
            tableColumnDefinitions
          )}
        />
        <OrderCancellationProvider
          onOrderCancelled={(orderId) => removeOrder(orderId)}
        >
          <Table
            tableColumns={tableColumnDefinitions}
            records={orders.map((o) => ({ ...o, key: o.id }))}
            onSortChange={async ({ sort, direction }) =>
              updateSearchParameters({
                sortColumn: sort,
                sortDirection: direction,
              })
            }
            {...searchParameters}
          />
        </OrderCancellationProvider>
        <LoadMoreLink
          loading={loading}
          count={orders.length}
          totalCount={totalCount}
          onClick={async () => await loadMore()}
        />
      </section>
    </ToastProvider>
  )
}

const fetchInitialData = async (args: Omit<Props, "initialData">) => {
  return fetchInitialFilters(args.supplierOrganizationId).then(
    (response) => response
  )
}
export default withInitialData<InitialData, Props>(fetchInitialData)(
  DashboardTabPanel
)
