import React, { useContext } from "react"
import CmsDrawer from "../../../../components/CmsDrawer"
import {
  CatalogCustomAttribute,
  CatalogCustomAttributeOption,
} from "../../../../types/sharedTypes"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import { StaticFieldDefinition } from "../../../../types/form/sharedTypes"
import { updateAttributeOption } from "../../../../api/attributeOptions"
import Form from "../../../../../../components/form/Form"
import FormSegment from "../../../../components/Formik/FormSegment"
import { CanopyButton } from "@parachutehealth/canopy-button"
import classNames from "classnames"
import * as styles from "../EditAttributeOptionDrawer/index.module.scss"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import get from "lodash/get"
import FreesoloCombobox from "../../../../components/FreesoloCombobox"
import sortedUniqNumbers from "utilities/array/sortedUniqNumbers"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import isEmpty from "lodash/isEmpty"

type Props = {
  customAttribute: CatalogCustomAttribute
  customAttributeOption: CatalogCustomAttributeOption
  open?: boolean
  onSave?: (attribute: CatalogCustomAttributeOption) => void
  onClose: () => void
}

const EditAttributeOptionDrawer: React.FC<Props> = ({
  customAttribute,
  customAttributeOption,
  open = false,
  onClose,
  onSave,
}: Props): React.JSX.Element => {
  const { showNotice } = useContext(NoticeContext)

  const textFields: StaticFieldDefinition[] = [
    {
      label: "Attribute Value",
      name: "description",
      type: "text",
      required: true,
    },
  ]

  const handleSubmit = async (
    params: Record<string, any>,
    { setErrors }: any
  ) => {
    const response = await updateAttributeOption(
      customAttribute.externalId,
      customAttributeOption.externalId,
      params
    )
    if (response.errors) {
      setErrors(response.errors)
    } else {
      onClose()
      if (onSave) onSave(response.customAttributeOption)
      showNotice(
        `${response.customAttributeOption.description} has been updated`,
        "success"
      )
    }
  }

  const labelsToOptions = (
    items: { id: number; label: string }[]
  ): OptionItem[] => {
    return items.map((unit) => ({
      value: unit.id.toString(),
      label: unit.label,
    }))
  }

  const packagingLabelOptions = labelsToOptions(
    customAttribute.packagingLabels || []
  )

  const unitOptions = labelsToOptions(customAttribute.units || [])

  const optionValues =
    customAttribute.options?.filter((o) => o.value)?.map((o) => o.value!) || []

  // we can now have multiple existing options with the same value but different units/packaging, and we don't want
  // multiple copies of the same option showing up
  const uniqueValues: string[] = sortedUniqNumbers(optionValues)

  const catalogPackagingLabelKey = "catalogPackagingLabelId"
  const catalogUnitKey = "catalogUnitId"

  const getAttributeOptionFormValues = () => {
    if (customAttribute.valueType === "number") {
      return {
        catalogPackagingLabelId:
          customAttributeOption.packagingLabel?.id.toString() || "",
        value: customAttributeOption.value || "",
        catalogUnitId: customAttributeOption.unit?.id.toString() || "",
      }
    }

    return {
      description: customAttributeOption.description || "",
    }
  }

  const initialValues = getAttributeOptionFormValues()

  return (
    <>
      <CmsDrawer open={open} title="Edit Attribute value" onClose={onClose}>
        <Form initialValues={initialValues} onSubmit={handleSubmit} hideErrors>
          {({ isSubmitting, values, errors, setFieldValue }) => (
            <>
              {customAttribute.valueType === "number" ? (
                <div className={classNames(styles.formBody)}>
                  <CanopyComboboxField
                    onChange={(newValue) =>
                      setFieldValue(
                        catalogPackagingLabelKey,
                        get(newValue, "value")
                      )
                    }
                    options={packagingLabelOptions}
                    disabled={isEmpty(packagingLabelOptions)}
                    multiple={false}
                    size="small"
                    placeholder={
                      isEmpty(packagingLabelOptions) ? "N/A" : undefined
                    }
                    value={get(values, catalogPackagingLabelKey)}
                    label="Packaging"
                    feedbackMessage={errors[catalogPackagingLabelKey]}
                  />
                  <FreesoloCombobox
                    label="Value"
                    required={true}
                    feedbackMessage={errors["value"]}
                    defaultValue={get(values, "value")}
                    options={uniqueValues}
                    onChange={(newValue) =>
                      setFieldValue("value", newValue?.value)
                    }
                  />
                  <CanopyComboboxField
                    data-testid={catalogUnitKey}
                    onChange={(newValue) =>
                      setFieldValue(catalogUnitKey, get(newValue, "value"))
                    }
                    options={unitOptions}
                    disabled={isEmpty(unitOptions)}
                    multiple={false}
                    size="small"
                    value={get(values, catalogUnitKey)}
                    label="Unit of measure"
                    placeholder={isEmpty(unitOptions) ? "N/A" : undefined}
                    feedbackMessage={errors[catalogUnitKey]}
                  />
                </div>
              ) : (
                <FormSegment>{textFields}</FormSegment>
              )}
              {errors.base && (
                <CanopyNotice
                  variant="error"
                  title={errors.base}
                  className="canopy-mbe-10x"
                />
              )}
              <CanopyNotice
                variant="warning"
                title="Updating this value will change all custom Attributes currently in use"
                className="canopy-mbe-10x"
              />
              <CanopyButton
                size="small"
                variant="primary"
                type="submit"
                loading={isSubmitting}
              >
                Save
              </CanopyButton>
              <CanopyButton
                className="canopy-mis-4x"
                size="small"
                variant="tertiary"
                disabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </CanopyButton>
            </>
          )}
        </Form>
      </CmsDrawer>
    </>
  )
}

export default EditAttributeOptionDrawer
