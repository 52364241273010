import React, { useState } from "react"
import * as styles from "../index.module.scss"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import ClinicalFacilityProfileProgress from "./ClinicalFacilityProfileProgress"
import { skipInviteUsers } from "../api"
import { User, ClinicalFacilityProfile } from ".."
import UsersTable from "./UsersTable"

type Props = {
  exit: () => void
  next: () => void
  back: () => void
  clinicalFacility: ClinicalFacilityProfile
  users: User[]
}

const ClinicalFacilityProfileConfirmUsers = (props: Props) => {
  const { exit, next, back, users, clinicalFacility } = props
  const [inviteMoreUsers, setInviteMoreUsers] = useState<string>("true")

  const onContinue = async () => {
    if (inviteMoreUsers === "true") {
      next()
    } else {
      await skipInviteUsers()
      exit()
    }
  }

  const yesLabelText =
    users.length > 1 ? "Yes, invite more users" : "Yes, there are other users"

  const noLabelText =
    users.length > 1 ? "No, this is everyone" : "No, there are no other users"

  return (
    <div className={styles.profileWorkflowStepContainer}>
      <ClinicalFacilityProfileProgress step={4} />
      <div
        className={styles.formContainer}
        data-testid="facility-confirm-users-form"
      >
        <h1 className={styles.header}>
          Does anyone else at {clinicalFacility.name} manage DME orders?
        </h1>
        <p>
          Invite nurses, case managers, or social workers to streamline order
          management.{" "}
          {users.length > 1 &&
            "You currently have " + users.length + " users on Parachute."}
        </p>

        <UsersTable users={users} />

        <CanopyRadioInputField
          label=""
          name="confirmUsers"
          className={`${styles.formContainer} canopy-mt-16x`}
          value={inviteMoreUsers}
          onChange={(e) => setInviteMoreUsers(e.target.value)}
          options={[
            { label: yesLabelText, value: "true" },
            { label: noLabelText, value: "false" },
          ]}
        />
        <div className={styles.buttonContainer}>
          <CanopyButton variant="secondary" onClick={back} type="button">
            Back
          </CanopyButton>
          <CanopyButton onClick={onContinue} type="button">
            Continue
          </CanopyButton>
        </div>
      </div>
    </div>
  )
}

export default ClinicalFacilityProfileConfirmUsers
