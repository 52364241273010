import React from "react"
import { CanopyFlex } from "@parachutehealth/canopy-flex"

const SaveStatusContainer = ({ children }: { children: React.ReactNode }) => (
  <CanopyFlex direction="row" alignItems="center">
    {children}
  </CanopyFlex>
)

const SaveStatusText = ({
  children,
  color,
}: {
  children: React.ReactNode
  color?: string
}) => (
  <span className="canopy-typography-body-small" style={{ color }}>
    {children}
  </span>
)

export const SaveStatusIndicatorComponents = {
  Container: SaveStatusContainer,
  Text: SaveStatusText,
}
