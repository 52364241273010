import React, { PropsWithChildren } from "react"
import PayorSidebar from "../../layouts/PayorSidebar/PayorSidebar"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import * as styles from "./PayorDefaultLayout.module.scss"

const PayorDefaultLayout = ({
  children,
}: PropsWithChildren<{}>): React.JSX.Element => (
  <CanopyFlex className="mt-n3">
    <PayorSidebar />
    <div className={styles.main}>{children}</div>
  </CanopyFlex>
)

export default PayorDefaultLayout
