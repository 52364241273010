import React, { useState } from "react"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import * as styles from "../index.module.scss"
import { PatientEmergencyContact } from "../../../sharedTypes"
import { useIsMutating } from "@tanstack/react-query"
import { updatePatientEmergencyContact } from "./api"
import { CanopyTextInputTypeValues } from "@parachutehealth/canopy-text-input"
import { onBlurHelper, useGenericUpdate } from "../helpers"
import { SubSectionHeader } from "../SectionHeader"

const updatePatientEmergencyContactMutationKey = [
  "updatePatientEmergencyContact",
]

const NonCanopyConflictNamedTextField = ({
  patientEmergencyContact,
  label,
  fieldName,
  placeholder,
  type,
}: {
  patientEmergencyContact: PatientEmergencyContact
  fieldName: keyof PatientEmergencyContact
  label: string
  placeholder: string
  type?: CanopyTextInputTypeValues
}) => {
  const numberOfMutations = useIsMutating({
    mutationKey: updatePatientEmergencyContactMutationKey,
  })

  const [error, setError] = useState<string | undefined>(undefined)
  const [value, setValue] = useState(patientEmergencyContact[fieldName])
  const mutateEmergencyContact = useGenericUpdate(
    fieldName,
    updatePatientEmergencyContact,
    updatePatientEmergencyContactMutationKey
  )

  const onBlur = onBlurHelper(
    fieldName,
    value,
    mutateEmergencyContact,
    setError,
    setValue
  )

  const val = patientEmergencyContact[fieldName]
  const formattedValue =
    fieldName === "phoneNumber" && val
      ? [val.slice(0, 3), val.slice(3, 6), val.slice(6)].join("-")
      : val

  return (
    <CanopyTextInputField
      label={label}
      defaultValue={formattedValue}
      onBlur={onBlur}
      feedbackMessage={error}
      placeholder={placeholder}
      readOnly={mutateEmergencyContact.isLoading || numberOfMutations > 0}
      type={type}
    />
  )
}

export const EmergencyContactInformation = ({
  patientEmergencyContact,
}: {
  patientEmergencyContact: PatientEmergencyContact
}) => {
  return (
    <>
      <SubSectionHeader text="Emergency contact" />
      <div className={styles.dataEntryLineContainer}>
        <NonCanopyConflictNamedTextField
          patientEmergencyContact={patientEmergencyContact}
          label="First name"
          fieldName="firstName"
          placeholder="Enter first name"
        />
        <NonCanopyConflictNamedTextField
          patientEmergencyContact={patientEmergencyContact}
          label="Last name"
          fieldName="lastName"
          placeholder="Enter last name"
        />
      </div>
      <div className={styles.dataEntryLineContainer}>
        <NonCanopyConflictNamedTextField
          patientEmergencyContact={patientEmergencyContact}
          fieldName="phoneNumber"
          label="Phone number"
          placeholder="(000) 000-0000"
          type="tel"
        />
        <NonCanopyConflictNamedTextField
          patientEmergencyContact={patientEmergencyContact}
          fieldName="relationship"
          label="Relationship"
          placeholder="Relationship to patient"
        />
      </div>
    </>
  )
}
