import { get, put } from "services/api"
import { searchSkusUrl, updateCartItemsUrl } from "../../../urls"

export type Sku = {
  id: string
  description: string
  hcpcs: string
}

export type CartItem = {
  skuId: string
  quantity: number
  description: string
}

export const searchSkus = async (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  searchTerm: string
): Promise<Sku[]> => {
  return get(searchSkusUrl(supplierOrganizationId, csrInboxStateId), {
    searchTerm: searchTerm,
  })
    .then((res) => {
      return (
        res.data?.map((sku) => ({
          id: sku.id,
          description: sku.description,
          hcpcs: sku.hcpcs,
        })) || []
      )
    })
    .catch(() => [])
}

export const updateCartItems = async (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  cartItems: Pick<CartItem, "skuId">[]
): Promise<{ success?: boolean; errors?: Record<string, string[]> }> => {
  return put(updateCartItemsUrl(supplierOrganizationId, csrInboxStateId), {
    cart_items: cartItems,
  })
    .then(() => ({ success: true }))
    .catch((error) => ({
      errors: error.response?.data?.errors || {
        base: ["Failed to update cart items"],
      },
    }))
}
