import React from "react"
import * as styles from "./BucketCard.module.scss"
import { CanopyText } from "@parachutehealth/canopy-text"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyHeading } from "@parachutehealth/canopy-heading"
import classNames from "classnames"

type Props = {
  count?: number
  variant: "discovery" | "warning"
  subtitle: string
  title: string
}

const BucketCard = ({ count, subtitle, title, variant }: Props) => {
  return (
    <CanopyFlex
      inline
      direction="column"
      className={classNames([styles.container, "canopy-mt-12x"])}
    >
      <span className={classNames([styles.topBorder, styles[variant]])} />
      <CanopyFlex
        className={styles.cardContent}
        padding="8X"
        inline
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <CanopyFlex direction="column" justifyContent="flex-start" gap="0">
          <CanopyText variant="primary" size="medium" weight="bold">
            {title}
          </CanopyText>
          <CanopyText variant="secondary" size="xSmall">
            {subtitle}
          </CanopyText>
        </CanopyFlex>
        <CanopyHeading level={6} size="2xLarge">
          {String(count || 0)}
        </CanopyHeading>
      </CanopyFlex>
    </CanopyFlex>
  )
}

export { BucketCard }
