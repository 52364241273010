// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-Workflow-containers-SupplierList-YourOrganizationsSuppliersHeader-index-module__arrow-icon--W5d3x";
var _2 = "app-javascript-applications-Workflow-containers-SupplierList-YourOrganizationsSuppliersHeader-index-module__arrow-with-word--uIjjY";
var _3 = "app-javascript-applications-Workflow-containers-SupplierList-YourOrganizationsSuppliersHeader-index-module__catalog-word--KR2fb";
var _4 = "app-javascript-applications-Workflow-containers-SupplierList-YourOrganizationsSuppliersHeader-index-module__header--Wm7WG";
var _5 = "app-javascript-applications-Workflow-containers-SupplierList-YourOrganizationsSuppliersHeader-index-module__header-top-row--qrYCX";
var _6 = "app-javascript-applications-Workflow-containers-SupplierList-YourOrganizationsSuppliersHeader-index-module__manage-suppliers-container--Mu7xZ";
var _7 = "app-javascript-applications-Workflow-containers-SupplierList-YourOrganizationsSuppliersHeader-index-module__manage-suppliers-label--DEM6x";
var _8 = "app-javascript-applications-Workflow-containers-SupplierList-YourOrganizationsSuppliersHeader-index-module__subtitle--fABLw";
export { _1 as "arrowIcon", _2 as "arrowWithWord", _3 as "catalogWord", _4 as "header", _5 as "headerTopRow", _6 as "manageSuppliersContainer", _7 as "manageSuppliersLabel", _8 as "subtitle" }
