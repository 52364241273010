// @team @demand-ordering
import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { DmeOrder, ApplicationError } from "sharedTypes"
import { handleError } from "utilities/error"
import * as api from "applications/Workflow/containers/Review/api"
import { navigateToNewTab } from "utilities/navigation"

type Props = {
  dmeOrder: DmeOrder
}

const DuplicateOrderButton = ({ dmeOrder }: Props) => {
  const [loading, setLoading] = useState(false)

  const duplicateOrder = async () => {
    try {
      setLoading(true)
      const response = await api.duplicateOrder(dmeOrder.id)
      navigateToNewTab(response.data.redirectUrl)
    } catch (error) {
      handleError(error as ApplicationError)
    } finally {
      setLoading(false)
    }
  }

  return (
    <CanopyButton
      className="btn btn-block canopy-mt-8x"
      onClick={duplicateOrder}
      loading={loading}
    >
      Duplicate order
    </CanopyButton>
  )
}

export default DuplicateOrderButton
