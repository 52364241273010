import React from "react"
import { CanopyHeading } from "@parachutehealth/canopy-heading"
import { CanopyFlex } from "@parachutehealth/canopy-flex"

const countWithLimit = (count: number, limit: number) => {
  if (count > limit) {
    return `${limit}+`
  }
  return count
}

function DashboardHeader({ title, count, limit }) {
  return (
    <CanopyFlex
      alignItems="center"
      justifyContent="space-between"
      className="canopy-mbe-4x"
    >
      <CanopyHeading size="large" level={3}>
        {title}
      </CanopyHeading>
      <div className="badge canopy-mbe-0">{countWithLimit(count, limit)}</div>
    </CanopyFlex>
  )
}

export default DashboardHeader
