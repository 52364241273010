import React from "react"
import { useEffectRunOnEveryRender, useGlobalContext } from "hooks"
import { followOrder, unfollowOrder, updatePatient } from "./api"
import {
  DmeOrder,
  DmeOrderPermissions,
  EmployerType,
  History,
  Page,
} from "sharedTypes"
import { SidebarTab } from "./sharedTypes"
import { inOrderNavReplacement } from "applications/Workflow/utilities/utilityNav"
import InternalNavigation from "./components/InternalNavigation/InternalNavigation"
import { handleError } from "utilities/error"
import {
  snoozeOrder,
  unsnoozeOrder,
  updateFollowers,
} from "components/ClinicalModals/api"

type Props = {
  nextPagePath: string
  pages: Page[]
  permissions: DmeOrderPermissions
  history: History
  dmeOrder: DmeOrder
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
  currentTab: SidebarTab
  setSidebarTab(tab: SidebarTab): void
  isSidebarOpen: boolean
}

function Navigation({
  nextPagePath,
  dmeOrder,
  setDmeOrder,
  pages,
  permissions,
  history,
  currentTab,
  setSidebarTab,
  isSidebarOpen,
}: Props) {
  useEffectRunOnEveryRender(() => {
    document.body.classList.add("has-mobile-nav")
  })

  const context = useGlobalContext()

  const { currentEmployer } = context

  const patientLinkDisabled =
    (currentEmployer.employerType === EmployerType.Supplier &&
      currentEmployer.employerId !== dmeOrder.supplier?.externalId) ||
    currentEmployer.employerType === EmployerType.InsurancePayor

  const isFacilityUser =
    currentEmployer.employerType === EmployerType.ClinicalFacility

  const toggle = () => {
    document.body.classList.toggle("mobile-nav-open")
  }

  const close = () => {
    document.body.classList.remove("mobile-nav-open")
  }

  const setPatient = (id, params) => {
    return updatePatient(id, params).then((response) => {
      setDmeOrder(response.data)
    })
  }

  const setFollowers = (params, orderId) => {
    return updateFollowers(params, orderId).then((response) => {
      setDmeOrder(response.data)
    })
  }

  const followDmeOrder = () => {
    return followOrder().then((response) => {
      setDmeOrder(response.data)
    })
  }

  const unfollowDmeOrder = (followingId: string) => {
    return unfollowOrder(followingId).then((response) => {
      setDmeOrder(response.data)
    })
  }

  const snoozeDmeOrder = (params) => {
    return snoozeOrder(params)
      .then((response) => {
        setDmeOrder(response.data)
      })
      .catch((error) => handleError(error))
  }

  const unsnoozeDmeOrder = () => {
    return unsnoozeOrder()
      .then((response) => {
        setDmeOrder(response.data)
      })
      .catch((error) => handleError(error))
  }

  return (
    <InternalNavigation
      patientLinkDisabled={patientLinkDisabled}
      nextPagePath={nextPagePath}
      dmeOrder={dmeOrder}
      history={history}
      pages={pages}
      permissions={permissions}
      updatePatient={setPatient}
      updateFollowers={setFollowers}
      toggle={toggle}
      close={close}
      inOrderNavReplacement={inOrderNavReplacement(context)}
      currentTab={currentTab}
      setSidebarTab={setSidebarTab}
      isFacilityUser={isFacilityUser}
      followDmeOrder={followDmeOrder}
      unfollowDmeOrder={unfollowDmeOrder}
      snoozeDmeOrder={snoozeDmeOrder}
      unsnoozeDmeOrder={unsnoozeDmeOrder}
      isSidebarOpen={isSidebarOpen}
    />
  )
}

export default Navigation
