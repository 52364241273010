import React from "react"
import { pluralize } from "utilities/string"
import { LineItem } from "sharedTypes"

interface LineItemGroup {
  lineItems: LineItem[]
  quantity: number
}

export const formatItemQuantity = (lineItemGroup: LineItemGroup) => {
  if (lineItemGroup.lineItems.length !== 1) {
    return lineItemGroup.quantity
  }

  const lineItem = lineItemGroup.lineItems[0]
  const { totalQuantityWithUtilization, utilizationUnitLabel } = lineItem

  if (!(totalQuantityWithUtilization && utilizationUnitLabel)) {
    return lineItemGroup.quantity
  }

  return `${totalQuantityWithUtilization} ${lineItem.utilizationUnitLabel} (${
    lineItemGroup.quantity
  } ${pluralize("pack", lineItemGroup.quantity)})`
}

export const formatSkuDescription = (skuDescription) => {
  const [name, ...accessories] = skuDescription.split(",")
  if (accessories.length > 0) {
    return (
      <span>
        <strong>{name}, </strong>
        {accessories.join(", ")}
      </span>
    )
  }
  return <strong>{name}</strong>
}

export const packagesForFulfillment = (dmeOrder, fulfillment) => {
  return dmeOrder.lineItemGroups
    .filter((lig) => lig.dmeOrderFulfillmentId === fulfillment.id)
    .map((lig) => lig.displayName)
    .join(", ")
}

export const buildDeliverySectionCardId = (index) => {
  return `delivery-${index + 1}-card`
}
