import React from "react"
import { AttachButton } from "../AttachButton"
import { attachToExternalOrder } from "./api"
import { inboxDashboardUrl } from "../../../urls"

type AttachButtonProps = {
  supplierOrganizationId: string
  supplierId: string
  csrInboxStateId: string
  supplierSystemOrderId: string
}

export const AttachToExternalOrderButton: React.FC<AttachButtonProps> = ({
  supplierOrganizationId,
  supplierId,
  csrInboxStateId,
  supplierSystemOrderId,
}) => {
  return (
    <AttachButton
      onClick={() =>
        attachToExternalOrder(
          supplierOrganizationId,
          csrInboxStateId,
          supplierSystemOrderId
        )
      }
      successUrl={inboxDashboardUrl(supplierOrganizationId, supplierId)}
      ariaLabel="Attach document to external order"
    />
  )
}
