import { Box, CircularProgress } from "@material-ui/core"
import {
  GridColDef,
  GridRenderCellParams,
  DataGridPro,
  GridLinkOperator,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import DataGridToolbar from "applications/Cms/components/DataGridToolbar"
import React, { useState } from "react"
import { isTest } from "utilities/environment"
import {
  UnitsResponse,
  getAllUnits,
  getProductsForUnit,
} from "applications/Cms/api/units"
import useServerSideDataGrid, {
  ServerSideDataGridOptions,
  ServerSideDataGridParams,
} from "applications/Cms/hooks/useServerSideDataGrid"
import { CatalogProduct, CatalogUnit } from "applications/Cms/types/sharedTypes"
import UnitProductsDrawer from "../UnitProductsDrawer"

type DrawerState = { open: boolean }

const UnitsDataGrid: React.FC = (): React.JSX.Element => {
  const [unitsData, setUnitsData] = useState<UnitsResponse>({
    units: [],
    totalCount: 0,
  })

  const defaultOptions: CatalogUnitOptions = {
    page: 1,
    sort: undefined,
  }

  const gridApi = useGridApiRef()

  const [loading, setLoading] = useState<boolean>(false)

  const fetchFunction = async (
    params: ServerSideDataGridParams
  ): Promise<void> => {
    const data = await getAllUnits(params)

    setUnitsData((prev) => ({
      ...prev,
      units: data.units,
      totalCount: data.totalCount,
    }))
  }

  const beforeFetch = () => {
    setLoading(true)
  }
  const afterFetch = () => {
    setLoading(false)
  }

  const [loadingButton, setLoadingButton] = useState<string | null>(null)

  /**
   * The key to store the button that launched the currently-loaded state
   * is logically centralized here.
   */
  const buttonKey = (id: string): string => `${id}`

  type DrawerLaunchingCellProps = {
    params: GridRenderCellParams
    countProperty: string
  }
  const DrawerLaunchingCell = (props: DrawerLaunchingCellProps) => {
    const { params, countProperty } = props
    const count: number = params.row[countProperty] || 0
    const selectedButton = buttonKey(params.row.id)

    const disabled =
      count === 0 || Boolean(loadingButton && loadingButton !== selectedButton)

    if (disabled) {
      return <b>{count}</b>
    } else if (loadingButton === selectedButton) {
      return (
        <b>
          <CircularProgress
            title="Loading..."
            color="primary"
            style={{ width: "16px" }}
            size="small"
          />
        </b>
      )
    } else {
      return (
        <a
          href="" // needed for styling
          role="button"
          title="Click to view detail"
          onClick={(e) => {
            e.preventDefault()
            void openDrawer(params.row.id)
          }}
        >
          {count}
        </a>
      )
    }
  }

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "label",
        flex: 1,
        headerName: "Unit type",
      },
      {
        field: "productCount",
        flex: 1,
        headerName: "Products",
        type: "number",
        filterable: false,
        headerAlign: "left",
        align: "left",
        renderCell: (params: GridRenderCellParams) => (
          <DrawerLaunchingCell params={params} countProperty="productCount" />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingButton]
  )

  const [products, setProducts] = useState<CatalogProduct[] | null>(null)
  const [selectedUnit, setSelectedUnit] = useState<CatalogUnit | null>(null)
  const [drawerState, setDrawerState] = useState<DrawerState>({ open: false })

  const openDrawer = async (unitId: string) => {
    setLoadingButton(buttonKey(unitId.toString()))

    const label = gridApi.current.getRow(unitId) as CatalogUnit

    await getProductsForUnit(unitId)
      .then((response) => {
        setSelectedUnit(label)
        setProducts(response.products)
        setDrawerState({ open: true })
      })
      .finally(() => {
        setLoadingButton(null)
      })
  }

  const closeDrawer = () => {
    setProducts([])
    setSelectedUnit(null)
    setDrawerState({ open: false })
  }

  type CatalogUnitOptions = ServerSideDataGridOptions

  const {
    filterModel,
    options,
    handlePageChange,
    handleFilterModelChange,
    handleSortModelChange,
  } = useServerSideDataGrid<CatalogUnitOptions>({
    defaultFilterModel: {
      columnFilters: [],
      columnFilterLinkOperator: GridLinkOperator.And,
    },
    defaultOptions,
    columnDefinitions: columns,
    fetchFunction,
    beforeFetch,
    afterFetch,
  })

  return (
    <>
      <div>
        <div
          role="heading"
          className="canopy-typography-heading-large canopy-mbe-4x"
        >
          Units of measure
        </div>
        <div>The unit that an Attribute is comprised of.</div>
      </div>
      <Box mt={2}>
        <DataGridPro
          autoHeight
          apiRef={gridApi}
          className="borderless"
          columns={columns}
          rowCount={unitsData.totalCount}
          rows={unitsData.units}
          loading={loading}
          disableVirtualization={isTest()}
          page={options.page - 1} // account for DataGrid's zero-based indexing
          onPageChange={(page) => {
            handlePageChange(page + 1)
          }} // account for DataGrid's zero-based indexing
          hideFooterSelectedRowCount
          getRowId={(row) => row.id}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          sortingMode="server"
          sortModel={options.sort}
          onSortModelChange={handleSortModelChange}
          rowsPerPageOptions={[50]}
          pagination={true}
          paginationMode="server"
          pageSize={50}
          components={{
            Toolbar: DataGridToolbar,
          }}
          componentsProps={{
            toolbar: { filter: true },
          }}
        />
        {products && selectedUnit && (
          <UnitProductsDrawer
            open={drawerState.open}
            onClose={closeDrawer}
            catalogUnit={selectedUnit}
            products={products}
          />
        )}
      </Box>
    </>
  )
}

export default UnitsDataGrid
