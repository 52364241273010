import { Route, RouterChildContext, Switch, useHistory } from "react-router-dom"
import React, { useContext } from "react"
import { NoticeContext, withNoticeProvider } from "../../contexts/NoticeContext"
import { withBrowserRouter } from "../../../../routers/BrowserRouter"
import { withMaterialTheme } from "../../../../themes/theme"
import AttributesPage from "./components/AttributesPage"
import AttributeDetailsPage from "./components/AttributeDetailsPage"
import { Policies, PoliciesProvider } from "../../contexts/PoliciesContext"
import { FeatureFlagProvider } from "components/FeatureFlagContext"
import CreateAttributeFormPage from "./components/GlobalAttributeForm/CreateAttributeFormPage"
import { QueryClientProvider } from "@tanstack/react-query"
import { useStableQueryClient } from "utilities/queryClient"
import EditAttributeFormPage from "./components/GlobalAttributeForm/EditAttributeFormPage"

type Props = {
  featureFlags: Record<string, boolean>
  policies: Policies
}

const AttributesWorkflow: React.FC<Props> = (props: Props) => {
  const history: RouterChildContext["router"]["history"] = useHistory()
  const { clearNotice } = useContext(NoticeContext)
  const queryClient = useStableQueryClient()

  React.useEffect(() => {
    clearNotice()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname])

  return (
    <QueryClientProvider client={queryClient}>
      <FeatureFlagProvider flags={props.featureFlags}>
        <PoliciesProvider policies={props.policies}>
          <Switch>
            <Route path="/cms/catalog/attributes" exact>
              <AttributesPage />
            </Route>
            <Route path="/cms/catalog/attributes/new" exact>
              <CreateAttributeFormPage />
            </Route>
            <Route path="/cms/catalog/attributes/:attributeId/edit" exact>
              <EditAttributeFormPage />
            </Route>
            <Route path="/cms/catalog/attributes/:attributeId" exact>
              <AttributeDetailsPage />
            </Route>
          </Switch>
        </PoliciesProvider>
      </FeatureFlagProvider>
    </QueryClientProvider>
  )
}

export default withBrowserRouter(
  withNoticeProvider(withMaterialTheme(AttributesWorkflow)),
  {}
)
