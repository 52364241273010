import React from "react"
import { CanopyHeading } from "@parachutehealth/canopy-heading"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyText } from "@parachutehealth/canopy-text"

interface Props {
  oneTimeOrderEnabled: boolean
}

function Header({ oneTimeOrderEnabled }: Props) {
  const subheaderText = oneTimeOrderEnabled
    ? "Order from your organization's suppliers, or from any supplier on the Parachute Platform."
    : "Order from your organization's suppliers."

  return (
    <CanopyFlex direction="column" gap="4X" className="canopy-mbe-8x">
      <CanopyHeading size="2xLarge" level={1}>
        Select Supplier
      </CanopyHeading>
      <CanopyText>{subheaderText}</CanopyText>
    </CanopyFlex>
  )
}

export default Header
