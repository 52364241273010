import { BrightreeSalesOrderClassification } from "./types"

export function isInvalidClassificationId(
  classificationIdOptions: BrightreeSalesOrderClassification[],
  classificationId: string
): boolean {
  return !classificationIdOptions.find(
    (option) => option.id === classificationId
  )
}
