import { QueryClient, useQuery } from "@tanstack/react-query"
import { Patient } from "./sharedTypes"
import { format, isDateValid } from "utilities/date"

export const infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually = Infinity

export const patientQueryKey = ["csrInboxState", "patient"]

export const setPatientQueryData = (client: QueryClient, patient: Patient) => {
  const dateOfBirth = isDateValid(patient.dateOfBirth)
    ? format(patient.dateOfBirth)
    : patient.dateOfBirth
  client.setQueryData(patientQueryKey, { ...patient, dateOfBirth })
}
export const usePatientQuery = () =>
  useQuery<Patient>({
    queryKey: patientQueryKey,
    queryFn: () => ({ firstName: "", lastName: "", dateOfBirth: "" }),
    staleTime: infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  })
