import { employerPrefix } from "utilities/url"

export function dashboardUrl() {
  return `${employerPrefix()}/dashboard`
}

export function completeFacilityProfileUrl() {
  return `${employerPrefix()}/complete_profile`
}

export function updateFaxNumbersUrl() {
  return `${employerPrefix()}/complete_profile/update_fax_numbers`
}

export function updateFacilityNameUrl() {
  return `${employerPrefix()}/complete_profile/update_facility_name`
}

export function updateFacilityAddressUrl() {
  return `${employerPrefix()}/complete_profile/update_facility_address`
}

export function skipInviteUsersUrl() {
  return `${employerPrefix()}/complete_profile/skip_invite_users`
}

export function inviteUsersUrl() {
  return `${employerPrefix()}/complete_profile/invite_users`
}
