import { post, put } from "services/api"
import { setFollowersUrl, snoozeOrderUrl } from "./urls"
import { cancelUrl } from "applications/Workflow/urls"

export const updateFollowers = (params, orderId) => {
  return put(setFollowersUrl(orderId), params)
}

export const snoozeOrder = (params, orderId?) => {
  return post(snoozeOrderUrl(orderId), params)
}

export const unsnoozeOrder = (orderId?) => {
  return put(snoozeOrderUrl(orderId))
}

export const cancelOrder = (
  cancelReason?: string,
  cancelComment?: string,
  dmeOrderId?: string
) =>
  cancelReason
    ? post(cancelUrl(dmeOrderId), { cancelReason, cancelComment })
    : post(cancelUrl(dmeOrderId))
