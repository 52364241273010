import {
  CarrierAuthorization,
  Doctor,
  RankedDiagnosis,
} from "graphql/__generated__/graphql"
import { format, DATE_TIME_FORMAT } from "utilities/date"
import { formatAddress } from "utilities/address"
import { formatPhone } from "utilities/phone"
import React from "react"
import { capitalize } from "utilities/string"
import { CardDetailConfig } from "../components/AuthCard/AuthCard"

export interface PreSubmitAuthRequestDetails {
  [title: string]: CardDetailConfig
}

export interface PostSubmitAuthRequestDetails {
  title: string
  data: {
    title: string
    key: string
    valueGetter?: (authorization: CarrierAuthorization) => any
  }[]
}

export const PRE_SUBMIT_REQUEST_DETAILS: PreSubmitAuthRequestDetails = {
  referralType: {
    title: "Referral type",
    formatFn: (value: CarrierAuthorization["referralType"]) =>
      capitalizeField(value),
    required: false,
  },
  orderingDoctor: {
    title: "Ordering physician",
    required: false,
    formatFn: (value: Doctor) => {
      const { firstName, lastName, credential } = value
      return `
        ${firstName || ""}
        ${lastName || ""}${credential ? ", " + credential : ""}
      `
    },
  },
  rankedDiagnoses: {
    title: "Diagnosis codes",
    required: false,
    formatFn: (value: RankedDiagnosis[]) => {
      return value?.map((rankedDiagnosis) => {
        return `${rankedDiagnosis?.icd10Code?.codeLabel} -
            ${rankedDiagnosis?.icd10Code?.description}`
      })
    },
  },
}

export const POST_SUBMIT_REQUEST_DETAILS: PostSubmitAuthRequestDetails = {
  title: "Request",
  data: [
    {
      title: "Submitted",
      key: "requestSubmitDateTime",
      valueGetter: (authorization) => {
        const submitAt = format(
          new Date(authorization.requestSubmitDateTime).toLocaleString(),
          DATE_TIME_FORMAT
        )

        return submitAt
      },
    },
    {
      title: "Referral type",
      key: "referralType",
      valueGetter: (authorization) =>
        capitalizeField(authorization.referralType),
    },
    {
      title: "Diagnosis codes",
      key: "diagnosisCodes",
      valueGetter: (authorization) => {
        const diagnosisCodes = authorization?.dmeOrder?.icd10Codes?.map(
          (code) => {
            return (
              <div key={code.codeLabel}>
                {code.codeLabel} - {code.description}
              </div>
            )
          }
        )
        return diagnosisCodes && diagnosisCodes.length > 0
          ? diagnosisCodes
          : "-"
      },
    },
    {
      title: "Diagnosis code version",
      key: "diagnosisCodeVersion",
      valueGetter: () => "ICD-10",
    },
    {
      title: "Eligibility check",
      key: "eligibilityCheck",
      valueGetter: (authorization) => {
        const enrollmentStatus = authorization?.carrierMembership?.enrollmentStatus?.toLowerCase()
        switch (enrollmentStatus) {
          case "active":
            return "Eligible"
          case "inactive":
            return "Ineligible"
          default:
            return "Unknown"
        }
      },
    },
  ],
}

export const SUPPLIER_DETAILS: PostSubmitAuthRequestDetails = {
  title: "Supplier",
  data: [
    {
      title: "Name",
      key: "supplier.name",
    },
    {
      title: "Address",
      key: "supplierAddress",
      valueGetter: (authorization) => addressFormatter(authorization?.supplier),
    },
    {
      title: "Phone",
      key: "supplierPhone",
      valueGetter: (authorization) =>
        phoneNumberFormatter(authorization?.supplier?.phoneNumber),
    },
    {
      title: "Fax",
      key: "supplierFax",
    },
    {
      title: "Email",
      key: "supplierEmail",
    },
    {
      title: "NPI",
      key: "supplier.npi",
    },
  ],
}

export const SUPPLIER_LEGAL_ENTITY_DETAILS: PostSubmitAuthRequestDetails = {
  title: "Supplier legal entity",
  data: [
    {
      title: "Name",
      key: "dmeOrder.supplierLegalEntity.name",
    },
    {
      title: "Address",
      key: "supplierLegalEntityAddress",
      valueGetter: (authorization) =>
        addressFormatter(authorization?.dmeOrder?.supplierLegalEntity),
    },
    {
      title: "Phone",
      key: "supplierLegalEntityPhone",
      valueGetter: (authorization) =>
        phoneNumberFormatter(
          authorization?.dmeOrder?.supplierLegalEntity?.phoneNumber
        ),
    },
    {
      title: "Fax",
      key: "supplierLegalEntityFax",
    },
    {
      title: "Email",
      key: "supplierLegalEntityEmail",
    },
    {
      title: "NPI",
      key: "dmeOrder.supplierLegalEntity.npi",
    },
  ],
}

export const SUBSCRIBER_DETAILS: PostSubmitAuthRequestDetails = {
  title: "Subscriber",
  data: [
    {
      title: "First name",
      key: "firstName",
      valueGetter: (authorization) => getSubscriber(authorization)?.firstName,
    },
    {
      title: "Last name",
      key: "lastName",
      valueGetter: (authorization) => getSubscriber(authorization)?.lastName,
    },
    {
      title: "Middle initial",
      key: "subscriberMiddleInitial",
    },
    {
      title: "Subscriber ID",
      key: "memberNumber",
      valueGetter: (authorization) =>
        getSubscriber(authorization)?.memberNumber,
    },
    {
      title: "Person ID suffix",
      key: "personIdSuffix",
      valueGetter: (authorization) =>
        getSubscriber(authorization)?.personIdSuffix,
    },
    {
      title: "MBI",
      key: "mbi",
      valueGetter: (authorization) => getSubscriber(authorization)?.mbi,
    },
    {
      title: "Alternative member ID",
      key: "altMemberNumber",
      valueGetter: (authorization) =>
        getSubscriber(authorization)?.altMemberNumber,
    },
    {
      title: "Date of birth",
      key: "subscriberDateOfBirth",
      valueGetter: (authorization) => getSubscriber(authorization)?.dateOfBirth,
    },
    {
      title: "Sex",
      key: "carrierMembership.subscriber.sex",
      valueGetter: (authorization) =>
        capitalizeField(getSubscriber(authorization)?.sex),
    },
    {
      title: "Address",
      key: "subscriberAddress",
      valueGetter: (authorization) =>
        addressFormatter(getSubscriber(authorization)),
    },
    {
      title: "Phone",
      key: "subscriberPhone",
      valueGetter: (authorization) =>
        phoneNumberFormatter(getSubscriber(authorization)?.phoneNumber),
    },
    {
      title: "Fax",
      key: "subscriberFax",
    },
    {
      title: "Email",
      key: "subscriberEmail",
    },
  ],
}

export const PLAN_DETAILS: PostSubmitAuthRequestDetails = {
  title: "Plan",
  data: [
    {
      title: "Eligibility start date",
      key: "planEligibilityStartDate",
      valueGetter: (authorization) =>
        dateFormatter(authorization?.carrierMembership?.enrollmentStartDate),
    },
    {
      title: "Eligibility end date",
      key: "planEligibilityEndDate",
      valueGetter: (authorization) =>
        dateFormatter(authorization?.carrierMembership?.enrollmentEndDate),
    },
    {
      title: "Group name",
      key: "planGroupName",
    },
    {
      title: "Group number",
      key: "carrierMembership.groupNumber",
    },
    {
      title: "Plan name",
      key: "carrierMembership.insuranceProduct.name",
    },
    {
      title: "Health plan ID",
      key: "carrierMembership.insuranceProduct.id",
    },
    {
      title: "Line of business",
      key: "carrierMembership.insuranceProduct.planType",
      valueGetter: (authorization) =>
        capitalizeField(
          authorization?.carrierMembership?.insuranceProduct?.planType
        ),
    },
    {
      title: "Plan type",
      key: "carrierMembership.planType",
    },
    {
      title: "Plan code",
      key: "carrierMembership.planTypeCode",
    },
    {
      title: "CMS contract ID",
      key: "carrierMembership.cmsContractId",
    },
    {
      title: "CMS plan ID",
      key: "carrierMembership.cmsPlanId",
    },
  ],
}

export const PATIENT_DETAILS: PostSubmitAuthRequestDetails = {
  title: "Patient",
  data: [
    {
      title: "Member ID",
      key: "carrierMembership.memberNumber",
    },
    {
      title: "Person ID suffix",
      key: "carrierMembership.personIdSuffix",
    },
    {
      title: "MBI",
      key: "carrierMembership.mbi",
    },
    {
      title: "Alternative member ID",
      key: "carrierMembership.altMemberNumber",
    },
    {
      title: "Eligibility start date",
      key: "carrierMembership.enrollmentStartDate",
      valueGetter: (authorization) =>
        dateFormatter(authorization?.carrierMembership?.enrollmentStartDate),
    },
    {
      title: "Eligibility end date",
      key: "carrierMembership.enrollmentEndDate",
      valueGetter: (authorization) =>
        dateFormatter(authorization?.carrierMembership?.enrollmentEndDate),
    },
    {
      title: "First name",
      key: "carrierMembership.firstName",
    },
    {
      title: "Last name",
      key: "carrierMembership.lastName",
    },
    {
      title: "Middle initial",
      key: "carrierMembershipMiddleInitial",
    },
    {
      title: "Date of birth",
      key: "carrierMembership.dateOfBirth",
      valueGetter: (authorization) =>
        dateFormatter(authorization?.carrierMembership?.dateOfBirth),
    },
    {
      title: "Sex",
      key: "carrierMembership.sex",
      valueGetter: (authorization) =>
        capitalizeField(authorization?.carrierMembership?.sex),
    },
    {
      title: "Relationship",
      key: "carrierMembership.relationToSubscriber",
      valueGetter: (authorization) =>
        capitalizeField(authorization?.carrierMembership?.relationToSubscriber),
    },
    {
      title: "Address",
      key: "carrierMembershipAddress",
      valueGetter: (authorization) =>
        addressFormatter(authorization?.carrierMembership),
    },
    {
      title: "Phone",
      key: "carrierMembershipPhoneNumber",
      valueGetter: (authorization) =>
        phoneNumberFormatter(authorization?.carrierMembership?.phoneNumber),
    },
    {
      title: "Fax",
      key: "carrierMembershipFax",
    },
    {
      title: "Email",
      key: "carrierMembershipEmail",
    },
    {
      title: "Preferred Language",
      key: "carrierMembershipLanguage",
    },
  ],
}

export const FACILITY_DETAILS: PostSubmitAuthRequestDetails = {
  title: "Discharging facility",
  data: [
    {
      title: "Name",
      key: "orderingFacility.name",
    },
    {
      title: "Address",
      key: "orderingFacilityAddress",
      valueGetter: (authorization) =>
        addressFormatter(authorization?.orderingFacility),
    },
    {
      title: "Phone",
      key: "orderingFacilityPhoneNumber",
      valueGetter: (authorization) =>
        phoneNumberFormatter(authorization?.orderingFacility?.phoneNumber),
    },
    {
      title: "Fax",
      key: "orderingFacilityFaxNumber",
      valueGetter: (authorization) =>
        phoneNumberFormatter(authorization?.orderingFacility?.faxNumber),
    },
    {
      title: "Email",
      key: "orderingFacility.email",
    },
  ],
}

export const DOCTOR_DETAILS: PostSubmitAuthRequestDetails = {
  title: "Physician",
  data: [
    {
      title: "First name",
      key: "orderingDoctor.firstName",
    },
    {
      title: "Last name",
      key: "orderingDoctor.lastName",
    },
    {
      title: "Middle initial",
      key: "orderingDoctorMiddleInitial",
    },
    {
      title: "Address",
      key: "orderingDoctorAddress",
      valueGetter: (authorization) =>
        addressFormatter(authorization?.orderingDoctor),
    },
    {
      title: "Phone",
      key: "orderingDoctorPhoneNumber",
      valueGetter: (authorization) =>
        phoneNumberFormatter(authorization?.orderingDoctor?.phoneNumber),
    },
    {
      title: "Fax",
      key: "orderingDoctorFaxNumber",
      valueGetter: (authorization) =>
        phoneNumberFormatter(authorization?.orderingDoctor?.faxNumber),
    },
    {
      title: "Email",
      key: "orderingDoctor.email",
    },
    {
      title: "NPI",
      key: "orderingDoctor.npi",
    },
    {
      title: "Physician type",
      key: "orderingDoctorType",
    },
  ],
}

export const SUBMITTER_DETAILS: PostSubmitAuthRequestDetails = {
  title: "Submitter",
  data: [
    {
      title: "Phone",
      key: "submitterPhone",
      valueGetter: (authorization) =>
        phoneNumberFormatter(authorization?.submitter?.phoneNumber),
    },
    {
      title: "Fax",
      key: "submitterFax",
    },
    {
      title: "Email",
      key: "submitter.email",
    },
    {
      title: "First name",
      key: "submitter.firstName",
    },
    {
      title: "Last name",
      key: "submitter.lastName",
    },
    {
      title: "Middle initial",
      key: "submitterMiddleInitial",
    },
    {
      title: "Submitter type",
      key: "sourceType",
    },
    {
      title: "Referral method",
      key: "submitterReferralMethod",
      valueGetter: () => "Portal",
    },
    {
      title: "Referral source",
      key: "submitterReferralSource",
      valueGetter: () => "Patient's ordering physician",
    },
  ],
}

const phoneNumberFormatter = (phoneNumber: any) => {
  return phoneNumber ? formatPhone(phoneNumber) : "-"
}

const addressFormatter = (address: any) => {
  return address ? formatAddress(address) : "-"
}

const dateFormatter = (date: string) => {
  return date ? format(date, "MM/DD/YYYY") : "-"
}

const capitalizeField = (field: any) => {
  return field ? capitalize(field) : "-"
}

const getSubscriber = (authorization: CarrierAuthorization) => {
  return (
    authorization?.carrierMembership?.subscriber ||
    authorization?.carrierMembership
  )
}
