import React from "react"
import { Link } from "react-router-dom"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import * as styles from "./Layout.module.scss"
import classNames from "classnames"
import { CanopyRequiredFieldKey } from "@parachutehealth/canopy-required-field-key"

interface LayoutHeadingProps {
  backlinkPath?: string
  backlinkText?: string
  headingText: string
  showRequiredFieldKey: boolean
}

interface LayoutBodyProps {
  children: React.ReactNode
}

interface LayoutProps {
  children: React.ReactNode
}

export const LayoutHeading = ({
  backlinkPath,
  backlinkText,
  headingText,
  showRequiredFieldKey,
}: LayoutHeadingProps) => {
  const containerClassName: string = backlinkPath
    ? "canopy-mt-8x"
    : "canopy-mt-16x"
  return (
    <div className={containerClassName}>
      {backlinkPath && (
        <div className="canopy-mb-8x canopy-py-4x">
          <Link className="link" to={backlinkPath}>
            <CanopyIcon
              name="arrow-left"
              className="canopy-mb-2x canopy-mr-4x"
            />
            <span>{backlinkText}</span>
          </Link>
        </div>
      )}
      <h2
        className={classNames(
          styles.multiActorH2,
          "canopy-typography-heading-xlarge"
        )}
      >
        {headingText}
      </h2>
      {showRequiredFieldKey && (
        <CanopyRequiredFieldKey className="canopy-mt-4x" />
      )}
    </div>
  )
}

export const LayoutBody = ({ children }: LayoutBodyProps) => {
  return <div className={styles.gridSpacing}>{children}</div>
}

export const Layout = ({ children }: LayoutProps) => {
  const containerClassName: string = classNames(
    styles.gridSpacing,
    "canopy-mx-16x"
  )
  return <div className={containerClassName}>{children}</div>
}

Layout.Heading = LayoutHeading
Layout.Body = LayoutBody
