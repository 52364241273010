import React from "react"
import Sidebar from "components/LeftSidebar"
import {
  carrierAuthorizationsDashboardUrl,
  inboundDocumentsDashboardUrl,
  ordersDashboardUrl,
  salesCollaborationToolsNetworkUrl,
  salesCollaborationToolsSavedUrl,
  signatureRequestsDashboardUrl,
} from "./urls"
import { LocalShippingOutlined, Inbox } from "@material-ui/icons"
import DriveFileRenameOutlined from "./DriveFileRenameOutlinedIcon"
import Approval from "./ApprovalIcon"
import HospitalUserRegular from "./HospitalUserRegular"
import { fetchInitialLeftSidebarData } from "./api"
import withInitialData from "../withInitialData"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

export enum Option {
  Orders = "Orders",
  SignatureRequests = "Signature Requests",
  InboundDocuments = "Inbound Documents",
  Authorizations = "Authorizations",
  Saved = "Saved",
  Network = "Network",
  LowEngagement = "Low_Engagement",
}

interface InitialData {
  signatureRequestDashboardEnabled: boolean
  carrierAuthorizationDashboardEnabled: boolean
  hasSuppliersWithUnworkedInboundDocuments: boolean
  salesTeamCollaborationTools: boolean
  salesTeamCollaborationToolsSavedMainApp: boolean
}
interface Props {
  active: Option
  initialData: InitialData
}

const options = (active, initialData) => [
  {
    icon: LocalShippingOutlined,
    text: Option.Orders,
    href: ordersDashboardUrl(),
    active: active === Option.Orders,
    tooltip: "Orders Dashboard",
  },
  {
    icon: Inbox,
    text: Option.InboundDocuments,
    href: inboundDocumentsDashboardUrl(),
    active: active === Option.InboundDocuments,
    tooltip: "Inbound Documents Dashboard",
    badgeEnabled: initialData?.hasSuppliersWithUnworkedInboundDocuments,
  },
  initialData?.signatureRequestDashboardEnabled && {
    icon: DriveFileRenameOutlined,
    text: Option.SignatureRequests,
    href: signatureRequestsDashboardUrl(),
    active: active === Option.SignatureRequests,
    tooltip: "Signature Requests Dashboard",
  },
  initialData?.carrierAuthorizationDashboardEnabled && {
    icon: Approval,
    text: Option.Authorizations,
    href: carrierAuthorizationsDashboardUrl(),
    active: active === Option.Authorizations,
    tooltip: "Authorizations Dashboard",
  },
  initialData?.salesTeamCollaborationToolsSavedMainApp && {
    icon: (props) => <CanopyIcon name="star" {...props} />,
    text: Option.Saved,
    href: salesCollaborationToolsSavedUrl(),
    active: active === Option.Saved,
    tooltip: "Saved",
  },
  initialData?.salesTeamCollaborationTools && {
    icon: HospitalUserRegular,
    text: Option.Network,
    href: salesCollaborationToolsNetworkUrl(),
    active: active === Option.Network || active === Option.LowEngagement,
    tooltip: "Network",
  },
]

const filteredOptions = (active, initialData) =>
  options(active, initialData).filter(Boolean)

const LeftSidebar = ({ active, initialData }: Props) => (
  <Sidebar options={filteredOptions(active, initialData)} fixed />
)

LeftSidebar.Option = Option

const fetchInitialData = (): Promise<InitialData> => {
  return fetchInitialLeftSidebarData().then((response) => response)
}

const defaultInitialData = {
  signatureRequestDashboardEnabled: false,
  carrierAuthorizationDashboardEnabled: false,
  hasSuppliersWithUnworkedInboundDocuments: false,
  salesTeamCollaborationTools: false,
  salesTeamCollaborationToolsSavedMainApp: false,
}

export default withInitialData(
  fetchInitialData,
  (props: Omit<Props, "initialData">) => (
    <LeftSidebar {...props} initialData={defaultInitialData} />
  )
)(LeftSidebar)
