import React from "react"
import { isNullOrUndefined } from "../../../../../../../utilities/isNullOrUndefined"
import { List, ListItem } from "@material-ui/core"
import { Category } from "../../../../../types/sharedTypes"

const CategoriesList = (props: {
  categoryHierarchy?: Category[][]
}): React.JSX.Element | null => {
  if (isNullOrUndefined(props.categoryHierarchy)) {
    return null
  }

  return (
    <div>
      <h4 className="canopy-typography-heading-medium">Categories</h4>

      {props.categoryHierarchy && (
        <List disablePadding>
          {props.categoryHierarchy.map((hierarchy: Category[], index) => {
            return (
              <ListItem
                dense
                disableGutters
                key={index}
                className="canopy-typography-body-small"
              >
                {hierarchy.map((category, categoryIndex) => {
                  return (
                    <React.Fragment key={categoryIndex}>
                      <a href={category.url}>{category.name}</a>
                      {categoryIndex < hierarchy.length - 1 && (
                        <span className="canopy-mx-4x">&rarr;</span>
                      )}
                    </React.Fragment>
                  )
                })}
              </ListItem>
            )
          })}
        </List>
      )}
    </div>
  )
}

export default CategoriesList
