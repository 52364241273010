import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import * as styles from "./index.module.scss"
import LLMResultJson from "./components/LLMResultJson"
import { SupplierIntakeNavbar } from "./components/SupplierIntakeNavbar"
import { Document } from "components/DocumentsBrowser/sharedTypes"
import { withBrowserRouter } from "routers/BrowserRouter"
import { inboxDashboardUrl, inboxDocumentUrl, pingUrl } from "./urls"
import { DataEntryColumn } from "./components/DataEntryColumn"
import { CsrInboxStateProvider } from "./csrInboxStateContext"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ErrorContextProvider } from "./error-context"
import CsrInboxStateAiDetails from "../CsrInboxStateAiDetails"
import { DocumentBrowserFrame } from "./components/DocumentBrowserFrame"
import { PatientSearch } from "./components/PatientPages/PatientSearchPage"
import { CompletePatient } from "./components/PatientPages/CompletePatientPage"
import { NewPatient } from "./components/PatientPages/NewPatientPage"
import { OrderHistory } from "./components/OrderHistory"
import { ExternalSystemBranchData } from "./components/DataEntryColumn/FacilityClinicianSection/ExternalSystemSelect"
import { OrderingClinicianData } from "./components/DataEntryColumn/ClinicianData"
import { Diagnosis as DiagnosisType } from "./components/DataEntryColumn/Diagnoses/api"
import Pinger from "../Pinger"
import {
  Insurance,
  LLMResult,
  Patient,
  PatientEmergencyContact,
  InternalStatus,
  Assignee,
  Supplier,
} from "./sharedTypes"
import { PatientAddress } from "./components/DataEntryColumn/ContactInformation/api"
import { PatientContactInformationProps } from "./components/DataEntryColumn/ContactInformation/PatientContactInformation/api"
import { InboxStateClinicalFacility } from "./components/DataEntryColumn/FacilityData"
import { CartItem } from "./components/DataEntryColumn/OrderItems/api"
import { setPatientQueryData } from "./queries"
import { Nullable } from "../../sharedTypes"
import { ROUTES } from "./routes"
import { hasCompleteDemographics } from "./utilities"

type Props = {
  csrInboxState: {
    supplier: Supplier
    id: string
    supplierOrganization: { id: string }
    document: Document
    documentationInformation?: {
      title?: string
      summary?: string
      qualificationStatus?: "qualified" | "undetermined"
    }
    clinician: OrderingClinicianData
    externalSystemBranch: ExternalSystemBranchData
    diagnoses: DiagnosisType[]
    insurances: Insurance[]
    patient: Patient
    patientAddress: PatientAddress
    patientContactInformation: PatientContactInformationProps
    patientEmergencyContact: PatientEmergencyContact
    facility: InboxStateClinicalFacility
    llmResult: Nullable<LLMResult>
    internalStatus?: InternalStatus
    assignee?: Assignee
    categories: { name: string; id: string }[]
    cartItems: CartItem[]
  }
  internalStatuses: InternalStatus[]
  categories: { name: string; id: string }[]
  pingIntervalMs: number
  skuSetterEnabled: boolean
}

const SupplierOrganizationInboxDocument = ({
  csrInboxState: {
    id,
    supplier,
    supplierOrganization,
    document,
    documentationInformation,
    internalStatus,
    assignee,
    categories: csrInboxStateCategories,
    cartItems,
    ...propsForThePage
  },
  internalStatuses,
  categories,
  pingIntervalMs,
  skuSetterEnabled,
}: Props) => {
  const allPropsForThePage = {
    ...propsForThePage,
    cartItems,
    skuSetterEnabled,
  }

  return (
    <>
      <SupplierIntakeNavbar
        dashboardUrl={inboxDashboardUrl(supplierOrganization.id, supplier.id)}
        documentTitle={documentationInformation?.title}
        internalStatuses={internalStatuses}
        internalStatus={internalStatus}
        assignee={assignee}
      />
      <div className={styles.columnContainer}>
        <div className={styles.documentColumn}>
          <CsrInboxStateAiDetails
            aiIntakeEnabled={supplier.aiIntakeEnabled}
            documentation={documentationInformation}
            isOnDeprecatedPage={false}
          />
          <DocumentBrowserFrame document={document} />
        </div>
        <div className={styles.dataEntryColumn}>
          <Switch>
            <Route path={ROUTES.JSON_BLOB}>
              <LLMResultJson {...allPropsForThePage} />
            </Route>
            <Route path={ROUTES.PATIENT_SEARCH}>
              <PatientSearch
                {...allPropsForThePage}
                supplier={supplier}
                csrInboxStateId={id}
                supplierOrganizationId={supplierOrganization.id}
                initialSelectedCategories={csrInboxStateCategories}
                categories={categories}
              />
            </Route>
            <Route path={ROUTES.NEW_PATIENT}>
              <NewPatient
                csrInboxStateId={id}
                supplierOrganizationId={supplierOrganization.id}
              />
            </Route>
            <Route path={ROUTES.ORDER_HISTORY}>
              <OrderHistory />
            </Route>
            <Route path={ROUTES.COMPLETE_PATIENT}>
              <CompletePatient
                csrInboxStateId={id}
                supplierOrganizationId={supplierOrganization.id}
              />
            </Route>
            <Route path={ROUTES.NEW_ORDER}>
              <DataEntryColumn {...allPropsForThePage} />
            </Route>
            <Route>
              {hasCompleteDemographics(allPropsForThePage.patient) ? (
                <Redirect to={ROUTES.NEW_ORDER} />
              ) : (
                <Redirect to={ROUTES.PATIENT_SEARCH} />
              )}
            </Route>
          </Switch>
        </div>
      </div>
      <Pinger
        pingUrl={pingUrl(supplier.id, id)}
        pingIntervalMs={pingIntervalMs}
      />
    </>
  )
}

const SupplierOrganizationInboxDocumentWithProviders = (
  props: Props & { initialPath: string }
) => {
  const {
    csrInboxState: { id, supplier, supplierOrganization, patient },
  } = props
  const queryClient: QueryClient = new QueryClient()
  setPatientQueryData(queryClient, patient)
  const basename = inboxDocumentUrl(supplierOrganization.id, id)

  // Ensure routing happens after queryClient initialization
  // so that there's no re-initialization after re-render
  const RoutedInboxDocument = withBrowserRouter(
    SupplierOrganizationInboxDocument,
    { basename }
  )
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorContextProvider>
        <CsrInboxStateProvider
          id={id}
          supplierOrganization={supplierOrganization}
          supplier={supplier}
        >
          <RoutedInboxDocument {...props} />
        </CsrInboxStateProvider>
      </ErrorContextProvider>
    </QueryClientProvider>
  )
}

export default SupplierOrganizationInboxDocumentWithProviders
