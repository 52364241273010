import { useState } from "react"
import { DataGridOptions, DataGridPageArgs } from "../../sharedTypes"
import { GridColDef, GridSortModel } from "@mui/x-data-grid-pro"
import isEqual from "lodash.isequal"
import { PageInfo } from "../../graphql/__generated__/graphql"

export const useGraphqDataGrid = (
  refetch: any,
  paginate: any,
  pageInfo?: PageInfo,
  pageSize: number = 50,
  initialOptions?: Partial<DataGridOptions>,
  initialColumnDefs?: GridColDef[]
) => {
  const [gridOptions, setGridOptions] = useState<DataGridOptions>({
    page: 0,
    ...initialOptions,
  })

  const [gridColumnDefs, setGridColumnDefs] = useState<GridColDef[]>(
    initialColumnDefs || []
  )

  const handleSorting = async (sortModel: GridSortModel) => {
    if (!isEqual(sortModel, gridOptions.sort)) {
      setGridOptions(() => ({
        page: 0,
        sort: sortModel,
      }))

      await refetch({
        sort: sortModel.length ? sortModel[0].field : null,
        sortDirection: sortModel.length
          ? sortModel[0].sort?.toUpperCase()
          : null,
      })
    }
  }

  const handlePagination = async (nextPage: number) => {
    if (gridOptions.page > 0 && !pageInfo?.hasPreviousPage) {
      setGridOptions({
        page: 0,
      })
      return
    }
    const pagingForward = nextPage > gridOptions.page

    const fetchArgs = {
      after: pagingForward ? pageInfo?.endCursor : undefined,
      before: !pagingForward ? pageInfo?.startCursor : undefined,
      first: pagingForward ? pageSize : undefined,
      last: !pagingForward ? pageSize : undefined,
    } as DataGridPageArgs

    setGridOptions((prev) => ({
      ...prev,
      pageArgs: fetchArgs,
      page: nextPage,
    }))
    await paginate({ variables: fetchArgs })
  }

  const handleColumnVisibilityChange = (params: {
    field: string
    colDef: GridColDef
  }) => {
    setGridColumnDefs((prev) => {
      return prev.map((def) =>
        def.field === params.field ? params.colDef : def
      )
    })
  }

  return {
    gridOptions,
    gridColumnDefs,
    handlePagination,
    handleSorting,
    handleColumnVisibilityChange,
  }
}
