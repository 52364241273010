import React, { useState } from "react"
import { CanopyFormFieldGroup } from "@parachutehealth/canopy-form-field-group"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyTextInput } from "@parachutehealth/canopy-text-input"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import * as tokens from "@parachutehealth/canopy-tokens-color"
import { formatPhone, isPhoneValid } from "utilities/phone"

type Props = {
  faxNumbers: string[]
  onChange(faxNumbers: string[]): void
  limit?: number
}

const FaxNumbersFormGroup = (props: Props) => {
  const [faxNumbers, setFaxNumbers] = useState(props.faxNumbers)
  const [newFaxNumber, setNewFaxNumber] = useState("")

  const addFaxNumber = () => {
    if (!validFaxNumber(newFaxNumber)) {
      return
    }

    const newFaxNumbers = [...faxNumbers, newFaxNumber]
    setFaxNumbers(newFaxNumbers)
    setNewFaxNumber("")
    props.onChange(newFaxNumbers)
  }
  const removeFaxNumber = (faxNumber: string) => {
    const newFaxNumbers = faxNumbers.filter((f) => f !== faxNumber)
    setFaxNumbers(newFaxNumbers)
    props.onChange(newFaxNumbers)
  }
  const validFaxNumber = (faxNumber: string) =>
    isPhoneValid(faxNumber) && !faxNumbers.some((f) => f === faxNumber)

  return (
    <CanopyFormFieldGroup label="Fax Numbers">
      {() => (
        <CanopyFlex direction="column" style={{ width: "100%" }}>
          <CanopyFlex style={{ width: "100%" }}>
            <CanopyTextInput
              id="newFaxNumber"
              name="newFaxNumber"
              placeholder="Enter fax number"
              style={{ flexGrow: 1 }}
              value={newFaxNumber}
              onChange={(e: any) => {
                setNewFaxNumber(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault()
                  addFaxNumber()
                }
              }}
            />
            <CanopyButton
              onClick={() => addFaxNumber()}
              type="button"
              style={{ whiteSpace: "nowrap" }}
              disabled={
                !validFaxNumber(newFaxNumber) ||
                props.limit === faxNumbers.length
              }
            >
              + Add
            </CanopyButton>
          </CanopyFlex>

          <CanopyFlex direction="column" style={{ width: "100%" }}>
            {faxNumbers.map((faxNumber) => (
              <CanopyFlex
                style={{
                  backgroundColor: tokens.canopyColorBackgroundSecondary,
                  width: "100%",
                  borderRadius: "4px",
                }}
                key={faxNumber}
              >
                <CanopyFlex style={{ width: "100%" }}>
                  <div style={{ flexGrow: 1, padding: "8px" }}>
                    {formatPhone(faxNumber)}
                  </div>
                  <CanopyButton
                    onClick={() => removeFaxNumber(faxNumber)}
                    iconStart="xmark"
                    accessibleText="Remove fax number"
                    title="Remove fax number"
                    variant="tertiary"
                    size="small"
                  />
                </CanopyFlex>
              </CanopyFlex>
            ))}
          </CanopyFlex>
        </CanopyFlex>
      )}
    </CanopyFormFieldGroup>
  )
}

export default FaxNumbersFormGroup
