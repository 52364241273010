import React from "react"
import * as styles from "./AuthCard.module.scss"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { AuthCardDetail } from "../AuthCardDetail/AuthCardDetail"
import AuthStatusPill from "../AuthStatusPill/AuthStatusPill"
import {
  AuthLineItemStatusToSeverity,
  AuthLineItemStatusToTitle,
} from "../../utilities/statusFormatters"
import { CarrierLineItemAuthorization } from "graphql/__generated__/graphql"
import LineItemStatusBanner, {
  bannerStatuses,
} from "../AuthCardDetail/LineItemStatusBanner/LineItemStatusBanner"
import { CanopyFlex } from "@parachutehealth/canopy-flex"

export type CardDetailConfig = {
  title: string
  required: boolean
  formatFn?: (value: any) => string | string[] | undefined
}

export type AuthCardProps = {
  title: string
  cardDetails: { [key: string]: CardDetailConfig }
  dmeOrderId?: string
  data: { [key: string]: any }
  onEditClick: () => void
  hasEditAccess: boolean
  loading: boolean
}

export function AuthCard({
  title,
  cardDetails,
  data,
  onEditClick,
  hasEditAccess,
  dmeOrderId,
  loading,
}: AuthCardProps) {
  const showStatusBanner = bannerStatuses.includes(data?.decisionStatus)

  return (
    <div role="grid" className={styles.authCard}>
      <div className={styles.authCardHeader}>
        <h4 className="canopy-m-0 canopy-py-4x canopy-typography-heading-large">
          {title}
        </h4>
        <CanopyFlex alignItems="center">
          {data?.decisionStatus && (
            <AuthStatusPill
              statusLevel={AuthLineItemStatusToSeverity[data.decisionStatus]}
              statusText={AuthLineItemStatusToTitle[data.decisionStatus]}
            />
          )}
          {hasEditAccess && (
            <CanopyButton
              variant="secondary"
              className="canopy-ml-8x"
              size="small"
              onClick={() => onEditClick()}
              disabled={loading}
            >
              Edit
            </CanopyButton>
          )}
        </CanopyFlex>
      </div>
      <div className={styles.authCardBody}>
        {showStatusBanner && (
          <LineItemStatusBanner
            lineItem={data as CarrierLineItemAuthorization}
            dmeOrderId={dmeOrderId!}
            className="canopy-mb-8x"
          />
        )}
        {Object.keys(cardDetails).map((keyName, index) => (
          <AuthCardDetail
            key={index}
            title={cardDetails[keyName]?.title}
            displayValue={
              data?.[keyName]
                ? cardDetails[keyName].formatFn?.(data[keyName]) ||
                  data[keyName]
                : null
            }
            requiredField={cardDetails[keyName]?.required}
          />
        ))}
      </div>
    </div>
  )
}
