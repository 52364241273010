import React from "react"
import * as QuestionInputText from "./questions/QuestionInputText"
import * as QuestionOptional from "./questions/QuestionOptional"
import * as QuestionInputNumber from "./questions/QuestionInputNumber"
import * as QuestionSelectMulti from "./questions/QuestionSelectMulti"
import * as QuestionSelectOne from "./questions/QuestionSelectOne"
import * as QuestionInputDate from "./questions/QuestionInputDate"
import * as t from "sharedTypes"
import { ApplicationError } from "sharedTypes"
import { Formik } from "formik"
import { handleError } from "../../../../utilities/error"
import {
  DOCTOR_RX_NOTE_TYPE,
  INPUT_DATE_QUESTION_TYPE,
  INPUT_NUMBER_QUESTION_TYPE,
  INPUT_TEXT_QUESTION_TYPE,
  OPTIONAL_TYPE,
  SELECT_MANY_QUESTION_TYPE,
  SELECT_ONE_QUESTION_TYPE,
  SELECT_YES_NO_QUESTION_TYPE,
  Values,
} from "./sharedTypes"

type Props = {
  answerQuestion: (
    question: t.SurveyQuestion,
    answerType: t.SurveyAnswerType,
    answerValue: string
  ) => Promise<void>
  question: t.SurveyQuestion
  showMostCommon: boolean
  setFieldSaving: (saving: boolean) => void
  requiresConfirmation: boolean
  missingRequiredInformation: boolean
}

const SurveyQuestion = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      question,
      answerQuestion,
      showMostCommon,
      setFieldSaving,
      requiresConfirmation,
      missingRequiredInformation,
    },
    ref
  ) => {
    const questionId = `question-${question.questionId}`

    const onAnswer = (answerType, answerValue) =>
      answerQuestion(question, answerType, answerValue)

    const getQuestionComponent = (question: t.SurveyQuestion) => {
      switch (question.type) {
        case INPUT_DATE_QUESTION_TYPE:
          return QuestionInputDate
        case INPUT_NUMBER_QUESTION_TYPE:
          return QuestionInputNumber
        case INPUT_TEXT_QUESTION_TYPE:
          return QuestionInputText
        case SELECT_MANY_QUESTION_TYPE:
          return QuestionSelectMulti
        case SELECT_ONE_QUESTION_TYPE:
          return QuestionSelectOne
        case SELECT_YES_NO_QUESTION_TYPE:
          return QuestionSelectOne
        case OPTIONAL_TYPE:
          return QuestionOptional
        case DOCTOR_RX_NOTE_TYPE:
          return QuestionOptional
        default:
          return QuestionInputText
      }
    }

    const {
      QuestionComponent,
      validate,
      getInitialValues,
      formatAnswer,
    } = getQuestionComponent(question)

    const handleValidation = (values: Values) => validate(values, question)

    const handleSubmit = async (values, { setSubmitting }) => {
      setFieldSaving && setFieldSaving(true)
      const { answerType, answerValue } = formatAnswer(values)

      try {
        await onAnswer(answerType || "value", answerValue)
        setSubmitting(false)
        setFieldSaving && setFieldSaving(false)
      } catch (e) {
        handleError(e as ApplicationError)
      }
    }

    return (
      <div style={{ marginBlockEnd: "40px", whiteSpace: "pre-line" }}>
        <Formik
          enableReinitialize
          initialValues={getInitialValues(question)}
          validate={handleValidation}
          validateOnMount={missingRequiredInformation}
          onSubmit={handleSubmit}
        >
          {(formProps) => {
            const valid = Object.keys(formProps.errors).reduce(
              (currentlyValid, field) => {
                // if there are any invalid fields we set the touched state to true so that the error message is shown
                if (formProps.errors[field] && missingRequiredInformation) {
                  formProps.initialTouched[field] = true
                }
                return currentlyValid && !formProps.errors[field]
              },
              true
            )

            return (
              <form onSubmit={formProps.handleSubmit}>
                <div className="form-group">
                  <QuestionComponent
                    question={question}
                    inputId={questionId}
                    showMostCommon={showMostCommon}
                    requiresConfirmation={requiresConfirmation}
                    ref={ref}
                    valid={valid}
                    missingRequiredInformation={missingRequiredInformation}
                    {...formProps}
                  />
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    )
  }
)
export default SurveyQuestion
