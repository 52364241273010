import React, { useCallback, useEffect, useState } from "react"
import {
  updateEmploymentPreferences,
  fetchInitialData,
  getLineItemGroup,
  updateLineItemGroup,
} from "./api"
import { deleteLineItemGroup } from "../../api"
import withInitialData from "components/withInitialData"
import { ApplicationError, DmeOrder, Employer, History } from "sharedTypes"
import {
  LineItemGroup as LineItemGroupData,
  LineItemGroupStep,
  Preferences,
} from "./sharedTypes"
import Modal from "components/Modal"
import { getModalTitle } from "./utilities/modal"
import { handleError } from "utilities/error"
import * as utilities from "./utilities"
import InternalLineItemGroup from "./components/InternalLineItemGroup"
import * as routes from "../../routes"
import { FormikValues } from "formik"

type InitialData = {
  preferences: Preferences
  showServiceTier: boolean
}

type Props = {
  currentEmployer: Employer
  dmeOrder: DmeOrder
  goToNextPage(): Promise<void>
  history: History
  initialData: InitialData
  lineItemGroupId: string
  refreshDmeOrder(): Promise<void>
  returnToBrowse(): Promise<void>
  supplierId?: string
}

const LineItemGroup: React.FC<Props> = ({
  currentEmployer,
  dmeOrder,
  refreshDmeOrder,
  goToNextPage,
  initialData,
  lineItemGroupId,
  returnToBrowse,
  history,
  supplierId,
}) => {
  const [step, setStep] = useState<LineItemGroupStep>()
  const [lineItemGroupLoading, setLineItemGroupLoading] = useState(false)
  const [lineItemGroup, setLineItemGroup] = useState<LineItemGroupData>()
  const [useFeetForHeight, setUseFeetForHeight] = useState(
    initialData?.preferences?.useFeetForHeight || false
  )

  const calculateStep = useCallback((lineItemGroup: LineItemGroupData) => {
    const lineItemGroupStep = utilities.calculateStep(lineItemGroup)
    setStep(lineItemGroupStep)
  }, [])

  const changeHeightUnit = () => {
    setUseFeetForHeight(!useFeetForHeight)
    updateEmploymentPreferences({
      employmentPreference: { useFeetForHeight },
    })
  }

  const loadLineItemGroup = useCallback(async () => {
    setLineItemGroupLoading(true)
    try {
      const lineItemGroupResponse = await getLineItemGroup(lineItemGroupId)

      setLineItemGroup(lineItemGroupResponse.data)
      calculateStep(lineItemGroupResponse.data)
      setLineItemGroupLoading(false)

      return lineItemGroupResponse.data
    } catch (e) {
      setLineItemGroupLoading(false)

      handleError(e as ApplicationError)
    }
  }, [lineItemGroupId, calculateStep])

  const remove = async () => {
    setLineItemGroupLoading(true)
    await deleteLineItemGroup(lineItemGroupId)
    await refreshDmeOrder()
    history.push(routes.productsPath(supplierId))
  }

  const update = async (params: FormikValues) => {
    try {
      const response = await updateLineItemGroup(lineItemGroupId, params)
      setLineItemGroup(response.data)
      calculateStep(response.data)
      return response.data
    } catch (e) {
      handleError(e as ApplicationError)
    }
  }

  useEffect(() => {
    if (!lineItemGroup) loadLineItemGroup()
  }, [lineItemGroup, loadLineItemGroup])

  return (
    <Modal
      title={getModalTitle(step, lineItemGroup?.offeringType)}
      show={!lineItemGroupLoading}
      cancel={returnToBrowse}
      backdrop="static"
    >
      <Modal.Body>
        {lineItemGroup ? (
          <InternalLineItemGroup
            changeHeightUnit={changeHeightUnit}
            changeStep={setStep}
            currentEmployer={currentEmployer}
            dmeOrder={dmeOrder}
            goToNextPage={goToNextPage}
            lineItemGroup={lineItemGroup}
            recalculateStep={() => calculateStep(lineItemGroup)}
            remove={remove}
            returnToBrowse={returnToBrowse}
            step={step || ""}
            update={update}
            useFeetForHeight={useFeetForHeight}
          />
        ) : (
          <p>
            Something went wrong. We could not load the configuration for this
            line item group.
          </p>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default withInitialData(fetchInitialData)(LineItemGroup)
