import React from "react"
import SeverityIcon from "components/SeverityIcon"
import { Severity } from "sharedTypes"
import Icon from "../../../../../components/Icon"
import { CanopyText } from "@parachutehealth/canopy-text"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

interface AuthStatusPillProps {
  statusLevel: Severity | "notice" | "successEdits"
  statusText: string
  className?: string
}

/*
 * TODO Replace with the Canopy pill component when it becomes available in early 2025
 */
const AuthStatusPill = ({
  statusLevel,
  statusText,
  className,
}: AuthStatusPillProps) => {
  const authStatusLevel = statusLevel === "notice" ? "info" : statusLevel

  const editsSavedStatus = () => {
    return (
      <>
        <CanopyText
          variant="success"
          color="text-secondary"
          size="xSmall"
          className={className}
        >
          <CanopyIcon name="check" size="small" className="canopy-mie-2x" />
          {statusText}
        </CanopyText>
      </>
    )
  }

  const pillStatus = () => {
    return (
      <div className={`pill pill-${authStatusLevel} canopy-m-0 ${className}`}>
        {statusLevel === "notice" ? (
          <Icon type="check-circle" className="canopy-mie-2x color-info" />
        ) : (
          <SeverityIcon
            className="canopy-mie-2x"
            type={statusLevel as Severity}
          />
        )}
        {statusText}
      </div>
    )
  }

  return (
    <>{statusLevel === "successEdits" ? editsSavedStatus() : pillStatus()}</>
  )
}

export default AuthStatusPill
