import React, { useContext, useEffect, useMemo, useState } from "react"
import Activity from "./containers/Activity"
import Cart from "./containers/Cart"
import Documents from "./containers/Documents"
import PatientPortal from "./containers/PatientPortal"
import {
  build as buildPatientPortalEndpoint,
  PatientPortalData,
} from "services/patientPortal"
import PatientChatIcon from "./containers/PatientPortal/components/PatientChatIcon"
import ChatSettings from "./containers/PatientPortal/components/ChatSettings"
import TransferredOrderOriginalCart from "./containers/TransferredOrderOriginalCart"
import { DmeOrder, DmeOrderWarning, Nullable } from "sharedTypes"
import RightSidebar from "./RightSidebar"
import GlobalContext from "context/Global"
import Icon from "components/Icon"
import { getActivityTitle } from "./utilities/activity"
import classNames from "classnames"
import { DEFAULT_FILTER_OPTION } from "./containers/Activity/utilities/filters"
import ErrorBoundary from "components/ErrorBoundary"
import { SidebarTab } from "../Navigation/sharedTypes"
import { usePatientPortalQuery } from "applications/Workflow/api"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { primaryPolicyFor } from "applications/Workflow/utilities/payment"

interface Analytics {
  closed: string
  open: string
}

export interface Tab {
  icon: React.ReactNode
  sidebarTab: SidebarTab
  alertDot?: boolean
  tooltip?: string
  analytics?: Analytics
  tabName: string
  title: string
  subtitle?: React.ReactNode
  content: React.ReactNode
  headerButton?: React.ReactNode
}

type Props = {
  hasNewComments: boolean
  tab: SidebarTab
  setSidebarTab(tab: SidebarTab): void
  close(): void
  refreshDmeOrder(): Promise<void>
  setDmeOrder(dmeOrder: DmeOrder): void
  dmeOrder: DmeOrder
  warnings: DmeOrderWarning[]
}

const Sidebar: React.FC<Props> = ({
  hasNewComments,
  tab,
  setSidebarTab,
  close,
  refreshDmeOrder,
  setDmeOrder,
  dmeOrder,
  warnings,
}) => {
  const { currentEmployer } = useContext(GlobalContext)
  const patientPortalEndpoint = buildPatientPortalEndpoint()
  const { isFeatureEnabled } = useFeatureFlags()

  const [selectedOption, setSelectedOption] = useState(DEFAULT_FILTER_OPTION)
  const [unreadPatientMessages, setUnreadPatientMessages] = useState(false)

  const portalDataParams = {
    orderId: dmeOrder.id,
    globalFacilityEnabled: dmeOrder.clinicalFacility.patientPortalEnabled,
    globalSupplierEnabled: dmeOrder.supplier?.patientPortalEnabled,
    latestPatientPortalEventTimestamp:
      dmeOrder.latestPatientPortalEventTimestamp,
  }

  // @ts-ignore
  const { data: portalData } = usePatientPortalQuery(portalDataParams)

  useEffect(() => {
    if (portalData) {
      setUnreadPatientMessages(!!portalData.unreadPatientMessages)
    }
  }, [portalData])

  const chatEnabled = !!(
    portalData &&
    portalData.phoneCanReceiveSms &&
    portalData.portalMessagingEnabled &&
    dmeOrder.supplier?.patientPortalEnabled
  )

  const showPatientChat =
    chatEnabled || !!(portalData && portalData.serviceValidationCompleted)

  const tabs = useMemo(() => {
    const shouldRenderMessagingTab = (
      portalData: Nullable<PatientPortalData> | undefined
    ): boolean => {
      if (portalData && portalData.serviceValidationCompleted) {
        return true
      } else if (isFeatureEnabled("chatCommercialization")) {
        return !!(portalData && portalData.chatId)
      } else {
        return !!(
          portalData &&
          portalData.chatId &&
          portalData.portalMessagingSupplierEnabled
        )
      }
    }

    const primaryInsurancePolicy = primaryPolicyFor(dmeOrder)
    const payorActivityFeedInclusion =
      isFeatureEnabled("payorOrderDetails") &&
      !!primaryInsurancePolicy?.payorEnhancedWorkflowEnabled

    const tabs: Tab[] = [
      {
        icon: (
          <div
            className={classNames("far fa-comments", {
              "new-comments": hasNewComments,
            })}
          ></div>
        ),
        tabName: "Activity",
        title: getActivityTitle(
          currentEmployer.employerType,
          dmeOrder.lineItemGroups,
          dmeOrder.permissions.comment,
          payorActivityFeedInclusion
        ),
        subtitle: !dmeOrder.permissions.comment && (
          <p className="color-dark-gray font-subparagraph">
            <Icon type="lock" size="sm" /> Read only
          </p>
        ),
        content: (
          <Activity
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            dmeOrder={dmeOrder}
            clinicalFacility={dmeOrder.clinicalFacility}
            warnings={warnings}
            employer={currentEmployer}
          />
        ),
        sidebarTab: SidebarTab.Activity,
        tooltip: "Activity Feed",
        analytics: {
          open: "activity-tab:from-open",
          closed: "activity-tab:from-closed",
        },
      },
      {
        icon: <div className="far fa-file-alt"></div>,
        tabName: "Documents",
        title: "Documents",
        sidebarTab: SidebarTab.Documents,
        tooltip: "Order Documents",
        content: (
          <Documents
            refreshDmeOrder={refreshDmeOrder}
            setDmeOrder={setDmeOrder}
            dmeOrderId={dmeOrder.id}
            supplierFaxNumber={dmeOrder.supplierFaxNumber}
            hasSigningClinician={!!dmeOrder.doctor}
            documentationRequirements={dmeOrder.documentationRequirements}
          />
        ),
        analytics: {
          open: "document-tab:from-open",
          closed: "document-tab:from-closed",
        },
      },
    ]
    tabs.push({
      icon: <div className="fas fa-shopping-cart"></div>,
      tabName: "Cart",
      title: "Cart",
      sidebarTab: SidebarTab.Cart,
      tooltip: "Cart",
      content: <Cart dmeOrder={dmeOrder} refreshDmeOrder={refreshDmeOrder} />,
      analytics: {
        open: "cart-tab:from-open",
        closed: "cart-tab:from-closed",
      },
    })

    if (dmeOrder.permissions.sidebar.showTransferredOrderOriginalCart) {
      tabs.push({
        icon: <div className="fas fa-exchange-alt"></div>,
        tabName: "SupplierTransferOriginalOrder",
        title: "Original Cart",
        sidebarTab: SidebarTab.SupplierTransfer,
        tooltip: "Transferred Order Original Cart",
        content: (
          <TransferredOrderOriginalCart
            dmeOrder={dmeOrder}
            warnings={warnings}
            employer={currentEmployer}
          />
        ),
        analytics: {
          open: "supplier-transfer-tab:from-open",
          closed: "supplier_transfer-tab:from-closed",
        },
      })
    }

    const messagingTabText = () => {
      if (chatEnabled) {
        return {
          title: "Chat with the patient",
          subtitle: (
            <p className="color-dark-gray font-notice">
              Not visible to referral.
            </p>
          ),
        }
      } else if (portalData && portalData.serviceValidationCompleted) {
        return {
          title: "Patient's Feedback",
          subtitle: (
            <p className="color-dark-gray font-notice">
              A patient provided post-delivery feedback
            </p>
          ),
        }
      } else {
        return {
          title: "",
          subtitle: <></>,
        }
      }
    }

    if (shouldRenderMessagingTab(portalData)) {
      tabs.push({
        alertDot: unreadPatientMessages,
        icon: (
          <PatientChatIcon classes="d-block canopy-m-auto" fontSize="small" />
        ),
        tabName: "PatientMessaging",
        title: messagingTabText().title,
        subtitle: messagingTabText().subtitle,
        sidebarTab: SidebarTab.PatientMessaging,
        tooltip: "Patient Messaging",
        content: (
          <PatientPortal
            dmeOrderId={dmeOrder.id}
            chatId={portalData!.chatId}
            supplierName={portalData!.supplierName}
            supplierId={dmeOrder.supplier?.externalId}
            chatAvailable={showPatientChat}
            patientPortalEndpoint={patientPortalEndpoint}
            setUnreadPatientMessages={setUnreadPatientMessages}
            portalMessagingSupplierEnabled={
              portalData!.portalMessagingSupplierEnabled
            }
          />
        ),
        analytics: {
          open: "patient-messaging-tab:from-open",
          closed: "patient-messaging-tab:from-closed",
        },
        headerButton: showPatientChat && (
          <ChatSettings chatId={portalData!.chatId} />
        ),
      })
    }

    return tabs
  }, [
    showPatientChat,
    patientPortalEndpoint,
    portalData,
    selectedOption,
    currentEmployer,
    dmeOrder,
    hasNewComments,
    refreshDmeOrder,
    setDmeOrder,
    unreadPatientMessages,
    warnings,
    isFeatureEnabled,
    chatEnabled,
  ])

  return (
    <ErrorBoundary>
      <RightSidebar
        fixed
        tabs={tabs}
        onClick={({ sidebarTab }) => setSidebarTab(sidebarTab)}
        onClose={close}
        currentTab={tab}
        orderStatus={dmeOrder.orderStatus}
        portalMessagingSupplierEnabled={
          !!(portalData && portalData.portalMessagingSupplierEnabled)
        }
        serviceValidationCompleted={
          !!(portalData && portalData.serviceValidationCompleted)
        }
      />
    </ErrorBoundary>
  )
}

export default Sidebar
