import React, { useCallback, useState } from "react"
import {
  CanopyComboboxField,
  CanopyComboboxFieldProps,
  SelectOption,
  SelectOptions,
} from "@parachutehealth/canopy-combobox-field"
import { OptionItem } from "@parachutehealth/canopy-select"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"

export type FreesoloComboboxProps = {
  options: SelectOptions
  onChange: (newValue: SelectOption) => void
  defaultValue?: string
} & Omit<CanopyComboboxFieldProps, "multiple" | "ref">

/**
 * This component is a simple wrapper around Canopy's Combobox, with the difference that it allows adding a new option.
 * It is purpose-built to handle the creation of attribute descriptions (text) or values (numeric) in all the places
 * where we allow such things.
 * When an option is created by the user, it will be yielded in the onChange event in the form of
 * an enhanced OptionItem -- namely,
 * @example
 *  { label: "User-created", value: "User-created", freeSolo: true }
 * This will allow calling components to easily differentiate the user-created items from those provided as props.
 */
const FreesoloCombobox = (props: FreesoloComboboxProps): React.JSX.Element => {
  const { options, onChange, defaultValue = "", ...rest } = props

  const [savedOptions, setSavedOptions] = useState<SelectOptions>(options)
  const [filteredOptions, setFilteredOptions] = useState<SelectOptions>(
    savedOptions
  )
  const [value, setValue] = useState<SelectOption | null>(defaultValue)

  const valueAsString = (option: SelectOption): string => {
    return get(option, "value") || option?.toString() || ""
  }

  const displayValueAsString = (option: SelectOption): string => {
    return get(option, "label") || option?.toString() || ""
  }

  const dynamicLabel = (userValue: string) => {
    return `Add "${userValue}"`
  }

  const filterOptions = useCallback(
    (enteredValue: string) => {
      const cleanValue = enteredValue.trim()
      // this seems to be needed to allow, e.g., backspacing or clearing the input with the delete key
      if (cleanValue.length === 0) {
        setValue(null)
      }

      if (cleanValue.length > 0) {
        const options = savedOptions.filter((o) => {
          return displayValueAsString(o)
            .toLowerCase()
            .includes(cleanValue.toLowerCase())
        })

        if (!options.find((o) => displayValueAsString(o) === cleanValue)) {
          // Insert an "Add New" option at the end of the options list
          const appendedOption = {
            value: cleanValue,
            label: dynamicLabel(cleanValue),
            freeSolo: true,
          }
          options.push(appendedOption)
        }
        setFilteredOptions(options)
      } else {
        // reset to the default set of options
        setFilteredOptions(savedOptions)
      }
    },
    [savedOptions]
  )

  /**
   * A user can clear the combobox via the `x` button, but they can also just
   * erase the text, which doesn't inherently clear the value.
   * If a user clears the input and blurs, treat that like a clearing function yield null
   * to both the internal value and the onChange function passed by the caller.
   */
  const handleBlur = (event) => {
    if (isEmpty(event.target?.value)) {
      setValue(null)
      onChange(null)
    }
  }

  const handleSelectionChange = useCallback(
    (item: OptionItem | OptionItem[] | null) => {
      let option = item as OptionItem | null
      if (
        !isEmpty(option) &&
        !savedOptions.find((o) => valueAsString(o) === option!.value)
      ) {
        // The set of options doesn't include this value, add it
        option = { label: option.value, freeSolo: true, value: option.value }
        setSavedOptions([
          ...savedOptions,
          { label: option.value, freeSolo: true, value: option.value },
        ])
      }

      if (!isEmpty(option)) {
        setValue(option)
        onChange(option)
      } else {
        setValue(null)
        onChange(null)
      }
    },
    // don't worry about onChange
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [savedOptions]
  )

  return (
    <CanopyComboboxField
      size="small"
      onChange={handleSelectionChange}
      options={filteredOptions}
      value={value}
      onBlur={handleBlur}
      defaultValue={defaultValue}
      onInputChange={filterOptions}
      {...rest}
    />
  )
}

export default FreesoloCombobox
