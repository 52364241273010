import React from "react"
import DmeOrdersTable from "./components/DmeOrdersTable/DmeOrdersTable"
import { useLazyQuery } from "@apollo/client"
import { DmeOrderConnection } from "../../../../../graphql/__generated__/graphql"
import { SearchBar } from "../../components/SearchBar/SearchBar"
import { MemberSearch_GetPayorDmeOrders } from "../graphql/memberSearchGetDmeOrders"

const MemberSearchDashboard = () => {
  const [
    getPayorDmeOrders,
    { data, loading, called, fetchMore },
  ] = useLazyQuery(MemberSearch_GetPayorDmeOrders, {
    fetchPolicy: "network-only",
  })

  const dmeOrders = data?.payorDmeOrders as DmeOrderConnection

  const handleSearch = async (memberId: string) => {
    if (memberId) {
      await getPayorDmeOrders({ variables: { memberId: memberId } })
    }
  }

  return (
    <>
      <h1 className="canopy-typography-heading-2xlarge canopy-mbs-12x canopy-mbe-0">
        Member Search
      </h1>
      <p className="canopy-mbe-20x">
        Search for members to find their recent orders
      </p>
      <SearchBar label="Member ID" required={true} onSearch={handleSearch} />
      {called && (
        <DmeOrdersTable
          dmeOrdersData={dmeOrders}
          loading={loading}
          fetchMore={fetchMore}
        />
      )}
    </>
  )
}

export default MemberSearchDashboard
