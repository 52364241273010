import React, { useEffect, useState } from "react"
import * as api from "./api"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import { infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually } from "../../../../queries"
import { isOptionItem } from "../../helpers"
import {
  useErrorContext,
  useMissingRequiredExternalSystemBranch,
} from "applications/SupplierOrganizationInboxDocument/error-context"
import { useDebounce } from "hooks/useDebounce"
import { useFacilityQuery } from "../../FacilityData"
import { useEffectThatWontRunOnMount } from "hooks/useEffectThatWontRunOnMount"

export const SELECT_BRIGHT_TREE_BRANCH_PLACEHOLDER =
  "Select Brightree Branch for this Facility"

const externalSystemBranchQueryKey = ["csrInboxState", "externalSystemBranch"]
export const setExternalSystemBranchQueryData = (
  client: QueryClient,
  externalSystemBranchData: ExternalSystemBranchData
) => {
  client.setQueryData(externalSystemBranchQueryKey, externalSystemBranchData)
}

export const useExternalSystemBranchQuery = () => {
  return useQuery<ExternalSystemBranchData>({
    queryKey: externalSystemBranchQueryKey,
    queryFn: () => ({}),
    staleTime: infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  })
}

export type ExternalSystemBranchData =
  | {
      id?: string
      name?: string
    }
  | undefined

export const ExternalSystemBranchSelect = () => {
  const { data: externalSystemBranch } = useExternalSystemBranchQuery()
  const [searchTerm, setSearchTerm] = React.useState<string>()
  const { supplier, supplierOrganization, id } = useCsrInboxState()
  const { data: facilityData } = useFacilityQuery()
  const { submissionAttempts } = useErrorContext()
  const facilityId = facilityData?.facility?.id
  const isMissingRequiredExternalSystemBranch = useMissingRequiredExternalSystemBranch(
    supplier.brightreeAcceptance
  )

  const [
    externalSystemBranchOptionItem,
    setExternalSystemBranchOptionItem,
  ] = useState<OptionItem>()
  const [displayErrorMessage, setDisplayErrorMessage] = useState(
    !!submissionAttempts && isMissingRequiredExternalSystemBranch
  )

  const mutateBranch = useMutation({
    mutationFn: (branch: ExternalSystemBranchData) => {
      return api
        .updateBranch(supplierOrganization.id, id, branch?.id || null)
        .then(() => {
          queryClient.setQueryData<ExternalSystemBranchData>(
            externalSystemBranchQueryKey,
            { id: branch?.id, name: branch?.name }
          )
        })
    },
  })

  const queryClient = useQueryClient()
  const [preExistingDefaultBranch, setPreExistingDefaultBranch] = useState(
    !!facilityId
  )

  const selectExternalSystemBranch = (value: OptionItem | undefined) => {
    setExternalSystemBranchOptionItem(value)
    mutateBranch.mutateAsync(
      value ? { name: value.label || "", id: value.value } : undefined
    )
  }

  useEffect(() => {
    if (facilityId) {
      api
        .fetchPreExistingExternalSystemBranch(
          supplierOrganization.id,
          id,
          facilityId
        )
        .then((branch) => {
          if (branch?.id) {
            selectExternalSystemBranch({
              label: branch.name,
              value: branch.id,
            })
            setPreExistingDefaultBranch(true)
          } else {
            setPreExistingDefaultBranch(false)
          }
        })
        .catch()
    } else {
      setPreExistingDefaultBranch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId])

  useEffectThatWontRunOnMount(() => {
    setDisplayErrorMessage(isMissingRequiredExternalSystemBranch)
  }, [isMissingRequiredExternalSystemBranch, submissionAttempts])

  useEffect(() => {
    setExternalSystemBranchOptionItem(
      externalSystemBranch
        ? ({
            label: externalSystemBranch.name,
            value: externalSystemBranch.id,
          } as OptionItem)
        : undefined
    )
  }, [externalSystemBranch])

  const fetchExternalSystemBranches = useDebounce(async (term) =>
    api.fetchExternalSystemBranches(supplier.id, term)
  )

  const { data: options } = useQuery({
    queryKey: ["branches", id, searchTerm],
    queryFn: async () => {
      if (searchTerm) {
        return fetchExternalSystemBranches(searchTerm)
          .then((results) => {
            return results.map((result) => ({
              label: result.name,
              value: result.id,
            }))
          })
          .catch(() => {
            return []
          })
      }
      return []
    },
  })

  return (
    <CanopyComboboxField
      label="Brightree Branch"
      placeholder={SELECT_BRIGHT_TREE_BRANCH_PLACEHOLDER}
      onInputChange={setSearchTerm}
      feedbackMessage={
        displayErrorMessage
          ? "A branch is required to create an order"
          : undefined
      }
      onChange={(newValue) => {
        if (!newValue) {
          selectExternalSystemBranch(undefined)
        } else if (isOptionItem(newValue)) {
          selectExternalSystemBranch(newValue)
        } else {
          console.warn(
            "new value on canopycomboboxfield was not an option item? That shouldn't happen",
            newValue
          )
        }
      }}
      required={true}
      multiple={false}
      disabled={preExistingDefaultBranch}
      options={options || []}
      value={externalSystemBranchOptionItem}
      noOptionsFoundMessage="No branch found for that search"
    />
  )
}
