import React from "react"
import * as styles from "./index.module.scss"

const ErrorMessageContainer = () => {
  return (
    <>
      <div className={`position-relative ${styles.container}`}>
        <div className="canopy-typography-heading-medium">
          Something went wrong
        </div>
        <div className="canopy-typography-body-small">
          We couldn't load the health plan's questionnaire(s) for this order.
        </div>
      </div>
    </>
  )
}

export default ErrorMessageContainer
