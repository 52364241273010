import { del, post, put } from "services/api"
import {
  followersUrl,
  patientUrl,
  unfollowOrderUrl,
} from "applications/Workflow/urls"

export const updatePatient = (id, params) => {
  return put(`${patientUrl(id)}`, { patient: params })
}

export const followOrder = () => {
  return post(followersUrl())
}

export const unfollowOrder = (followingId: string) => {
  return del(unfollowOrderUrl(followingId))
}
