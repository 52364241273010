import AuthStatusPill from "applications/Insurance/CarrierAuthorizationRequest/components/AuthStatusPill/AuthStatusPill"
import {
  AuthStatusToSeverity,
  AuthStatusToTitle,
} from "applications/Insurance/CarrierAuthorizationRequest/utilities/statusFormatters"
import { CarrierAuthStatusesEnum } from "graphql/__generated__/graphql"
import React from "react"

type Props = {
  status: CarrierAuthStatusesEnum
}

const CarrierAuthorizationStatus = ({ status }: Props) => {
  return (
    <>
      <span className="font-xs canopy-mie-4x">Authorization status:</span>
      <AuthStatusPill
        statusLevel={AuthStatusToSeverity[status]}
        statusText={AuthStatusToTitle[status]}
      />
    </>
  )
}

export default CarrierAuthorizationStatus
