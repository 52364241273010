import React from "react"
import Markdown from "markdown-to-jsx"

type Props = {
  text: string
}

const options = {
  disableAutoLink: true,
  disableParsingRawHTML: true,
  forceBlock: true,
  overrides: {
    a: {
      props: {
        target: "_blank",
        rel: "noopener noreferrer",
      },
    },
  },
}

export const MarkdownText = ({ text }: Props) => {
  return <Markdown options={options}>{text}</Markdown>
}
