// @ts-strict-ignore
import React from "react"
import {
  ChartNote,
  DmeOrderPermissions,
  DmeOrderWarning,
  DocumentationRequirement,
  DocumentationRequirementSatisfyMethod,
  DocumentationRequirementSatisfyStatus,
  PackageConfigurationWarningMetadata,
  QuestionnaireResponse,
} from "sharedTypes"
import DocumentationRequirementRow from "./../DocumentationRequirements/DocumentationRequirementRow"
import Alert from "components/Alert"
import {
  productsPackageConfigurationPath,
  questionnaireResponsePath,
} from "applications/Workflow/routes"
import { Link } from "react-router-dom"
import ClinicalInformationRow from "../DocumentationRequirements/ClinicalInformationRow"
import { isChartNoteRelatedToDocumentationRequirement } from "./../utilities"
import { useFeatureFlags } from "components/FeatureFlagContext"
import AttachManualDocumentationModalButton from "../DocumentationRequirements/AttachManualDocumentationModalButton"
import ExternalQuestionnaireStatusPill from "applications/Workflow/containers/Review/components/ExternalQuestionnaireStatusPill"
import MedicalNecessityReviewButton from "./MedicalNecessityReviewButton"
import { EditDocumentationLink } from "../DocumentationRequirements/DocumentationRequirementRow/GenerateDetails"
import ErrorMessageContainer from "./ErrorMessageContainer"

interface Props {
  questionnaireResponses?: QuestionnaireResponse[]
  chartNotes: ChartNote[]
  className: string
  dmeOrderId: string
  documentationRequirements: DocumentationRequirement[]
  hasSigningClinician: boolean
  permissions: DmeOrderPermissions
  supplierFaxNumber: string
  updateSatisfyMethod(
    documentationRequirement: DocumentationRequirement,
    satisfyMethod: DocumentationRequirementSatisfyMethod
  ): Promise<void>
  uploadDocuments(
    documentationRequirementIds: string[],
    files: File[],
    signed: boolean,
    otherAttachmentTitle: string
  ): Promise<void>
  updateDocumentationRequirementsSatisfyStatus(values: {
    manualSatisfyStatus: DocumentationRequirementSatisfyStatus
    documentationRequirementIds: string[]
  })
  warnings: DmeOrderWarning[]
  bounceToSection: {
    shouldBounce: boolean
    triggerBounce: () => void
  }
  setShowIcd10Error: (show: boolean) => void
  discoverDmeGuidelinesResponseRequestStatus?: string
}

const OptumDocumentationRequirements = ({
  chartNotes,
  className,
  dmeOrderId,
  documentationRequirements,
  hasSigningClinician,
  permissions,
  supplierFaxNumber,
  questionnaireResponses,
  updateSatisfyMethod,
  uploadDocuments,
  updateDocumentationRequirementsSatisfyStatus,
  warnings,
  bounceToSection,
  setShowIcd10Error,
  discoverDmeGuidelinesResponseRequestStatus,
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const contextDrivenDocs = isFeatureEnabled("contextDrivenDocs")
  const demoClinicalGuidelines = isFeatureEnabled("demoClinicalGuidelines") // dmeOrder.payorEnhancedWorkflowEnabled check not required because OptumDocumentationRequirements is only used for optum orders

  if (!documentationRequirements.length) {
    return <></>
  }

  const packageConfigurationWarning = warnings.find(
    (warning) =>
      warning.key === "rx_details" || warning.key === "product_requirements"
  )

  const hasOptumErrors = () => {
    return (
      discoverDmeGuidelinesResponseRequestStatus !== null ||
      questionnaireResponses.some(
        (questionnaireResponse) => questionnaireResponse.errors !== null
      )
    )
  }

  const renderActionButton = () => {
    return (
      <div className="canopy-mbs-12x">
        <div className="canopy-mbe-4x">
          <MedicalNecessityReviewButton
            chartNotes={chartNotes}
            questionnaireResponses={questionnaireResponses}
          />
        </div>
        <div className="canopy-typography-heading-medium canopy-mb-6x canopy-mt-10x">
          Have documents that support the medical necessity of your order?
        </div>
        <AttachManualDocumentationModalButton
          btnClass="btn-brand-o"
          documentationRequirements={documentationRequirements}
          uploadDocuments={uploadDocuments}
          dmeOrderId={dmeOrderId}
          supplierFaxNumber={supplierFaxNumber}
          hasSigningClinician={hasSigningClinician}
          updateDocumentationRequirementsSatisfyStatus={
            updateDocumentationRequirementsSatisfyStatus
          }
          manuallySatisfyGeneratedDocs={
            permissions.manuallySatisfyGeneratedDocs
          }
        />
      </div>
    )
  }

  const requirementRows = () => {
    return (
      <>
        <div className="well canopy-p-8x">
          {documentationRequirements.map((documentationRequirement, index) => (
            <div key={documentationRequirement.externalId}>
              {index !== 0 && <hr />}
              <DocumentationRequirementRow
                dmeOrderId={dmeOrderId}
                chartNote={chartNotes.find((cn) =>
                  isChartNoteRelatedToDocumentationRequirement(
                    documentationRequirement,
                    cn,
                    contextDrivenDocs
                  )
                )}
                documentationRequirement={documentationRequirement}
                permissions={permissions}
                missingChartNotesPrerequisites={!!packageConfigurationWarning}
                updateSatisfyMethod={updateSatisfyMethod}
                bounceToSection={bounceToSection}
                detailed
                setShowIcd10Error={setShowIcd10Error}
              />
            </div>
          ))}
          {!demoClinicalGuidelines &&
            !hasOptumErrors() &&
            questionnaireResponses.map((questionnaireResponse) => (
              <div key={questionnaireResponse.id}>
                <hr />
                <ClinicalInformationRow
                  showDetails={true}
                  title={questionnaireResponse.title}
                  isDocumentParachuteGenerated={true}
                  status={
                    <ExternalQuestionnaireStatusPill
                      satisfyStatus={questionnaireResponse.status}
                    />
                  }
                  details={
                    <EditDocumentationLink
                      bounceToSection={bounceToSection}
                      setShowIcd10Error={setShowIcd10Error}
                      itemId={questionnaireResponse.id}
                      linkPath={questionnaireResponsePath(
                        questionnaireResponse.id
                      )}
                      satisfyStatus={questionnaireResponse.status}
                    />
                  }
                />
              </div>
            ))}
          {!demoClinicalGuidelines && hasOptumErrors() && (
            <>
              <hr />
              <ErrorMessageContainer />
            </>
          )}
        </div>
        {renderActionButton()}
      </>
    )
  }

  return (
    <div className={className}>
      <div className="well">
        <div className="row canopy-px-8x">
          <div className="canopy-typography-heading-large canopy-mb-8x">
            Demonstrate medical necessity
          </div>
        </div>
        {packageConfigurationWarning && (
          <Alert status="danger" leftIcon>
            The documentation requirements are disabled because of missing
            details for your product(s).
            <Link
              className="canopy-pis-2x"
              to={productsPackageConfigurationPath(
                null,
                (packageConfigurationWarning.metadata as PackageConfigurationWarningMetadata)
                  .packageConfigurationId,
                true
              )}
            >
              Click here to add product details
            </Link>
          </Alert>
        )}
        {requirementRows()}
      </div>
    </div>
  )
}

export default OptumDocumentationRequirements
