// @team @paypals

import React from "react"
import Warnings from "./Warnings"
import { DmeOrderWarning } from "sharedTypes"
import BasicSupplierWarning from "applications/Workflow/components/BasicSupplierWarning"
import { Button, Form, TextArea } from "components/form"
import Alert from "components/Alert"
import { isCtrlEnter } from "utilities/browser/event"

type Props = {
  createComment: (values: { content: string }) => Promise<void>
  warnings: DmeOrderWarning[]
  warningMessages: string[]
}

const PayorCommentForm = ({
  warnings,
  createComment,
  warningMessages,
}: Props) => {
  const basicSupplierWarning = warnings.find(
    (warning) => warning.key === "basic_supplier"
  )
  return (
    <div>
      <Warnings warnings={warningMessages} />
      {!!basicSupplierWarning && (
        <BasicSupplierWarning warning={basicSupplierWarning} />
      )}
      {/* {TODO: migrate to all comment forms to use canopy form to prevent one-offs} */}
      <Form
        initialValues={{ content: "" }}
        onSubmit={(values, actions) =>
          createComment(values).then(() => {
            actions.resetForm()
          })
        }
        className="form-group-container full-width"
      >
        {({ isSubmitting, submitForm, values }) => (
          <>
            {/* TODO: migrate to canopy text area if/when the critical guidance prop can support icons? */}
            <TextArea
              autoFocus
              placeholder="Type a global comment"
              name="content"
              onKeyDown={(event) => isCtrlEnter(event) && submitForm()}
              disabled={isSubmitting}
              alert={
                <Alert
                  leftIcon="fa-eye"
                  status="default"
                  className="bg-lightest-gray"
                >
                  Visible to everyone, including supplier and ordering facility.
                  Do not post credit card info.
                </Alert>
              }
            />
            {values.content && (
              <>
                <div className="row">
                  <div className="text-right col-xl-6 offset-xl-6 col-lg-6 offset-lg-6">
                    {/* TODO: consider migrating all comment forms to use canopy button? */}
                    <Button
                      type="submit"
                      className="btn-brand btn-block"
                      disabled={isSubmitting}
                    >
                      Post Comment
                    </Button>
                  </div>
                </div>
                <p className="text-right canopy-mbs-8x color-dark-gray font-notice">
                  or press 'Ctrl+Enter' to post
                </p>
              </>
            )}
          </>
        )}
      </Form>
    </div>
  )
}

export default PayorCommentForm
