// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-CsrInboxStateAiDetails-components-index-module__beta-badge--RozbC";
var _2 = "app-javascript-applications-CsrInboxStateAiDetails-components-index-module__indicator--P6MXc";
var _3 = "app-javascript-applications-CsrInboxStateAiDetails-components-index-module__markdown-container--pfVJY";
var _4 = "app-javascript-applications-CsrInboxStateAiDetails-components-index-module__new-flow-beta-badge--lsuce";
var _5 = "app-javascript-applications-CsrInboxStateAiDetails-components-index-module__see-more-or-less-button--M0KPK";
var _6 = "app-javascript-applications-CsrInboxStateAiDetails-components-index-module__success-indicator--_a7CH";
var _7 = "app-javascript-applications-CsrInboxStateAiDetails-components-index-module__summary--BRmox";
var _8 = "app-javascript-applications-CsrInboxStateAiDetails-components-index-module__warning-indicator--DHu0y";
export { _1 as "betaBadge", _2 as "indicator", _3 as "markdownContainer", _4 as "newFlowBetaBadge", _5 as "seeMoreOrLessButton", _6 as "successIndicator", _7 as "summary", _8 as "warningIndicator" }
