import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { handleError } from "utilities/error"
import { navigate } from "utilities/navigation"

type AttachButtonProps = {
  onClick: () => Promise<{ success: boolean }>
  successUrl: string
  errorMessage?: string
  ariaLabel?: string
}

export const AttachButton: React.FC<AttachButtonProps> = ({
  onClick,
  successUrl,
  ariaLabel = "Attach document to order",
}: AttachButtonProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const errorMessage =
    "Error attaching document to order. Please refresh the page and try again."

  const handleClick = async () => {
    setIsLoading(true)
    onClick().then((response) => {
      setIsLoading(false)
      if (!response.success) {
        handleError(undefined, errorMessage)
      } else {
        navigate(successUrl)
      }
    })
  }

  return (
    <CanopyButton
      variant="secondary"
      size="small"
      iconStart="paperclip"
      onClick={handleClick}
      disabled={isLoading}
      aria-label={ariaLabel}
    >
      Attach
    </CanopyButton>
  )
}
