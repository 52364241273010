import React, { useState, useEffect } from "react"
import Skeleton from "@material-ui/lab/Skeleton"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyText } from "@parachutehealth/canopy-text"
import { useCsrInboxState } from "../../../csrInboxStateContext"
import { getExternalOrderDetails } from "./api"
import { handleError } from "utilities/error"
import * as styles from "./index.module.scss"

type Props = {
  orderId: string
  preFetchItems: boolean
}

export const ExternalOrderLineItems = ({ orderId, preFetchItems }: Props) => {
  const [loading, setLoading] = useState(false)
  const [displayButton, setDisplayButton] = useState(true)
  const [displayLineItems, setDisplayLineItems] = useState(false)
  const [lineItemsToDisplay, setLineItemsToDisplay] = useState<String[]>([
    "This order has no items.",
  ])
  const csrInboxState = useCsrInboxState()

  const fetchLineItems = async () => {
    setLoading(true)
    setDisplayButton(false)
    await getExternalOrderDetails(
      csrInboxState.supplierOrganization.id,
      csrInboxState.supplier.id,
      orderId
    )
      .then((response) => {
        if (response.salesOrderItems === null) {
          setLoading(false)
          setDisplayLineItems(true)
          return
        }
        const itemInfo = response.salesOrderItems.salesOrderItemInfo
        if (Array.isArray(itemInfo)) {
          const lineItems = itemInfo.map(
            (item) =>
              `${
                item.itemName[0].toUpperCase() +
                item.itemName.slice(1).toLowerCase()
              }, `
          )
          setLineItemsToDisplay(lineItems)
        } else if (itemInfo.itemName) {
          setLineItemsToDisplay([
            itemInfo.itemName[0].toUpperCase() +
              itemInfo.itemName.slice(1).toLowerCase(),
          ])
        }
        setLoading(false)
        setDisplayLineItems(true)
      })
      .catch(() => {
        handleError()
        setLoading(false)
        setDisplayButton(true)
      })
  }

  useEffect(() => {
    if (preFetchItems) {
      fetchLineItems()
    }
    // Ignoring deps warning because this only should run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading && <Skeleton animation="wave" width="240px" />}
      {displayButton && (
        <CanopyButton
          variant="tertiary"
          size="small"
          className={`${styles.paddingUnset} ${styles.minHeightUnset} ${styles.colorInteractiveLinkDefault}`}
          onClick={fetchLineItems}
        >
          Show items
        </CanopyButton>
      )}
      {displayLineItems && (
        <CanopyText variant="secondary" size="small" className="canopy-pt-1x">
          {lineItemsToDisplay}
        </CanopyText>
      )}
    </>
  )
}
