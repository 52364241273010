import { patch } from "services/api"
import { employerPrefix } from "utilities/url"

export const forwardDocument = ({
  supplierOrganizationId,
  csrInboxStateId,
  forwardedToEmails,
}: {
  supplierOrganizationId: string
  csrInboxStateId: string
  forwardedToEmails: string
}): Promise<
  { success: boolean } | { errors: { forwardedToEmails: string[] } | {} }
> => {
  const pathToCsrInboxState = `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox_documents/${csrInboxStateId}/forwarding`
  return patch(pathToCsrInboxState, {
    forwardedToEmails: forwardedToEmails,
  })
    .then(() => ({ success: true }))
    .catch((e) => ({ errors: e.response?.data?.errors || {} }))
}
