import React from "react"
import { CanopyText } from "@parachutehealth/canopy-text"
import * as styles from "./index.module.scss"

type Props = {
  text: string
  variant: "filled" | "outlined"
}

export const Badge = ({ text, variant = "outlined" }: Props) => {
  return (
    <div
      className={`
      ${styles.roundedBorders} ${styles.fitContent} 
        ${
          variant === "filled"
            ? styles.canopyBackgroundColorGray32
            : styles.canopyBorderColorDefault
        }
        canopy-px-4x canopy-py-2x
      `}
    >
      <CanopyText
        size="xSmall"
        variant={variant === "filled" ? "inverse" : "secondary"}
        weight="bold"
      >
        {text}
      </CanopyText>
    </div>
  )
}
