// @team @expanse

import React, { useState } from "react"
import { navigate } from "utilities/navigation"
import ReorderErrorModal from "applications/PatientProfile/components/ReorderErrorModal"
import { employerTypeFromContext } from "utilities/url"
import { makeApi } from "applications/PatientProfile/api"
import { Button } from "components/form"
import { Employer } from "sharedTypes"

type Props = {
  dmeOrderId: string
  currentEmployer: Employer
  buttonClassName?: string
}

export const useReorder = (
  currentEmployer
): {
  open: boolean
  setOpen: (open: boolean) => void
  reorderDmeOrder(dmeOrderId: string): Promise<void>
  errors: string
} => {
  const [open, setOpen] = useState(false)
  const [errors, setErrors] = useState("")
  const urlEmployerType = employerTypeFromContext(currentEmployer.employerType)
  const api = makeApi(urlEmployerType, currentEmployer.employerId)
  const reorderDmeOrder = async (dmeOrderId: string) => {
    try {
      const response = await api.reorderDmeOrder(dmeOrderId)

      if (!!response.data.dmeOrderPath) {
        navigate(response.data.dmeOrderPath)
      }
    } catch (error: any) {
      setErrors(error.response.data.errors.join(", "))
      setOpen(true)
    }
  }
  return { open, setOpen, reorderDmeOrder, errors }
}

const ReorderForm = (props: Props): React.JSX.Element => {
  const [isSubmitting, setSubmitting] = useState(false)
  const { open, setOpen, reorderDmeOrder, errors } = useReorder(
    props.currentEmployer
  )
  const { dmeOrderId, buttonClassName } = props

  const handleSubmit = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setSubmitting(true)
    reorderDmeOrder(dmeOrderId).then(() => setSubmitting(false))
  }

  return (
    <>
      <Button
        type="submit"
        className={buttonClassName || "btn-brand btn-block"}
        loading={isSubmitting}
        loadingText="Loading..."
        disabled={isSubmitting}
        onClick={handleSubmit}
      >
        Reorder
      </Button>
      <ReorderErrorModal open={open} setOpen={setOpen} errorMessage={errors} />
    </>
  )
}

export default ReorderForm
