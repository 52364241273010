import { CanopyIconNames } from "@parachutehealth/canopy-icons/build/canopyIconGlyphNames"
import React from "react"
import * as styles from "./CardSelect.module.scss"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import classNames from "classnames"
import _ from "lodash"

/**
 * Specifies the data within a single "option" that can be passed
 * in from outside the component.
 */
export type CardSelectOption = {
  /**
   * Will show as an icon on the lefthand side of the card; must be a known Canopy Icon.
   */
  icon: CanopyIconNames
  /**
   * Will show as a bold-weight "title" for the card
   */
  label: string
  /**
   * The internal value that will be yielded via callback when the card is selected
   */
  value: string
  /**
   * Descriptive text that will be displayed below the main title of the card.
   */
  description: string
  /**
   * If true, the card will be greyed out and not able to be selected.
   */
  disabled?: boolean
}

type CardSelectOptionProps = {
  selected: boolean
  onSelect: (value: string) => void
} & CardSelectOption

export type CardSelectProps = {
  /**
   * When specified, the corresponding option will be preselected, even when the input is not controlled.
   */
  defaultValue?: string
  /**
   * Specify the selected value of the input (controlled).
   */
  value?: string
  /**
   * The available options to be rendered
   */
  options: CardSelectOption[]
  /**
   * The callback that will be fired whenever the selected value of the input changes.
   * Will yield the internal value of the selected option.
   */
  onChange: (newValue: string) => void
}

/**
 * A custom form component that displays radio-like controls
 * in the form of rich cards with icons and descriptive text.
 * @todo Make these properly keyboard-navigable.
 */
const CardSelect: React.FC<CardSelectProps> = ({
  options,
  defaultValue,
  value,
  onChange,
}): React.JSX.Element => {
  const optionValues: string[] = React.useMemo(
    () => options.map((o) => o.value),
    [options]
  )

  const OptionCard: React.FC<CardSelectOptionProps> = ({
    icon,
    label,
    onSelect,
    value,
    selected,
    description,
    disabled = false,
  }) => {
    const onClick = () => {
      onSelect(value)
    }

    /**
     * If nothing is selected, the first option should have focus;
     * if an item is selected, _it_ should have focus.
     */
    const tabIndex = (): 0 | -1 => {
      if (selected) {
        return 0
      } else if (optionValues.indexOf(value) === 0) {
        return 0
      } else {
        return -1
      }
    }

    return (
      <div
        role="radio"
        aria-disabled={disabled}
        aria-checked={selected}
        aria-labelledby={`label-${value}`}
        tabIndex={tabIndex()}
        onClick={onClick}
        className={classNames(styles.option, {
          [styles.selected]: selected,
          [styles.disabled]: disabled,
        })}
      >
        <div className={styles.iconContainer}>
          <CanopyIcon className={styles.icon} size="large" name={icon} />
        </div>
        <div className={styles.labelContainer}>
          <label id={`label-${value}`}>{label}</label>
          <p className="canopy-mbe-0">
            <i>{description}</i>
          </p>
        </div>
      </div>
    )
  }

  const [selectedValue, setSelectedValue] = React.useState<string | undefined>(
    value || defaultValue
  )

  const onSelect = (newValue: string) => {
    const changed: boolean = newValue !== selectedValue

    setSelectedValue(newValue)
    if (changed) onChange(newValue)
  }

  const render = React.useCallback(() => {
    return (
      <div role="radiogroup">
        {options.map((option) => {
          return (
            <OptionCard
              key={option.value}
              selected={selectedValue === option.value}
              onSelect={option.disabled ? _.noop : onSelect}
              {...option}
            />
          )
        })}
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue])

  return render()
}

export default CardSelect
