import { put } from "services/api"
import { employerPrefix } from "utilities/url"

export const attachToDmeOrder = (
  supplierId,
  csrInboxStateId,
  dmeOrderId
): Promise<{ success: boolean }> => {
  return put(
    `${employerPrefix(
      "Supplier",
      supplierId
    )}/inbox/${csrInboxStateId}/assign.json`,
    { dme_order_id: dmeOrderId }
  )
    .then(() => ({
      success: true,
    }))
    .catch(() => ({
      success: false,
    }))
}
