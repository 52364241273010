import React from "react"
import * as styles from "./ActionableOrderCard.module.scss"
import classNames from "classnames"
import { Chip } from "@material-ui/core"
import { SignatureStatus } from "sharedTypes"

interface Props {
  signatureStatus: SignatureStatus
  unsatisfiedManualDocumentation: boolean
  stackChips: boolean
}

const SignatureAndDocumentStatus: React.FC<Props> = ({
  signatureStatus,
  unsatisfiedManualDocumentation,
  stackChips,
}) => {
  const showSignatureStatus =
    signatureStatus === SignatureStatus.Needed ||
    signatureStatus === SignatureStatus.Pending ||
    signatureStatus === SignatureStatus.Unknown

  const label =
    signatureStatus === SignatureStatus.Needed
      ? "Signature needed"
      : signatureStatus === SignatureStatus.Pending
      ? "Signature pending"
      : signatureStatus === SignatureStatus.Unknown
      ? "Signature unknown"
      : ""

  return (
    <div
      className={classNames(
        styles.signatureAndDocumentStatus,
        stackChips && styles.stackChips
      )}
      data-testid="signature-document-status"
    >
      {showSignatureStatus && (
        <div>
          <Chip
            className={classNames(
              styles.chip,
              (signatureStatus === SignatureStatus.Pending ||
                signatureStatus === SignatureStatus.Needed) &&
                styles.solidWarningChip,
              signatureStatus === SignatureStatus.Unknown &&
                styles.signatureUnknownChip
            )}
            label={label}
          />
        </div>
      )}
      {unsatisfiedManualDocumentation && (
        <div>
          <Chip
            className={classNames(styles.chip, styles.solidWarningChip)}
            label="Missing documents"
          />
        </div>
      )}
    </div>
  )
}

export default SignatureAndDocumentStatus
