import { gql } from "@apollo/client"

export const SupplierLegalEntityAddressFields = gql`
  fragment SupplierLegalEntityAddressFields on SupplierLegalEntity {
    line1
    line2
    city
    state
    zip
  }
`

export const DeliveryAddressFields = gql`
  fragment DeliveryAddressFields on DmeOrderFulfillment {
    deliveryAddress {
      line1
      line2
      city
      state
      zip
    }
  }
`
