import { Box, CircularProgress } from "@material-ui/core"
import {
  DataGridPro,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import DataGridToolbar from "applications/Cms/components/DataGridToolbar"
import useServerSideDataGrid, {
  ServerSideDataGridOptions,
  ServerSideDataGridParams,
} from "applications/Cms/hooks/useServerSideDataGrid"
import {
  CatalogPackagingLabel,
  CatalogProduct,
} from "applications/Cms/types/sharedTypes"
import React, { useState } from "react"
import { isTest } from "utilities/environment"
import PackagingLabelProductsDrawer from "../PackagingLabelProductsDrawer"
import {
  PackagingLabelsResponse,
  getAllPackagingLabels,
  getProductsForPackagingLabel,
} from "applications/Cms/api/packagingLabels"

type DrawerState = { open: boolean }

const PackagingLabelsDataGrid: React.FC = (): React.JSX.Element => {
  const [packagingLabelsData, setPackagingLabelsData] = useState<
    PackagingLabelsResponse
  >({
    packagingLabels: [],
    totalCount: 0,
  })

  const defaultOptions: CatalogPackagingLabelOptions = {
    page: 1,
    sort: undefined,
  }

  const gridApi = useGridApiRef()

  const [loading, setLoading] = useState<boolean>(false)

  const fetchFunction = async (
    params: ServerSideDataGridParams
  ): Promise<void> => {
    const data = await getAllPackagingLabels(params)

    setPackagingLabelsData((prev) => ({
      ...prev,
      packagingLabels: data.packagingLabels,
      totalCount: data.totalCount,
    }))
  }

  const beforeFetch = () => {
    setLoading(true)
  }
  const afterFetch = () => {
    setLoading(false)
  }

  const [loadingButton, setLoadingButton] = useState<string | null>(null)

  /**
   * The key to store the button that launched the currently-loaded state
   * is logically centralized here.
   */
  const buttonKey = (id: string): string => `${id}`

  type DrawerLaunchingCellProps = {
    params: GridRenderCellParams
    countProperty: string
  }
  const DrawerLaunchingCell = (props: DrawerLaunchingCellProps) => {
    const { params, countProperty } = props
    const count: number = params.row[countProperty] || 0
    const selectedButton = buttonKey(params.row.id)

    const disabled =
      count === 0 || Boolean(loadingButton && loadingButton !== selectedButton)

    if (disabled) {
      return <b>{count}</b>
    } else if (loadingButton === selectedButton) {
      return (
        <b>
          <CircularProgress
            title="Loading..."
            color="primary"
            style={{ width: "16px" }}
            size="small"
          />
        </b>
      )
    } else {
      return (
        <a
          href="" // needed for styling
          role="button"
          title="Click to view detail"
          onClick={(e) => {
            e.preventDefault()
            void openDrawer(params.row.id)
          }}
        >
          {count}
        </a>
      )
    }
  }

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "label",
        flex: 1,
        headerName: "Packaging label",
      },
      {
        field: "productCount",
        flex: 1,
        headerName: "Products",
        type: "number",
        filterable: false,
        headerAlign: "left",
        align: "left",
        renderCell: (params: GridRenderCellParams) => (
          <DrawerLaunchingCell params={params} countProperty="productCount" />
        ),
      },
      // TODO: add back in in future ticket or remove completely; needs more scoping
      // {
      //   field: "actions",
      //   flex: 1,
      //   headerName: "Actions",
      //   minWidth: 200,
      //   renderCell: (_params) => {
      //     return (
      //       <>
      //         <a role="button" className="color-primary" onClick={() => {}}>
      //           Edit
      //         </a>
      //         <b
      //           role="presentation"
      //           className="canopy-min-4x"
      //           aria-hidden="true"
      //         >
      //           |
      //         </b>
      //         <a role="button" className="color-danger" onClick={() => {}}>
      //           Delete
      //         </a>
      //       </>
      //     )
      //   },
      //   sortable: false,
      //   filterable: false,
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingButton]
  )

  const [products, setProducts] = useState<CatalogProduct[] | null>(null)
  const [
    selectedPackagingLabel,
    setSelectedPackagingLabel,
  ] = useState<CatalogPackagingLabel | null>(null)
  const [drawerState, setDrawerState] = useState<DrawerState>({ open: false })

  const openDrawer = async (packagingId: string) => {
    setLoadingButton(buttonKey(packagingId.toString()))

    const label = gridApi.current.getRow(packagingId) as CatalogPackagingLabel

    await getProductsForPackagingLabel(packagingId)
      .then((response) => {
        setSelectedPackagingLabel(label)
        setProducts(response.products)
        setDrawerState({ open: true })
      })
      .finally(() => {
        setLoadingButton(null)
      })
  }

  const closeDrawer = () => {
    setProducts([])
    setSelectedPackagingLabel(null)
    setDrawerState({ open: false })
  }

  type CatalogPackagingLabelOptions = ServerSideDataGridOptions

  const {
    filterModel,
    options,
    handlePageChange,
    handleFilterModelChange,
    handleSortModelChange,
  } = useServerSideDataGrid<CatalogPackagingLabelOptions>({
    defaultFilterModel: {
      columnFilters: [],
      columnFilterLinkOperator: GridLinkOperator.And,
    },
    defaultOptions,
    columnDefinitions: columns,
    fetchFunction,
    beforeFetch,
    afterFetch,
  })

  return (
    <>
      <div>
        <div
          role="heading"
          className="canopy-typography-heading-large canopy-mbe-4x"
        >
          Packaging labels
        </div>
        <div>How Attributes of a Product are packaged when shipped.</div>
      </div>
      <Box mt={2}>
        <DataGridPro
          autoHeight
          apiRef={gridApi}
          className="borderless"
          columns={columns}
          rowCount={packagingLabelsData.totalCount}
          rows={packagingLabelsData.packagingLabels}
          loading={loading}
          disableVirtualization={isTest()}
          page={options.page - 1} // account for DataGrid's zero-based indexing
          onPageChange={(page) => {
            handlePageChange(page + 1)
          }} // account for DataGrid's zero-based indexing
          hideFooterSelectedRowCount
          getRowId={(row) => row.id}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          sortingMode="server"
          sortModel={options.sort}
          onSortModelChange={handleSortModelChange}
          rowsPerPageOptions={[50]}
          pagination={true}
          paginationMode="server"
          pageSize={50}
          components={{
            Toolbar: DataGridToolbar,
          }}
          componentsProps={{
            toolbar: { filter: true },
          }}
        />
        {products && selectedPackagingLabel && (
          <PackagingLabelProductsDrawer
            open={drawerState.open}
            onClose={closeDrawer}
            catalogPackagingLabel={selectedPackagingLabel}
            products={products}
          />
        )}
      </Box>
    </>
  )
}

export default PackagingLabelsDataGrid
