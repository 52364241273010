// @ts-strict-ignore
import React, { useEffect } from "react"
import { Field, FormikValues } from "formik"
import TextLengthCheck from "../components/TextLengthCheck"
import MostCommonCallout from "../components/MostCommonCallout"
import RadioButtons from "components/form/RadioButtons"
import { SurveyAnswerType, SurveyOption, SurveyQuestion } from "sharedTypes"
import { isTab, isEnter } from "utilities/browser/event"
import { SaveButton } from "../components/SaveButton"
import QuestionTitle from "../components/QuestionTitle"
import { InitialValues, Values } from "../sharedTypes"
import { isAnswerLengthValid } from "../utilities/validation"

const OTHER_VALUE = "OTHER"
const NA_VALUE = "na"

// parsing the string is currently the IQ recommended way of identifying this choice option hence the below
const OPTUM_ADD_COMMENT = "Other clinical information (add comment)"

function isDefaultChoice(choice, question) {
  return (
    question.defaultChoices && question.defaultChoices.includes(choice.code)
  )
}

const createAddOn = (field, form, choiceCode, dirty, question) => {
  if (field.value === choiceCode) {
    return (
      <div className="canopy-mbs-4x">
        <Field
          className="form-control"
          type="text"
          name="otherAnswer"
          disabled={form.isSubmitting}
          onKeyDown={(event) =>
            dirty && (isTab(event) || isEnter(event)) && form.handleSubmit()
          }
        />
        {question.maximumLength && (
          <TextLengthCheck
            question={question}
            answer={form.values.otherAnswer}
          />
        )}
      </div>
    )
  }
}

const getOptions = (question, answer, showMostCommon, dirty) => {
  const options: SurveyOption[] = question.choices.map((choice) => {
    const option: SurveyOption = {
      label: choice.label,
      value: choice.code,
    }
    if (showMostCommon && isDefaultChoice(choice, question)) {
      option.callout = <MostCommonCallout />
    }
    if (choice.label === OPTUM_ADD_COMMENT) {
      option.addOnPosition = "inside"
      option.addOn = ({ field, form }) =>
        createAddOn(field, form, choice.code, dirty, question)
    }
    return option
  })

  // non-optum flow
  if (question.other) {
    options.push({
      label: question.other,
      value: OTHER_VALUE,
      addOnPosition: "inside",
      addOn: ({ form, field }) =>
        createAddOn(field, form, OTHER_VALUE, dirty, question),
    })
  }
  if (question.naEnabled) {
    const option: SurveyOption = {
      label: question.naLabel || "None of these",
      value: NA_VALUE,
    }
    if (showMostCommon && question.defaultChoices?.includes("na")) {
      option.callout = <MostCommonCallout />
    }
    options.push(option)
  }
  return options
}

type Props = {
  question: SurveyQuestion
  setFieldValue(field: string, value, shouldValidate?: boolean): void
  submitForm(): Promise<void>
  isSubmitting: boolean
  inputId: string
  showMostCommon?: boolean
  values: FormikValues
  initialValues: FormikValues
  dirty: boolean
  requiresConfirmation: boolean
  valid: boolean
  missingRequiredInformation: boolean
}

const validate = (values: Values, question: SurveyQuestion) => {
  const { answerValue, otherAnswer } = values
  if (answerValue === OTHER_VALUE) {
    if (!otherAnswer) {
      return { otherAnswer: "is required" }
    } else if (!isAnswerLengthValid(question, otherAnswer)) {
      return { otherAnswer: "is not valid" }
    }
  }
  return {}
}

const getInitialValues: (question: SurveyQuestion) => InitialValues = (
  question
) => {
  const answer: string = (question.answerValue as string) || ""
  let answerValue: string = answer
  let otherAnswer: string = ""

  const staticChoices: string[] = question.choices
    ? question.choices.map(({ code }) => code)
    : []

  if (question.answerType === SurveyAnswerType.Na) {
    answerValue = NA_VALUE
  } else if (answer && !staticChoices.includes(answer)) {
    answerValue = OTHER_VALUE
    otherAnswer = answer
  }

  return { answerValue, otherAnswer }
}

const formatAnswer = (values: Values) => {
  const { answerValue, otherAnswer } = values
  if (answerValue === NA_VALUE) {
    return { answerValue: null, answerType: SurveyAnswerType.Na }
  } else if (answerValue === OTHER_VALUE) {
    return {
      answerValue: otherAnswer,
      answerType: SurveyAnswerType.Value,
    }
  }
  return { answerValue, answerType: SurveyAnswerType.Value }
}

const QuestionSelectOne: React.FC<Props> = ({
  dirty,
  initialValues,
  question,
  showMostCommon,
  submitForm,
  values,
  isSubmitting,
  valid,
  missingRequiredInformation,
  inputId,
  ...props
}) => {
  useEffect(() => {
    if (
      values !== initialValues &&
      values.answerValue &&
      values.answerValue !== OTHER_VALUE
    ) {
      submitForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.answerValue])

  const options = getOptions(
    question,
    question.answerValue || "",
    showMostCommon,
    dirty
  )

  const renderSaveButton = dirty && values.otherAnswer

  return (
    <>
      <QuestionTitle
        questionTitle={question.title}
        questionText={question.text}
        tooltips={question.tooltips}
        questionId={inputId}
      />
      <RadioButtons
        id={inputId}
        name="answerValue"
        block
        disabled={isSubmitting}
        options={options}
        onChange={(event) => {
          if (event.target.value !== OTHER_VALUE) {
            props.setFieldValue("otherAnswer", "")
          }
        }}
      />
      {renderSaveButton && (
        <SaveButton
          isSubmitting={isSubmitting}
          valid={valid}
          missingRequiredInformation={missingRequiredInformation}
          question={question}
        />
      )}
    </>
  )
}

export {
  QuestionSelectOne as QuestionComponent,
  validate,
  getInitialValues,
  formatAnswer,
}
