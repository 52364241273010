import React, { useState } from "react"
import * as styles from "../index.module.scss"
import { User } from ".."
import Table, { TableColumnDefinition } from "components/Table"
import { format as formatDate } from "utilities/date"
import CanopyIcon from "applications/CanopyIcon"

type Props = {
  users: User[]
}

const columns: TableColumnDefinition<User & { key: string }>[] = [
  {
    title: "Name",
    attr: "fullName",
    render: (record) => (
      <div style={{ width: "100%", minWidth: "120px" }}>{record.fullName}</div>
    ),
  },
  {
    title: "Email",
    attr: "email",
    render: (record) => (
      <div
        style={{
          wordBreak: "break-word",
          whiteSpace: "normal",
          maxWidth: "300px",
          minWidth: "200px",
        }}
      >
        {record.email}
      </div>
    ),
  },
  {
    title: "Last Log In",
    attr: "lastSignInAt",
    render: (record) => (
      <div style={{ width: "100%", minWidth: "100px" }}>
        {record.lastSignInAt
          ? formatDate(new Date(record.lastSignInAt), "MMM D, YYYY")
          : "Never"}
      </div>
    ),
  },
]

const UsersTable = ({ users }: Props) => {
  const [showTable, setShowTable] = useState<boolean>(false)

  if (users.length <= 1) {
    return null
  }

  const sortedUsers = users
    .sort((a, b) => a.fullName.localeCompare(b.fullName))
    .map((user) => ({
      ...user,
      key: JSON.stringify(user),
    }))

  return (
    <>
      <div
        onClick={() => setShowTable(!showTable)}
        className={styles.toggleButton}
      >
        {showTable ? "Hide current users" : "Show current users"}
        <span style={{ marginLeft: "8px" }}>
          <CanopyIcon name={showTable ? "angle-up" : "angle-down"} />
        </span>
      </div>
      {showTable && (
        <div className={styles.tableWrapper}>
          <Table
            records={sortedUsers}
            tableColumns={columns}
            truncate={false}
            overrideHeaderPointerEvents="auto"
          />
        </div>
      )}
    </>
  )
}

export default UsersTable
