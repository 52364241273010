import React from "react"
import {
  DmeOrder,
  LineItemGroup,
  Employer,
  RxDetailsOutput,
  OfferingType,
} from "sharedTypes"
import ServiceLineItemGroup from "./CartSectionInnerContent/ServiceLineItemGroup"
import EquipmentLineItemGroup from "./CartSectionInnerContent/EquipmentLineItemGroup"
import DocumentLineItemGroup from "./CartSectionInnerContent/DocumentLineItemGroup"
import {
  supplierLegalEntityModalPath,
  salesRepModalPath,
} from "applications/Workflow/routes"
import { Link } from "react-router-dom"
import { CanopyLink } from "@parachutehealth/canopy-link"

type Props = {
  dmeOrder: DmeOrder
  lineItemGroups: LineItemGroup[]
  currentEmployer: Employer
  rxDetailsOutputs: RxDetailsOutput[]
  canUpdateSupplierLegalEntity: boolean
  canUpdateSalesRep: boolean
  showFulfilledBy: boolean
}

const CartSectionContent = ({
  canUpdateSupplierLegalEntity,
  canUpdateSalesRep,
  ...props
}: Props) => {
  const renderLineItemGroup = (lineItemGroup) => {
    switch (lineItemGroup.offeringType) {
      case OfferingType.Service:
        return (
          <ServiceLineItemGroup
            key={lineItemGroup.id}
            lineItemGroup={lineItemGroup}
            {...props}
          />
        )
      case OfferingType.O2Recert:
        return (
          <DocumentLineItemGroup
            key={lineItemGroup.id}
            lineItemGroup={lineItemGroup}
            {...props}
          />
        )
      default:
        return (
          <EquipmentLineItemGroup
            key={lineItemGroup.id}
            lineItemGroup={lineItemGroup}
            {...props}
          />
        )
    }
  }
  return (
    <>
      {props.lineItemGroups.map(renderLineItemGroup)}
      {canUpdateSupplierLegalEntity && (
        <div>
          <CanopyLink as={Link} to={supplierLegalEntityModalPath()}>
            Update legal entity
          </CanopyLink>
        </div>
      )}
      {canUpdateSalesRep && (
        <div>
          <CanopyLink as={Link} to={salesRepModalPath()}>
            Update sales rep
          </CanopyLink>
        </div>
      )}
    </>
  )
}

export default CartSectionContent
