import React from "react"
import { SupplierLegalEntity } from "../../../../../../../../graphql/__generated__/graphql"
import { canopyColorTextPrimary } from "@parachutehealth/canopy-tokens-color"
import { cityLine } from "../../../../../../../../utilities/address"
import { formatPhone } from "../../../../../../../../utilities/phone"

interface Props {
  supplier: SupplierLegalEntity
}

const SupplierInfo: React.FC<Props> = ({ supplier }) => {
  return (
    <div
      className="canopy-typography-body-medium canopy-mbe-0"
      style={{ color: canopyColorTextPrimary }}
    >
      <p>{supplier.name}</p>
      <address className="canopy-mbs-6x">
        {[
          supplier.line1,
          supplier.line2,
          cityLine(supplier),
          formatPhone(supplier.phoneNumber || undefined),
        ]
          .filter((l) => !!l)
          .map((l) => (
            <React.Fragment key={l}>
              {l}
              <br />
            </React.Fragment>
          ))}
      </address>
    </div>
  )
}

export default SupplierInfo
