// @ts-strict-ignore
import React from "react"
import {
  DmeOrder,
  DmeOrderWarning,
  Employer,
  EmployerType,
  LineItemGroup,
  RxDetailsOutput,
} from "sharedTypes"
import withInitialData from "components/withInitialData"
import * as api from "./api"
import CartReviewCard from "applications/Workflow/containers/Review/components/ReviewCard/variants/CartReviewCard/CartReviewCard"

type InitialData = {
  originalSupplierName?: string
  lineItemGroups?: LineItemGroup[]
  rxDetailsOutputs?: RxDetailsOutput[]
}

type Props = {
  employer: Employer
  dmeOrder: DmeOrder
  initialData: InitialData
  warnings: DmeOrderWarning[]
}

function TransferredOrderOriginalCart(props: Props) {
  const { dmeOrder, employer, warnings, initialData } = props
  const { rxDetailsOutputs = [], lineItemGroups = [] } = initialData || {}
  const matchedLineItemGroups =
    dmeOrder.mostRecentSupplierTransfer.matchedLineItemGroups
  const unMatchedLineItemGroups =
    dmeOrder.mostRecentSupplierTransfer.unmatchedLineItemGroups

  const alertText = (): string =>
    lineItemGroups.length
      ? `Some of the products & services selected on the original order do not match ${dmeOrder.supplier.name}’s catalog. Please select the products to be fulfilled from ${dmeOrder.supplier.name}’s catalog.`
      : `The original referrer did not select any packages before transferring this order. Please select products to be fulfilled from ${dmeOrder.supplier.name}’s catalog to complete this order.`

  return (
    <div className="canopy-pbs-4x">
      {!!dmeOrder.mostRecentSupplierTransfer.unmatchedLineItemGroups.length &&
        employer.employerType === EmployerType.Supplier && (
          <div className="card card-warning bg-alert-light">{alertText()}</div>
        )}
      <div className="canopy-pbs-4x">
        <CartReviewCard
          dmeOrder={dmeOrder}
          expanded
          currentEmployer={employer}
          lineItemGroups={matchedLineItemGroups}
          rxDetailsOutputs={rxDetailsOutputs}
          warnings={warnings}
          canUpdateSalesRep={false}
          canUpdateSupplierLegalEntity={false}
          title="Matched Packages"
          emptyCartText="There are no matching packages."
          showFulfilledBy={false}
        />
      </div>
      <div className="canopy-pbs-4x">
        <CartReviewCard
          dmeOrder={dmeOrder}
          expanded
          currentEmployer={employer}
          lineItemGroups={unMatchedLineItemGroups}
          rxDetailsOutputs={rxDetailsOutputs}
          warnings={warnings}
          canUpdateSalesRep={false}
          canUpdateSupplierLegalEntity={false}
          title="Unmatched Packages"
          emptyCartText="There are no non-matching packages."
          showFulfilledBy={false}
        />
      </div>
    </div>
  )
}

const fetchInitialData = () =>
  api.getSupplierTransferOriginalCartData().then(({ data }) => data)
export default withInitialData(fetchInitialData)(TransferredOrderOriginalCart)
