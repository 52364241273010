import { OptionItem } from "@parachutehealth/canopy-combobox-field"
import { BrightreeSalesOrderClassification } from "../../types"

export function findOptionItemFromValue(
  dropdownOptions: OptionItem[],
  value: string
): OptionItem | null {
  if (!value) return null
  return (
    dropdownOptions.find((option) => option.value === value) ||
    invalidOption(value)
  )
}

function invalidOption(value: string): OptionItem {
  return {
    label: `Invalid classification ID: ${value}`,
    value: value,
  }
}

export function buildOptionFromSalesOrderClassification(
  salesOrderClassification: BrightreeSalesOrderClassification
): OptionItem {
  return {
    label: `${salesOrderClassification.name} - ID ${salesOrderClassification.id}`,
    value: salesOrderClassification.id,
  }
}
