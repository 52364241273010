import React, { ChangeEventHandler, useRef } from "react"
import classNames from "classnames"
import { DATE_FORMAT, format } from "utilities/date"
import Flatpickr from "react-flatpickr"
import flatpickr from "flatpickr"
import { english } from "flatpickr/dist/l10n/default.js"
import * as styles from "./index.module.scss"

import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"

interface DatePickerProps {
  name: string
  id: string
  value: string
  onChange: React.FormEventHandler
  minDate: string
  maxDate: string
  feedbackMessage: string
}

const DatePickerForSnoozeModal: React.FC<DatePickerProps> = ({
  name,
  id,
  value,
  onChange,
  minDate,
  maxDate,
  feedbackMessage,
}) => {
  const datepicker = useRef<flatpickr.Instance | null>(null)

  const DATEPICKER_DATE_FORMAT = "m/d/Y"
  const WEEKDAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]

  const flatPickerOnChange = (dates) => {
    const date = dates[0]
    if (date && typeof onChange === "function") {
      onChange(({
        target: { value: format(date), name, id },
      } as unknown) as React.FormEvent)
    }
  }

  return (
    <div className={classNames(styles.container, styles.inline, "input-group")}>
      <CanopyTextInputField
        name={name}
        id={id}
        label="Enter a date to snooze the order until"
        required
        feedbackMessage={feedbackMessage}
        description="Enter a date within one year of the order creation date, using the format MM/DD/YYYY"
        onChange={onChange as ChangeEventHandler<HTMLInputElement>}
        value={value}
        autoFocus
        placeholder={DATE_FORMAT}
      />
      <Flatpickr
        value={value}
        onCreate={(flatpickr) => {
          datepicker.current = flatpickr
        }}
        onDestroy={() => {
          datepicker.current?.destroy()
          datepicker.current = null
        }}
        options={{
          inline: true,
          minDate,
          maxDate,
          onChange: flatPickerOnChange,
          allowInput: true,
          clickOpens: false,
          dateFormat: DATEPICKER_DATE_FORMAT,
          locale: {
            ...english,
            weekdays: {
              shorthand: WEEKDAYS,
            },
          },
        }}
        render={(_props, ref) => {
          return <div ref={ref}></div>
        }}
      />
    </div>
  )
}

export default DatePickerForSnoozeModal
