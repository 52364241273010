import React from "react"
import { Form, RadioButtons, NumberField } from "components/form"
import { formatPaymentMethod } from "utilities/payment"
import { LineItemGroupCommonAttributes } from "sharedTypes"
import { FormikValues } from "formik"
type Props = {
  lineItemGroupCommonAttributes: LineItemGroupCommonAttributes
  onContinue(
    params?: FormikValues
  ): Promise<LineItemGroupCommonAttributes> | Promise<void>
}

function Details({ lineItemGroupCommonAttributes, onContinue }: Props) {
  const {
    quantity,
    lengthOfNeedMonths,
    paymentMethod,
    supportedPaymentMethods,
    requiresLengthOfNeed,
    allowsQuantity,
  } = lineItemGroupCommonAttributes
  const onSubmit = (values, { setErrors }) => {
    onContinue(values).catch((errors) => {
      setErrors(errors)
    })
  }

  return (
    <Form
      initialValues={{ quantity, lengthOfNeedMonths, paymentMethod }}
      onSubmit={onSubmit}
    >
      <div className="row">
        <div className="col-md-9">
          {allowsQuantity && (
            <div className="row">
              <div className="col-md-3">
                <NumberField label="Quantity" name="quantity" />
              </div>
            </div>
          )}
          {requiresLengthOfNeed && (
            <div className="row">
              <div className="col-md-4">
                <NumberField
                  label="Length of need"
                  name="lengthOfNeedMonths"
                  suffix="mo"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {supportedPaymentMethods.length > 1 && (
        <RadioButtons
          label="Payment Method"
          name="paymentMethod"
          options={supportedPaymentMethods.map((paymentMethod) => ({
            label: formatPaymentMethod(paymentMethod),
            value: paymentMethod,
          }))}
        />
      )}
      <div className="text-right">
        <button type="submit" className="btn btn-brand">
          Continue
        </button>
      </div>
    </Form>
  )
}

export default Details
