import React from "react"
import DataRow from "../../../../../../../components/DataRow"
import { CanopyButton } from "@parachutehealth/canopy-button"
import ReviewCard from "../../ReviewCard/ReviewCard"
import { useQuery } from "@apollo/client"
import { CarrierAuthorizationSection_GetCarrierAuthorizationById } from "./graphql/carrierAuthorizationById"
import { sendError } from "../../../../../../../utilities/error"
import { carrierAuthorizationDetailsUrl } from "../../../../../urls"
import { DATE_TIME_FORMAT, format } from "../../../../../../../utilities/date"
import { withApollo } from "hocs/withApollo"
import {
  AuthStatusToSeverity,
  AuthStatusToTitle,
} from "applications/Insurance/CarrierAuthorizationRequest/utilities/statusFormatters"
import AuthStatusPill from "applications/Insurance/CarrierAuthorizationRequest/components/AuthStatusPill/AuthStatusPill"

export interface CarrierAuthorizationSectionContentProps {
  carrierAuthorizationId: string
  carrierAuthorizationStatus: string
  expanded: boolean
}

const CarrierAuthorizationSectionContent = ({
  carrierAuthorizationId,
  carrierAuthorizationStatus,
  expanded,
}: CarrierAuthorizationSectionContentProps) => {
  const { data } = useQuery(
    CarrierAuthorizationSection_GetCarrierAuthorizationById,
    {
      variables: { id: carrierAuthorizationId },
      onError: () => {
        sendError(
          `Error fetching carrier authorization ${carrierAuthorizationId}`
        )
      },
    }
  )

  const carrierAuthorization = data?.carrierAuthorization

  const prettyPrintSubmitAt = carrierAuthorization?.requestSubmitDateTime
    ? format(
        carrierAuthorization?.requestSubmitDateTime?.toLocaleString(),
        DATE_TIME_FORMAT
      )
    : undefined

  return (
    <ReviewCard
      title="Authorization"
      expanded={expanded}
      summary={carrierAuthorizationId}
      status={AuthStatusToSeverity[carrierAuthorizationStatus]}
      badge={() => (
        <AuthStatusPill
          className="canopy-mr-4x"
          statusLevel={AuthStatusToSeverity[carrierAuthorizationStatus]}
          statusText={AuthStatusToTitle[carrierAuthorizationStatus]}
        />
      )}
    >
      <DataRow label="Request ID" value={carrierAuthorizationId} />
      <DataRow label="Submitted" value={prettyPrintSubmitAt || "-"} />
      <CanopyButton
        as="a"
        variant="secondary"
        size="small"
        href={carrierAuthorizationDetailsUrl(carrierAuthorizationId)}
        target="_blank"
        fullWidth
        className="canopy-mbs-8x"
        iconEnd="arrow-up-right-from-square"
      >
        View auth request
      </CanopyButton>
    </ReviewCard>
  )
}

export default withApollo(CarrierAuthorizationSectionContent)
