import { Icd10Code } from "sharedTypes"
import { SaveStatusIndicatorState } from "../SaveStatus/SaveStatusIndicator"

type FormattedIcd10CodeOption = {
  label: string
  value: string
}

type GroupedIcd10Codes = {
  label: string
  options: FormattedIcd10CodeOption[]
}

export const formatIcd10CodeOption = (
  icd10Code: Icd10Code
): FormattedIcd10CodeOption => ({
  label: `${icd10Code.codeLabel} - ${icd10Code.description}`,
  value: icd10Code.code,
})

const categorize = (icd10Codes: Icd10Code[]) => {
  const common: FormattedIcd10CodeOption[] = []
  const other: FormattedIcd10CodeOption[] = []
  icd10Codes.forEach((icd10Code) => {
    const option = formatIcd10CodeOption(icd10Code)
    if (icd10Code.common) {
      common.push(option)
    } else {
      other.push(option)
    }
  })
  return { common, other }
}

const wrapByCategory = ({ common, other }) => {
  const categorized: GroupedIcd10Codes[] = []
  if (common.length) {
    categorized.push({ label: "Common Diagnoses", options: common })
  }
  if (other.length) {
    categorized.push({ label: "Other Diagnoses", options: other })
  }
  return categorized
}

export const groupByCategory = (
  icd10Codes: Icd10Code[]
): GroupedIcd10Codes[] => {
  return wrapByCategory(categorize(icd10Codes))
}

export const excludeRelevantDiagnoses = (
  allDiagnoses: Icd10Code[],
  relevantDiagnoses: Icd10Code[]
): Icd10Code[] => {
  if (!relevantDiagnoses) {
    return allDiagnoses
  }

  const relevantDiagnosesCodes: string[] = relevantDiagnoses.map(
    (icd10Code) => icd10Code.code
  )
  return allDiagnoses.filter(
    (diagnosis) => !relevantDiagnosesCodes.includes(diagnosis.code)
  )
}

export const getSaveStatusIndicatorState = (
  isLoading: boolean,
  isError: boolean
): SaveStatusIndicatorState => {
  if (isError) return "error"
  if (isLoading) return "saving"
  return "saved"
}
