export interface TherapyType {
  id: string
  name: string
}

export enum WorkflowStep {
  MedicalNecessity = "medical_necessity",
  Rx = "rx",
}

export enum QualificationStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Qualified = "qualified",
  Disqualified = "disqualified",
}
