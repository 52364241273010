import {
  LineItemGroup as BaseLineItemGroup,
  PaymentMethod,
  Supplier,
} from "sharedTypes"

export enum LineItemGroupStep {
  RequirementsStep = "requirements",
  DetailsStep = "details",
  SummaryStep = "summary",
}

export type Preferences = {
  useFeetForHeight: boolean
}

// Wherever the global line item group type is used, it doesn't need these
// extra attributes, so keep this separate so we don't force extra object
// loading where it's unneeded
export type LineItemGroup = BaseLineItemGroup & {
  requiresHeight: boolean
  requiresWeight: boolean
  requiresWoundCount: boolean
  heightInches: number
  weightLbs: number
  numberOfWounds: number
  supplier: Supplier
  lengthOfNeedMonths: number
  supportedPaymentMethods: PaymentMethod[]
  requiresLengthOfNeed: boolean
  warnings: string[]
  description: string
}
