import React from "react"
import { DmeOrder } from "../../../../../../../graphql/__generated__/graphql"
import { Card, CardContent, Grid } from "@material-ui/core"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { canopyColorTextSecondary } from "@parachutehealth/canopy-tokens-color"
import { CardSectionContainer } from "./CardSectionContainer"
import { DeliveryDetails } from "./DeliveryDetails/DeliveryDetails"
import SupplierInfo from "./SupplierInfo/SupplierInfo"
import { CreatorDetails } from "./CreatorDetails/CreatorDetails"
import { OrderStatuses } from "./OrderStatus/OrderStatuses"
import {
  DeliveryStatus,
  OrderStatus,
  SignatureStatus,
} from "../../../../../../../sharedTypes"
import * as styles from "./DmeOrderCard.module.scss"
import { carrierAuthorizationUrl, dmeOrderUrl } from "../../../../urls"

interface DmeOrderCardProps {
  dmeOrder: DmeOrder
  orderDetailsEnabled: boolean
  authDetailsEnabled: boolean
}

const DmeOrderCard: React.FC<DmeOrderCardProps> = ({
  dmeOrder,
  orderDetailsEnabled,
  authDetailsEnabled,
}) => {
  const {
    id,
    carrierAuthorization,
    orderStatus,
    signatureStatus,
    missingDocuments,
    clinicalFacility,
    creator,
    deliveryFulfillments,
    lineItemGroups,
    supplierLegalEntity,
    orderDate,
    originatorType,
  } = dmeOrder

  const deliveryFulfillment = !!deliveryFulfillments?.length
    ? deliveryFulfillments[0]
    : null

  return (
    <Grid item>
      <Card>
        <CardContent className={styles.cardContainer}>
          <Grid direction="row" container className="canopy-mb-8x">
            {!!lineItemGroups?.length && (
              <CardSectionContainer headerText="In this order">
                <ul className="canopy-pis-12x">
                  {lineItemGroups.map((lineItemGroup) => (
                    <li key={lineItemGroup.id}>{lineItemGroup.displayName}</li>
                  ))}
                </ul>
              </CardSectionContainer>
            )}
            {supplierLegalEntity && (
              <CardSectionContainer headerText="Supplied by" borderLeft>
                <SupplierInfo supplier={supplierLegalEntity} />
              </CardSectionContainer>
            )}
            <CardSectionContainer headerText="Created by" borderLeft>
              <CreatorDetails
                clinicalFacility={clinicalFacility}
                creator={creator}
                supplier={supplierLegalEntity}
                orderDate={orderDate}
                originatorType={originatorType}
              />
            </CardSectionContainer>
            <CardSectionContainer headerText="Signing clinician" borderLeft>
              {dmeOrder?.orderingDoctor ? (
                <p>{dmeOrder?.orderingDoctor?.name}</p>
              ) : (
                <p className="italic">Unassigned</p>
              )}
            </CardSectionContainer>
            {deliveryFulfillment && (
              <CardSectionContainer headerText="Delivery" borderLeft>
                <DeliveryDetails deliveryFulfillment={deliveryFulfillment} />
              </CardSectionContainer>
            )}

            <CardSectionContainer headerText="Order status" borderLeft>
              <OrderStatuses
                orderStatus={(orderStatus as OrderStatus) || null}
                deliveryStatus={
                  (deliveryFulfillment?.deliveryStatus as DeliveryStatus) ||
                  null
                }
                missingDocuments={missingDocuments || false}
                signatureStatus={(signatureStatus as SignatureStatus) || null}
              />
            </CardSectionContainer>
          </Grid>
          <Grid
            container
            alignItems="flex-end"
            justifyContent="space-between"
            className="canopy-px-8x"
          >
            <p
              className="canopy-typography-body-small canopy-mbe-0"
              style={{ color: canopyColorTextSecondary }}
            >
              Order {dmeOrder.id}
            </p>
            <div className="canopy-mbe-0">
              {authDetailsEnabled && !!carrierAuthorization && (
                <CanopyButton
                  variant="secondary"
                  iconEnd="arrow-up-right-from-square"
                  size="small"
                  className="canopy-mie-4x"
                  as="a"
                  target="_blank"
                  rel="noreferrer"
                  href={carrierAuthorizationUrl(carrierAuthorization?.id)}
                >
                  View auth
                </CanopyButton>
              )}
              {orderDetailsEnabled && (
                <CanopyButton
                  variant="secondary"
                  iconEnd="arrow-up-right-from-square"
                  size="small"
                  as="a"
                  target="_blank"
                  rel="noreferrer"
                  href={dmeOrderUrl(id)}
                >
                  View order
                </CanopyButton>
              )}
            </div>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default DmeOrderCard
