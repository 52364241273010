import React from "react"
import ClinicalInformationRow from "applications/Workflow/containers/ClinicalFacilityClinical/components/DocumentationRequirements/ClinicalInformationRow"
import ExternalQuestionnaireModalButton from "../ExternalQuestionnaireModalButton/ExternalQuestionnaireModalButton"
import { ChartNoteQuestion } from "../../../../../../../../../../sharedTypes"

interface Props {
  title: string
  questionsWithSelectedAnswers?: ChartNoteQuestion[]
  recommendation?: string //Temporarily making this optional until we start rendering the actual questionnaires
}

const MedicalNecessityRow = ({
  title,
  questionsWithSelectedAnswers = [],
  recommendation,
}: Props) => {
  return (
    <ClinicalInformationRow
      title={title}
      showDetails={false}
      isDocumentParachuteGenerated={true}
      status={
        <ExternalQuestionnaireModalButton
          title={title}
          questionsWithSelectedAnswers={questionsWithSelectedAnswers}
        />
      }
      recommendation={recommendation}
    />
  )
}

export default MedicalNecessityRow
