export const truncateText = (
  text: string,
  maxChars: number,
  maxLines: number
): string => {
  const lines = text.split("\n")
  const firstLines = lines.slice(0, maxLines).join("\n")

  return firstLines.length > maxChars
    ? firstLines.substring(0, maxChars)
    : firstLines
}
