// @team @demand-ordering

import React from "react"
import { CanopyNotice } from "@parachutehealth/canopy-notice"

type Props = {
  dmeOrderId?: string
  referralApiCatalogPackage?: string
}

const PendingOrderPackageAlert: React.FC<Props> = ({
  referralApiCatalogPackage,
}) => {
  return (
    <CanopyNotice
      variant="discovery"
      title={`${referralApiCatalogPackage} Created by EnsoData Integration`}
      message="Select a supplier and configure products to advance the order."
    ></CanopyNotice>
  )
}

export default PendingOrderPackageAlert
