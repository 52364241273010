import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import Card from "components/Card"
import { format } from "utilities/date"
import { titlecase, titleize } from "utilities/string"
import { toSentence } from "utilities/array"
import { inboxDashboardUrl, orderUrl } from "../../urls"
import {
  DmeOrder,
  ExternalOrder,
  DocumentationOrder,
  attachToDocumentationOrder,
} from "./api"
import { AttachToDmeOrderButton } from "./AttachToDmeOrderButton"
import { AttachToExternalOrderButton } from "./AttachToExternalOrderButton"
import { StackedData } from "../StackedData"
import { Badge } from "./Badge"
import { AttachButton } from "./AttachButton"
import { ExternalOrderLineItems } from "./ExternalOrderLineItems"

type Props = {
  order: DmeOrder | ExternalOrder | DocumentationOrder
  preFetchItems: boolean
  csrInboxStateId: string
  supplierOrganizationId: string
  supplierId: string
}

export const renderOrderCard = ({
  order,
  preFetchItems,
  csrInboxStateId,
  supplierOrganizationId,
  supplierId,
}: Props) => {
  if ("supplierSystemId" in order) {
    return (
      <ExternalOrderCard
        key={order.supplierSystemId}
        order={order}
        preFetchItems={preFetchItems}
        csrInboxStateId={csrInboxStateId}
        supplierOrganizationId={supplierOrganizationId}
        supplierId={supplierId}
      />
    )
  }
  if ("clinician" in order) {
    return (
      <DocumentationOrderCard
        key={order.id}
        order={order}
        csrInboxStateId={csrInboxStateId}
        supplierOrganizationId={supplierOrganizationId}
        supplierId={supplierId}
      />
    )
  }
  return (
    <DmeOrderCard
      key={order.id}
      order={order}
      csrInboxStateId={csrInboxStateId}
    />
  )
}

type DmeOrderCardProps = {
  order: DmeOrder
  csrInboxStateId: string
}

export const DmeOrderCard: React.FC<DmeOrderCardProps> = ({
  order,
  csrInboxStateId,
}: DmeOrderCardProps) => {
  const orderHref = `${orderUrl(order.supplier.id, order.id)}/review`
  return (
    <Card role="listitem" aria-label="Order">
      <CanopyFlex
        justifyContent="space-between"
        alignItems="center"
        className="canopy-mb-12x"
        wrap
      >
        <div>
          <h4 className="canopy-typography-heading-medium canopy-mb-1x">
            {order.clinicalFacility.name}
          </h4>
          <div className="canopy-typography-body-small">
            {toSentence(
              order.lineItemGroups.map(({ description }) => description)
            )}
          </div>
        </div>
        <div className="canopy-typography-body-small">Order ID: {order.id}</div>
      </CanopyFlex>
      <CanopyFlex justifyContent="space-between" wrap={true}>
        <div className="canopy-typography-body-small">
          <div>
            <span className="canopy-typography-heading-small">STATUS</span>{" "}
            {titleize(order.orderStatus)}
          </div>
          <div>
            <span className="canopy-typography-heading-small">DELIVERY</span>{" "}
            {format(order.requestedDeliveryDate)}
          </div>
        </div>
        <CanopyFlex gap="2X" wrap={true}>
          <AttachToDmeOrderButton
            supplierId={order.supplier.id}
            csrInboxStateId={csrInboxStateId}
            dmeOrderId={order.id}
          />
          <CanopyButton
            as="a"
            href={orderHref}
            target="_blank"
            variant="secondary"
            iconStart="eye"
            size="small"
          >
            View
          </CanopyButton>
        </CanopyFlex>
      </CanopyFlex>
    </Card>
  )
}

type ExternalOrderCardProps = {
  order: ExternalOrder
  preFetchItems: boolean
  supplierOrganizationId: string
  supplierId: string
  csrInboxStateId: string
}

export const ExternalOrderCard: React.FC<ExternalOrderCardProps> = ({
  order,
  preFetchItems,
  supplierOrganizationId,
  supplierId,
  csrInboxStateId,
}: ExternalOrderCardProps) => {
  return (
    <Card role="listitem" aria-label="External Order">
      <CanopyFlex justifyContent="space-between" className="canopy-mb-12x">
        <div>
          <h4 className="canopy-typography-heading-medium canopy-mb-1x">
            Brightree order
          </h4>
          <ExternalOrderLineItems
            orderId={order.supplierSystemId}
            preFetchItems={preFetchItems}
          />
        </div>
        <CanopyFlex
          alignItems="flex-end"
          direction="column"
          className="canopy-typography-body"
          style={{ flexShrink: 0 }}
        >
          <Badge text="External order" variant="filled" />
          <div className="canopy-typography-body-small">
            Sales ID: {order.supplierSystemId}
          </div>
        </CanopyFlex>
      </CanopyFlex>

      <CanopyFlex justifyContent="space-between" alignItems="center">
        <CanopyFlex gap="12X" wrap>
          <StackedData label="CREATED" value={format(order.createdAt)} />
          <StackedData label="STATUS" value={order.status} />
        </CanopyFlex>
        <CanopyFlex gap="2X" wrap>
          <AttachToExternalOrderButton
            supplierOrganizationId={supplierOrganizationId}
            supplierId={supplierId}
            csrInboxStateId={csrInboxStateId}
            supplierSystemOrderId={order.supplierSystemId}
          />
          <CanopyButton
            as="a"
            href={order.externalOrderUrl}
            target="_blank"
            variant="secondary"
            iconStart="eye"
            size="small"
          >
            View
          </CanopyButton>
        </CanopyFlex>
      </CanopyFlex>
    </Card>
  )
}

type DocumentationOrderCardProps = {
  order: DocumentationOrder
  csrInboxStateId: string
  supplierOrganizationId: string
  supplierId: string
}

export const DocumentationOrderCard: React.FC<DocumentationOrderCardProps> = ({
  order,
  csrInboxStateId,
  supplierOrganizationId,
  supplierId,
}) => {
  return (
    <Card role="listitem" aria-label="Signature Request">
      <CanopyFlex
        justifyContent="space-between"
        alignItems="flex-start"
        className="canopy-mb-12x"
        wrap
      >
        <div>
          <div className="canopy-typography-body-small canopy-mb-2x">
            CLINICIAN
          </div>
          <h4 className="canopy-typography-body-medium">
            {titlecase(order.clinician.name)}
          </h4>
        </div>
        <Badge text="Signature request" variant="outlined" />
      </CanopyFlex>
      <CanopyFlex justifyContent="space-between" alignItems="center">
        <CanopyFlex alignItems="center" gap="12X">
          <StackedData label="CREATED" value={format(order.createdAt)} />
          <StackedData label="STATUS" value={titleize(order.signingStatus)} />
        </CanopyFlex>
        {order.signingSignatureRequestId && (
          <AttachButton
            onClick={() =>
              attachToDocumentationOrder(
                supplierId,
                csrInboxStateId,
                order.signingSignatureRequestId
              )
            }
            successUrl={inboxDashboardUrl(supplierOrganizationId, supplierId)}
            ariaLabel=""
          />
        )}
      </CanopyFlex>
    </Card>
  )
}
