import React from "react"
import { ServiceValidation } from "services/patientPortal"
import {
  canopyColorBrandPrimary,
  canopyColorTextDisabled,
} from "@parachutehealth/canopy-tokens-color"
import * as styles from "./ServiceValidationMessage.module.scss"

const renderStars = (rating: number, tenStars: boolean) => {
  const length = tenStars ? 10 : 5

  return [...Array(length).keys()].map((i) => {
    return (
      <span
        key={i}
        style={{
          color: i < rating ? canopyColorBrandPrimary : canopyColorTextDisabled,
        }}
      >
        ★
      </span>
    )
  })
}

const ReceivedOrder = ({
  serviceValidation,
  supplierName,
}: {
  serviceValidation: ServiceValidation
  supplierName: string
}) => {
  return (
    <div>
      <h2>Service Validation</h2>
      <ol style={{ listStyleType: "alpha", paddingInlineStart: "1rem" }}>
        <li className={styles.serviceValidationRating}>
          <p>
            {serviceValidation.hasReceivedOrder
              ? "Patient confirmed receipt of their product"
              : "Not received"}
          </p>
        </li>
        <li className={styles.serviceValidationRating}>
          {renderStars(
            serviceValidation.overallExperience!,
            serviceValidation.tenStarRating
          )}
          <p>Overall experience with receiving the product(s).</p>
        </li>
        <li className={styles.serviceValidationRating}>
          {renderStars(
            serviceValidation.clearCommunications!,
            serviceValidation.tenStarRating
          )}
          <p>Clear and helpful communications about the order</p>
        </li>
        <li className={styles.serviceValidationRating}>
          {renderStars(
            serviceValidation.deliveryTimeliness!,
            serviceValidation.tenStarRating
          )}
          <p>Timeliness of the delivery of medical equipment</p>
        </li>
        <li className={styles.serviceValidationRating}>
          {renderStars(
            serviceValidation.likelyToRecommend!,
            serviceValidation.tenStarRating
          )}
          <p>Recommend {supplierName} to others who need medical equipment</p>
        </li>
        <li className={styles.serviceValidationRating}>
          Do you have any additional feedback or suggestions for improving the
          experience?
          <p style={{ marginLeft: "1rem", marginTop: "1rem" }}>
            <strong>A: </strong>
            {serviceValidation.additionalFeedback}
          </p>
        </li>
      </ol>
    </div>
  )
}

const NotReceivedOrder = ({
  serviceValidation,
}: {
  serviceValidation: ServiceValidation
}) => {
  return (
    <div>
      <h2>Service Validation</h2>
      <ol style={{ listStyleType: "alpha", paddingInlineStart: "1rem" }}>
        <li className={styles.serviceValidationRating}>
          <p>Patient did not receive their product(s).</p>
        </li>
        <li className={styles.serviceValidationRating}>
          <p>
            {serviceValidation.productStillNeeded
              ? "Patient still needs their product(s)."
              : "Patient no longer needs their product(s)."}
          </p>
        </li>
        <li className={styles.serviceValidationRating}>
          Do you have any additional feedback or suggestions?
          <p style={{ marginLeft: "1rem", marginTop: "1rem" }}>
            <strong>A: </strong>
            {serviceValidation.stillNeededFeedback}
          </p>
        </li>
      </ol>
    </div>
  )
}

type Props = {
  serviceValidation: ServiceValidation
  supplierName: string
}

const ServiceValidationMessage: React.FC<Props> = ({
  serviceValidation,
  supplierName,
}) => {
  if (serviceValidation.hasReceivedOrder) {
    return (
      <ReceivedOrder
        serviceValidation={serviceValidation}
        supplierName={supplierName}
      />
    )
  } else {
    return <NotReceivedOrder serviceValidation={serviceValidation} />
  }
}

export default ServiceValidationMessage
