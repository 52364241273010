import { CatalogSeed } from "../../../../types/sharedTypes"
import SidebarLayout from "../../../../components/SidebarLayout"
import React, { useContext, useEffect } from "react"
import {
  Backdrop,
  Box,
  CircularProgress,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@material-ui/core"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { deleteCatalogSeed, getCatalogSeed } from "../../../../api/seeds"
import SeedDetailsTable from "./components/SeedDetailsTable"
import { Link, useHistory, useParams } from "react-router-dom"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import { isNullOrUndefined } from "utilities/isNullOrUndefined"
import { seedEditUrl, seedsUrl } from "applications/Cms/urls/seeds"
import DropdownMenu from "../../../../components/DropdownMenu"
import ConfirmDialog from "../../../../components/ConfirmDialog"
import { isNonBlankString } from "../../../../../../utilities/isNonBlankString"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import Timestamp from "../../../../components/Timestamp"
import { CanopyText } from "@parachutehealth/canopy-text"

type InternalSeedDetailsPageProps = { seed: CatalogSeed }
const InternalSeedDetailsPage: React.FC<InternalSeedDetailsPageProps> = (
  props: InternalSeedDetailsPageProps
): React.JSX.Element => {
  const deleteSeed = async () => {
    const success: boolean = await deleteCatalogSeed(catalogSeed.externalId)
    if (success) {
      history.push(seedsUrl())
      showNotice(
        `The ${catalogSeed.name} seed has been deleted`,
        "success",
        [],
        true
      )
    } else {
      showNotice("An error occurred while deleting this seed", "error")
    }
  }

  const history = useHistory()
  const { showNotice } = useContext(NoticeContext)

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(
    false
  )
  const [catalogSeed] = React.useState<CatalogSeed>(props.seed)

  useEffect(() => {
    document.title = `Catalog Seed: ${catalogSeed.name}`
  })

  const HeaderContent: React.JSX.Element = (
    <>
      <Box className={classNames(styles.headerContainer)}>
        <Box className={classNames(styles.leftHeader)}>
          <Link to={seedsUrl()} className={classNames(styles.link)}>
            <CanopyIcon
              className={classNames(styles.backBtn)}
              name="angle-left"
              size="small"
              data-testid="back-btn"
            />
            <span className={classNames(styles.allSeeds)}>All Seeds</span>
          </Link>

          <span className="canopy-typography-heading-xlarge canopy-mbs-4x">
            {catalogSeed.name}
          </span>
        </Box>
        <Box className={classNames(styles.rightHeader)}>
          {catalogSeed.permissions?.edit && (
            <CanopyButton
              as={Link}
              to={seedEditUrl(catalogSeed.externalId)}
              variant="secondary"
              size="small"
              iconStart="pencil"
              disabled={!Boolean(catalogSeed.permissions?.edit)}
            >
              Edit Seed Settings
            </CanopyButton>
          )}
          <Box>
            <DropdownMenu
              label="More Actions"
              id="actions-menu"
              className="canopy-mis-4x"
            >
              {[
                {
                  label: "Delete Seed",
                  variant: "danger",
                  icon: "box-archive",
                  onClick: () => setDeleteConfirmationOpen(true),
                  ifTrue: Boolean(catalogSeed.permissions?.destroy),
                },
              ]}
            </DropdownMenu>
          </Box>
          <ConfirmDialog
            title="Delete Seed"
            message="Deleting this Seed will make it unavailable for future use and it will have to be re-created from scratch."
            confirmButtonText="Confirm"
            cancelButtonText="Cancel"
            open={deleteConfirmationOpen}
            onConfirm={deleteSeed}
            onCancel={() => setDeleteConfirmationOpen(false)}
            handleClose={() => setDeleteConfirmationOpen(false)}
          />
        </Box>
      </Box>
    </>
  )

  const MainContent = (
    <div className={styles.mainContent}>
      <h2 className="canopy-typography-heading-large canopy-mbe-4x">
        Seed Configuration
      </h2>
      <div>
        This Seed contains <b>{catalogSeed.packageCount} packages</b> and{" "}
        <b>{catalogSeed.productVariationCount} SKUs.</b>
      </div>

      <SeedDetailsTable seed={catalogSeed} />
    </div>
  )

  const OpenContent = (): React.ReactNode[] => {
    const contentNodes: React.ReactNode[] = []

    const InvalidSeedNotice = (): React.JSX.Element => {
      if (!catalogSeed.hasInvalidReferences) return <></>

      return (
        <CanopyNotice
          role="note"
          variant="error"
          title="Invalid Seed"
          message="This seed contains one or more references to an archived global Package or SKU."
        />
      )
    }

    const InternalNotes = (): React.JSX.Element => {
      if (!isNonBlankString(catalogSeed.internalNotes)) {
        return <></>
      } else {
        return (
          <div
            className={`${classNames(
              styles.internalNotesContainer
            )} canopy-p-6x`}
          >
            <Typography variant="subtitle2">
              <CanopyIcon
                fill="canopyColorPrimitivesBlue41"
                name="flag"
                size="small"
              />
              <b className="canopy-mis-2x">Internal Note</b>
            </Typography>

            <Typography variant="body1">{catalogSeed.internalNotes}</Typography>
          </div>
        )
      }
    }

    const items: { [p: string]: React.JSX.Element } = {
      "Last edited": <Timestamp time={catalogSeed.updatedAt} />,
    }

    contentNodes.push(<InternalNotes />)
    contentNodes.push(<InvalidSeedNotice />)
    contentNodes.push(
      <div>
        <CanopyText size="small" weight="bold">
          Usage Details
        </CanopyText>
        <ul className="list-unstyled canopy-mbe-0">
          {Object.keys(items).map((key) => {
            return (
              <li key={key} className="canopy-my-0">
                <CanopyText size="small">
                  {key}: <strong>{items[key]}</strong>
                </CanopyText>
              </li>
            )
          })}
        </ul>
      </div>
    )
    return contentNodes
  }

  const ClosedContent: React.JSX.Element = (
    <List>
      {isNonBlankString(catalogSeed.internalNotes) && (
        <Tooltip arrow placement="right" title={catalogSeed.internalNotes!}>
          <ListItem
            style={{ justifyContent: "center" }}
            disableGutters
            key="notes"
          >
            <CanopyIcon
              fill="canopyColorPrimitivesBlue41"
              name="flag"
              size="medium"
            />
          </ListItem>
        </Tooltip>
      )}
    </List>
  )

  return (
    <>
      <SidebarLayout
        openContent={OpenContent()}
        mainContent={MainContent}
        closedContent={ClosedContent}
        headerContent={HeaderContent}
        sidebarTitle="Seed Details"
      />
    </>
  )
}

const SeedDetailsPage: React.FC = () => {
  const { seedId } = useParams()

  const [catalogSeed, setCatalogSeed] = React.useState<
    CatalogSeed | undefined
  >()

  const [loading, setLoading] = React.useState<boolean>(
    isNullOrUndefined(catalogSeed)
  )

  const loadSeed = async (seedId: string | number) => {
    await getCatalogSeed(seedId).then((seed) => {
      setCatalogSeed(seed)
    })
    setLoading(false)
  }

  useEffect(() => {
    void loadSeed(seedId)
  }, [seedId])

  if (loading) {
    return (
      <Backdrop style={{ zIndex: 999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return <InternalSeedDetailsPage seed={catalogSeed!} />
  }
}

export default SeedDetailsPage
