import { get } from "services/api"
import { employerPrefix } from "utilities/url"

export const getExternalOrderDetails = async (
  supplierOrganizationId: string,
  supplierId: string,
  orderId: string
): Promise<any> => {
  const prefix = employerPrefix("SupplierOrganization", supplierOrganizationId)
  return get(
    `${prefix}/supplier_system_integrated_search/suppliers/${supplierId}/external_orders/${orderId}.json`
  ).then((response) => response.data)
}
