import { flatten } from "lodash"
import { FieldSet, StaticFieldDefinition } from "../types/form/sharedTypes"
import { isNullOrUndefined } from "../../../utilities/isNullOrUndefined"
import { OptionItem } from "@parachutehealth/canopy-combobox"
import { FormikErrors } from "formik"

/**
 *  A helper function to retrieve all required fields from a list of StaticFieldDefinitions.
 */
export const requiredFields = (
  fieldSets: FieldSet[]
): StaticFieldDefinition[] => {
  const reducerFunction = (
    accumulator: StaticFieldDefinition[],
    current: FieldSet
  ) => {
    if (Array.isArray(current)) {
      if (current.length > 0) {
        current
          .filter((sfd) => sfd.required)
          .forEach((sfd) => {
            accumulator.push(sfd)
          })
      }
    } else if (current.required) {
      accumulator.push(current)
    }

    return accumulator
  }

  //@ts-ignore
  return fieldSets.reduce(reducerFunction, [])
}

/**
 *  A helper function to transform the OptionItem value emitted by CanopyComboboxField into strings or string arrays.
 */
export const transformOptionItems = (
  value: OptionItem | OptionItem[] | null
): string | string[] | null => {
  if (isNullOrUndefined(value)) {
    return null
  } else if (Array.isArray(value)) {
    return value.map((x) => x.value)
  } else {
    return value!.value
  }
}

export const stringifyErrors = <T>(
  errors: FormikErrors<T>,
  key: string
): string | undefined => {
  if (!errors || !errors[key]) return undefined

  const error = errors[key]
  if (Array.isArray(error)) {
    return error.join("; ")
  } else {
    return error.toString()
  }
}

/**
 * Given an array of field definitions, which may contain nested arrays,
 * remove any questions that don't match a specified function.
 *
 * One noted use case is forms used for both "new" and "edit" mode but have differing questions.
 */
export const filterFieldSets = (
  fieldSets: FieldSet[],
  keepFn: (field: StaticFieldDefinition) => boolean
) => {
  const reducerFunction = (accumulator: FieldSet[], current: FieldSet) => {
    if (Array.isArray(current)) {
      const arr = current.reduce(reducerFunction, [])
      if (arr.length > 0) {
        accumulator.push(arr as StaticFieldDefinition[])
      }
    } else if (keepFn(current)) {
      accumulator.push(current)
    }

    return accumulator
  }

  return fieldSets.reduce(reducerFunction, [])
}

/**
 * Generate a one-dimensional object of initial values for feeding to Formik.
 * It takes a list of FieldSets (a list of questions for use in a form) and optionally
 * a record containing default values (such as the object you're editing).
 * It considers an optional initialValueGetter function for extracting values from the object that are not
 * at the direct field level.
 *
 * Broadly-speaking, it will prefer the default value, then any default values in the field definition, else blank.
 */
export const generateInitialValues = (
  fields: FieldSet[],
  defaultValues?: Record<string, any>
): Record<string, string> => {
  return flatten(fields).reduce((acc, curr) => {
    const value = firstPresent([
      curr.initialValueGetter?.(defaultValues),
      defaultValues?.[curr.name],
      curr.initialValue,
      "",
    ])
    acc[curr.name] = Array.isArray(value) ? value : value.toString()
    return acc
  }, {})
}

const firstPresent = (arr: any[]): any => {
  return arr.find((x) => !isNullOrUndefined(x))
}
