import {
  CatalogProduct,
  CatalogProductCustomAttribute,
} from "../../../../../../types/sharedTypes"
import React, { useContext, useState } from "react"
import {
  DataGridPro,
  GridColDef,
  GridRowModel,
  GridRowParams,
  GridSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import CopyableCell from "../../../../../../components/CopyableCell"
import InfoTooltip from "../../../../../../components/InfoTooltip"
import { isNullOrUndefined } from "../../../../../../../../utilities/isNullOrUndefined"
import DataGridToolbar from "../../../../../../components/DataGridToolbar"
import NoRows from "../../../../../../components/DataGrid/NoRows"
import { isTest } from "../../../../../../../../utilities/environment"
import useServerSideDataGrid, {
  ServerSideDataGridOptions,
  ServerSideDataGridParams,
} from "../../../../../../hooks/useServerSideDataGrid"
import { CountContext } from "../../../../../../contexts/CountContext"
import { getSkusForProduct, ProductSkusDataGridResponse } from "./api"
import { productVariationDetailUrl } from "../../../../../../urls/productVariations"
import isFunction from "lodash/isFunction"
import ConfirmDialog from "../../../../../../components/ConfirmDialog"
import { usePolicies } from "../../../../../../contexts/PoliciesContext"
import { trackEvent as trackAnalyticsEvent } from "../../../../../../../../utilities/analytics"
import {
  bulkArchiveProductVariations,
  unarchiveProductVariation,
} from "../../../../../../api/productVariations"
import {
  NoticeContext,
  NoticeState,
} from "../../../../../../contexts/NoticeContext"
import DropdownMenu from "../../../../../../components/DropdownMenu"
import { pluralize } from "../../../../../../../../utilities/string"
import { Backdrop, CircularProgress } from "@material-ui/core"
import { CanopyButton } from "@parachutehealth/canopy-button"
import ProductVariationForm from "../ProductVariationForm"
import { useQueryClient } from "@tanstack/react-query"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyHeading } from "@parachutehealth/canopy-heading"

const defaultOptions: ServerSideDataGridOptions = {
  page: 1,
  // todo: make this be driven by the backend? right now it's set in two different places
  sort: [{ field: "description", sort: "asc" }],
}

const defaultFilterModel = {
  columnFilters: [{ name: "status", operator: "is", value: "Live" }],
}

export type SkusDataGridProps = {
  product: CatalogProduct
  productCustomAttributes: CatalogProductCustomAttribute[]
}
const SkusDataGrid: React.FC<SkusDataGridProps> = (
  props: SkusDataGridProps
) => {
  const { productCustomAttributes, product } = props

  const [overlayVisible, setOverlayVisible] = useState<boolean>(false)
  const { hasPermission } = usePolicies()
  const { showNotice } = useContext(NoticeContext)
  const canArchiveSku = hasPermission("Catalog::ProductVariation", "destroy")
  const canEditSku = hasPermission("Catalog::ProductVariation", "edit")
  const canCreateSku = hasPermission("Catalog::ProductVariation", "create")
  const queryClient = useQueryClient()

  const columns: GridColDef[] = React.useMemo(() => {
    return [
      {
        field: "externalId",
        headerName: "SKU ID",
        flex: 1,
        minWidth: 100,
        type: "string",
        align: "left",
        headerAlign: "left",
        hide: true,
        renderCell: (params) => {
          return (
            <CopyableCell
              text={params.row.externalId}
              dataTestId={`sku-id-${params.row.externalId}`}
            />
          )
        },
      },
      {
        field: "description",
        headerName: "SKU Description",
        flex: 2,
        minWidth: 500,
        renderCell: (params) => {
          return (
            <>
              <a href={productVariationDetailUrl(params.row.externalId)}>
                {params.row.description}
              </a>
              {params.row.internalNotes && (
                <InfoTooltip
                  className="canopy-mis-6x color-dark-gray"
                  title={params.row.internalNotes}
                />
              )}
            </>
          )
        },
      },
      {
        field: "status",
        headerName: "Status",
        flex: 2,
        hide: true,
        minWidth: 100,
        type: "singleSelect",
        valueOptions: ["Live", "Archived"],
      },
      {
        field: "hcpcs",
        headerName: "HCPCS",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "manufacturerId",
        flex: 1,
        headerName: "Manufacturer ID",
        headerAlign: "left",
        minWidth: 150,
        type: "string",
        renderCell: (params) => {
          return (
            <CopyableCell
              text={params.row.manufacturerId}
              dataTestId={`manufacturer-id-${params.row.manufacturerId}`}
            />
          )
        },
      },
      {
        field: "quantity",
        headerName: "Quantity",
        flex: 1,
        minWidth: 100,
        type: "number",
        headerAlign: "left",
      },
      ...productCustomAttributes.map((pca) => {
        return {
          // this generated field name must match the naming convention used by the backend
          // in the ProductVariationsForProductDataGridQuery class
          field: `customAttribute${pca.customAttribute.id}`,
          headerName: pca.name,
          flex: 1,
          minWidth: 200,
        }
      }),
      {
        field: "supplierCount",
        headerName: "# of Suppliers",
        flex: 1,
        minWidth: 100,
        type: "number",
        align: "left",
        headerAlign: "left",
        renderCell: (params) => {
          return (
            <a
              href={`${productVariationDetailUrl(
                params.row.externalId
              )}#supplier_skus`}
            >
              {params.value}
            </a>
          )
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        minWidth: 160,
        sortable: false,
        filterable: false,

        renderCell: (params) => (
          <DropdownMenu label="Actions" buttonProps={{ variant: "tertiary" }}>
            {[
              {
                label: "Edit",
                ifTrue: canEditSku,
                onClick: () => {
                  setSkuFormState({
                    open: true,
                    mode: "edit",
                    skuId: params.row.externalId,
                  })
                },
              },
              {
                label: "Duplicate",
                ifTrue: canCreateSku,
                onClick: () => {
                  setSkuFormState({
                    open: true,
                    mode: "duplicate",
                    skuId: params.row.externalId,
                  })
                },
              },
              {
                label: "Archive",
                variant: "danger",
                ifTrue: Boolean(!params.row.archivedAt && canArchiveSku),
                onClick: () => {
                  setSelectedProductVariationIds([params.row.externalId])
                  setArchiveConfirmationOpen(true)
                },
              },
              {
                label: "Unarchive",
                ifTrue: Boolean(params.row.archivedAt && canArchiveSku),
                onClick: async () => {
                  await unarchive(params.row.externalId)
                },
              },
            ]}
          </DropdownMenu>
        ),
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCustomAttributes])

  // keeps track of which items are selected for individual or bulk archiving/editing/duplicating
  const [
    selectedProductVariationIds,
    setSelectedProductVariationIds,
  ] = React.useState<string[]>([])

  const gridApi = useGridApiRef()
  const [loading, setLoading] = useState<boolean>(false)
  const beforeFetch = () => setLoading(true)
  const afterFetch = () => setLoading(false)
  const { changeCount } = React.useContext(CountContext)

  const [gridData, setGridData] = React.useState<ProductSkusDataGridResponse>({
    records: [],
    totalCount: 0,
  })

  type SkuFormState = {
    open: boolean
    mode?: "create" | "edit" | "duplicate"
    skuId?: string
  }

  const [skuFormState, setSkuFormState] = React.useState<SkuFormState>({
    open: false,
  })

  const [archiveConfirmationOpen, setArchiveConfirmationOpen] = React.useState<
    boolean
  >(false)

  const onCancelConfirmArchive = () => {
    setArchiveConfirmationOpen(false)
  }

  const handleArchiveToolbarButtonClick = (): void => {
    setArchiveConfirmationOpen(true)
    setSelectedProductVariationIds(getSelectedIds())
  }

  /**
   * Get the currently-selected row (for use in functions like editing that are only applicable
   * when one row is selected).
   *
   * @return The first of the Grid's selected rows, or undefined if no rows are selected.
   */
  const selectedRow = (): GridRowModel | undefined => {
    return getSelectedRows().at(0)
  }

  /**
   * Pull out selected rows from the DataGrid api ref.
   * The return is guarded because the getSelectedRows function isn't immediately available.
   */
  const getSelectedRows = (): GridRowModel[] => {
    // this may not be available (or at least not a function)
    // when this is first called, so we have to guard against it
    if (isFunction(gridApi.current.getSelectedRows)) {
      return Array.from(gridApi.current.getSelectedRows().values())
    } else {
      return []
    }
  }

  /**
   * Whenever a SKU is mutated (added, removed, update) we want to both refresh the parent context
   * that contains our list of relevant attributes/values (in case we added or removed any) and also
   * the SKU grid itself.
   */
  const invalidateQueries = React.useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ["ProductDetailsPage"],
    })
  }, [queryClient])

  const unarchive = async (id: string) => {
    setOverlayVisible(true)

    await unarchiveProductVariation(id)
      .then(async (success) => {
        if (success) {
          await invalidateQueries()
          showNotice(
            "Successfully unarchived SKU, but associated supplier data is still lost",
            "success"
          )
        } else {
          showNotice("Failed to unarchive SKU", "error")
        }
      })
      .finally(() => {
        setOverlayVisible(false)
      })
  }

  const createMessageWithStatus = (
    successCount: number,
    errorCount: number
  ): { message: string; status: NoticeState["variant"] } => {
    const statuses: NoticeState["variant"][] = []
    const messages: string[] = []

    if (successCount) {
      messages.push(
        `Successfully archived ${successCount} ${pluralize(
          "SKU",
          successCount
        )}.`
      )
      statuses.push("success")
    }
    if (errorCount) {
      messages.push(
        `Unable to archive ${errorCount} ${pluralize("SKU", errorCount)}.`
      )
      statuses.push("error")
    }

    return statuses.length > 1
      ? { message: messages.join(" "), status: "warning" }
      : { message: messages[0], status: statuses[0] }
  }

  const handleConfirmArchive = async () => {
    setOverlayVisible(true)
    void trackAnalyticsEvent([
      "CMS Catalog",
      `SKU Archive:${
        selectedProductVariationIds.length > 1 ? "Bulk" : "Individual"
      } SKU Archive`,
    ])

    const response = await bulkArchiveProductVariations(
      selectedProductVariationIds
    ).finally(() => setOverlayVisible(false))

    const { successCount, errorCount } = response

    if (successCount) {
      await invalidateQueries()
    }
    const { message, status } = createMessageWithStatus(
      successCount,
      errorCount
    )
    showNotice(message, status)
    setSelectedProductVariationIds([])
    setArchiveConfirmationOpen(false)
  }

  /**
   * Convenience method for retrieve externalId values from selected rows.
   */
  const getSelectedIds = (): string[] => {
    return getSelectedRows().map((x) => x.externalId)
  }

  const handleSelectionChange = (
    _gridSelectionModel: GridSelectionModel
  ): void => {
    setSelectedProductVariationIds(getSelectedIds())
  }

  const confirmationMessage = () => {
    let item: string
    if (selectedProductVariationIds.length > 1) {
      item = `${selectedProductVariationIds.length} ${pluralize(
        "SKU",
        selectedProductVariationIds.length
      )}`

      return `Are you sure you want to archive these ${item}? This will delete the Supplier SKUs as well. `
    } else {
      item =
        gridData.records.find(
          (pv) => pv.externalId === selectedProductVariationIds[0]
        )?.description || "this SKU"

      return `Archiving ${item} will delete this SKU for all Suppliers who have it in their catalog.`
    }
  }

  const fetchFunction = async (
    params: ServerSideDataGridParams
  ): Promise<void> => {
    const data = await getSkusForProduct(product.externalId, params)
    changeCount("skus", data.totalCount)
    setGridData((prev) => ({
      ...prev,
      records: data.records,
      totalCount: data.totalCount,
    }))
  }

  const {
    filterModel,
    options,
    handlePageChange,
    handleFilterModelChange,
    handleSortModelChange,
  } = useServerSideDataGrid<ServerSideDataGridOptions>({
    trackHistory: false,
    defaultOptions,
    columnDefinitions: columns,
    fetchFunction,
    defaultFilterModel,
    beforeFetch,
    afterFetch,
  })

  return (
    <>
      <CanopyFlex
        justifyContent="space-between"
        alignItems="center"
        className="canopy-mbe-12x"
      >
        <div>
          <CanopyHeading level={3} size="large" className="canopy-mbe-4x">
            SKUs
          </CanopyHeading>
          <div className="canopy-typography-body-medium">
            Unique identifiers for each distinct product variant that can be
            ordered.
          </div>
        </div>
        <div>
          <CanopyButton
            as="a"
            href={`/cms/catalog/products/${product.externalId}.csv`}
            size="small"
            variant="secondary"
          >
            Export SKUs
          </CanopyButton>

          {!skuFormState.open && canCreateSku && (
            <CanopyButton
              className="canopy-mis-4x"
              size="small"
              variant="primary"
              onClick={() => setSkuFormState({ open: true, mode: "create" })}
            >
              Add SKU
            </CanopyButton>
          )}
        </div>
      </CanopyFlex>

      {skuFormState.open && (
        <ProductVariationForm
          product={product}
          productCustomAttributes={productCustomAttributes}
          onSuccess={async () => {
            setSkuFormState({ open: false })
            await invalidateQueries()
          }}
          onCancel={() => setSkuFormState({ open: false })}
          {...skuFormState}
        />
      )}

      <DataGridPro
        className="borderless"
        checkboxSelection={canArchiveSku || canEditSku}
        isRowSelectable={(params: GridRowParams) =>
          isNullOrUndefined(params.row.archivedAt)
        }
        loading={loading}
        density="standard"
        rows={gridData.records}
        rowCount={gridData.totalCount}
        columns={columns}
        components={{
          Toolbar: DataGridToolbar,
          NoRowsOverlay: NoRows,
        }}
        componentsProps={{
          noRowsOverlay: {
            message: "There are currently no SKUs in this product",
          },
          toolbar: {
            filter: true,
            selectedRows: getSelectedRows(),
            archiveOnClick: handleArchiveToolbarButtonClick,
            canDestroy: canArchiveSku,
            canEdit: canEditSku,
            editOnClick: () =>
              setSkuFormState({
                open: true,
                mode: "edit",
                skuId: selectedRow()!.externalId,
              }),
            duplicateOnClick: () =>
              setSkuFormState({
                open: true,
                mode: "duplicate",
                skuId: selectedRow()!.externalId,
              }),
          },
        }}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        onSortModelChange={handleSortModelChange}
        onSelectionModelChange={handleSelectionChange}
        apiRef={gridApi}
        filterMode="server"
        paginationMode="server"
        sortingMode="server"
        getRowId={(row) => row.externalId}
        pagination={true}
        onPageChange={handlePageChange}
        sortModel={options.sort}
        pageSize={50}
        rowsPerPageOptions={[50]}
        disableVirtualization={isTest()} // Needs to be true for tests to work but ideally false in production, esp. for higher row counts
        disableSelectionOnClick
        hideFooterSelectedRowCount
        autoHeight
      />
      <ConfirmDialog
        title="Confirm action"
        message={confirmationMessage()}
        confirmButtonText="Confirm"
        confirmButtonVariant="danger"
        cancelButtonText="Cancel"
        open={archiveConfirmationOpen}
        onConfirm={handleConfirmArchive}
        onCancel={onCancelConfirmArchive}
        handleClose={onCancelConfirmArchive}
      />
      {/* We can easily invoke this for any potentially-slow operations for which we want to block the UI (such as adding or deleting) */}
      <Backdrop style={{ zIndex: 999 }} open={overlayVisible}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default SkusDataGrid
