import React, { FC, MouseEventHandler, useRef } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { EventCategory, trackEvent } from "utilities/tracking"
import * as styles from "./ActionableOrderCard.module.scss"
import ActionsDropdownButton from "./ActionsDropdownButton"
import FollowersModal from "components/ClinicalModals/FollowersModal"
import { Follower, Originator } from "sharedTypes"
import SnoozeModal from "components/ClinicalModals/SnoozeModal"
import UnsnoozeModal from "components/ClinicalModals/UnsnoozeModal"
import { useFeatureFlags } from "components/FeatureFlagContext"
import CancelModal, {
  CancelModalRef,
} from "components/ClinicalModals/CancelModal"
import classNames from "classnames"

interface Props {
  modalFollowers: Follower[]
  dmeOrder: {
    externalId: string
    maxSnoozeDate: string | undefined
    minSnoozeDate: string | undefined
    originator: Originator
    patient: {
      firstName: string
      lastName: string
    }
    snooze?: {
      snoozedUntil: string
    }
  }
  syncFollowers: (params: { followerIds: string[] }) => Promise<void>
  snoozeDmeOrder: (params) => Promise<void>
  unsnoozeDmeOrder: () => Promise<void>
  cancelDmeOrder: (
    cancelReason?: string,
    cancelComment?: string
  ) => Promise<void>
  openDropdownId: string | null
  setOpenDropdownId: (id: string | null) => void
}

const ActionsDropdown: FC<Props> = ({
  syncFollowers,
  dmeOrder,
  modalFollowers,
  snoozeDmeOrder,
  unsnoozeDmeOrder,
  cancelDmeOrder,
  openDropdownId,
  setOpenDropdownId,
}) => {
  const open = openDropdownId === dmeOrder.externalId
  const dropdownClosed = openDropdownId === null
  const [followersModalOpen, setFollowersModalOpen] = React.useState(false)
  const [snoozeModalOpen, setSnoozeModalOpen] = React.useState(false)
  const [unsnoozeModalOpen, setUnsnoozeModalOpen] = React.useState(false)
  const activatorElementRef = useRef(null)
  const cancelModalRef = useRef<CancelModalRef>(null)
  const { isFeatureEnabled } = useFeatureFlags()
  const isSnoozeFeatureEnabled = isFeatureEnabled("facilitySnoozeOrders")

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (dropdownClosed) {
      setOpenDropdownId(dmeOrder.externalId)
      trackEvent(EventCategory.ClinicalFacilityDashboard, "open_order_actions")
    } else {
      setOpenDropdownId(null)
    }
    event.preventDefault()
  }

  const handleFollowersButtonClick: MouseEventHandler = (e) => {
    trackEvent(
      EventCategory.ClinicalFacilityDashboard,
      "open_order_card_actions_edit_followers_selected"
    )
    e.preventDefault()
    setOpenDropdownId(null)
    setFollowersModalOpen(true)
  }

  const handleSnoozeButtonClick: MouseEventHandler = (e) => {
    trackEvent(
      EventCategory.ClinicalFacilityDashboard,
      "open_order_card_actions_snooze_selected"
    )
    e.preventDefault()
    setOpenDropdownId(null)
    setSnoozeModalOpen(true)
  }

  const handleUnsnoozeButtonClick: MouseEventHandler = (e) => {
    trackEvent(
      EventCategory.ClinicalFacilityDashboard,
      "open_order_card_actions_unsnooze_selected"
    )
    e.preventDefault()
    setOpenDropdownId(null)
    setUnsnoozeModalOpen(true)
  }

  const handleCancelButtonClick: MouseEventHandler = (e) => {
    trackEvent(
      EventCategory.ClinicalFacilityDashboard,
      "open_order_card_actions_cancel_selected"
    )
    e.preventDefault()
    setOpenDropdownId(null)
    cancelModalRef.current?.open()
  }

  const handleOverlayClick = (event) => {
    setOpenDropdownId(null)
    event.preventDefault()
    event.stopPropagation()
  }

  const icon = open ? "caret-up" : "caret-down"

  return (
    <>
      {open && (
        <div
          className={styles.overlay}
          onClick={(e) => handleOverlayClick(e)}
          data-testid="overlay"
        ></div>
      )}
      <div className={styles.actionsDropdownContainer}>
        <CanopyButton
          className={classNames(
            styles.actionsButton,
            open && styles.openActionsButton
          )}
          size="small"
          variant="secondary"
          iconEnd={icon}
          onClick={handleClick}
          ref={activatorElementRef}
        >
          Actions
        </CanopyButton>
        {open && (
          <div className={styles.actionsContainer}>
            <ActionsDropdownButton
              iconName="user"
              onClick={handleFollowersButtonClick}
            >
              Edit&nbsp;followers
            </ActionsDropdownButton>
            {isSnoozeFeatureEnabled &&
              (dmeOrder.snooze?.snoozedUntil ? (
                <ActionsDropdownButton
                  iconName="clock-rotate-left"
                  onClick={handleUnsnoozeButtonClick}
                >
                  Unsnooze&nbsp;order
                </ActionsDropdownButton>
              ) : (
                <ActionsDropdownButton
                  iconName="clock-rotate-left"
                  onClick={handleSnoozeButtonClick}
                >
                  Snooze&nbsp;order
                </ActionsDropdownButton>
              ))}
            <ActionsDropdownButton
              iconFill="canopyColorTextDanger"
              iconName="xmark"
              onClick={handleCancelButtonClick}
            >
              Cancel&nbsp;order
            </ActionsDropdownButton>
          </div>
        )}
      </div>
      <FollowersModal
        activatorElementRef={activatorElementRef}
        show={followersModalOpen}
        closeModal={() => setFollowersModalOpen(false)}
        editable={true}
        updateFollowers={syncFollowers}
        dmeOrder={{
          externalId: dmeOrder.externalId,
          followers: modalFollowers,
          patient: {
            firstName: dmeOrder.patient.firstName,
            lastName: dmeOrder.patient.lastName,
          },
        }}
        key={`${dmeOrder.externalId} follower modal open ${followersModalOpen}`}
      />
      <SnoozeModal
        activatorElementRef={activatorElementRef}
        isOpen={snoozeModalOpen}
        onClose={() => setSnoozeModalOpen(false)}
        snoozeDmeOrder={snoozeDmeOrder}
        dmeOrder={dmeOrder}
        key={`${dmeOrder.externalId} snooze modal open ${snoozeModalOpen}`}
      />
      <UnsnoozeModal
        activatorElementRef={activatorElementRef}
        isOpen={unsnoozeModalOpen}
        onClose={() => setUnsnoozeModalOpen(false)}
        unsnoozeDmeOrder={unsnoozeDmeOrder}
        patientName={`${dmeOrder.patient.firstName} ${dmeOrder.patient.lastName}`}
        key={`${dmeOrder.externalId} unsnooze modal open ${unsnoozeModalOpen}`}
      />
      <CancelModal
        patientName={`${dmeOrder.patient.firstName} ${dmeOrder.patient.lastName}`}
        ref={cancelModalRef}
        activatorElementRef={activatorElementRef}
        onClose={() => setOpenDropdownId(null)}
        cancelDmeOrder={cancelDmeOrder}
        originator={dmeOrder.originator}
      />
    </>
  )
}

export default ActionsDropdown
