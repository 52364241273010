import React, { useState } from "react"
import { patch } from "services/api"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import {
  CanopyComboboxField,
  SelectOption,
} from "@parachutehealth/canopy-combobox-field"
import { makeApi } from "../../../../FacilitySelectForm/api"
import { fullName } from "utilities/person"
import { isComboBoxOptionItem } from "../helpers"
import { infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually } from "../../../queries"
import { Doctor } from "sharedTypes"

const clinicianQueryKey = ["csrInboxState", "clinician"]

export const setClinicianQueryData = (
  client: QueryClient,
  doctor: OrderingClinicianData
) => {
  client.setQueryData(clinicianQueryKey, doctor)
}

export const useClinicianQuery = () => {
  return useQuery<OrderingClinicianData>({
    queryKey: clinicianQueryKey,
    queryFn: () => ({}),
    staleTime: infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  })
}

type DoctorOption = Pick<Doctor, "firstName" | "lastName" | "npi"> & {
  id: string
}

export type OrderingClinicianData = {
  extractedFirstName?: string
  extractedLastName?: string
  extractedNpi?: string
  doctor?: DoctorOption
  matchedClinicianNpi?: string
}

export type InboxStateDoctor = {
  doctor?: {
    id: string
    name: string
  }
}

type UpdateClinicianInformation = (
  updatedData: Partial<InboxStateDoctor>
) => Promise<void>

const updateClinicianInformation = ({
  supplierOrganizationId,
  csrInboxStateId,
}: {
  supplierOrganizationId: string
  csrInboxStateId: string
}): UpdateClinicianInformation => async (
  updatedData: Partial<InboxStateDoctor>
): Promise<void> => {
  const pathToCsrInboxState = `/u/r/${supplierOrganizationId}/inbox_documents/${csrInboxStateId}`
  const requestBody = {
    doctor_id: updatedData.doctor?.id || null,
  }
  await patch(`${pathToCsrInboxState}/clinician`, requestBody)
}

const clinicianSelectDescription = ({
  extractedFirstName,
  extractedLastName,
  extractedNpi,
  matchedClinicianNpi,
}: OrderingClinicianData) => {
  if (matchedClinicianNpi) {
    return undefined
  }
  const clinicianName =
    extractedFirstName && extractedLastName
      ? `${extractedFirstName} ${extractedLastName}`
      : ""
  const clinicianNpi = extractedNpi ? `NPI - ${extractedNpi}` : ""

  return `Extracted text: ${clinicianName || ""}${
    clinicianName && clinicianNpi ? "," : ""
  } ${clinicianNpi || ""}`
}

const doctorToOption = (doctor, id) => {
  const doctorLabel = `${fullName(doctor)} - ${doctor.npi}`
  return {
    label: doctorLabel,
    value: id,
  }
}

export const ClinicianData = (props: {
  clinicianInformation: OrderingClinicianData
}) => {
  const { doctor } = props.clinicianInformation

  const [searchTerm, setSearchTerm] = useState<string>()

  const [selectedValue, setSelectedValue] = useState<SelectOption>(
    doctor ? doctorToOption(doctor, doctor.id) : { label: "", value: "" }
  )

  const queryClient = useQueryClient()
  const csrInboxState = useCsrInboxState()

  const onChange = updateClinicianInformation({
    supplierOrganizationId: csrInboxState.supplierOrganization.id,
    csrInboxStateId: csrInboxState.id,
  })

  const mutateClinician = useMutation({
    mutationFn: (doctor: InboxStateDoctor["doctor"]) => {
      return onChange({ doctor: doctor }).then(() => {
        queryClient.setQueryData<InboxStateDoctor>(clinicianQueryKey, (old) => {
          return {
            ...old,
            doctor: doctor || undefined,
          }
        })
      })
    },
  })

  const fetchDoctors = makeApi(
    "SupplierOrganization",
    csrInboxState.supplierOrganization.id
  ).fetchDoctors

  const { data: options } = useQuery({
    queryKey: ["clinicians", csrInboxState.id, searchTerm],
    queryFn: async () => {
      if (searchTerm) {
        return fetchDoctors(searchTerm).then((results) => {
          return results.map((result) =>
            doctorToOption(result, result.doctorId)
          )
        })
      }
      return []
    },
  })

  return (
    <>
      <CanopyComboboxField
        placeholder="Search by name or NPI"
        label="Clinician"
        options={options || []}
        multiple={false}
        value={selectedValue}
        onInputChange={setSearchTerm}
        onChange={(newValue) => {
          if (!newValue) {
            mutateClinician.mutateAsync(undefined)
            setSelectedValue("")
          } else if (isComboBoxOptionItem(newValue)) {
            mutateClinician.mutateAsync({
              id: newValue.value,
              name: newValue.label || "",
            })
            setSelectedValue(newValue)
          } else {
            console.warn(
              "new value on canopycomboboxfield was not an option item? That shouldn't happen",
              newValue
            )
          }
        }}
        noOptionsFoundMessage="No clinicians found for that search"
        description={clinicianSelectDescription(props.clinicianInformation)}
      ></CanopyComboboxField>
    </>
  )
}
