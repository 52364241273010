import { CatalogCustomAttribute } from "../../../../../../types/sharedTypes"
import { get } from "../../../../../../../../services/api"

export const getAttributesAndOptionsForProduct = async (
  productId: string
): Promise<CatalogCustomAttribute[]> => {
  return await get(
    `/cms/catalog/products/${productId}/custom_attributes_and_options.json`
  ).then((response) => response.data.records)
}
