import React, { useState } from "react"
import { ExternalPatient, ParachutePatient } from "../api"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { canopyColorTextSecondary } from "./index.module.scss"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { useHistory } from "react-router-dom"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { ROUTES } from "applications/SupplierOrganizationInboxDocument/routes"
import { usePatientMutation } from "../../usePatientMutation"
import { format } from "utilities/date"
import { getPatientData } from "../api"
import { handleError } from "utilities/error"
import { ApplicationError } from "sharedTypes"
import Card from "components/Card"
import { StackedData } from "applications/SupplierOrganizationInboxDocument/components/StackedData"

type PatientHeaderProps = {
  firstName?: string
  lastName: string
}

const PatientHeader = ({ firstName, lastName }: PatientHeaderProps) => (
  <h3 className="canopy-typography-body-large canopy-typography-font-weight-bold">
    {`${firstName} ${lastName}`}
  </h3>
)

type BasePatientCardProps = {
  children: React.ReactNode
  ariaLabel: string
}

const BasePatientCard = ({ children, ariaLabel }: BasePatientCardProps) => (
  <Card className="canopy-p-12" role="listitem" aria-label={ariaLabel}>
    <CanopyFlex gap="12X" direction="column">
      {children}
    </CanopyFlex>
  </Card>
)

type ParachutePatientCardProps = {
  patient: ParachutePatient
  supplierOrganizationId: string
  csrInboxStateId: string
}
type ViewOrdersButtonProps = {
  onClick: () => void
  patient: ParachutePatient | ExternalPatient
  loading: boolean
}

const ViewOrdersButton = ({
  onClick,
  patient,
  loading,
}: ViewOrdersButtonProps) => (
  <CanopyButton
    onClick={onClick}
    variant="secondary"
    iconEnd="arrow-right"
    loading={loading}
    aria-label={`View orders for ${patient.firstName} ${patient.lastName}`}
  >
    View orders
  </CanopyButton>
)

export const ParachutePatientCard = ({
  patient,
  supplierOrganizationId,
  csrInboxStateId,
}: ParachutePatientCardProps) => {
  const history = useHistory()
  const { mutateAsync: mutatePatient, isLoading } = usePatientMutation({
    supplierOrganizationId,
    csrInboxStateId,
    onSuccess: () => {
      history.push(ROUTES.ORDER_HISTORY)
    },
  })

  return (
    <BasePatientCard
      ariaLabel={`Parachute Patient Card for ${patient.firstName} ${patient.lastName}`}
    >
      <CanopyFlex
        gap="16X"
        justifyContent="space-between"
        style={{ width: "100%" }}
        alignItems="flex-start"
        wrap
      >
        <CanopyFlex gap="6X">
          <PatientHeader
            firstName={patient.firstName}
            lastName={patient.lastName}
          />
          <span
            className={`canopy-typography-body-small ${canopyColorTextSecondary}`}
          >
            {patient.sex}
          </span>
        </CanopyFlex>
        <ViewOrdersButton
          onClick={() =>
            mutatePatient({ masterPatientId: patient.masterPatientId })
          }
          patient={patient}
          loading={isLoading}
        />
      </CanopyFlex>
      <StackedData label="DOB" value={format(patient.dateOfBirth)} />
    </BasePatientCard>
  )
}

type ExternalPatientCardProps = {
  patient: ExternalPatient
  supplierOrganizationId: string
  csrInboxStateId: string
  supplierId: string
}

export const ExternalPatientCard: React.FC<ExternalPatientCardProps> = ({
  patient,
  supplierOrganizationId,
  csrInboxStateId,
  supplierId,
}) => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const { mutateAsync: mutatePatient } = usePatientMutation({
    supplierOrganizationId,
    csrInboxStateId,
    onSuccess: () => {
      history.push(ROUTES.ORDER_HISTORY)
    },
  })

  const handleViewOrders = async () => {
    setIsLoading(true)
    try {
      const patientData = await getPatientData(
        supplierOrganizationId,
        supplierId,
        patient.supplierSystemPatientKey
      )
      const sex = patientData.sex === "unknown" ? undefined : patientData.sex

      await mutatePatient({
        firstName: patient.firstName,
        lastName: patient.lastName,
        dateOfBirth: patient.dateOfBirth,
        sex,
        supplierSystemPatientId: patientData.supplierSystemPatientId,
      })
    } catch (error) {
      handleError(error as ApplicationError)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <BasePatientCard
      ariaLabel={`External Patient Card for ${patient.firstName} ${patient.lastName}`}
    >
      <CanopyFlex
        gap="16X"
        justifyContent="space-between"
        style={{ width: "100%" }}
        alignItems="flex-start"
        wrap
      >
        <CanopyFlex gap="6X">
          <PatientHeader
            firstName={patient.firstName}
            lastName={patient.lastName}
          />
        </CanopyFlex>
        <ViewOrdersButton
          onClick={handleViewOrders}
          patient={patient}
          loading={isLoading}
        />
      </CanopyFlex>
      <CanopyFlex gap="16X" wrap>
        <StackedData
          label="DOB"
          value={patient.dateOfBirth ? format(patient.dateOfBirth) : undefined}
        />
        <StackedData label="Zip" value={patient.deliveryZip} />
      </CanopyFlex>
      <a
        href={patient.profileUrl}
        target="_blank"
        rel="noreferrer"
        className="link"
      >
        View profile in brightree <CanopyIcon name="arrow-up-right" />
      </a>
    </BasePatientCard>
  )
}
