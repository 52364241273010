// @ts-strict-ignore
import React, { useContext } from "react"
import { EmployerType, OrderStatus, RecentOrder, Severity } from "sharedTypes"
import SignatureStatus from "components/SignatureStatus"
import DeliveryStatus from "components/DeliveryStatus"
import DeliveryDates from "components/DeliveryDates"
import DuplicateOrderButton from "./DuplicateOrderButton"
import classNames from "classnames"
import GlobalContext from "context/Global"
import { getDeliveryStatusSeverity } from "utilities/deliveryStatus"
import { getSignatureStatusSeverity } from "utilities/signatureStatus"
import { getSeverity } from "utilities/swatch"
import { sentenceCase } from "utilities/string"
import { useFeatureFlags } from "../../../components/FeatureFlagContext"
import ReorderForm from "applications/ReorderForm"

type Props = {
  recentOrder: RecentOrder
  dmeOrderUrl: string
  showSuppliers: boolean
}

const RecentOrderCard: React.FC<Props> = ({
  dmeOrderUrl,
  recentOrder,
  showSuppliers,
}: Props) => {
  const {
    id,
    products,
    supplierNames,
    clinicalFacilityName,
    creatorName,
    providerOriginator,
    status,
    isStatusVisible,
    reorderable,
    carrierAuthorizationNumber,
    permissions,
    orderStatus,
    cancellationMessage,
    signatureStatus,
    fulfillments,
    createdAt,
    snooze,
  } = recentOrder
  const { isFeatureEnabled } = useFeatureFlags()
  const dmeOrderCanceled = orderStatus === OrderStatus.Canceled
  const { currentEmployer } = useContext(GlobalContext)
  const deliveryStatuses = fulfillments.map(
    ({ deliveryStatus }) => deliveryStatus
  )
  const showDuplicateOrderButton =
    isFeatureEnabled("duplicateFacilityOrder") &&
    dmeOrderCanceled &&
    currentEmployer.employerType === EmployerType.ClinicalFacility

  const deliveryStatusSeverity =
    status === "Snoozed"
      ? Severity.Snoozed
      : getSeverity(deliveryStatuses.map(getDeliveryStatusSeverity))
  const severity = getSeverity([
    getSignatureStatusSeverity(signatureStatus),
    deliveryStatusSeverity,
  ])
  const deliveryStatusText =
    status === "Snoozed"
      ? `Snoozed until ${snooze?.snoozedUntil}`
      : sentenceCase(status)

  const createdByFacility = () => {
    return `${creatorName} (${clinicalFacilityName})`
  }
  const createdBySupplier = () => {
    return `${creatorName} (${supplierNames}) for ${clinicalFacilityName}`
  }
  const renderCreatedBy = () => {
    return providerOriginator ? createdByFacility() : createdBySupplier()
  }

  const renderProductName = (product) => {
    return product.isConsignmentCloset
      ? `${product.name} (Consignment)`
      : product.name
  }
  const renderProductNames = () => {
    if (!!products && products.length > 0) {
      return (
        <ul>
          {products.map((product) => (
            <li key={product.name}>{renderProductName(product)}</li>
          ))}
        </ul>
      )
    }

    return (
      <span className="paragraph color-mid-gray">
        Nothing has been added to this order.
      </span>
    )
  }
  const renderOrderStatuses = () => {
    if (dmeOrderCanceled) {
      return (
        <span className="font-xs color-dark-gray bold">
          {cancellationMessage}
        </span>
      )
    }
    return (
      <>
        <div>
          <DeliveryStatus
            statusLevel={deliveryStatusSeverity}
            statusText={deliveryStatusText}
          />
        </div>
        <div>
          <SignatureStatus
            signatureStatus={signatureStatus}
            orderStatus={orderStatus}
            employerType={currentEmployer.employerType}
          />
        </div>
      </>
    )
  }
  return (
    <a
      className={classNames("card row", isStatusVisible && `card-${severity}`, {
        "card-inactive": dmeOrderCanceled,
      })}
      href={dmeOrderUrl}
    >
      <div className="card-status col-lg-4 order-lg-8">
        {renderOrderStatuses()}
        <div className="font-xs color-dark-gray">Order {id}</div>
        {showDuplicateOrderButton && (
          <DuplicateOrderButton
            dmeOrder={recentOrder}
            currentEmployer={currentEmployer}
          ></DuplicateOrderButton>
        )}
        {carrierAuthorizationNumber && (
          <div className="font-xs color-dark-gray">
            Auth: #{carrierAuthorizationNumber}
          </div>
        )}
        {reorderable && permissions.reorder && (
          <div className="card-action">
            <ReorderForm
              dmeOrderId={id}
              currentEmployer={currentEmployer}
              buttonClassName="btn-sm btn-primary-o"
            />
          </div>
        )}
      </div>
      <div className="card-content col-lg-8 order-lg-4">
        <div className="row">
          <div className="card-notes col-lg-6">
            <div className="card-note">
              <h6 className="title">Delivery:</h6>
              <DeliveryDates
                fulfillments={fulfillments}
                createdAt={createdAt}
              />
            </div>
            {showSuppliers && (
              <div className="card-note">
                <h6 className="title">Supplied By:</h6>
                <span>{supplierNames || "-"}</span>
              </div>
            )}
            <div className="card-note">
              <h6 className="title">Created by:</h6>
              <span>{renderCreatedBy()}</span>
            </div>
          </div>
          <div className="card-body col-lg-6">{renderProductNames()}</div>
        </div>
      </div>
    </a>
  )
}

export default RecentOrderCard
