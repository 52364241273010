import React from "react"
import { Route, Switch } from "react-router-dom"
import { withBrowserRouter } from "../../routers/BrowserRouter"
import {
  therapySelectionPath,
  diagnosesPath,
  medicalNecessityPath,
} from "./routes"
import Therapy from "./Therapy/Therapy"
import Diagnoses from "./Diagnoses/Diagnoses"
import MedicalNecessity from "./MedicalNecessity/MedicalNecessity"
import { QueryClientProvider } from "@tanstack/react-query"
import { employerPrefix } from "utilities/url"
import { useStableQueryClient } from "utilities/queryClient"

interface DoctorPendingOrderWorkflowProps {
  therapyName: string
}

const DoctorPendingOrderWorkflow = ({
  therapyName,
}: DoctorPendingOrderWorkflowProps) => {
  const queryClient = useStableQueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route
          path={therapySelectionPath(":pendingOrderId", ":therapySelectionId")}
          exact
        >
          <Therapy therapyName={therapyName} />
        </Route>
        <Route
          path={diagnosesPath(":pendingOrderId", ":therapySelectionId")}
          exact
        >
          <Diagnoses />
        </Route>
        <Route
          path={medicalNecessityPath(":pendingOrderId", ":therapySelectionId")}
          exact
        >
          <MedicalNecessity />
        </Route>
      </Switch>
    </QueryClientProvider>
  )
}

export default withBrowserRouter(DoctorPendingOrderWorkflow, {
  basename: employerPrefix(),
})
