import React from "react"
import * as styles from "./AuthDetailsAccordion.module.scss"
import { CarrierAuthorization } from "../../../../../graphql/__generated__/graphql"
import { AuthDetailsAccordionSection } from "../AuthDetailsAccordionSection/AuthDetailsAccordionSection"
import MuiAccordion from "@material-ui/core/Accordion"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { AccordionDetails, AccordionSummary } from "@material-ui/core"
import {
  DOCTOR_DETAILS,
  FACILITY_DETAILS,
  PATIENT_DETAILS,
  PLAN_DETAILS,
  POST_SUBMIT_REQUEST_DETAILS,
  SUBMITTER_DETAILS,
  SUBSCRIBER_DETAILS,
  SUPPLIER_DETAILS,
  SUPPLIER_LEGAL_ENTITY_DETAILS,
} from "../../utilities/authRequestDetails"
import AuthStatusPill from "../AuthStatusPill/AuthStatusPill"
import {
  AuthStatusToSeverity,
  AuthStatusToTitle,
} from "../../utilities/statusFormatters"

interface AuthDetailsAccordionProps {
  authorization: CarrierAuthorization
}

export const AuthDetailsAccordion: React.FC<AuthDetailsAccordionProps> = ({
  authorization,
}) => {
  const sections = [
    POST_SUBMIT_REQUEST_DETAILS,
    SUPPLIER_DETAILS,
    SUPPLIER_LEGAL_ENTITY_DETAILS,
    SUBSCRIBER_DETAILS,
    PLAN_DETAILS,
    PATIENT_DETAILS,
    FACILITY_DETAILS,
    DOCTOR_DETAILS,
    SUBMITTER_DETAILS,
  ]

  return (
    <>
      <MuiAccordion className={styles.accordionRoot}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={styles.authAccordionHeader}>
            <h4 className="canopy-typography-heading-large canopy-m-0 canopy-py-4x">
              All auth request details
            </h4>
            {authorization.authStatus && (
              <AuthStatusPill
                statusLevel={AuthStatusToSeverity[authorization.authStatus]}
                statusText={AuthStatusToTitle[authorization.authStatus]}
              />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className="d-block">
          {sections.map((section) => (
            <AuthDetailsAccordionSection
              key={section.title}
              authorization={authorization}
              dataDefinition={section}
            />
          ))}
        </AccordionDetails>
      </MuiAccordion>
    </>
  )
}
