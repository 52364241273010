import React from "react"
import Icon from "components/Icon"
import classNames from "classnames"

type Props = {
  className?: string
  strokeColor?: string
  percentage: number
}
const ProgressDonut = ({ className, percentage, strokeColor }: Props) => {
  const r = 44
  const shift = 15
  const cx = r + shift
  const cy = r + shift
  const circ = 2 * Math.PI * r
  const filledCirc = (circ * percentage) / 100
  const pathD = `M ${cx},${cy} m 0,-${r} a ${r},${r} 0 1 1 0,${
    2 * r
  } a ${r},${r} 0 1 1 0,-${2 * r}`
  const emptyStyle = {
    strokeDasharray: `${circ}px ${circ}px`,
    strokeDashoffset: "0px",
    transition:
      "stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s",
  }
  const fillStyle = {
    ...emptyStyle,
    strokeDasharray: `${filledCirc}px, ${circ}px`,
  }
  if (percentage >= 100) {
    return (
      <>
        <span className="color-secondary small bold canopy-mie-2x">
          Ready to Send
        </span>
        <Icon type="check-circle" className="color-check-success" />
      </>
    )
  }

  return (
    <svg
      className={classNames("progress-circle", className)}
      viewBox={`0 0 ${2 * (r + shift)} ${2 * (r + shift)}`}
    >
      <path
        d={pathD}
        fillOpacity="0"
        stroke="#eaeae9"
        strokeWidth="12"
        style={emptyStyle}
      />
      <path
        d={pathD}
        fillOpacity="0"
        stroke={strokeColor || "#00beaa"}
        strokeLinecap="square"
        strokeWidth="12"
        style={fillStyle}
      />
    </svg>
  )
}

export default ProgressDonut
