// @ts-strict-ignore
import React from "react"
import {
  DmeOrderWarning,
  Employer,
  NotAddablePackageConfigurationWarningMetadata,
  PackageConfigurationWarningMetadata,
  RejectedSupplierWarningMetadata,
  InsuranceWarningMetadata,
  InsuranceRank,
  DmeOrder,
} from "sharedTypes"
import Warning from "./Warning"
import SignatureWarning from "./SignatureWarning"
import BasicSupplierWarning from "applications/Workflow/components/BasicSupplierWarning"
import { Permissions } from "../../sharedTypes"
import { reportError } from "utilities/error"
import * as routes from "applications/Workflow/routes"
import {
  stepPath,
  defaultPath,
} from "applications/Workflow/utilities/navigation"
import WarningTracker from "./WarningTracker"
import { getWarningText } from "applications/Workflow/utilities/warningText"
import cx from "classnames"

interface Props {
  warnings: DmeOrderWarning[]
  permissions: Permissions
  currentEmployer: Employer
  trackEvents: boolean
  submissionErrorMessage?: string
  dmeOrder: DmeOrder
  sendSignatureRequest: (params: {}) => Promise<void>
}

interface RendererProps {
  warning: DmeOrderWarning
  permissions: Permissions
  currentEmployer: Employer
  trackEvents: boolean
  dmeOrder: DmeOrder
  sendSignatureRequest: (params: {}) => Promise<void>
}

const warnings = {
  product_requirements: productRequirementsWarning,
  rx_details: rxDetailsWarning,
  rx_quantity: rxQuantityWarning,
  not_addable_package_configuration: notAddablePackageConfigurationWarning,
  supplier_signature: supplierSignatureWarning,
  manager_approval: managerApprovalWarning,
  basic_supplier: basicSupplierWarning,
  proof_of_delivery: proofOfDeliveryWarning,
  rejected_supplier: rejectedSupplierWarning,
  supplier_system_clinical_facility_id: supplierSystemClinicalFacilityIdWarning,
  carrier_authorization_number: carrierAuthorizationNumberWarning,
  primary_insurance_policy: primaryInsurancePolicyWarning,
  secondary_insurance_policy: secondaryInsurancePolicyWarning,
  tertiary_insurance_policy: tertiaryInsurancePolicyWarning,
}

const genericWarning = (
  warning: DmeOrderWarning,
  permissions: { update: boolean },
  trackEvents: boolean,
  to?: string,
  title?: string
) => (
  <Warning
    editable={permissions.update}
    warningType={warning.key}
    severity={warning.severity}
    text={warning.text}
    to={to || defaultPath}
    trackEvents={trackEvents}
    title={title}
  />
)

const incompletePreauthorization = (warning, permissions, trackEvents) => {
  return genericWarning(
    warning,
    permissions,
    trackEvents,
    routes.paymentPath(null, "preauthorization")
  )
}

const insurancePolicyWarningHandling = (
  rank: InsuranceRank,
  warning,
  permissions,
  trackEvents
) => {
  const { errorType } = warning.metadata as InsuranceWarningMetadata
  return genericWarning(
    warning,
    permissions,
    trackEvents,
    routes.paymentPath(rank, errorType)
  )
}

function productRequirementsWarning({
  warning,
  permissions,
  trackEvents,
}: RendererProps) {
  const {
    packageConfigurationId,
  } = warning.metadata as PackageConfigurationWarningMetadata
  return genericWarning(
    warning,
    permissions,
    trackEvents,
    routes.productsPackageConfigurationPath(null, packageConfigurationId, true)
  )
}

function rxDetailsWarning({
  warning,
  permissions,
  trackEvents,
}: RendererProps) {
  const {
    packageConfigurationId,
  } = warning.metadata as PackageConfigurationWarningMetadata
  return genericWarning(
    warning,
    permissions,
    trackEvents,
    routes.productsPackageConfigurationPath(null, packageConfigurationId, true)
  )
}

function rxQuantityWarning({
  warning,
  permissions,
  trackEvents,
}: RendererProps) {
  const {
    packageConfigurationId,
  } = warning.metadata as PackageConfigurationWarningMetadata
  return genericWarning(
    warning,
    permissions,
    trackEvents,
    routes.productsPackageConfigurationPath(null, packageConfigurationId, true)
  )
}

function notAddablePackageConfigurationWarning({
  warning,
  permissions,
  trackEvents,
}: RendererProps) {
  const {
    packageConfigurationId,
  } = warning.metadata as NotAddablePackageConfigurationWarningMetadata
  return genericWarning(
    warning,
    permissions,
    trackEvents,
    routes.productsPackageConfigurationPath(null, packageConfigurationId, false)
  )
}

function managerApprovalWarning({
  warning,
  trackEvents,
  permissions,
}: RendererProps) {
  return genericWarning(
    warning,
    permissions,
    trackEvents,
    null,
    "Manager Approval Required"
  )
}

function proofOfDeliveryWarning({
  warning,
  trackEvents,
  permissions,
}: RendererProps) {
  return genericWarning(
    warning,
    permissions,
    trackEvents,
    null,
    "Consignment reminder"
  )
}

function rejectedSupplierWarning({
  warning,
  permissions,
  trackEvents,
}: RendererProps) {
  const {
    packageConfigurationId,
  } = warning.metadata as RejectedSupplierWarningMetadata
  return genericWarning(
    warning,
    permissions,
    trackEvents,
    routes.productsPackageConfigurationPath(null, packageConfigurationId, false)
  )
}

function supplierSystemClinicalFacilityIdWarning({
  warning,
  permissions,
  trackEvents,
}: RendererProps) {
  return genericWarning(
    warning,
    permissions,
    trackEvents,
    routes.supplierSystemClinicalFacilityExternalSystemIdModalPath()
  )
}

function supplierSignatureWarning({
  warning,
  trackEvents,
  permissions,
  dmeOrder,
  sendSignatureRequest,
}: RendererProps) {
  return (
    <SignatureWarning
      warning={warning}
      trackEvents={trackEvents}
      permissions={permissions}
      dmeOrder={dmeOrder}
      sendSignatureRequest={sendSignatureRequest}
    />
  )
}

function basicSupplierWarning({ warning, trackEvents }: RendererProps) {
  return (
    <WarningTracker warningType={warning.key} trackEvents={trackEvents}>
      <BasicSupplierWarning warning={warning} />
    </WarningTracker>
  )
}

function carrierAuthorizationNumberWarning({
  warning,
  permissions,
  trackEvents,
}: RendererProps) {
  return incompletePreauthorization(warning, permissions, trackEvents)
}

function primaryInsurancePolicyWarning({
  warning,
  permissions,
  trackEvents,
}: RendererProps) {
  return insurancePolicyWarningHandling(
    "primary",
    warning,
    permissions,
    trackEvents
  )
}

function secondaryInsurancePolicyWarning({
  warning,
  permissions,
  trackEvents,
}: RendererProps) {
  return insurancePolicyWarningHandling(
    "secondary",
    warning,
    permissions,
    trackEvents
  )
}

function tertiaryInsurancePolicyWarning({
  warning,
  permissions,
  trackEvents,
}: RendererProps) {
  return insurancePolicyWarningHandling(
    "tertiary",
    warning,
    permissions,
    trackEvents
  )
}

const InternalWarning = ({
  warning,
  permissions,
  currentEmployer,
  trackEvents,
  dmeOrder,
  sendSignatureRequest,
}: RendererProps) => {
  const warningToRender = warnings[warning.key]

  if (warningToRender) {
    return warningToRender({
      warning,
      permissions,
      currentEmployer,
      trackEvents,
      dmeOrder,
      sendSignatureRequest,
    })
  }

  if (!warning.text) {
    reportError(new Error(`Unknown DmeOrderWarning type: ${warning.key}`))

    return (
      <Warning
        editable={false}
        warningType={warning.key}
        severity={warning.severity}
        text={`Error with this order: ${warning.key}`}
        to={stepPath(warning.step) || defaultPath}
        trackEvents={trackEvents}
      />
    )
  }

  return genericWarning(
    warning,
    permissions,
    trackEvents,
    stepPath(warning.step)
  )
}

export default ({
  warnings,
  permissions,
  currentEmployer,
  trackEvents,
  submissionErrorMessage,
  dmeOrder,
  sendSignatureRequest,
}: Props) => {
  return (
    <>
      {warnings.length > 0 && (
        <div className={cx("wide-fixed-row")}>
          {warnings.map((warning) => {
            const warningWithText = {
              ...warning,
              text: getWarningText(warning, currentEmployer),
            }
            return (
              <InternalWarning
                key={warning.key}
                warning={warningWithText}
                permissions={permissions}
                currentEmployer={currentEmployer}
                trackEvents={trackEvents}
                dmeOrder={dmeOrder}
                sendSignatureRequest={sendSignatureRequest}
              />
            )
          })}
        </div>
      )}
      {submissionErrorMessage && (
        <Warning
          severity="error"
          title="Error submitting"
          text={submissionErrorMessage}
          warningType="submission_error"
          trackEvents={trackEvents}
        />
      )}
    </>
  )
}
