import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import { fetchAssignees, assignDocument } from "./api"
import { canopyDialogModalWithComboboxOrPicker } from "../../index.module.scss"
import { isComboBoxOptionItem } from "../../../DataEntryColumn/helpers"
import { Assignee } from "../../../../sharedTypes"
import { handleError } from "utilities/error"

const assigneeQueryKey = ["csrInboxState", "assignee"]

type Props = {
  assignee?: Assignee
}

const assigneeToOption = (result) => {
  return {
    label: result.name,
    value: result.id,
  }
}
const navAssignTo = (currentAssignee: OptionItem | undefined) => {
  return `Assign to${
    !!currentAssignee ? `: ${currentAssignee?.label?.substring(0, 30)}` : ""
  }`
}

export const AssignTo = ({ assignee }: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>()
  const [currentAssignee, setCurrentAssignee] = useState<
    OptionItem | undefined
  >(assignee ? assigneeToOption(assignee) : undefined)
  const [selectedValue, setSelectedValue] = useState<OptionItem | undefined>(
    currentAssignee
  )
  const openModal = () => setOpen(true)
  const closeModal = () => {
    setOpen(false)
    setSelectedValue(currentAssignee)
  }
  const queryClient = useQueryClient()
  const csrInboxState = useCsrInboxState()

  const { data: options } = useQuery({
    queryKey: ["clinicians", csrInboxState.id, searchTerm],
    queryFn: async () => {
      if (searchTerm) {
        return fetchAssignees(csrInboxState.supplier.id, searchTerm).then(
          (results) => {
            return results.map((result) => assigneeToOption(result))
          }
        )
      }
      return []
    },
  })

  const mutateAssignee = useMutation({
    mutationFn: (assignee: Assignee) => {
      return assignDocument(
        csrInboxState.supplier.id,
        csrInboxState.id,
        assignee.id
      ).then(() => {
        queryClient.setQueryData<Assignee>(
          assigneeQueryKey,
          assignee || undefined
        )
        selectedValue?.value
          ? setCurrentAssignee(selectedValue)
          : setCurrentAssignee(undefined)
      })
    },
  })

  const handleAssign = () => {
    mutateAssignee
      .mutateAsync({
        id: selectedValue?.value || "",
        name: selectedValue?.label || "",
      })
      .then(() => setOpen(false))
      .catch(handleError)
  }

  return (
    <>
      <CanopyButton
        iconStart="user"
        size="small"
        variant="tertiary"
        onClick={openModal}
      >
        {navAssignTo(currentAssignee)}
      </CanopyButton>
      <CanopyDialogModal
        className={canopyDialogModalWithComboboxOrPicker}
        header="Document assignment"
        open={open}
        onClose={closeModal}
        primaryFooterButton={
          <CanopyButton
            type="submit"
            onClick={handleAssign}
            loading={mutateAssignee.isLoading}
          >
            Assign
          </CanopyButton>
        }
        secondaryFooterButton={
          <CanopyButton variant="secondary" onClick={closeModal}>
            Cancel
          </CanopyButton>
        }
      >
        Assign this document to the appropriate user by searching and selecting
        from the list below.
        <CanopyComboboxField
          label="Assign to"
          className="canopy-pt-8x"
          placeholder="Type to search"
          options={options || []}
          value={selectedValue}
          disabled={mutateAssignee.isLoading}
          onInputChange={setSearchTerm}
          onChange={(newValue) => {
            if (!newValue) {
              setSelectedValue({ value: "", label: "" })
            } else if (isComboBoxOptionItem(newValue)) {
              setSelectedValue({
                value: newValue.value,
                label: newValue.label || "",
              })
            } else {
              console.warn(
                "new value on canopycomboboxfield was not an option item? That shouldn't happen",
                newValue
              )
            }
          }}
        ></CanopyComboboxField>
      </CanopyDialogModal>
    </>
  )
}
