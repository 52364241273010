import React from "react"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { dashboardUrl } from "../urls"
import * as styles from "./BackToDashboardLink.module.scss"
import Link from "components/Link"

interface Props {
  className?: string
}

const BackToDashboardLink = (props: Props) => {
  return (
    <div className={`${styles.backToDashboardContainer} ${props.className}`}>
      <CanopyIcon
        name="arrow-left"
        size="small"
        className={styles.backToDashboardArrow}
      />
      <Link href={dashboardUrl()} className={styles.backToDashboardLink}>
        Back to dashboard
      </Link>
    </div>
  )
}

export default BackToDashboardLink
