import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import React from "react"
import ssrAppQrCodeAppleAppStore from "ssr-app-qr-code-apple-app-store.png"
import ssrAppQrCodeGooglePlay from "ssr-app-qr-code-google-play.png"

type SsrAppStoreBannerModalProps = {
  isOpen: boolean
  onClose: () => void
}
const SsrAppStoreBannerModal = (props: SsrAppStoreBannerModalProps) => {
  return (
    <CanopyDialogModal
      header="Download Instructions"
      onClose={props.onClose}
      open={props.isOpen}
      primaryFooterButton={
        <CanopyButton onClick={props.onClose}>Close</CanopyButton>
      }
      size="medium"
    >
      Scan the QR code with your camera app to download the Sales Rep app on
      your phone.
      <CanopyFlex
        direction="row"
        justifyContent="center"
        gap="32X"
        className="canopy-py-12x"
      >
        <img
          src={ssrAppQrCodeGooglePlay}
          alt="Ssr App QR Code Google Play"
          style={{ width: "200px" }}
        />
        <img
          src={ssrAppQrCodeAppleAppStore}
          alt="Ssr App QR Code Apple"
          style={{ width: "200px" }}
        />
      </CanopyFlex>
    </CanopyDialogModal>
  )
}

export default SsrAppStoreBannerModal
