import { get, del, post } from "services/api"
import { isNullOrUndefined } from "utilities/isNullOrUndefined"
import { employerPrefix } from "utilities/url"

export const fetchAssignees = (supplierId: string, term: string) => {
  return get(`${employerPrefix("Supplier", supplierId)}/assignees.json`, {
    term,
  }).then(({ data }) => data)
}

export const assignDocument = (
  supplierId: string,
  csrInboxStateId: string,
  assigneeId: string | null | undefined
) => {
  const csrInboxDocumentAssigneeUrl = `${employerPrefix(
    "Supplier",
    supplierId
  )}/inbox/${csrInboxStateId}/assignees.json`

  if (isNullOrUndefined(assigneeId)) {
    return del(csrInboxDocumentAssigneeUrl)
  } else {
    return post(csrInboxDocumentAssigneeUrl, {
      csr_inbox_state: { assignee_id: assigneeId },
    })
  }
}
