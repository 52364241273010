import React, { useState } from "react"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyPickerField } from "@parachutehealth/canopy-picker-field"
import { isOptionItem } from "../../DataEntryColumn/helpers"
import { Patient } from "applications/SupplierOrganizationInboxDocument/sharedTypes"
import { isDateValid } from "utilities/date"

export const sexOptions = {
  male: "male",
  female: "female",
  unknown: "unknown",
}

type PatientDemographicsFormProps = {
  formData: Patient
  onSubmit: () => void
  onInputChange: (name: string, value: string) => void
  isLoading: boolean
  submitButtonText: string
  submitButtonIconEnd?: React.ComponentProps<typeof CanopyButton>["iconStart"]
  requireFields?: boolean
  errors: {
    firstName?: string
    lastName?: string
    dateOfBirth?: string
    sex?: string
  }
}

const sexFieldOptions = [
  { label: "female", value: "female" },
  { label: "male", value: "male" },
  { label: "unspecified/prefer not to say", value: "unknown" },
]

export const PatientDemographicsForm: React.FC<PatientDemographicsFormProps> = ({
  formData,
  onSubmit,
  onInputChange,
  isLoading,
  submitButtonText,
  submitButtonIconEnd,
  requireFields = false,
  errors: externalErrors = {},
}) => {
  const sex = formData.sex || "none_selected"
  const [internalErrors, setInternalErrors] = useState<
    PatientDemographicsFormProps["errors"]
  >({})

  const validateForm = () => {
    const newErrors: Record<string, string> = {}
    if (requireFields) {
      if (!formData.firstName) newErrors.firstName = "First name is required"
      if (!formData.lastName) newErrors.lastName = "Last name is required"
      if (!formData.dateOfBirth) {
        newErrors.dateOfBirth = "Date of birth is required"
      }
      if (!formData.sex) newErrors.sex = "Sex is required"
    }
    if (
      (formData.dateOfBirth?.length || 0) > 0 &&
      !isDateValid(formData.dateOfBirth)
    ) {
      newErrors.dateOfBirth = "Invalid date format; use MM/DD/YYYY"
    }

    setInternalErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const allErrors = { ...internalErrors, ...externalErrors }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validateForm()) {
      onSubmit()
    }
  }

  return (
    <CanopyForm onSubmit={handleSubmit}>
      <CanopyFlex gap="12X">
        <CanopyTextInputField
          label="First name"
          style={{ width: "100%" }}
          value={formData.firstName}
          onChange={(e) => onInputChange("firstName", e.target.value)}
          required={requireFields}
          feedbackMessage={allErrors.firstName}
        />

        <CanopyTextInputField
          label="Last name"
          style={{ width: "100%" }}
          value={formData.lastName}
          onChange={(e) => onInputChange("lastName", e.target.value)}
          required={requireFields}
          feedbackMessage={allErrors.lastName}
        />
      </CanopyFlex>
      <CanopyFlex gap="12X">
        <CanopyTextInputField
          style={{ width: "100%" }}
          label="Date of birth"
          value={formData.dateOfBirth}
          onChange={(e) => onInputChange("dateOfBirth", e.target.value)}
          required={requireFields}
          feedbackMessage={allErrors.dateOfBirth}
          placeholder="MM/DD/YYYY"
        />
        <CanopyPickerField
          label="Sex"
          style={{ width: "100%" }}
          value={sex}
          onChange={(value) => {
            if (isOptionItem(value)) {
              onInputChange(
                "sex",
                value.value === "none_selected"
                  ? undefined
                  : sexOptions[value.value]
              )
            }
          }}
          required={requireFields}
          feedbackMessage={allErrors.sex}
          placeholder={requireFields ? "Enter sex" : undefined}
          options={
            requireFields
              ? sexFieldOptions
              : [
                  { label: "Select sex...", value: "none_selected" },
                  ...sexFieldOptions,
                ]
          }
        />
      </CanopyFlex>
      <CanopyButton
        iconEnd={submitButtonIconEnd}
        type="submit"
        loading={isLoading}
      >
        {submitButtonText}
      </CanopyButton>
    </CanopyForm>
  )
}
