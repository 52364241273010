import { ServerSideDataGridParams } from "../../../../../hooks/useServerSideDataGrid"
import { get } from "../../../../../../../services/api"

export type ProductPackageDataGridRecord = {
  id: number
  packageExternalId: string
  packageName: string
  required: boolean
  archived: boolean
}

export type ProductPackageDataGridResponse = {
  records: ProductPackageDataGridRecord[]
  totalCount: number
}

export const getPackagesForProduct = async (
  productId: string,
  params?: ServerSideDataGridParams
): Promise<ProductPackageDataGridResponse> => {
  return await get(
    `/cms/catalog/products/${productId}/packages.json`,
    params
  ).then((response) => response.data)
}
