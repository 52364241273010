import { Option } from "components/SupplierOrganizationDashboard/LeftSidebar"
import { get, patch } from "../../services/api"
import { SalesCollaborationFacilitiesResponse, SalesRep } from "./sharedTypes"

const toggleSavedFacilityUrl = (supplierOrganizationExternalId: string) =>
  `/u/r/${supplierOrganizationExternalId}/sales_user_favorite_clinical_facilities`

export const inviteReferralUrl = (supplierOrganizationExternalId: string) => {
  return `/u/r/${supplierOrganizationExternalId}/supplier_sign_up`
}

export const getSalesCollaborationFacilities = async (
  supplierOrganizationId: string,
  params: { [key: string]: any } = {}
): Promise<SalesCollaborationFacilitiesResponse> => {
  const url = `/u/r/${supplierOrganizationId}/networks.json`
  return await get(url, params).then((response) => {
    return response.data as SalesCollaborationFacilitiesResponse
  })
}

export const getSavedFacilities = async (
  supplierOrganizationId: string,
  params: { [key: string]: any } = {}
): Promise<SalesCollaborationFacilitiesResponse> => {
  const url = `/u/r/${supplierOrganizationId}/saved.json`
  return await get(url, params).then((response) => {
    return response.data as SalesCollaborationFacilitiesResponse
  })
}

export const getLowEngagementFacilities = async (
  supplierOrganizationId: string,
  params: { [key: string]: any } = {}
): Promise<SalesCollaborationFacilitiesResponse> => {
  const url = `/u/r/${supplierOrganizationId}/low_engagement.json`
  return await get(url, params).then((response) => {
    return response.data as SalesCollaborationFacilitiesResponse
  })
}

export const toggleSavedFacility = async ({
  supplierOrganizationId,
  favorite,
  clinicalFacilityId,
}: {
  supplierOrganizationId: string
  favorite: boolean
  clinicalFacilityId: string
}) => {
  return await patch(toggleSavedFacilityUrl(supplierOrganizationId), {
    clinical_facility_id: clinicalFacilityId,
    favorite,
    supplier_organization_id: supplierOrganizationId,
  })
}

export const searchZipCodes = async ({
  supplierOrgExternalId,
  searchTerm,
  screen,
}: {
  screen: Option
  supplierOrgExternalId: string
  searchTerm?: string
}): Promise<string[]> => {
  const url = `/u/r/${supplierOrgExternalId}/zip_code_search.json?screen=${screen?.toLocaleLowerCase()}${
    searchTerm ? `&search_query=${searchTerm}` : ""
  }`

  return await get(url, {
    search_query: searchTerm ?? "",
  }).then((res) => res.data as string[])
}

export const searchSalesReps = async ({
  supplierOrgExternalId,
  searchTerm,
  screen,
}: {
  screen: Option
  supplierOrgExternalId: string
  searchTerm?: string
}): Promise<SalesRep[]> => {
  const url = `/u/r/${supplierOrgExternalId}/sales_rep_search.json?screen=${screen?.toLocaleLowerCase()}${
    searchTerm ? `&search_query=${searchTerm}` : ""
  }`

  return await get(url, {
    search_query: searchTerm ?? "",
  }).then((res) => res.data as SalesRep[])
}
