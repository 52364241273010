import React, { useEffect, useState } from "react"
import DocumentDashboard from "./components/DocumentDashboard"
import LeftSidebar, {
  Option,
} from "components/SupplierOrganizationDashboard/LeftSidebar"
import { SupplierList } from "components/SupplierOrganizationDashboard/SupplierList"
import { Supplier } from "applications/SupplierOrganizationInboxDocument/sharedTypes"
import classNames from "classnames"
import { getIdsOfSuppliersWithUnworkedDocuments } from "./api"
import { withBrowserRouter } from "routers/BrowserRouter"
import { useHistory, Route } from "react-router-dom"
import { employerPrefix } from "../../utilities/url"

type Props = {
  supplierOrganizationId: string
  suppliers: Pick<Supplier, "id" | "aiIntakeEnabled" | "name">[]
  initialSupplierId: string | undefined // TODO: WF-28578 make this required
  suppliersWithUnworkedIds: string[]
}

const SupplierOrganizationDocumentDashboard = ({
  supplierOrganizationId,
  suppliers,
  initialSupplierId,
  suppliersWithUnworkedIds,
}: Props) => {
  const history = useHistory()
  const [highlightedSupplierIds, setHighlightedSupplierIds] = useState(
    suppliersWithUnworkedIds
  )

  const showSupplierList = suppliers.length > 1

  const inboxUrlBase = `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox`

  const selectSupplier = (supplierId) => {
    history.push(`${inboxUrlBase}/${supplierId}`)
  }

  useEffect(() => {
    if (initialSupplierId) selectSupplier(initialSupplierId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSupplierId])

  useEffect(() => {
    const intervalId = setInterval(
      () =>
        getIdsOfSuppliersWithUnworkedDocuments(supplierOrganizationId).then(
          setHighlightedSupplierIds
        ),
      110000
    )
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Route
      path={`${inboxUrlBase}/:supplierId?`}
      render={({ match }) => {
        const supplier = suppliers.find((s) => s.id === match.params.supplierId)
        return (
          <div className="position-relative">
            <LeftSidebar active={Option.InboundDocuments} />
            <div className="row mt-n3">
              <div className="canopy-mbs-12x col-12">
                <h3>
                  {" "}
                  <span className="bold">Inbound Documents</span>
                </h3>
                <div className="row canopy-mbs-8x">
                  {showSupplierList && (
                    <div className="col-3 col-lg-2">
                      <SupplierList
                        suppliers={suppliers}
                        selectedSupplierId={match.params.supplierId}
                        selectSupplier={selectSupplier}
                        highlightedSupplierIds={highlightedSupplierIds}
                      />
                    </div>
                  )}
                  {supplier && (
                    <div
                      className={classNames({
                        "col-9 col-lg-10": showSupplierList,
                        "col-12 col-lg-12": !showSupplierList,
                      })}
                    >
                      <DocumentDashboard
                        key={supplier.id}
                        supplier={supplier}
                        supplierOrganizationId={supplierOrganizationId}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default withBrowserRouter(SupplierOrganizationDocumentDashboard, {})
