import { Address } from "sharedTypes"
import { format } from "utilities/date"
import { formatAddress } from "utilities/address"
import { CardDetailConfig } from "../components/AuthCard/AuthCard"
import { CarrierAuthorizationReferralTypesEnum } from "../../../../graphql/__generated__/graphql"
import isEmpty from "lodash/isEmpty"

enum LineItemDetailKey {
  HCPCS = "hcpcs",
  HCPCS_MODIFIERS = "hcpcsModifiers",
  QUANTITY = "quantity",
  UNITS_OF_MEASURE = "unitsOfMeasure",
  PREVIOUS_AUTHORIZATION_NUMBER = "previousAuthorizationNumber",
  START_DATE = "startDate",
  END_DATE = "endDate",
  PRIORITY = "priority",
  DELIVERY_ADDRESS = "deliveryAddress",
  SUPPLIER_LEGAL_ENTITY = "supplierLegalEntity",
}

export const BASE_LINE_ITEM_DETAILS: {
  [key in LineItemDetailKey]?: CardDetailConfig
} = {
  hcpcs: {
    title: "HCPCS",
    required: true,
  },
  hcpcsModifiers: {
    title: "HCPCS modifiers",
    required: false,
    formatFn: (value) => {
      return !isEmpty(value) ? value.join(", ") : "-"
    },
  },
  quantity: {
    title: "Units",
    required: true,
  },
  startDate: {
    title: "Start date",
    required: true,
    formatFn: (value) => format(value),
  },
  endDate: {
    title: "End date",
    required: true,
    formatFn: (value) => format(value),
  },
  priority: {
    title: "Priority",
    required: true,
    formatFn: (value) => value.charAt(0).toUpperCase() + value.slice(1),
  },
  deliveryAddress: {
    title: "Delivery address",
    required: true,
    formatFn: (value: Address) => formatAddress(value),
  },
  supplierLegalEntity: {
    title: "Supplier",
    required: true,
    formatFn: (value) => value.name,
  },
}

export const ORDERED_LINE_ITEM_DETAIL_KEYS: LineItemDetailKey[] = [
  LineItemDetailKey.HCPCS,
  LineItemDetailKey.HCPCS_MODIFIERS,
  LineItemDetailKey.QUANTITY,
  LineItemDetailKey.UNITS_OF_MEASURE,
  LineItemDetailKey.PREVIOUS_AUTHORIZATION_NUMBER,
  LineItemDetailKey.START_DATE,
  LineItemDetailKey.END_DATE,
  LineItemDetailKey.PRIORITY,
  LineItemDetailKey.DELIVERY_ADDRESS,
  LineItemDetailKey.SUPPLIER_LEGAL_ENTITY,
]

export const lineItemDetailsByReferralType = (
  referralType: CarrierAuthorizationReferralTypesEnum
) => {
  const previousAuthorizationNumber: CardDetailConfig = {
    title: "Previous auth number",
    required: true,
  }

  const details =
    referralType === CarrierAuthorizationReferralTypesEnum.Initial
      ? BASE_LINE_ITEM_DETAILS
      : { ...BASE_LINE_ITEM_DETAILS, previousAuthorizationNumber }

  const orderedDetails = {}
  ORDERED_LINE_ITEM_DETAIL_KEYS.forEach((key) => {
    if (details.hasOwnProperty(key)) {
      orderedDetails[key] = details[key]
    }
  })

  return orderedDetails
}
