import { post } from "services/api"
import {
  updateFacilityNameUrl,
  updateFacilityAddressUrl,
  inviteUsersUrl,
  skipInviteUsersUrl,
  updateFaxNumbersUrl,
} from "./urls"

export function updateFaxNumbers(faxNumbers: string[]): Promise<any> {
  return post(updateFaxNumbersUrl(), { faxNumbers })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data.errors))
}

export function updateFacilityName(name: string): Promise<any> {
  return post(updateFacilityNameUrl(), { name })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data.errors))
}

export function updateFacilityAddress(address: {
  line1: string
  city: string
  state: string
  zip: string
}): Promise<any> {
  return post(updateFacilityAddressUrl(), { address })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data.errors))
}

export function inviteUsers(
  users: {
    firstName: string
    lastName: string
    role: string
    email: string
  }[]
): Promise<any> {
  return post(inviteUsersUrl(), { users })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data.errors))
}

export function skipInviteUsers(): Promise<any> {
  return post(skipInviteUsersUrl())
}
