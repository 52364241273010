import React from "react"
import SupplierCommentForm from "./SupplierCommentForm"
import ClinicalFacilityCommentForm from "./ClinicalFacilityCommentForm"
import PayorCommentForm from "./PayorCommentForm"
import {
  DmeOrderWarning,
  ContextEmployer,
  EmployerType,
  DmeOrder,
  EmploymentMention,
  CommentType,
} from "sharedTypes"

type Props = {
  createComment: (values: { content: string }) => Promise<void>
  getEmploymentMentions(
    term: string,
    commentType: CommentType
  ): Promise<EmploymentMention[]>
  createInternalComment: (values: { content: string }) => Promise<void>
  employer: ContextEmployer
  warnings: DmeOrderWarning[]
  warningMessages: string[]
  viewFilter: string
  dmeOrder: DmeOrder
  createSupplierComment: (values: { content: string }) => Promise<void>
}

const CommentForm = ({
  warnings,
  employer,
  createComment,
  getEmploymentMentions,
  createInternalComment,
  createSupplierComment,
  warningMessages,
  viewFilter,
  dmeOrder,
}: Props) => {
  if (employer.employerType === EmployerType.Supplier) {
    return (
      <SupplierCommentForm
        createComment={createComment}
        getEmploymentMentions={getEmploymentMentions}
        createInternalComment={createInternalComment}
        createSupplierComment={createSupplierComment}
        warningMessages={warningMessages}
        viewFilter={viewFilter}
        dmeOrder={dmeOrder}
      />
    )
  }
  if (employer.employerType === EmployerType.InsurancePayor) {
    return (
      <PayorCommentForm
        createComment={createComment}
        warnings={warnings}
        warningMessages={warningMessages}
      />
    )
  }
  return (
    <ClinicalFacilityCommentForm
      createComment={createComment}
      warnings={warnings}
      warningMessages={warningMessages}
      dmeOrder={dmeOrder}
      getEmploymentMentions={getEmploymentMentions}
    />
  )
}

export default CommentForm
