import React, { useState } from "react"
import LeftSidebar, {
  Option,
} from "components/SupplierOrganizationDashboard/LeftSidebar"
import { SupplierList } from "components/SupplierOrganizationDashboard/SupplierList"
import CarrierAuthorizationDashboard from "../../components/CarrierAuthorizationDashboard"
import classNames from "classnames"

type Supplier = {
  id: string
  name: string
}

export interface Props {
  supplierOrganizationId: string
  suppliers: Supplier[]
  currentSupplierId: string
}

const SupplierOrganizationCarrierAuthorizationDashboard = (props: Props) => {
  const [currentSupplierId, setCurrentSupplierId] = useState(
    props.currentSupplierId
  )

  const showSupplierList = true //props.suppliers.length > 1

  return (
    <div className="position-relative">
      <LeftSidebar active={Option.Authorizations} />
      <div className="row mt-n3">
        <div className="canopy-mbs-12x col-12">
          <h3>
            <span className="bold">Authorizations</span>
          </h3>
          <div className="row canopy-mbs-8x">
            {showSupplierList && (
              <div className="col-3 col-lg-2">
                <SupplierList
                  suppliers={props.suppliers}
                  selectedSupplierId={currentSupplierId}
                  selectSupplier={setCurrentSupplierId}
                  highlightedSupplierIds={[]}
                />
              </div>
            )}
            <div
              className={classNames({
                "col-9 col-lg-10": showSupplierList,
                "col-12 col-lg-12": !showSupplierList,
              })}
            >
              <CarrierAuthorizationDashboard
                key={currentSupplierId}
                supplierId={currentSupplierId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupplierOrganizationCarrierAuthorizationDashboard
