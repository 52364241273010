import ProductSearchTabs from ".././CatalogBrowseTabs"
import { CatalogBrowseTab } from "sharedTypes"
import React from "react"
import { CanopyHeading } from "@parachutehealth/canopy-heading"

interface Props {
  currentTab: CatalogBrowseTab
  setSelectedTab: (tab: CatalogBrowseTab) => void
  showSearchByProductTabFirst: boolean
}

const ProductsAndServicesHeader: React.FC<Props> = ({
  currentTab,
  setSelectedTab,
  showSearchByProductTabFirst,
}: Props) => {
  return (
    <>
      <CanopyHeading level={1} size="2xLarge" className="canopy-mbe-12x">
        Products & Services
      </CanopyHeading>
      <ProductSearchTabs
        selectedTab={currentTab}
        setSelectedTab={setSelectedTab}
        showSearchByProductTabFirst={showSearchByProductTabFirst}
      ></ProductSearchTabs>
    </>
  )
}

export default ProductsAndServicesHeader
