import React, { useEffect } from "react"
import { get } from "services/api"

type Props = {
  pingUrl: string
  pingIntervalMs: number
}

const Pinger = ({ pingUrl, pingIntervalMs }: Props) => {
  useEffect(() => {
    get(pingUrl)
    const interval = setInterval(() => get(pingUrl), pingIntervalMs)
    return () => clearInterval(interval)
  }, [pingUrl, pingIntervalMs])

  return <></>
}

export default Pinger
