import React, { useState } from "react"
import { Nullable } from "sharedTypes"
import { formatPhone, normalizePhone } from "utilities/phone"
import Switch from "components/input/Switch"
import Alert from "components/Alert"
import * as routes from "applications/Workflow/routes"
import * as styles from "./index.module.scss"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { Box } from "@material-ui/core"
import { Link } from "react-router-dom"
import LearnMore from "./LearnMore"
import PatientActivityStatus from "./PatientActivityStatus"
import ResendInviteLink from "./ResendInviteLink"
import Header from "./Header"
import cx from "classnames"
import { conjugate } from "./utils"

type Props = {
  facilityEnabled: boolean
  globalFacilityEnabled: boolean
  orderStatus: string
  phoneNumber: string
  phoneCanReceiveSms: boolean
  latestMessageScheduledAt: Nullable<Date>
  lastPatientLoginAt: Nullable<Date>
  lastFailedLoginAt: Nullable<Date>
  updateFacilityEnabled: (facilityEnabled: boolean) => void
  resendFacilityInvite: () => void
  confirmViewImgUrl: string
  inviteSmsCopy: Nullable<string>
  lastAccessEventType: Nullable<string>
  phoneNumberRejectedAt: Nullable<string>
  optedOutAt: Nullable<string>
  canForceInviteResend: boolean
  facilityInviteResentAt: Nullable<Date>
  eSigningEnabled: boolean
  deliveryTicketSignedAt: string
}

const SmsPreview = ({ inviteSmsCopy }: { inviteSmsCopy: string }) => {
  const [showSmsPreview, setShowSmsPreview] = useState(false)

  return (
    <>
      <button
        className={styles.linkStyleBtn}
        onClick={() => setShowSmsPreview(!showSmsPreview)}
      >
        {showSmsPreview ? "Hide SMS" : "View SMS"}
        <CanopyIcon name={showSmsPreview ? "chevron-up" : "chevron-down"} />
      </button>

      {showSmsPreview && (
        <Box className="bg-lightest-gray canopy-p-4x canopy-my-4x rounded font-subparagraph">
          {inviteSmsCopy}
        </Box>
      )}
    </>
  )
}

const ClinicalFacilityPatientPortal: React.FC<Props> = (props) => {
  const {
    facilityEnabled,
    globalFacilityEnabled,
    orderStatus,
    phoneNumber,
    updateFacilityEnabled,
    resendFacilityInvite,
    phoneCanReceiveSms,
    latestMessageScheduledAt,
    lastPatientLoginAt,
    lastFailedLoginAt,
    confirmViewImgUrl,
    inviteSmsCopy,
    lastAccessEventType,
    phoneNumberRejectedAt,
    optedOutAt,
    canForceInviteResend,
    facilityInviteResentAt,
    eSigningEnabled,
    deliveryTicketSignedAt,
  } = props

  const formattedPhoneNumber = formatPhone(normalizePhone(phoneNumber))

  const confirmViewImg = (
    <img
      data-testid="facility-confirm-image"
      src={confirmViewImgUrl}
      alt=""
      style={{ width: 0, height: 0, border: 0 }}
    />
  )

  // invite already scheduled
  if (latestMessageScheduledAt) {
    const invited = latestMessageScheduledAt < new Date()
    return (
      <div className={cx("wide-fixed-row", "canopy-pbe-8x")}>
        <div className="card">
          <div className="flex">
            <Header
              invited={invited}
              eSigningEnabled={eSigningEnabled}
              latestMessageScheduledAt={latestMessageScheduledAt}
            />
          </div>
          <div className="canopy-mbs-8x">
            {/* order is facility enabled */}
            {globalFacilityEnabled && facilityEnabled && (
              <div className="canopy-mbe-4x">
                <span>
                  An SMS with a link to the order tracker {conjugate(invited)}{" "}
                  sent to <strong>{formattedPhoneNumber}</strong>
                  {". "}
                </span>

                {inviteSmsCopy && <SmsPreview inviteSmsCopy={inviteSmsCopy} />}
              </div>
            )}
            {/* not facility enabled, but is supplier enabled (we know this because invite already scheduled) */}
            {(!globalFacilityEnabled || !facilityEnabled) && (
              <div className="canopy-mbe-4x">
                <span className="font-subparagraph">
                  The supplier has elected to keep the patient in the loop by
                  enabling order tracking. An SMS with a link to the order
                  tracker {conjugate(invited)} sent to{" "}
                  <strong>{formattedPhoneNumber}</strong> . The patient can use
                  the link to view their order details.{" "}
                </span>

                {inviteSmsCopy && <SmsPreview inviteSmsCopy={inviteSmsCopy} />}
              </div>
            )}
            {invited && (
              <PatientActivityStatus
                lastPatientLoginAt={lastPatientLoginAt}
                lastFailedLoginAt={lastFailedLoginAt}
                lastAccessEventType={lastAccessEventType}
                phoneNumberRejectedAt={phoneNumberRejectedAt}
                optedOutAt={optedOutAt}
                eSigningEnabled={eSigningEnabled}
                deliveryTicketSignedAt={deliveryTicketSignedAt}
              />
            )}
            <span className={styles.widgetLinksLayout}>
              <LearnMore />
              <ResendInviteLink
                canForceInviteResend={canForceInviteResend}
                facilityInviteResentAt={facilityInviteResentAt}
                phoneNumber={formattedPhoneNumber!}
                resendFacilityInvite={resendFacilityInvite}
              />
            </span>
            {confirmViewImg}
          </div>
        </div>
      </div>
    )
  } else {
    // facility enabled and invite message not yet scheduled
    return (
      <div className={cx("wide-fixed-row", "canopy-pbe-8x")}>
        <div className="card">
          <div className="flex">
            <Header
              invited={false}
              eSigningEnabled={eSigningEnabled}
              latestMessageScheduledAt={latestMessageScheduledAt}
            />
            <Switch
              id="facility-enabled"
              disabled={!phoneCanReceiveSms}
              className="d-inline float-right"
              label={facilityEnabled ? "On" : "Off"}
              onChange={(value) => updateFacilityEnabled(value)}
              checked={phoneCanReceiveSms && facilityEnabled}
            />
          </div>
          <div className="canopy-mbs-8x">
            <span>
              An SMS with a link to the order tracker will be sent to{" "}
              <strong>{formattedPhoneNumber}</strong> (patient&#39;s primary
              phone number).{" "}
            </span>

            {inviteSmsCopy && <SmsPreview inviteSmsCopy={inviteSmsCopy} />}

            <p className="font-subparagraph canopy-mbs-4x">
              If enabled, you confirm that, to the best of your knowledge, the
              patient phone number is true and accurate, and that the patient
              consents to receiving the order tracker via SMS.
            </p>
            <LearnMore />
            {!phoneCanReceiveSms && (
              <Alert leftIcon bordered status="info">
                <strong>Action Recommended!</strong> To enable this feature the
                patient&#39;s primary phone number should be mobile.{" "}
                {orderStatus === "facility_in_progress" && (
                  <>
                    <Link to={routes.deliveryPath()}>Add a mobile number</Link>{" "}
                    as the patient&#39;s primary phone number.
                  </>
                )}
              </Alert>
            )}
            {confirmViewImg}
          </div>
        </div>
      </div>
    )
  }
}

export default ClinicalFacilityPatientPortal
