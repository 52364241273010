import React from "react"
import {
  EmployerType,
  LineItem,
  OrderStatus,
  PaymentMethod,
  RxDetailsOutput,
} from "sharedTypes"
import DataRow from "components/DataRow"
import { formatItemQuantity, formatSkuDescription } from "../../../utilities"
import { formatPaymentMethod, isDefaultPaymentMethod } from "utilities/payment"
import Image from "components/Image"
import PrescriptionDetails from "./PrescriptionDetails"

interface LineItemGroup {
  catalogId: string
  paymentMethod: PaymentMethod
  activeCustomAttributeConfigurationId: number
  patientPreference: boolean
  lineItems: [LineItem, ...LineItem[]]
  displayName: string
  imageUrl: string
  id: string
  quantity: number
  packageConfigurationId: string
  packageSupplier: {
    name: string
    externalId: string
  }
}

interface MyDmeOrder {
  orderStatus: OrderStatus
  supplierLegalEntity: {
    id: string
    name: string
  }
  transferredOrderLocked: boolean
}

interface Employer {
  employerType: EmployerType
}

interface Props {
  dmeOrder: MyDmeOrder
  lineItemGroup: LineItemGroup
  currentEmployer: Employer
  rxDetailsOutputs: RxDetailsOutput[]
  showFulfilledBy: boolean
}

const Well: React.FC = (props) => (
  <div className="well well-shadow">{props.children}</div>
)

interface ImageProps {
  url: string
}

const EquipmentLineItemGroup = ({
  dmeOrder,
  lineItemGroup,
  currentEmployer,
  rxDetailsOutputs,
  showFulfilledBy,
}: Props) => {
  const lineItemGroupOutputs = rxDetailsOutputs.filter(
    (output) => output.lineItemGroupId === lineItemGroup.id
  )

  const ImageWithDescription: React.FC<ImageProps> = (props) => (
    <div className="image with-description">
      <div className="image-container">
        <Image src={props.url} />
      </div>
      <div className="content">{props.children}</div>
    </div>
  )
  return (
    <Well key={lineItemGroup.id}>
      <ImageWithDescription url={lineItemGroup.imageUrl}>
        <p className="bold font-xs">{lineItemGroup.displayName}</p>
        <ul className="canopy-pis-8x font-xs">
          {lineItemGroup.lineItems.map((lineItem) => (
            <li key={lineItem.id} className="canopy-mbe-2x">
              <p className="font-xs canopy-mbe-0">
                {formatSkuDescription(lineItem.skuDescription)}
                {lineItem.hcpcs && ` - ${lineItem.hcpcs}`}
              </p>
            </li>
          ))}
        </ul>
        <hr className="canopy-mbs-8x canopy-mbe-0" />
        <DataRow
          className="font-xs canopy-mbe-0"
          label="Quantity"
          value={formatItemQuantity(lineItemGroup)}
        />
        {lineItemGroupOutputs.length > 0 && (
          <PrescriptionDetails
            rxDetailsOutputs={lineItemGroupOutputs}
            lineItemGroup={lineItemGroup}
            orderStatus={dmeOrder.orderStatus}
            employerType={currentEmployer.employerType}
            transferredOrderLocked={dmeOrder.transferredOrderLocked}
          />
        )}
        {currentEmployer.employerType === EmployerType.ClinicalFacility && (
          <DataRow
            className="font-xs canopy-mbe-0"
            label="Supplier"
            value={lineItemGroup.packageSupplier.name}
          />
        )}
        {currentEmployer.employerType === EmployerType.Supplier &&
          showFulfilledBy && (
            <DataRow
              className="font-xs canopy-mbe-0"
              label="Fulfilled by"
              value={dmeOrder.supplierLegalEntity.name}
            />
          )}
        {!isDefaultPaymentMethod(lineItemGroup.paymentMethod) && (
          <DataRow
            className="font-xs canopy-mbe-0"
            label="Payment Method"
            value={formatPaymentMethod(lineItemGroup.paymentMethod)}
          />
        )}
        {lineItemGroup.catalogId && (
          <DataRow
            className="font-xs canopy-mbe-0"
            label="Catalog ID"
            value={lineItemGroup.catalogId}
          />
        )}
      </ImageWithDescription>
    </Well>
  )
}
export default EquipmentLineItemGroup
