import React, { useCallback, useEffect, useState } from "react"
import { useFeatureFlags } from "components/FeatureFlagContext"
import Modal from "components/Modal"
import { handleError } from "utilities/error"
import {
  deletePackageConfiguration,
  getPackageConfiguration as getPackageConfigurationV1,
  getPackageConfigurationV2,
  updatePackageConfiguration,
  updatePrescribedQuantity,
} from "../../api"
import InternalPackageConfiguration from "./components/InternalPackageConfiguration"
import { getModalTitle } from "./utilities/modal"
import * as utilities from "./utilities"
import {
  answerRxSurveyQuestion,
  fetchInitialPackageConfigurationData,
  updateEmploymentPreferences,
} from "./api"
import withInitialData from "components/withInitialData"

import {
  ApplicationError,
  DmeOrder,
  Employer,
  EmployerType,
  FhirCatalogPackage,
  History,
  SurveyAnswerType,
  SurveyQuestion,
  DmeOrderCreatedVia,
} from "sharedTypes"
import {
  PackageConfiguration as PackageConfigurationData,
  PackageConfigurationStep,
  Preferences,
} from "./sharedTypes"

import { PreferredSupplierAlertModal } from "./components/PreferredSupplierAlertModal/PreferredSupplierAlertModal"
import Overlay from "../../../../components/Overlay"
import * as routes from "../../routes"
import {
  PreferredSupplierContext,
  usePreferredSupplier,
} from "./hooks/usePreferredSupplier"

import PendingOrderPackageAlert from "./Alerts/PendingOrderPackageAlert"
import { getReferralApiCatalogPackage } from "applications/Workflow/api"

type InitialData = {
  preferences: Preferences
  showServiceTier: boolean
}

type Props = {
  currentEmployer: Employer
  dmeOrder: DmeOrder
  packageConfigurationId: string
  returnToBrowse(): Promise<void>
  refreshDmeOrder(): Promise<void>
  history: History
  initialData: InitialData
  goToNextPage(): Promise<void>
  supplierId?: string
  forceShowRxDetails: boolean
  initialSelection: boolean
  yourOrganizationsSuppliersOnly: boolean
  selectedServiceAreaState: string
  searchWorkflow: string
}

const PackageConfiguration: React.FC<Props> = ({
  initialData,
  currentEmployer,
  packageConfigurationId,
  initialSelection,
  forceShowRxDetails,
  dmeOrder,
  refreshDmeOrder,
  goToNextPage,
  returnToBrowse,
  supplierId,
  history,
  yourOrganizationsSuppliersOnly,
  selectedServiceAreaState,
  searchWorkflow,
}) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const isMarketplacePackageConfigurationEnabled = isFeatureEnabled(
    "marketplacePackageConfiguration"
  )
  const checkPreferredSuppliers =
    currentEmployer.employerType === EmployerType.ClinicalFacility

  const [packageConfiguration, setPackageConfiguration] = useState<
    PackageConfigurationData
  >()
  const [step, setStep] = useState<PackageConfigurationStep>()
  const [visitedRxDetails, setVisitedRxDetails] = useState(false)
  const [visitedPackageConfig, setVisitedPackageConfig] = useState(false)
  const [useFeetForHeight, setUseFeetForHeight] = useState(
    initialData?.preferences?.useFeetForHeight || false
  )

  const [
    dismissPreferredSupplierAlert,
    setDismissPreferredSupplierAlert,
  ] = useState(false)
  const [
    redirectToPreferredSupplier,
    setRedirectToPreferredSupplier,
  ] = useState<boolean>(false)

  const [configLoading, setConfigLoading] = useState(false)

  const calculateStep = useCallback(
    (packageConfig: PackageConfigurationData) => {
      const forceShowPackageConfiguration =
        initialSelection && packageConfig.optionalProducts.length > 0

      const cantSkipRxDetails = forceShowRxDetails && !visitedRxDetails

      const cantSkipPackageConfig =
        forceShowPackageConfiguration && !visitedPackageConfig

      const cantSkip: PackageConfigurationStep[] = []
      if (cantSkipRxDetails)
        cantSkip.push(PackageConfigurationStep.RxDetailsStep)
      if (cantSkipPackageConfig)
        cantSkip.push(PackageConfigurationStep.ConfigurationStep)

      const packageConfigStep = utilities.calculateStep(packageConfig, cantSkip)
      setStep(packageConfigStep)
      setVisitedRxDetails(
        visitedRxDetails ||
          packageConfigStep === PackageConfigurationStep.RxDetailsStep
      )
      setVisitedPackageConfig(
        visitedPackageConfig ||
          packageConfigStep === PackageConfigurationStep.ConfigurationStep
      )
    },
    [
      forceShowRxDetails,
      initialSelection,
      visitedPackageConfig,
      visitedRxDetails,
    ]
  )

  const loadPackageConfig = useCallback(async () => {
    setConfigLoading(true)
    try {
      const getPackageConfiguration = isMarketplacePackageConfigurationEnabled
        ? getPackageConfigurationV2
        : getPackageConfigurationV1
      const packageConfigResponse = await getPackageConfiguration(
        packageConfigurationId,
        searchWorkflow
      )
      setPackageConfiguration(packageConfigResponse.data)
      calculateStep(packageConfigResponse.data)
      setConfigLoading(false)

      return packageConfigResponse.data
    } catch (e) {
      setConfigLoading(false)

      handleError(e as ApplicationError)
    }
  }, [
    packageConfigurationId,
    calculateStep,
    isMarketplacePackageConfigurationEnabled,
    searchWorkflow,
  ])
  const {
    loadPreferredSuppliers,
    preferredSuppliers,
    omitOtherSupplierInfoText,
    loading: preferredSuppliersLoading,
    selectedSupplier,
    showDefaultSelectedSupplier,
    hasRankedSuppliers,
    setSelectedSupplier,
  } = usePreferredSupplier(dmeOrder)

  const remove = async () => {
    setConfigLoading(true)
    await deletePackageConfiguration(packageConfigurationId)
    await refreshDmeOrder()
    history.push(routes.productsPath(supplierId))
  }

  const update = async (params) => {
    try {
      const response = await updatePackageConfiguration(
        packageConfigurationId,
        params,
        searchWorkflow
      )
      setPackageConfiguration(response.data)
      calculateStep(response.data)
    } catch (e) {
      handleError(e as ApplicationError)
    }
  }

  const save = async (params) => {
    try {
      const response = await updatePackageConfiguration(
        packageConfigurationId,
        params,
        searchWorkflow
      )
      const newPackageConfiguration = response.data

      const isInitialSupplierSelection =
        !packageConfiguration?.supplier && params.supplierId

      const supplierChanged =
        packageConfiguration?.supplier?.externalId !==
        newPackageConfiguration.supplier?.externalId

      const configurationIsNewlyValid =
        !packageConfiguration?.supplier &&
        newPackageConfiguration.configured &&
        newPackageConfiguration.optionalProducts.length ===
          newPackageConfiguration.selectedOptionalProductIds.length

      const shouldAdvanceFromSupplierStep =
        step === PackageConfigurationStep.SupplierStep &&
        newPackageConfiguration.supplier.serviceTier !== "basic"

      const shouldAdvanceToSummaryStepFromSupplierStep =
        newPackageConfiguration.requiredProductCustomAttributes.length === 0 &&
        newPackageConfiguration.optionalProductCustomAttributes.length === 0 &&
        shouldAdvanceFromSupplierStep

      setPackageConfiguration(newPackageConfiguration)

      if (
        configurationIsNewlyValid ||
        (isInitialSupplierSelection && shouldAdvanceFromSupplierStep)
      ) {
        // When initially selecting a supplier, we need to calculate
        // what next step (view) to show to the user
        calculateStep(newPackageConfiguration)
      } else if (
        supplierChanged &&
        (shouldAdvanceFromSupplierStep ||
          shouldAdvanceToSummaryStepFromSupplierStep)
      ) {
        // When changing suppliers, we may need to advance to another step,
        // using calculateStep here is problematic because the function
        // triggers auto-selection of configuration options...
        // so we call setStep instead of calculateStep.
        setStep(
          shouldAdvanceToSummaryStepFromSupplierStep
            ? PackageConfigurationStep.SummaryStep
            : PackageConfigurationStep.ConfigurationStep
        )
      }
    } catch (e) {
      handleError(e as ApplicationError)
    }
  }

  const nextPage = async () => {
    setConfigLoading(true)

    try {
      await goToNextPage()
    } catch (e) {
      setConfigLoading(false)

      handleError(e as ApplicationError)
    }
  }

  const changeHeightUnit = () => {
    setUseFeetForHeight(!useFeetForHeight)
    updateEmploymentPreferences({
      employmentPreference: { useFeetForHeight },
    })
  }

  const onCancel = async () => {
    if (packageConfiguration && !packageConfiguration.configured) {
      history.push(routes.productsPath(supplierId))
      await deletePackageConfiguration(packageConfigurationId)
      await refreshDmeOrder()
    } else {
      await returnToBrowse()
    }
  }

  const submitRxSurveyQuestion = async (
    question: SurveyQuestion,
    answerType: SurveyAnswerType,
    answerValue: string
  ) => {
    if (!packageConfiguration) return
    try {
      const response = await answerRxSurveyQuestion(
        packageConfiguration.id,
        question,
        answerType,
        answerValue
      )
      await refreshDmeOrder()
      setPackageConfiguration((prev) => {
        if (prev) {
          return {
            ...prev,
            rxDetails: response,
          }
        }
      })
    } catch (e) {
      handleError(e as ApplicationError)
    }
  }

  useEffect(() => {
    if (!packageConfiguration) {
      void loadPackageConfig().then((data) => {
        if (checkPreferredSuppliers) {
          void loadPreferredSuppliers(data)
        }
      })
    }
  }, [
    packageConfiguration,
    loadPackageConfig,
    checkPreferredSuppliers,
    loadPreferredSuppliers,
  ])

  const [
    referralApiCatalogPackage,
    setReferralApiCatalogPackage,
  ] = useState<FhirCatalogPackage | null>(null)

  const fetchReferralApiCatalogPackage = useCallback(async () => {
    const packageData = await getReferralApiCatalogPackage(dmeOrder.id)
    setReferralApiCatalogPackage(packageData)
  }, [dmeOrder.id])

  const isReferralApiProductSelectionEnabled: boolean =
    isFeatureEnabled("referralApiProductSelectionEnabled") &&
    dmeOrder.createdVia === DmeOrderCreatedVia.referral_api
  const packageName = referralApiCatalogPackage?.name ?? ""

  useEffect(() => {
    if (isReferralApiProductSelectionEnabled) {
      fetchReferralApiCatalogPackage()
    }
  }, [fetchReferralApiCatalogPackage, isReferralApiProductSelectionEnabled])

  useEffect(() => {
    const selectedSupplierIsPreferred =
      preferredSuppliers.filter(
        (ps) =>
          ps.supplier.externalId === packageConfiguration?.supplier?.externalId
      ).length > 0
    setDismissPreferredSupplierAlert(
      !packageConfiguration?.supplier ||
        selectedSupplierIsPreferred ||
        !preferredSuppliers.length
    )
    // Disabling with ES Lint because we don't want to update setDismissPreferredSupplierAlert if the package config updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredSuppliers])

  const loadingData = preferredSuppliersLoading || configLoading
  const showPreferredSupplierModal =
    !loadingData &&
    !!packageConfiguration &&
    preferredSuppliers.length > 0 &&
    !dismissPreferredSupplierAlert

  const showPreferredSupplierAlertModal = (showModal: boolean) => {
    return (
      <PreferredSupplierAlertModal
        currentSupplier={packageConfiguration?.supplier}
        isOpen={showModal}
        onClose={() => setDismissPreferredSupplierAlert(true)}
        onSubmit={async (supplierId: string) => {
          if (supplierId) {
            await save({ supplierId })
          }
          setRedirectToPreferredSupplier(true)
          setDismissPreferredSupplierAlert(true)
          if (preferredSuppliers.length > 1) {
            setStep(PackageConfigurationStep.SupplierStep)
          } else {
            setStep(PackageConfigurationStep.ConfigurationStep)
          }
        }}
      />
    )
  }

  if (configLoading || preferredSuppliersLoading) {
    return <Overlay showSpinner={true} active={true} />
  }

  return (
    <PreferredSupplierContext.Provider
      value={{
        preferredSuppliers,
        omitOtherSupplierInfoText,
        preferredSuppliersLoading,
        selectedSupplier,
        showDefaultSelectedSupplier,
        hasRankedSuppliers,
        setSelectedSupplier,
      }}
    >
      {showPreferredSupplierAlertModal(showPreferredSupplierModal)}
      <Modal
        title={getModalTitle(step, packageConfiguration?.offeringType)}
        show={!loadingData && dismissPreferredSupplierAlert}
        cancel={() => onCancel()}
        backdrop="static"
      >
        <Modal.Body>
          {isReferralApiProductSelectionEnabled &&
            referralApiCatalogPackage &&
            packageConfiguration &&
            packageConfiguration.packageId === referralApiCatalogPackage.id && (
              <PendingOrderPackageAlert
                dmeOrderId={dmeOrder.id}
                referralApiCatalogPackage={packageName}
              ></PendingOrderPackageAlert>
            )}
          {packageConfiguration ? (
            <InternalPackageConfiguration
              redirectToPreferredSupplier={redirectToPreferredSupplier}
              currentEmployer={currentEmployer}
              dmeOrder={dmeOrder}
              packageConfiguration={packageConfiguration}
              useFeetForHeight={useFeetForHeight}
              remove={remove}
              update={update}
              updateLineItemPrescribedQuantity={updatePrescribedQuantity}
              save={save}
              returnToBrowse={returnToBrowse}
              loading={configLoading || preferredSuppliersLoading}
              step={step || ""}
              changeStep={setStep}
              recalculateStep={() => calculateStep(packageConfiguration)}
              changeHeightUnit={changeHeightUnit}
              goToNextPage={nextPage}
              answerRxSurveyQuestion={submitRxSurveyQuestion}
              showServiceTier={initialData.showServiceTier}
              preferredSuppliers={preferredSuppliers.map((ps) => ps.supplier)}
              yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
              selectedServiceAreaState={selectedServiceAreaState}
            />
          ) : (
            <p>
              Something went wrong. We could not load the configuration for this
              package.
            </p>
          )}
        </Modal.Body>
      </Modal>
    </PreferredSupplierContext.Provider>
  )
}

export default withInitialData(fetchInitialPackageConfigurationData)(
  PackageConfiguration
)
