import React, { useContext } from "react"
import {
  CatalogCustomAttribute,
  CatalogCustomAttributeOption,
} from "../../../../types/sharedTypes"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import { StaticFieldDefinition } from "../../../../types/form/sharedTypes"
import Form from "../../../../../../components/form/Form"
import FormSegment from "../../../../components/Formik/FormSegment"
import { CanopyButton } from "@parachutehealth/canopy-button"
import CmsDrawer from "../../../../components/CmsDrawer"
import { createAttributeOption } from "../../../../api/attributeOptions"
import { trackEvent as trackAnalyticsEvent } from "../../../../../../utilities/analytics"
import { CanopyComboboxField } from "@parachutehealth/canopy-combobox-field"
import FreesoloCombobox from "../../../../components/FreesoloCombobox"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import classNames from "classnames"
import * as styles from "../EditAttributeOptionDrawer/index.module.scss"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import sortedUniqNumbers from "utilities/array/sortedUniqNumbers"

type Props = {
  customAttribute: CatalogCustomAttribute
  open?: boolean
  onClose: () => void
  onSave?: (option: CatalogCustomAttributeOption) => void
}

const AddAttributeValueDrawer: React.FC<Props> = ({
  customAttribute,
  open = false,
  onClose,
  onSave,
}: Props): React.JSX.Element => {
  const { showNotice } = useContext(NoticeContext)

  const textFields: StaticFieldDefinition[] = [
    { label: "Description", name: "description", type: "text", required: true },
  ]
  const handleSubmit = async (
    params: Record<string, any>,
    { setErrors }: any
  ) => {
    const response = await createAttributeOption(
      customAttribute.externalId,
      params
    )
    if (response.errors) {
      setErrors(response.errors)
    } else {
      onClose()
      void trackAnalyticsEvent(["CMS Catalog", "Global Attribute: Add Value"])
      showNotice(
        `Successfully added "${response.customAttributeOption.description}"`,
        "success"
      )
      if (onSave) onSave(response.customAttributeOption)
    }
  }

  const labelsToOptions = (items: { id: number; label: string }[]) => {
    return items.map((unit) => ({
      value: unit.id.toString(),
      label: unit.label,
    }))
  }

  const packagingLabelOptions = labelsToOptions(
    customAttribute?.packagingLabels || []
  )
  const unitOptions = labelsToOptions(customAttribute?.units || [])

  const optionValues =
    customAttribute.options?.filter((o) => o.value)?.map((o) => o.value!) || []

  // we can now have multiple existing options with the same value but different units/packaging, and we don't want
  // multiple copies of the same option showing up
  const uniqueValues: string[] = sortedUniqNumbers(optionValues)

  const catalogPackagingLabelKey = "catalogPackagingLabelId"
  const catalogUnitKey = "catalogUnitId"

  const initializeOptionValues = () => {
    if (customAttribute.valueType === "number") {
      return {
        catalogPackagingLabelId: "",
        value: "",
        catalogUnitId: "",
      }
    }

    return {
      description: "",
    }
  }

  const initialValues = initializeOptionValues()

  return (
    <CmsDrawer open={open} title="Add Attribute value" onClose={onClose}>
      <Form onSubmit={handleSubmit} initialValues={initialValues} hideErrors>
        {({ isSubmitting, values, errors, setFieldValue }) => (
          <>
            {customAttribute?.valueType === "number" ? (
              <div className={classNames(styles.formBody)}>
                <CanopyComboboxField
                  onChange={(newValue) =>
                    setFieldValue(
                      catalogPackagingLabelKey,
                      get(newValue, "value")
                    )
                  }
                  options={packagingLabelOptions}
                  disabled={isEmpty(packagingLabelOptions)}
                  multiple={false}
                  size="small"
                  placeholder={
                    isEmpty(packagingLabelOptions) ? "N/A" : undefined
                  }
                  value={get(values, catalogPackagingLabelKey)}
                  label="Packaging"
                  feedbackMessage={errors[catalogPackagingLabelKey]}
                />
                <FreesoloCombobox
                  label="Value"
                  required={true}
                  feedbackMessage={errors["value"]}
                  defaultValue={get(values, "value")}
                  options={uniqueValues}
                  onChange={(newValue) =>
                    setFieldValue("value", newValue?.value || newValue)
                  }
                />
                <CanopyComboboxField
                  data-testid={catalogUnitKey}
                  onChange={(newValue) =>
                    setFieldValue(catalogUnitKey, get(newValue, "value"))
                  }
                  options={unitOptions}
                  disabled={isEmpty(unitOptions)}
                  multiple={false}
                  size="small"
                  value={get(values, catalogUnitKey)}
                  label="Unit of measure"
                  placeholder={isEmpty(unitOptions) ? "N/A" : undefined}
                  feedbackMessage={errors[catalogUnitKey]}
                />
              </div>
            ) : (
              <FormSegment>{textFields}</FormSegment>
            )}
            {errors.base && (
              <CanopyNotice
                variant="error"
                title={errors.base}
                className="canopy-mbe-10x"
              />
            )}
            <div>
              <CanopyButton
                size="small"
                variant="primary"
                type="submit"
                loading={isSubmitting}
              >
                Save
              </CanopyButton>
              <CanopyButton
                className="canopy-mis-4x"
                size="small"
                variant="tertiary"
                disabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </CanopyButton>
            </div>
          </>
        )}
      </Form>
    </CmsDrawer>
  )
}

export default AddAttributeValueDrawer
