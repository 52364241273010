import React, { Dispatch, SetStateAction } from "react"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import Pages from "../Pages"
import { DmeOrder, DmeOrderPermissions, Originator, Page } from "sharedTypes"
import * as styles from "./index.module.scss"
import OrderActionsDropdown from "applications/Workflow/containers/Navigation/components/OrderActionsDropdown/OrderActionsDropdown"
import { CanopyFlex } from "@parachutehealth/canopy-flex"

type Props = {
  pages: Page[]
  patientLink: React.ReactElement
  nextPagePath: string
  buttons: React.ReactElement[]
  originator: Originator
  followDmeOrder(): Promise<void>
  unfollowDmeOrder(followingId: string): Promise<void>
  setShowSnoozeModal: Dispatch<SetStateAction<boolean>>
  showSnoozeModal: boolean
  snoozeDmeOrder(params): Promise<void>
  unsnoozeDmeOrder(): Promise<void>
  dmeOrder: DmeOrder
  permissions: DmeOrderPermissions
}

function LeftNavigation({
  pages,
  patientLink,
  nextPagePath,
  buttons,
  followDmeOrder,
  unfollowDmeOrder,
  unsnoozeDmeOrder,
  originator,
  snoozeDmeOrder,
  dmeOrder,
  permissions,
}: Props) {
  const activePage = pages.find((page) => page.active)
  const isPageChecked = activePage && activePage.checked

  return (
    <>
      <div className={styles.nav}>
        {patientLink}
        {permissions.snooze && (
          <div className={styles.orderActionsContainer}>
            <OrderActionsDropdown
              originator={originator}
              followDmeOrder={followDmeOrder}
              unfollowDmeOrder={unfollowDmeOrder}
              snoozeDmeOrder={snoozeDmeOrder}
              unsnoozeDmeOrder={unsnoozeDmeOrder}
              dmeOrder={dmeOrder}
            />
          </div>
        )}
        <Pages pages={pages} removeBottomBorder={true} />
      </div>
      <CanopyFlex direction="column" gap="8X" paddingInline="8X">
        {nextPagePath && (
          <ContinueButton
            block
            isButtonOutlined={!isPageChecked}
            path={nextPagePath}
          />
        )}
        {buttons.map((button, index) => (
          <React.Fragment key={`button-${index}`}>{button}</React.Fragment>
        ))}
      </CanopyFlex>
    </>
  )
}

export default LeftNavigation
