import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { supplierIntakeNavbar, documentActions } from "./index.module.scss"
import { InternalStatus } from "./components/InternalStatus"
import {
  Assignee,
  InternalStatus as InternalStatusType,
} from "../../sharedTypes"
import { Discard } from "./components/Discard"
import { AssignTo } from "./components/AssignTo"
import { ForwardDocument } from "./components/ForwardDocument"

interface Props {
  dashboardUrl: string
  documentTitle: string | undefined
  internalStatuses: InternalStatusType[]
  internalStatus?: InternalStatusType
  assignee?: Assignee
}

export const SupplierIntakeNavbar = ({
  dashboardUrl,
  documentTitle,
  internalStatuses,
  internalStatus,
  assignee,
}: Props) => {
  return (
    <nav className={supplierIntakeNavbar}>
      <CanopyButton
        as="a"
        href={dashboardUrl}
        iconStart="arrow-left"
        size="small"
        variant="tertiary"
      >
        Return to dashboard
      </CanopyButton>

      <div className={documentActions}>
        <AssignTo assignee={assignee} />
        <InternalStatus
          internalStatuses={internalStatuses}
          internalStatus={internalStatus}
        />
        <ForwardDocument dashboardUrl={dashboardUrl} />

        <Discard documentTitle={documentTitle} dashboardUrl={dashboardUrl} />
      </div>
    </nav>
  )
}
