import { LineItemGroup, LineItemGroupStep } from "../sharedTypes"

const isProductRequirementsIncomplete = (lineItemGroup: LineItemGroup) => {
  const {
    requiresHeight,
    requiresWeight,
    requiresWoundCount,
    heightInches,
    weightLbs,
    numberOfWounds,
  } = lineItemGroup

  if (requiresHeight && !isNumber(heightInches)) {
    return true
  }
  if (requiresWeight && !isNumber(weightLbs)) {
    return true
  }
  if (requiresWoundCount && !isNumber(numberOfWounds)) {
    return true
  }
  return false
}

const isNumber = (value) => value !== null && isFinite(value)

export const calculateStep = (lineItemGroup) => {
  if (isProductRequirementsIncomplete(lineItemGroup)) {
    return LineItemGroupStep.RequirementsStep
  }

  return LineItemGroupStep.SummaryStep
}
