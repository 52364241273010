// @team @cat-crew
import { camelizeKeys } from "humps"
import {
  CatalogProduct,
  CatalogProductStatus,
} from "applications/Cms/types/sharedTypes"
import { CatalogProductVariation } from "applications/Cms/types/sharedTypes"
import { toRailsStyle } from "utilities/urlSearchParams"
import { ServerSideDataGridParams } from "../hooks/useServerSideDataGrid"
import { ApiResponse, paramsToFormData } from "./utilities"
import { meta } from "utilities/meta"
import { del, get, put } from "../../../services/api"

export type CatalogProductsParams = ServerSideDataGridParams & {
  searchKeywords?: string
  supplierId?: string
  status?: CatalogProductStatus | "All"
}

export type CatalogProductsResponse = {
  products: CatalogProduct[]
  totalCount: number
}

export const getCatalogProducts = async (
  params: CatalogProductsParams
): Promise<CatalogProductsResponse> => {
  const urlParams = toRailsStyle(params)
  const response = await fetch(`/cms/catalog/products.json?${urlParams}`)
  const jsonData = camelizeKeys(await response.json())
  return jsonData
}

export type CatalogProductSkusResponse = {
  skus: CatalogProductVariation[]
  totalCount: number
}

/* @deprecated */
export const getCatalogProductSkus = async (
  productId: string
): Promise<CatalogProductSkusResponse> => {
  const response = await fetch(`/cms/catalog/products/${productId}/skus.json`)
  const jsonData = camelizeKeys(await response.json())
  return jsonData
}

export const archiveCatalogProduct = async (
  productId: string
): Promise<boolean> => {
  return await del(`/cms/catalog/products/${productId}.json`)
    .then(() => true)
    .catch(() => false)
}

export const unarchiveCatalogProduct = async (
  productId: string
): Promise<boolean> => {
  return await put(`/cms/catalog/products/${productId}/unarchive.json`)
    .then(() => true)
    .catch(() => false)
}

export const updateCatalogProduct = async (
  productId: string,
  params: Record<string, any>
): Promise<ApiResponse<{ product: CatalogProduct }>> => {
  const formData: FormData = paramsToFormData(params, "catalog_product")

  const response = await fetch(`/cms/catalog/products/${productId}.json`, {
    method: "PATCH",
    headers: {
      "X-CSRF-Token": meta("csrf-token"),
    },
    body: formData,
  })
  const jsonData = camelizeKeys(await response.json())
  return jsonData
}

export const getCatalogProduct = async (
  productId: string
): Promise<ApiResponse<{ product: CatalogProduct }>> => {
  return await get(`/cms/catalog/products/${productId}.json`).then(
    (response) => response.data
  )
}
