import { get } from "../../../../../../../services/api"
import { CatalogProductCustomAttribute } from "../../../../../types/sharedTypes"

export type AttributesForProductResponse = {
  productCustomAttributes: CatalogProductCustomAttribute[]
  usedAttributeValues: Record<
    number,
    { description: string; patientPreference: boolean }[]
  >
}

export const getAttributesForProduct = async (
  productId: string
): Promise<AttributesForProductResponse> => {
  return await get(`/cms/catalog/products/${productId}/attributes.json`).then(
    (response) => response.data
  )
}
