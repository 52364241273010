import React, { FC } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"

interface Props {
  isOpen: boolean
  onClose: () => void
  unsnoozeDmeOrder: () => void
  patientName: string
  activatorElementRef?: React.RefObject<HTMLElement>
}

export const UnsnoozeModal: FC<Props> = ({
  isOpen,
  onClose,
  unsnoozeDmeOrder,
  patientName,
  activatorElementRef,
}) => {
  return (
    <CanopyDialogModal
      activatorElementRef={activatorElementRef}
      onClose={onClose}
      open={isOpen}
      header="Unsnooze order"
      headerOverline={`Order for ${patientName}`}
      primaryFooterButton={
        <CanopyButton onClick={unsnoozeDmeOrder}>Confirm</CanopyButton>
      }
      secondaryFooterButton={
        <CanopyButton onClick={onClose} variant="secondary">
          Cancel
        </CanopyButton>
      }
      size="small"
    >
      <div className="canopy-pbe-4x">
        Are you sure you want to unsnooze this order?
      </div>
    </CanopyDialogModal>
  )
}

export default UnsnoozeModal
