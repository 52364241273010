import React, { useEffect, useState } from "react"
import {
  Counts,
  Filters,
  ClinicalFacility,
  SupplierIntakeEmail,
  Category,
} from "./sharedTypes"
import { unique } from "utilities/array"
import { handleError } from "utilities/error"
import Table from "./Table"
import FilterForm from "./FilterForm"
import LoadMoreLink from "components/LoadMoreLink"
import BackToTop from "components/BackToTop"
import TableOverview from "./TableOverview"
import { Assignee, CsrInboxDocument, InternalStatus } from "sharedTypes"
import { makeApi, fetchInitialDashboardData } from "./api"
import withInitialData from "components/withInitialData"

export type Props = {
  supplier: {
    id: string
    aiIntakeEnabled: boolean
  }
  supplierOrganizationId: string
  initialData: {
    assignees: Assignee[]
    filteredFacilities: ClinicalFacility[]
    filteredDocumentSources: { source: string; sourceType: string }[]
    filteredReceivingFaxNumbers: string[]
    filters: Filters
    internalStatuses: InternalStatus[]
    counts: Counts
    intakePatientSearchEnabled: boolean
    supplierIntakeEmails: SupplierIntakeEmail[]
    categories: Category[]
  }
}

const DocumentDashboard = (props: Props) => {
  const [csrInboxDocuments, updateCsrInboxDocuments] = useState<
    CsrInboxDocument[]
  >([])
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState(props.initialData.filters)
  const [counts, updateCounts] = useState(props.initialData.counts)

  const { supplier, supplierOrganizationId } = props
  const api = makeApi(supplier.id)

  const handleFilterChange = (values) => {
    return getFilteredDocuments(
      { ...filters, ...values, page: 1 },
      { append: false }
    )
  }

  const handleLoadMore = () => {
    const currentPage = filters.page || 1
    return getFilteredDocuments(
      { ...filters, page: currentPage + 1 },
      { append: true }
    )
  }

  const clearFilters = () =>
    getFilteredDocuments(
      {
        sort: filters.sort,
        direction: filters.direction,
        clear: true,
        page: 1,
      },
      { append: false }
    )

  const getFilteredDocuments = (values, options) => {
    setLoading(true)

    return api
      .getFilteredDocuments(values)
      .then((data) => {
        setLoading(false)

        if (options?.append) {
          updateCsrInboxDocuments((documents) =>
            unique([...documents, ...data.csrInboxDocuments])
          )
        } else {
          updateCsrInboxDocuments(data.csrInboxDocuments)
        }
        updateCounts(data.counts)
        setFilters(values)
      })
      .catch((error) => {
        setLoading(false)
        handleError(error)
      })
  }

  useEffect(() => {
    getFilteredDocuments(filters, { append: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier.id])

  useEffect(() => {
    const timeoutId = setTimeout(
      () =>
        getFilteredDocuments(
          { ...filters, page: 1, autoRefresh: true },
          { append: true }
        ),
      110000
    )
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const onChange = (newDocument) => {
    updateCsrInboxDocuments(
      csrInboxDocuments.map((doc) =>
        doc.id === newDocument.id ? newDocument : doc
      )
    )
  }

  const getClinicalFacilities = (term) => {
    return api.searchClinicalFacilities(term).then((response) => {
      return response.results.map((result) => ({
        label: result.text,
        value: result.id,
      }))
    })
  }

  const getAssignees = (term) => {
    return api.getAssignees(term).then((assignees) => {
      return assignees.map((assignee) => ({
        label: assignee.name,
        value: assignee.id,
      }))
    })
  }

  const getDocumentSources = (term) => {
    return api.searchDocumentSources(term).then(({ results }) => results)
  }

  const getReceivingFaxNumbers = (term) => {
    return api.searchReceivingFaxNumbers(term).then(({ results }) => results)
  }

  const FILTER_TITLES = {
    assignee: "Assignee",
    from: "From",
    created_at: "Received",
    facility_name: "Facility",
    internal_status: "Internal Status",
  }

  const supplierIntakeEmailsEnabled =
    props.initialData.supplierIntakeEmails.length > 1

  return (
    <>
      <FilterForm
        onSubmit={handleFilterChange}
        filters={filters}
        assignees={props.initialData.assignees}
        clinicalFacilities={props.initialData.filteredFacilities}
        internalStatuses={props.initialData.internalStatuses}
        receivingFaxNumbers={props.initialData.filteredReceivingFaxNumbers}
        getClinicalFacilities={getClinicalFacilities}
        getDocumentSources={getDocumentSources}
        getReceivingFaxNumbers={getReceivingFaxNumbers}
        getAssignees={getAssignees}
        onReset={clearFilters}
        supplierIntakeEmailsEnabled={supplierIntakeEmailsEnabled}
        supplierIntakeEmails={props.initialData.supplierIntakeEmails}
        categories={props.initialData.categories}
        documentSources={props.initialData.filteredDocumentSources}
        aiIntakeEnabled={supplier.aiIntakeEnabled}
      />
      <TableOverview
        loading={loading}
        filteredCount={counts.filteredTotal}
        sortingBy={filters.sort && FILTER_TITLES[filters.sort]}
        suffix="documents"
      />
      <Table
        supplierIntakeEmailsEnabled={supplierIntakeEmailsEnabled}
        aiIntakeEnabled={supplier.aiIntakeEnabled}
        csrInboxDocuments={csrInboxDocuments}
        onSortChange={handleFilterChange}
        sortColumn={filters.sort}
        sortDirection={filters.direction}
        internalStatuses={props.initialData.internalStatuses}
        onChange={onChange}
        loading={loading}
        supplierId={supplier.id}
        supplierOrganizationId={supplierOrganizationId}
        intakePatientSearchEnabled={
          props.initialData.intakePatientSearchEnabled
        }
      />
      <BackToTop />
      <LoadMoreLink
        loading={loading}
        count={csrInboxDocuments.length}
        totalCount={counts.filteredTotal}
        onClick={handleLoadMore}
      />
    </>
  )
}

const fetchInitialData = (props): Promise<Props["initialData"]> => {
  return fetchInitialDashboardData(props.supplier.id).then(
    (response) => response
  )
}

export default withInitialData(fetchInitialData)(DocumentDashboard)
