import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updatePatient } from "../PatientSearchPage/api"
import { setPatientQueryData } from "../../../queries"
import { Patient, PatientUpdateErrors } from "../../../sharedTypes"
import { useState } from "react"

type UsePatientMutationOptions = {
  supplierOrganizationId: string
  csrInboxStateId: string
  onSuccess?: (data: Patient) => void
}

type PatientUpdateData = {
  masterPatientId?: string
} & Partial<Patient>

export const usePatientMutation = ({
  supplierOrganizationId,
  csrInboxStateId,
  onSuccess,
}: UsePatientMutationOptions) => {
  const queryClient = useQueryClient()
  const [errors, setErrors] = useState<PatientUpdateErrors>({})

  const mutation = useMutation({
    mutationFn: async (updateData: PatientUpdateData) => {
      try {
        const updatedPatient = await updatePatient(
          supplierOrganizationId,
          csrInboxStateId,
          updateData
        )
        setPatientQueryData(queryClient, updatedPatient)
        setErrors({})
        onSuccess?.(updatedPatient)
        return updatedPatient
      } catch (error) {
        setErrors(error as PatientUpdateErrors)
      }
    },
  })

  return {
    ...mutation,
    errors,
  }
}
