import * as styles from "./OrderLinker.module.scss"
import { EVENT_DATE_TIME_FORMAT, format } from "../../../../../utilities/date"
import { CanopyButton } from "@parachutehealth/canopy-button"
import React from "react"
import { dmeOrderUrl } from "../../../../CarrierAuthorization/urls"
import { CarrierAuthorization } from "../../../../../graphql/__generated__/graphql"
import { CanopyLink } from "@parachutehealth/canopy-link"

interface OrderLinkerProps {
  carrierAuthorization: CarrierAuthorization
}

export const OrderLinker = ({ carrierAuthorization }: OrderLinkerProps) => {
  const carrierAuthCreatedAtDate = new Date(carrierAuthorization?.createdAt)
  const dmeOrderPath = dmeOrderUrl(carrierAuthorization?.dmeOrder?.id)
  return (
    <div className={styles.orderLinkCard}>
      <p>
        This auth request was generated from{" "}
        <CanopyLink href={dmeOrderPath} target="_blank" rel="noreferrer">
          this order
        </CanopyLink>{" "}
        on{" "}
        {format(
          carrierAuthCreatedAtDate.toLocaleString(),
          EVENT_DATE_TIME_FORMAT
        )}
        . Any changes made to the order after that time will not be reflected on
        the auth request.
      </p>
      <CanopyButton
        size="small"
        as="a"
        fullWidth
        href={dmeOrderPath}
        iconEnd="arrow-up-right-from-square"
        rel="noreferrer"
        target="_blank"
        variant="secondary"
      >
        View order
      </CanopyButton>
    </div>
  )
}
