import { get } from "services/api"
import { followersUrl } from "applications/Workflow/urls"
import { FollowersGroup } from "sharedTypes"

export const getFollowers = (
  term: string,
  orderId: string
): Promise<FollowersGroup[]> => {
  return get(followersUrl(orderId), { term }).then(({ data }) => data)
}
