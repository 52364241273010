import { Drawer } from "@material-ui/core"
import classNames from "classnames"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import React from "react"
import {
  UserForm,
  UserFormFields,
  UserFormAction,
} from "../../../components/UserForm/UserForm"
import { FormikValues } from "formik"

interface UserActionDrawerProps {
  open: boolean
  initialFormValues: UserFormFields
  formAction: UserFormAction

  handleFormSubmit(values: FormikValues): void

  loading: boolean
  paperClasses?: string

  onClose(): void
}

export const UserActionDrawer = ({
  loading,
  open,
  onClose,
  handleFormSubmit,
  initialFormValues,
  paperClasses,
  formAction,
}: UserActionDrawerProps) => {
  const headerText =
    formAction === UserFormAction.New ? "Add user" : "Edit user"

  return (
    <Drawer
      id="user-action-drawer"
      variant="persistent"
      open={open}
      anchor="right"
      PaperProps={{
        className: classNames("shadow", paperClasses),
      }}
      data-testid="user-action-drawer"
    >
      <div className="canopy-p-12x d-flex justify-content-space-between align-items-center border-bottom bg-default">
        <h2 className="canopy-mie-24x canopy-mbe-0">{headerText}</h2>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={() => onClose()}
        >
          <CanopyIcon
            data-testid="user-action-drawer-close"
            name="xmark"
            size="medium"
            fill="canopyColorPrimitivesGray27"
          ></CanopyIcon>
        </button>
      </div>
      <div className="canopy-p-12x">
        <UserForm
          initialFormValues={initialFormValues}
          loading={loading}
          handleFormSubmit={handleFormSubmit}
          onCancel={onClose}
          formAction={formAction}
        />
      </div>
    </Drawer>
  )
}
