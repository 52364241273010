import { employerPrefix } from "../../utilities/url"

function baseUrl(pendingOrderId: string) {
  return `${employerPrefix()}/pending_orders/${pendingOrderId}`
}

export function relevantDiagnosesUrl(pendingOrderId: string): string {
  return `${baseUrl(pendingOrderId)}/diagnoses`
}

export function pendingOrdersSearchIcd10CodesUrl(
  pendingOrderId: string
): string {
  return `${baseUrl(pendingOrderId)}/icd10_codes/search.json`
}

export function pendingOrdersSaveQuestionAnswerUrl(
  pendingOrderId: string,
  questionId: string
): string {
  return `${baseUrl(pendingOrderId)}/question_answers/${questionId}.json`
}

export function pendingOrdersSaveDiagnosesUrl(pendingOrderId: string): string {
  return `${baseUrl(pendingOrderId)}/diagnoses`
}

export function pendingOrdersTherapySurveyQuestionsUrl(
  pendingOrderId: string,
  therapySelectionId: string
): string {
  return `${baseUrl(
    pendingOrderId
  )}/therapy_selections/${therapySelectionId}/survey_questions`
}
