// @team @destroythefax
import React from "react"
import { CanopyNotice } from "@parachutehealth/canopy-notice"

interface Props {
  message: string
}

const IncomingOrdersNotificationPreferenceAlert: React.FC<Props> = ({
  message,
}) => {
  return (
    <CanopyNotice
      closable
      title={message}
      variant="success"
      className="canopy-mbe-4x"
    />
  )
}

export default IncomingOrdersNotificationPreferenceAlert
