import React from "react"
import { CanopyHeading } from "@parachutehealth/canopy-heading"

export interface Props {
  suppliersCount: number
}

const OtherSuppliersOnParachuteHeader = ({ suppliersCount }: Props) => (
  <CanopyHeading size="xLarge" level={2} className="canopy-mbe-12x">
    {`Other Suppliers on Parachute (${suppliersCount})`}
  </CanopyHeading>
)

export default OtherSuppliersOnParachuteHeader
