import React from "react"
import { CanopyLink } from "@parachutehealth/canopy-link"

interface Props {
  signUpUrl: string
}

function SignUpLink({ signUpUrl }: Props) {
  return (
    <div className="text-center canopy-mbs-8x">
      Don’t have an account?
      <CanopyLink className="canopy-mis-2x" href={signUpUrl}>
        Sign up
      </CanopyLink>
    </div>
  )
}

export default SignUpLink
