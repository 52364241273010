import React from "react"
import { Nullable } from "sharedTypes"
import { conjugate } from "./utils"

const Header = ({
  invited,
  eSigningEnabled,
  latestMessageScheduledAt,
}: {
  invited: boolean
  eSigningEnabled: boolean
  latestMessageScheduledAt: Nullable<Date>
}) => {
  if (eSigningEnabled) {
    return (
      <h3 className="d-inline">
        {invited
          ? "Request to sign proof of delivery shared with Patient"
          : "Request Patient sign proof of delivery via Order Tracker"}
      </h3>
    )
  } else {
    return (
      <h3 className="d-inline">
        {latestMessageScheduledAt
          ? `Order tracker ${conjugate(invited)} shared with the patient`
          : "Share Order Tracker with the Patient"}
      </h3>
    )
  }
}

export default Header
