import React from "react"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import * as styles from "../index.module.scss"
import {
  CanopyPickerField,
  OptionItem,
} from "@parachutehealth/canopy-picker-field"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

type UserInfo = {
  firstName: string
  lastName: string
  email: string
  role: string
}

type Props = {
  index?: number
  user: UserInfo
  onChange: (user: UserInfo) => void
  removeUserInput?: () => void
  feedbackMessages: {
    email: string[]
    lastName: string[]
    firstName: string[]
    "employments.role": string[]
  }
}

const InviteUserForm = (props: Props) => {
  const { user, feedbackMessages } = props

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    props.onChange({
      ...user,
      [name]: value,
    })
  }

  return (
    <div>
      <CanopyFlex
        justifyContent="space-between"
        gap="16X"
        className={styles.inviteUserInputRow}
      >
        <CanopyTextInputField
          label="First Name"
          name="firstName"
          value={user.firstName}
          onChange={handleInputChange}
          className={styles.inviteUserInputField}
          feedbackMessage={feedbackMessages?.firstName?.[0]}
        />
        <CanopyTextInputField
          label="Last Name"
          name="lastName"
          value={user.lastName}
          onChange={handleInputChange}
          className={styles.inviteUserInputField}
          feedbackMessage={feedbackMessages?.lastName?.[0]}
        />
        {props.index !== 0 && (
          <a
            data-testid={`remove-user-input-${props.index}`}
            onClick={props.removeUserInput}
            className={styles.removeUserInput}
          >
            <CanopyIcon name="trash-can" size="medium" />
          </a>
        )}
      </CanopyFlex>
      <CanopyFlex
        justifyContent="space-between"
        gap="16X"
        className={styles.inviteUserInputRow}
      >
        <CanopyTextInputField
          label="Email"
          name="email"
          value={user.email}
          onChange={handleInputChange}
          className={styles.inviteUserInputField}
          feedbackMessage={
            feedbackMessages?.email?.[0] ||
            feedbackMessages?.["employments.user"]?.[0]
          }
        />
        <CanopyPickerField
          label="Role"
          placeholder="Select..."
          value={user.role}
          onChange={(newValue) => {
            void props.onChange({
              ...user,
              role: (newValue as OptionItem).value,
            })
          }}
          options={[
            {
              label: "Standard",
              value: "standard",
              description: "Can create and edit orders",
            },
            {
              label: "Admin",
              value: "admin",
              description:
                "Can create and edit orders, manage users, and manage facility",
            },
            {
              label: "Read only",
              value: "view_only",
              description: "Can only view orders",
            },
          ]}
          className={styles.inviteUserInputField}
          feedbackMessage={feedbackMessages?.["employments.role"]?.[0]}
          optionRenderFunction={({ option }: { option: OptionItem }) => {
            return (
              <div>
                <strong>{option.label}</strong>
                <br />
                <span>{option.description}</span>
              </div>
            )
          }}
        />
      </CanopyFlex>
    </div>
  )
}

export default InviteUserForm
