import React from "react"
import { AttachButton } from "../AttachButton"
import { attachToDmeOrder } from "./api"
import { orderUrl } from "../../../urls"

type AttachButtonProps = {
  supplierId: string
  csrInboxStateId: string
  dmeOrderId: string
}

export const AttachToDmeOrderButton: React.FC<AttachButtonProps> = ({
  supplierId,
  csrInboxStateId,
  dmeOrderId,
}: AttachButtonProps) => {
  return (
    <AttachButton
      onClick={() => attachToDmeOrder(supplierId, csrInboxStateId, dmeOrderId)}
      successUrl={orderUrl(supplierId, dmeOrderId)}
    />
  )
}
