export type PatientFormParams = {
  firstName: string
  lastName: string
  dateOfBirth: string
  sex: string
  supplierSystemPatientId: string
  mrn: string
}

export enum SidebarTab {
  Activity = "activity",
  Closed = "closed",
  Documents = "documents",
  Cart = "cart",
  SupplierTransfer = "supplierTransfer",
  PatientMessaging = "patientMessaging",
}

export interface NavOrder {
  id: string
  lineItemGroups: {
    quantity: number
  }[]
}
