import React from "react"
import { Grid } from "@material-ui/core"
import { format as formatDate } from "../../../../../../../utilities/date"
import { Patient } from "../../../../../../../graphql/__generated__/graphql"

interface Props {
  patient: Patient
  memberId: string
}

const DmeOrdersTableHeader: React.FC<Props> = ({ patient, memberId }) => {
  return (
    <>
      <h2 className="canopy-typography-heading-xlarge canopy-mbe-2x">
        {patient.firstName} {patient.lastName}
      </h2>
      <Grid container spacing={3}>
        <Grid item>
          <p className="canopy-typography-body-small">
            <span className="canopy-typography-font-weight-bold">
              Member ID:{" "}
            </span>
            {memberId}
          </p>
        </Grid>
        <Grid item>
          <p className="canopy-typography-body-small">
            <span className="canopy-typography-font-weight-bold">DOB: </span>
            {formatDate(patient.dateOfBirth, "MM/DD/YYYY")}
          </p>
        </Grid>
      </Grid>
    </>
  )
}

export default DmeOrdersTableHeader
