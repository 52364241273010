import React, { FC } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { Form } from "components/form"
import {
  format,
  isDateAfter,
  isDateInPast,
  isDateValid,
  today,
} from "utilities/date"
import { FormikErrors } from "formik"
import classNames from "classnames"
import { CanopyTextareaField } from "@parachutehealth/canopy-textarea-field"
import * as styles from "./SnoozeModal.module.scss"
import DatePickerForSnoozeModal from "./DatePickerForSnoozeModal/DatePickerForSnoozeModal"

interface Props {
  isOpen: boolean
  onClose: () => void
  snoozeDmeOrder(params): Promise<void>
  dmeOrder: {
    minSnoozeDate: string | undefined
    maxSnoozeDate: string | undefined
    patient: { firstName: string; lastName: string }
  }
  activatorElementRef?: React.RefObject<HTMLElement>
}

const SnoozeModal: FC<Props> = ({
  isOpen,
  onClose,
  snoozeDmeOrder,
  dmeOrder,
  activatorElementRef,
}) => {
  const [attemptedSubmit, setAttemptedSubmit] = React.useState(false)

  const handleSubmit = (params, { setSubmitting }) => {
    setSubmitting(true)
    snoozeDmeOrder(params).then((_response) => {
      setSubmitting(false)
      onClose()
    })
  }

  const validate = ({ snoozeDate, comment }) => {
    const errors: FormikErrors<{ snoozeDate: string; comment: string }> = {}

    if (snoozeDate.length === 0) {
      errors.snoozeDate = "Enter a date"
    } else if (!isDateValid(snoozeDate) || format(snoozeDate) !== snoozeDate) {
      errors.snoozeDate = "Enter a date using the following format: MM/DD/YYYY"
    } else if (isDateInPast(snoozeDate) || snoozeDate === today()) {
      errors.snoozeDate = "Enter a future date"
    } else if (isDateAfter(snoozeDate, dmeOrder.maxSnoozeDate)) {
      errors.snoozeDate = "Enter a date within one year of order creation"
    }

    if (comment.length > 255) {
      errors.comment = "255 character limit reached"
    }

    return errors
  }

  const initialValues = {
    snoozeDate: "",
    comment: "",
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      validateOnMount={false}
      validateOnChange={attemptedSubmit}
      validateOnBlur={false}
    >
      {({
        errors,
        handleChange,
        handleReset,
        isSubmitting,
        submitForm,
        setFieldValue,
        values,
      }) => {
        return (
          <CanopyDialogModal
            activatorElementRef={activatorElementRef}
            className={classNames(
              styles.snoozeModalContainer,
              errors.snoozeDate && styles.hasErrors
            )}
            header="Snooze order"
            headerOverline={`Order for ${dmeOrder.patient.firstName} ${dmeOrder.patient.lastName}`}
            size="medium"
            open={isOpen}
            onClose={() => {
              handleReset()
              onClose()
            }}
            primaryFooterButton={
              <CanopyButton
                type="submit"
                loading={isSubmitting}
                variant="primary"
                onClick={() => {
                  setAttemptedSubmit(true)
                  submitForm()
                }}
              >
                Snooze order
              </CanopyButton>
            }
            secondaryFooterButton={
              <CanopyButton
                variant="secondary"
                disabled={isSubmitting}
                onClick={() => {
                  handleReset()
                  onClose()
                }}
              >
                Cancel
              </CanopyButton>
            }
          >
            <div className={styles.innerSnoozeModalContainer}>
              <DatePickerForSnoozeModal
                name="snoozeDate"
                id="snoozeDate"
                onChange={(event) => {
                  const inputValue = (event.target as HTMLInputElement).value
                  setFieldValue("snoozeDate", inputValue)
                }}
                value={values.snoozeDate}
                feedbackMessage={errors.snoozeDate}
                minDate={format(dmeOrder.minSnoozeDate, "YYYY-MM-DD")}
                maxDate={format(dmeOrder.maxSnoozeDate, "YYYY-MM-DD")}
              />
              <CanopyTextareaField
                name="comment"
                label="Add a comment"
                description="Provide additional information on why you're snoozing the order"
                feedbackMessage={errors.comment}
                onChange={handleChange}
                height="80px"
                optional
              />
            </div>
          </CanopyDialogModal>
        )
      }}
    </Form>
  )
}

export default SnoozeModal
