import React from "react"
import * as styles from "../index.module.scss"
import { CanopyButton } from "@parachutehealth/canopy-button"

interface Props {
  exit: () => void
}

const ClinicalFacilityProfileThankYou = (props: Props) => {
  const { exit } = props

  return (
    <div className={styles.profileWorkflowStepContainer}>
      <div className={styles.thankyouBodyContainer}>
        <h1 className={styles.header}>Your profile is complete! 🎉</h1>
        <p>You have helped streamline order fulfillment for your patients.</p>
        <div className={styles.thankyouBackToDashboardContainer}>
          <CanopyButton onClick={exit} type="button">
            Back to dashboard
          </CanopyButton>
        </div>
      </div>
    </div>
  )
}

export default ClinicalFacilityProfileThankYou
