import React, { useState, useCallback, useMemo } from "react"
import { Field as FormikField } from "formik"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import {
  buildOptionFromSalesOrderClassification,
  findOptionItemFromValue,
} from "./utils"
import { ClassificationIdDropdownProps } from "./types"

const ClassificationIdDropdown: React.FC<ClassificationIdDropdownProps> = ({
  salesOrderClassifications,
  setFormValue: setFormStateValue,
  formValue,
  onBlur,
  feedbackMessage,
}) => {
  const dropdownOptions = useMemo(
    () =>
      salesOrderClassifications.map(buildOptionFromSalesOrderClassification),
    [salesOrderClassifications]
  )
  const [filteredOptions, setFilteredOptions] = useState<OptionItem[]>(
    dropdownOptions
  )
  const [value, setValue] = useState<OptionItem | null>(
    findOptionItemFromValue(dropdownOptions, formValue)
  )

  const filterOptions = useCallback(
    (searchTerm: string) => {
      const trimmedSearch = searchTerm.trim().toLowerCase()

      if (trimmedSearch.length === 0) {
        setFilteredOptions(dropdownOptions)
        return
      }

      const filteredOptions = dropdownOptions.filter(
        (option) =>
          option.value.toLowerCase().includes(trimmedSearch) ||
          option.label?.toLowerCase().includes(trimmedSearch)
      )

      setFilteredOptions(filteredOptions)
    },
    [setFilteredOptions, dropdownOptions]
  )

  const handleOptionSelection = useCallback(
    (selectedOption: OptionItem | null) => {
      setValue(selectedOption)
      setFormStateValue("classificationId", selectedOption?.value ?? "")
    },
    [setFormStateValue]
  )

  return (
    <FormikField
      as={CanopyComboboxField}
      className="form-group"
      onChange={handleOptionSelection}
      onInputChange={filterOptions}
      options={filteredOptions}
      value={value}
      label="Classification ID"
      placeholder="Select or create a classification ID"
      required
      onBlur={onBlur}
      feedbackMessage={feedbackMessage}
    />
  )
}

export default ClassificationIdDropdown
