// @ts-strict-ignore
import React from "react"
import { DmeOrder, LineItemGroup, RxDetailsOutput } from "sharedTypes"
import DataRow from "components/DataRow"
import { formatPaymentMethod, isDefaultPaymentMethod } from "utilities/payment"
import { addressForFulfillment, formatAddressType } from "utilities/address"
import { capitalize } from "utilities/string"
import LineItemDescription from "components/LineItemDescription"

type Props = {
  dmeOrder: DmeOrder
  lineItemGroup: LineItemGroup
  rxDetailsOutputs: RxDetailsOutput[]
}

const ServiceLineItemGroup = ({
  dmeOrder,
  lineItemGroup,
  rxDetailsOutputs,
}: Props) => {
  const fulfillment = dmeOrder.fulfillments.find(
    (fulfillment) => fulfillment.id === lineItemGroup.dmeOrderFulfillmentId
  )
  const address = addressForFulfillment(dmeOrder.addresses, fulfillment)

  const formatAddress = (address) => {
    return (
      <>
        {address.addressType && (
          <span className="bold color-black">
            {capitalize(formatAddressType(address))}
            <br />
          </span>
        )}
        {[address.line1, address.line2, address.room, address.city]
          .filter((l) => !!l)
          .map((l) => (
            <React.Fragment key={l}>
              {l}
              <br />
            </React.Fragment>
          ))}
      </>
    )
  }

  return (
    <div key={lineItemGroup.id} className="well well-expand">
      <div className="content">
        <p className="bold font-xs">{lineItemGroup.displayName}</p>
        <ul className="canopy-pis-8x font-xs">
          {lineItemGroup.lineItems.map((lineItem) => (
            <li key={lineItem.id} className="canopy-mbe-2x">
              <LineItemDescription lineItem={lineItem} />
            </li>
          ))}
          <hr className="canopy-mbs-8x canopy-mbe-0" />
        </ul>
        {!!rxDetailsOutputs.length && (
          <DataRow
            className="font-xs canopy-mbe-0"
            label="Service Details"
            value={rxDetailsOutputs
              .map((output) => `${output.label}: ${output.value}`)
              .join(", ")}
          />
        )}
        <DataRow
          className="font-xs canopy-mbe-0"
          label="Service Address"
          value={formatAddress(address)}
        />
        <DataRow
          className="font-xs canopy-mbe-0"
          label="Evaluation Date"
          value={fulfillment.deliveryDate}
        />
        {!isDefaultPaymentMethod(lineItemGroup.paymentMethod) && (
          <DataRow
            className="font-xs canopy-mbe-0"
            label="Payment Method"
            value={formatPaymentMethod(lineItemGroup.paymentMethod)}
          />
        )}
      </div>
    </div>
  )
}

export default ServiceLineItemGroup
