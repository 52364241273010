import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"

import { actionsFooter } from "../../../index.module.scss"
import { navigate, reload } from "utilities/navigation"
import { useCsrInboxState } from "../../../csrInboxStateContext"
import { useIsMutating } from "@tanstack/react-query"
import {
  useErrorContext,
  useAllRequiredFieldsSetCheck,
} from "../../../error-context"
import { createOrder } from "./api"

export const ActionsFooter = () => {
  const numberOfActiveMutations = useIsMutating()
  const csrInboxState = useCsrInboxState()
  const { incrementSubmissionAttempts } = useErrorContext()
  const allRequiredFieldsSet = useAllRequiredFieldsSetCheck(
    csrInboxState.supplier.brightreeAcceptance
  )

  const [saving, setSaving] = useState(false)
  const onClick = () => {
    incrementSubmissionAttempts()
    if (allRequiredFieldsSet) {
      setSaving(true)
      createOrder({
        supplierOrganizationId: csrInboxState.supplierOrganization.id,
        csrInboxStateId: csrInboxState.id,
      })
        .then((response) => {
          setSaving(false)
          navigate(response.orderPath)
        })
        .catch(() => {
          setSaving(false)
          reload()
        })
    }
  }

  return (
    <div className={actionsFooter}>
      <CanopyButton
        loading={saving}
        onClick={onClick}
        disabled={numberOfActiveMutations > 0}
      >
        Save and go to order
      </CanopyButton>
    </div>
  )
}

export default ActionsFooter
