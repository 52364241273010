import { post } from "services/api"
import { employerPrefix } from "utilities/url"

export type DmeOrder = {
  id: string
  clinicalFacility: {
    id: string
    name: string
  }
  supplier: {
    id: string
    name: string
  }
  orderStatus: string
  lineItemGroups: { description: string }[]
  createdAt: string
  requestedDeliveryDate: string
  supplierSystemIds: {
    orderId: string
    instanceId: string
  }[]
}

export type DocumentationOrder = {
  id: string
  signingSignatureRequestId: string
  clinician: {
    name: string
    npi: string
  }
  createdAt: string
  signingStatus: "pending" | "signed" | "denied"
}

export type ExternalOrder = {
  createdAt: string
  supplierSystemId: string
  externalOrderUrl: string
  status: string
}

type OrderSearchResponse = {
  dmeOrders: DmeOrder[]
  documentationOrders: DocumentationOrder[]
  externalOrders: ExternalOrder[]
}

type ApiOrderSearchParameters = {
  masterPatientId?: string
  supplierSystemIds?: {
    supplierSystemPatientId: string
    supplierId: string
  }[]
}

type SearchOrdersParams = {
  supplierOrganizationId: string
  supplierId: string
  masterPatientId?: string
  supplierSystemPatientId?: string
}

export const searchOrders = async ({
  supplierOrganizationId,
  supplierId,
  masterPatientId,
  supplierSystemPatientId,
}: SearchOrdersParams): Promise<OrderSearchResponse> => {
  const prefix = employerPrefix("SupplierOrganization", supplierOrganizationId)
  const supplierSystemIds = supplierSystemPatientId
    ? [{ supplierId, supplierSystemPatientId }]
    : []

  const searchParams: ApiOrderSearchParameters = {
    masterPatientId,
    supplierSystemIds,
  }

  return post(
    `${prefix}/supplier_system_integrated_search/order_search.json`,
    searchParams
  ).then((response) => response.data)
}

export const attachToDocumentationOrder = (
  supplierId: string,
  csrInboxStateId: string,
  signatureRequestId: string
): Promise<{ success: boolean }> => {
  return post(
    `${employerPrefix(
      "Supplier",
      supplierId
    )}/inbox/${csrInboxStateId}/signature_request_attachments`,
    { signing_signature_request_id: signatureRequestId }
  )
    .then(() => ({ success: true }))
    .catch(() => ({ success: false }))
}
