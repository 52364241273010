export function navigate(location) {
  window.location = location
}

export function reload() {
  window.location.reload()
}

// Not sure if openNewTab consistently opens the url in a new tab...
export function openNewTab(url) {
  window.open(url)
}

export function navigateToNewTab(url) {
  const newTab = window.open(url, "_blank")
  if (newTab) {
    newTab.focus()
  } else {
    // If browser settings treat the new tab as a popup and block it,
    // fallback to same window navigation
    window.location.href = url
  }
}
