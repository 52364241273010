import { get, put } from "services/api"
import {
  pendingOrdersSaveQuestionAnswerUrl,
  relevantDiagnosesUrl,
  pendingOrdersSearchIcd10CodesUrl,
  pendingOrdersSaveDiagnosesUrl,
  pendingOrdersTherapySurveyQuestionsUrl,
} from "./urls"
import { ApplicationError, Icd10Code, SurveyQuestion } from "../../sharedTypes"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { handleError } from "utilities/error"
import { QualificationStatus, WorkflowStep } from "./sharedTypes"

const surveyQuestionsKey = (workflowStep, pendingOrderId, therapySelectionId) =>
  ["survey", workflowStep, pendingOrderId, therapySelectionId] as const

interface SurveyQuestionsResponse {
  percentageComplete: number
  qualificationStatus: QualificationStatus
  questions: SurveyQuestion[]
  requiresConfirmation: boolean
  disqualifiedMessage?: string
}

export const useGetSurveyQuestions = (
  pendingOrderId: string,
  therapySelectionId: string,
  workflowStep: WorkflowStep
) => {
  return useQuery<SurveyQuestionsResponse>({
    queryKey: surveyQuestionsKey(
      workflowStep,
      pendingOrderId,
      therapySelectionId
    ),
    queryFn: async () => {
      try {
        const response = await get(
          pendingOrdersTherapySurveyQuestionsUrl(
            pendingOrderId,
            therapySelectionId
          ),
          {
            workflowStep,
          }
        )
        return response.data
      } catch (error) {
        handleError(error as ApplicationError)
        return {
          percentageComplete: 0,
          qualificationStatus: QualificationStatus.NotStarted,
          questions: [],
          requiresConfirmation: true,
        }
      }
    },
  })
}

interface UseGetRelevantDiagnosesResponse {
  diagnoses: Icd10Code[]
}

export type SaveDiagnosesResponsePayload = {
  name: string
  warnings: {
    reason: string
    severity: string
  }[]
}[]

export const useGetRelevantDiagnoses = (pendingOrderId: string) => {
  return useQuery({
    queryKey: ["diagnoses", "relevant", pendingOrderId],
    queryFn: async (): Promise<UseGetRelevantDiagnosesResponse> => {
      try {
        const response = await get(relevantDiagnosesUrl(pendingOrderId))
        return response.data
      } catch (error) {
        handleError(error as ApplicationError)
        return { diagnoses: [] }
      }
    },
  })
}

interface UseIcd10CodesQueryResponse {
  codes: Icd10Code[]
}

export const useIcd10CodesQuery = (
  pendingOrderId: string,
  searchTerm: string
) => {
  return useQuery({
    queryKey: ["diagnosis", "icd10Codes", pendingOrderId, searchTerm],
    queryFn: async (): Promise<UseIcd10CodesQueryResponse> => {
      try {
        const response = await get(
          pendingOrdersSearchIcd10CodesUrl(pendingOrderId),
          {
            search: searchTerm,
          }
        )
        return response.data
      } catch (error) {
        handleError(error as ApplicationError)
        return { codes: [] }
      }
    },
  })
}

interface UseSaveAnswerPayload {
  answerType: string
  answerValue: string
  question: SurveyQuestion
}

export const useSaveAnswer = (
  pendingOrderId: string,
  therapySelectionId: string,
  workflowStep: WorkflowStep
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ answerType, answerValue, question }: UseSaveAnswerPayload) =>
      put(
        pendingOrdersSaveQuestionAnswerUrl(pendingOrderId, question.questionId),
        {
          answerType,
          answerValue,
          surveyId: question.surveyId,
          workflowStep,
        }
      ),
    onSuccess: async (response) => {
      queryClient.setQueryData(
        surveyQuestionsKey(workflowStep, pendingOrderId, therapySelectionId),
        response.data
      )
      return response.data
    },
    onError: (error) => {
      handleError(error as ApplicationError)
    },
  })
}

interface UseSaveDiagnosesRequestPayload {
  diagnoses: string[]
}

type UseSaveDiagnosesResponse = {
  name: string
  warnings: {
    reason: string
    severity: string
  }[]
}[]

export const useSaveDiagnoses = (pendingOrderId: string) => {
  return useMutation({
    mutationFn: ({ diagnoses }: UseSaveDiagnosesRequestPayload) =>
      put(pendingOrdersSaveDiagnosesUrl(pendingOrderId), {
        diagnoses,
      }),
    onSuccess: async (response): Promise<UseSaveDiagnosesResponse> => {
      // todo: invalidate data/set new query data when we have queries for this
      return response.data
    },
    onError: (error) => {
      handleError(error as ApplicationError)
    },
  })
}
