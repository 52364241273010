import { unique } from "utilities/array"
import { EmployerType, LineItemGroup, Supplier } from "sharedTypes"

const getUniqueSuppliers = (lineItemGroups: LineItemGroup[]): Supplier[] => {
  return unique(
    lineItemGroups.map(({ packageSupplier }) => packageSupplier)
  ).filter(Boolean)
}

const getSupplierTitle = (lineItemGroups: LineItemGroup[]): string => {
  const suppliers = getUniqueSuppliers(lineItemGroups)
  if (suppliers.length === 0) {
    return "Chat with supplier"
  } else if (suppliers.length === 1) {
    const supplier = suppliers[0]
    if (supplier.logoUrl) {
      return "Chat with supplier"
    } else {
      return `Chat with ${supplier.name}`
    }
  }
  return "Chat with suppliers"
}

export const getActivityTitle = (
  employerType: EmployerType,
  lineItemGroups: LineItemGroup[],
  canComment: boolean,
  payorActivityFeedInclusion: boolean
): string => {
  if (
    employerType === EmployerType.ClinicalFacility &&
    canComment! &&
    !payorActivityFeedInclusion
  ) {
    return getSupplierTitle(lineItemGroups)
  }
  return "Activity feed"
}
