import React from "react"
import {
  ChartNote,
  ChartNoteQuestion,
  DmeOrder,
  DmeOrderWarning,
  DocumentationRequirement,
  DocumentationRequirementSatisfyMethod,
  PackageConfigurationWarningMetadata,
  QuestionnaireResponse,
} from "sharedTypes"
import DocumentationRequirementRow from "applications/Workflow/containers/ClinicalFacilityClinical/components/DocumentationRequirements/DocumentationRequirementRow"
import Alert from "components/Alert"
import {
  productsPackageConfigurationPath,
  questionnaireResponsePath,
} from "applications/Workflow/routes"
import { Link } from "react-router-dom"
import ClinicalInformationRow from "applications/Workflow/containers/ClinicalFacilityClinical/components/DocumentationRequirements/ClinicalInformationRow"
import { isChartNoteRelatedToDocumentationRequirement } from "applications/Workflow/containers/ClinicalFacilityClinical/components/utilities"
import { useFeatureFlags } from "components/FeatureFlagContext"
import ExternalQuestionnaireStatusPill from "applications/Workflow/containers/Review/components/ExternalQuestionnaireStatusPill"
import MedicalNecessityReviewButton from "applications/Workflow/containers/ClinicalFacilityClinical/components/OptumDocumentationRequirements/MedicalNecessityReviewButton"
import { EditDocumentationLink } from "applications/Workflow/containers/ClinicalFacilityClinical/components/DocumentationRequirements/DocumentationRequirementRow/GenerateDetails"
import { canopyColorTextSecondary } from "@parachutehealth/canopy-tokens-color"

interface Props {
  chartNotes: ChartNote[]
  className: string
  dmeOrder: DmeOrder
  documentationRequirements: DocumentationRequirement[]
  questionnaireResponses: QuestionnaireResponse[]
  updateSatisfyMethod?: (
    documentationRequirement: DocumentationRequirement,
    satisfyMethod: DocumentationRequirementSatisfyMethod
  ) => Promise<void>
  warnings?: DmeOrderWarning[]
  bounceToSection?: {
    shouldBounce: boolean
    triggerBounce: () => void
  }
  setShowIcd10Error?: (show: boolean) => void
  updateDocumentationRequirementsRequested(
    requestedIds: string[]
  ): Promise<void>
  fetchChartNoteQuestions({ id }: { id: string }): Promise<ChartNoteQuestion[]>
}

const OptumDocumentationRequirements = ({
  chartNotes,
  className,
  dmeOrder,
  documentationRequirements,
  questionnaireResponses,
  updateSatisfyMethod,
  warnings = [],
  bounceToSection = {
    shouldBounce: false,
    triggerBounce: () => {},
  },
  setShowIcd10Error,
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const contextDrivenDocs = isFeatureEnabled("contextDrivenDocs")

  if (
    documentationRequirements.length === 0 &&
    questionnaireResponses.length === 0 &&
    chartNotes.length === 0
  ) {
    return <></>
  }

  const packageConfigurationWarning = warnings.find(
    (warning) =>
      warning.key === "rx_details" || warning.key === "product_requirements"
  )

  const renderActionButton = () => {
    return (
      <div className="canopy-mbs-12x">
        <div className="canopy-mbe-4x">
          <MedicalNecessityReviewButton
            chartNotes={chartNotes}
            questionnaireResponses={questionnaireResponses}
            buttonText="Begin health plan medical review"
          />
        </div>
      </div>
    )
  }

  const renderMedicalNecessities = () => {
    return (
      <>
        {documentationRequirements.filter((requirement) => requirement.generate)
          .length > 0 && <hr />}
        {questionnaireResponses.map((questionnaireResponse, index) => (
          <div key={questionnaireResponse.id}>
            {index !== 0 && <hr />}
            <ClinicalInformationRow
              showDetails={true}
              title={questionnaireResponse.title}
              isDocumentParachuteGenerated={true}
              status={
                <ExternalQuestionnaireStatusPill
                  satisfyStatus={questionnaireResponse.status}
                />
              }
              details={
                <EditDocumentationLink
                  bounceToSection={bounceToSection}
                  setShowIcd10Error={setShowIcd10Error}
                  itemId={questionnaireResponse.id}
                  linkPath={questionnaireResponsePath(questionnaireResponse.id)}
                  satisfyStatus={questionnaireResponse.status}
                />
              }
            />
          </div>
        ))}
      </>
    )
  }

  const requirementRows = () => {
    return (
      <>
        <div className="well canopy-p-8x">
          {documentationRequirements
            .filter((requirement) => requirement.generate)
            .map((documentationRequirement, index) => (
              <div key={documentationRequirement.externalId}>
                {index !== 0 && <hr />}
                <DocumentationRequirementRow
                  dmeOrderId={dmeOrder.id}
                  chartNote={chartNotes.find((cn) =>
                    isChartNoteRelatedToDocumentationRequirement(
                      documentationRequirement,
                      cn,
                      contextDrivenDocs
                    )
                  )}
                  documentationRequirement={documentationRequirement}
                  permissions={dmeOrder.permissions}
                  missingChartNotesPrerequisites={!!packageConfigurationWarning}
                  updateSatisfyMethod={updateSatisfyMethod}
                  bounceToSection={bounceToSection}
                  detailed
                  setShowIcd10Error={setShowIcd10Error}
                />
              </div>
            ))}
          {renderMedicalNecessities()}
        </div>
        {renderActionButton()}
      </>
    )
  }

  return (
    <div className={className} data-testid="optum-documentation-requirements">
      <div className="well">
        <div className="row canopy-px-8x canopy-mb-8x">
          <div className="canopy-typography-heading-large canopy-mb-4x">
            Demonstrate medical necessity
          </div>
          <div
            className="canopy-typography-body-medium"
            style={{
              color: canopyColorTextSecondary,
            }}
          >
            The patient's health plan requires you to complete the following
            medical reviews in order to request authorization.
          </div>
        </div>
        {packageConfigurationWarning && (
          <Alert status="danger" leftIcon>
            The documentation requirements are disabled because of missing
            details for your product(s).
            <Link
              className="canopy-pis-2x"
              to={productsPackageConfigurationPath(
                null,
                (packageConfigurationWarning.metadata as PackageConfigurationWarningMetadata)
                  .packageConfigurationId,
                true
              )}
            >
              Click here to add product details
            </Link>
          </Alert>
        )}
        {requirementRows()}
      </div>
    </div>
  )
}

export default OptumDocumentationRequirements
