import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { Formik, Field as FormikField } from "formik"
import * as api from "../../api"
import {
  ClassificationIdDropdown,
  SalesOrderClassificationFormErrorNotice,
  SalesOrderClassificationFormSuccessNotice,
} from "./components"
import { CanopyComboboxField } from "@parachutehealth/canopy-combobox-field"
import { getCurrentUrl } from "../../../../utilities/url"
import { CreateSalesOrderClassificationMappingFormProps } from "./types"
import { isInvalidClassificationId } from "./utils"

const CreateSalesOrderClassificationMappingForm: React.FC<CreateSalesOrderClassificationMappingFormProps> = ({
  supplierName,
  catalogCategoryOptions,
  catalogCategoryId,
  catalogSubcategoryId,
  classificationId,
  catalogSubcategoryOptions,
  classificationIdOptions,
  salesOrderClassificationMappingExternalId,
  salesOrderClassificationMappingsPath,
}: CreateSalesOrderClassificationMappingFormProps) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async (values) => {
    setIsSubmitting(true)
    let promiseResult
    if (getCurrentUrl().includes("edit")) {
      promiseResult = api.updateSalesOrderClassificationMapping(
        salesOrderClassificationMappingsPath,
        salesOrderClassificationMappingExternalId,
        values
      )
    } else {
      promiseResult = api.createSalesOrderClassificationMapping(
        salesOrderClassificationMappingsPath,
        values
      )
    }

    return promiseResult
      .then(() => {
        setIsSuccess(true)
        setSubmissionErrors([])
      })
      .catch((error) => {
        setIsSuccess(false)

        if (error.response.status === 422) {
          setSubmissionErrors(error.response.data.errors)
          return
        }

        setSubmissionErrors(["Server error"])
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const validateForm = (values) => {
    const errors: {
      classificationId?: string
      catalogCategoryId?: string
      catalogSubcategoryId?: string
    } = {}

    if (!values.classificationId) {
      errors.classificationId = "Classification ID is required"
    } else if (
      isInvalidClassificationId(
        classificationIdOptions,
        values.classificationId
      )
    ) {
      errors.classificationId =
        "This classification ID does not exist. Please select a valid sales order classification"
    }

    if (!values.catalogCategoryId && !values.catalogSubcategoryId) {
      errors.catalogCategoryId = "A category or subcategory is required"
      errors.catalogSubcategoryId = "A category or subcategory is required"
    }

    if (values.catalogCategoryId && values.catalogSubcategoryId) {
      errors.catalogCategoryId =
        "Only one of category or subcategory can be selected"
      errors.catalogSubcategoryId =
        "Only one of category or subcategory can be selected"
    }

    return errors
  }

  const handleBlur = async (
    fieldName: string,
    setFieldTouched: (field: string) => void
  ) => {
    await setFieldTouched(fieldName)
  }

  const initialValues = {
    catalogCategoryId: catalogCategoryId,
    catalogSubcategoryId: catalogSubcategoryId,
    classificationId: classificationId,
  }

  const pageTitle = () => {
    const verb = salesOrderClassificationMappingExternalId ? "Edit" : "New"
    return `${verb} sales order classification mapping for ${supplierName}`
  }

  return (
    <div>
      <SalesOrderClassificationFormSuccessNotice
        salesOrderClassificationMappingsPath={
          salesOrderClassificationMappingsPath
        }
        isSuccess={isSuccess}
      />
      <SalesOrderClassificationFormErrorNotice
        salesOrderClassificationMappingsPath={
          salesOrderClassificationMappingsPath
        }
        submissionErrors={submissionErrors}
      />
      <h1>{pageTitle()}</h1>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validateForm}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          touched,
          errors,
        }) => {
          const categoryIdError = touched.catalogCategoryId
            ? errors.catalogCategoryId
            : undefined
          const subcategoryIdError = touched.catalogSubcategoryId
            ? errors.catalogSubcategoryId
            : undefined
          const classificationIdError = touched.classificationId
            ? (errors.classificationId as string)
            : undefined
          return (
            <form onSubmit={handleSubmit}>
              <FormikField
                as={CanopyComboboxField}
                className="form-group"
                label="Product category"
                placeholder="All product categories"
                options={catalogCategoryOptions}
                value={values.catalogCategoryId || ""}
                onChange={(event) => {
                  setFieldValue("catalogCategoryId", event?.value)
                }}
                onBlur={() => {
                  void handleBlur("catalogCategoryId", setFieldTouched)
                }}
                feedbackMessage={categoryIdError}
              />
              <FormikField
                as={CanopyComboboxField}
                className="form-group"
                label="Product subcategory"
                placeholder="All product subcategories"
                options={catalogSubcategoryOptions}
                value={values.catalogSubcategoryId || ""}
                onChange={(event) => {
                  setFieldValue("catalogSubcategoryId", event?.value)
                }}
                onBlur={() => {
                  void handleBlur("catalogSubcategoryId", setFieldTouched)
                }}
                feedbackMessage={subcategoryIdError}
              />
              <ClassificationIdDropdown
                salesOrderClassifications={classificationIdOptions}
                setFormValue={setFieldValue}
                formValue={values.classificationId}
                onBlur={() => {
                  void handleBlur("classificationId", setFieldTouched)
                }}
                feedbackMessage={classificationIdError}
              />
              <CanopyButton
                loading={isSubmitting}
                variant="primary"
                loadingText="Saving mapping"
                type="submit"
              >
                Save
              </CanopyButton>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}
export default CreateSalesOrderClassificationMappingForm
