import React, { Dispatch, SetStateAction, useContext } from "react"
import { ToastContext } from "components/Toaster"
import { bulkRemoveSignatureRequestReviews, RemoveReviews } from "../api"
import { DoctorSignatureRequest } from "../../../api"
import { pluralize } from "utilities/string"
import { EventActions, trackEvent } from "../../../utilities/tracking"
import { CanopyButton } from "@parachutehealth/canopy-button"

interface Props {
  selectedSignatureRequestsGids: string[]
  pendingSignatureRequests: DoctorSignatureRequest[]
  doctor: { name: string; npi: string; id: string }
  onRemoveReviews: () => void
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  notificationBadgeStatusChecker: () => void
}

const RemoveSignatureRequestReviewsButton = ({
  selectedSignatureRequestsGids,
  pendingSignatureRequests,
  doctor,
  onRemoveReviews,
  loading,
  setLoading,
  notificationBadgeStatusChecker,
}: Props) => {
  const { persistentAlert } = useContext(ToastContext)

  const convertSelectedGidsToIds = (
    selectedSignatureRequestsGids
  ): RemoveReviews => {
    const removeReviews: RemoveReviews = {
      orderIds: [],
      signingSignatureRequestIds: [],
    }
    pendingSignatureRequests
      .filter((s) => selectedSignatureRequestsGids.includes(s.gid))
      .forEach((s) => {
        if (s.type === "DmeOrder") {
          removeReviews.orderIds.push(s.id)
        } else if (s.type === "Signing::SignatureRequest") {
          removeReviews.signingSignatureRequestIds.push(s.id)
        }
      })
    return removeReviews
  }

  const removeReviews = () => {
    setLoading(true)
    trackEvent(EventActions.ReviewerRemoveReviews)
    return bulkRemoveSignatureRequestReviews(
      doctor.id,
      convertSelectedGidsToIds(selectedSignatureRequestsGids)
    )
      .then(() => {
        persistentAlert({
          message: `${selectedSignatureRequestsGids.length} ${pluralize(
            "order",
            selectedSignatureRequestsGids.length
          )} moved to the Review tab`,
          severity: "success",
        })
        onRemoveReviews()
        notificationBadgeStatusChecker()
      })
      .catch(() =>
        persistentAlert({
          message: `Encountered an error, please try again`,
          severity: "error",
        })
      )
  }

  return (
    <>
      <CanopyButton
        variant="secondary"
        className="canopy-mie-12x"
        disabled={selectedSignatureRequestsGids.length === 0 || loading}
        onClick={() => {
          trackEvent(EventActions.ReviewerRemoveReviews)
          removeReviews()
        }}
      >
        Send back to review
      </CanopyButton>
    </>
  )
}

export default RemoveSignatureRequestReviewsButton
