import React from "react"
import { Event, EventAction, Notice as NoticeType, Nullable } from "sharedTypes"
import ExternalLinks from "./ExternalLinks"
import Notice from "./utilities/Notice"
import { EventRawContent, PlainTextEventContent } from "./EventRawContent"
import ServiceValidationMessage from "../../applications/Workflow/containers/Sidebar/containers/PatientPortal/components/ServiceValidationMessage"
import { fullInternalLinkPath } from "./utilities/internalLinkPath"
import { CanopyLink } from "@parachutehealth/canopy-link"
import { getEmployerId, getEmployerType } from "../../utilities/url"

type Props = {
  event: Event
  notice?: Nullable<NoticeType>
  maxLength?: number
}

const innerContent = ({
  event,
  maxLength,
}: {
  event: Event
  maxLength?: number
}) => {
  if (event.data?.serviceValidation) {
    return (
      <ServiceValidationMessage
        serviceValidation={event.data.serviceValidation}
        supplierName={event.userEmployerName}
      />
    )
  } else if (event.rawContent) {
    return <EventRawContent event={event} maxLength={maxLength} />
  } else {
    return <PlainTextEventContent text={event.content} />
  }
}

const Content: React.FC<Props> = ({ event, notice, maxLength, children }) => {
  const {
    action,
    supplierId,
    content,
    externalLinks,
    internalLink,
    outerContent,
    rawContent,
  } = event

  const internalLinkPath = fullInternalLinkPath(internalLink?.path)
  const internalLinkText = internalLink?.text
  const employerId = getEmployerId()
  const employerType = getEmployerType()

  // TODO: This is a temporary fix to show the auth internal link for the supplier that originated the auth request only.
  // Will be revisited when we handle auth supplier transfer

  const showAuthInternalLink =
    employerType !== "Supplier" ||
    action !== EventAction.AuthRequestPayorReviewComplete ||
    (action === EventAction.AuthRequestPayorReviewComplete &&
      employerType === "Supplier" &&
      employerId === supplierId)
  return (
    <>
      {outerContent && <div className="outer-message-body">{outerContent}</div>}
      {(content || rawContent) && (
        <div className="message-body">
          {innerContent({ event, maxLength })}{" "}
          <ExternalLinks links={externalLinks} />
          {internalLinkPath && internalLinkText && showAuthInternalLink && (
            <>
              <br />
              <CanopyLink href={internalLinkPath} target="_blank">
                {internalLinkText}
              </CanopyLink>
            </>
          )}
          {children}
        </div>
      )}
      {notice && <Notice notice={notice} />}
    </>
  )
}

export default Content
