import React, { useRef, useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import {
  CanopyPickerField,
  OptionItem,
} from "@parachutehealth/canopy-picker-field"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import { alert } from "utilities/alert"
import { updateInternalStatus } from "./api"
import { InternalStatus as InternalStatusType } from "../../../../sharedTypes"
import { canopyDialogModalWithComboboxOrPicker } from "../../index.module.scss"

interface Props {
  internalStatuses: InternalStatusType[]
  internalStatus?: InternalStatusType
}

export const InternalStatus = ({ internalStatuses, internalStatus }: Props) => {
  const [open, setOpen] = React.useState(false)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)
  const internalStatusOptions: OptionItem[] = internalStatuses.map(
    (status) => ({
      label: status.status,
      value: status.id,
    })
  )
  const currentInternalStatus: OptionItem | undefined = internalStatus
    ? {
        label: internalStatus?.status,
        value: internalStatus?.id,
      }
    : undefined

  const selectedValue = useRef<OptionItem | undefined>(currentInternalStatus)

  const [status, setStatus] = useState(
    internalStatus ? currentInternalStatus : undefined
  )
  const activatorRef = React.useRef<HTMLButtonElement>(null)
  const csrInboxState = useCsrInboxState()
  const emptyStatusValueToDisplayWhenNull = "canopy-empty-status-workaround"
  const options = [
    {
      label: "None",
      value: emptyStatusValueToDisplayWhenNull,
    },
    ...internalStatusOptions,
  ]

  const navInternalStatus = `Internal status${
    !!status ? `: ${status?.label?.substring(0, 30)}` : ""
  }`

  const handleUpdateClick = () => {
    const usableStatus =
      selectedValue?.current?.value === emptyStatusValueToDisplayWhenNull
        ? null
        : selectedValue?.current
    updateInternalStatus({
      supplierOrganizationId: csrInboxState.supplierOrganization.id,
      csrInboxStateId: csrInboxState.id,
      internalStatusId: usableStatus?.value,
    })
      .then(() => setStatus(usableStatus || undefined))
      .catch(() =>
        alert("Something went wrong. Please refresh the page and try again.")
      )
    closeModal()
  }

  return (
    <>
      <CanopyButton
        iconStart="flag"
        size="small"
        variant="tertiary"
        onClick={openModal}
        ref={activatorRef}
      >
        {navInternalStatus}
      </CanopyButton>
      <CanopyDialogModal
        className={canopyDialogModalWithComboboxOrPicker}
        activatorElementRef={activatorRef}
        header="Update Internal Status"
        open={open}
        onClose={closeModal}
        primaryFooterButton={
          <CanopyButton type="submit" onClick={handleUpdateClick}>
            Update
          </CanopyButton>
        }
        secondaryFooterButton={
          <CanopyButton variant="secondary" onClick={closeModal}>
            Cancel
          </CanopyButton>
        }
      >
        Choose the internal status for this document from the options below.
        <CanopyPickerField
          label="Select status"
          className="canopy-pt-8x"
          options={options}
          defaultValue={status?.value || options[0].value}
          multiple={false}
          onChange={(option) =>
            Array.isArray(option)
              ? console.log("We shouldn't be here")
              : (selectedValue.current = option)
          }
        />
      </CanopyDialogModal>
    </>
  )
}
