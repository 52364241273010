// @team @demand-ordering
import React from "react"
import { Nullable } from "../../../../../sharedTypes"
import { CanopyButton } from "@parachutehealth/canopy-button"

interface Props {
  otherPackagesCount: Nullable<number>
  packagesCount: number
  selectCategory: (category: Nullable<number>, isSubcategory: boolean) => void
}
const NoSearchResults: React.FC<Props> = ({
  otherPackagesCount,
  packagesCount,
  selectCategory,
}) => {
  if (packagesCount) {
    return null
  }

  if (!otherPackagesCount) {
    return (
      <div className="alert alert-default text-center">
        <h1>No packages match your filters</h1>
      </div>
    )
  }

  return (
    <div className="alert alert-default alert-rounded bg-none">
      <div className="canopy-typography-heading-large canopy-pb-2x">
        No results
      </div>
      <div className="canopy-typography-body-large canopy-pb-6x">
        {otherPackagesCount} packages in different categories matching your
        search
      </div>
      <CanopyButton
        variant="primary"
        onClick={() => selectCategory(null, false)}
      >
        Clear Category Filter
      </CanopyButton>
    </div>
  )
}

export default NoSearchResults
