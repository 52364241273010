import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import { CanopyForm } from "@parachutehealth/canopy-form"
import React from "react"
import { Formik, FormikValues } from "formik"
import {
  CarrierAuthorization,
  CarrierAuthorizationReferralTypesEnum,
} from "../../../../../graphql/__generated__/graphql"
import * as styles from "./AuthDetailsEditForm.module.scss"
import { capitalize } from "utilities/string"

export interface AuthDetailsEditFormProps {
  onCancel: () => void
  onSubmit: (values) => void
  authRequest: CarrierAuthorization
}

const REFERRAL_TYPE_OPTIONS = [
  {
    label: capitalize(CarrierAuthorizationReferralTypesEnum.Initial),
    value: CarrierAuthorizationReferralTypesEnum.Initial,
  },
  {
    label: capitalize(CarrierAuthorizationReferralTypesEnum.Reauthorization),
    value: CarrierAuthorizationReferralTypesEnum.Reauthorization,
  },
]

export const AuthDetailsEditForm = ({
  onCancel,
  onSubmit,
  authRequest,
}: AuthDetailsEditFormProps) => {
  const initialValues = {
    referralType: authRequest.referralType,
  }

  const validate = (values: FormikValues) => {
    const errors = {}
    if (!values.referralType) {
      errors["referralType"] = "Selection required"
    }

    return errors
  }

  const handleOnSubmit = async (values: FormikValues) => {
    onSubmit({
      carrierAuthorizationId: authRequest.id,
      referralType: values.referralType,
    })
  }

  return (
    <>
      <div className="canopy-typography-body-medium canopy-mb-12x">
        <span className={styles.redText}>*</span> Indicates a required field
      </div>

      <Formik
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validate={validate}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => {
          return (
            <CanopyForm onSubmit={handleSubmit}>
              <CanopyRadioInputField
                role="radiogroup"
                label="Referral type"
                name="referralType"
                options={REFERRAL_TYPE_OPTIONS}
                value={values.referralType || undefined}
                onChange={handleChange}
                className={styles.radioInput}
                required
                feedbackMessage={
                  touched.referralType
                    ? (errors.referralType as string)
                    : undefined
                }
              />

              <div className={styles.formButtonSection}>
                <CanopyButton variant="primary" type="submit">
                  Save
                </CanopyButton>
                <CanopyButton variant="tertiary" onClick={() => onCancel()}>
                  Cancel
                </CanopyButton>
              </div>
            </CanopyForm>
          )
        }}
      </Formik>
    </>
  )
}
