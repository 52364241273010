import React from "react"
import Logo from "../../components/Logo"
import SignOutButton from "../Workflow/containers/Navigation/components/TopNavigation/TopLeftNavButton/SignOutButton"
import * as styles from "./index.module.scss"
import classNames from "classnames"

interface Props {
  firstName: string
  lastName: string
  externalPatientId: string
  showLogoutButton: boolean
}

const DoctorPendingOrderWorkflowHeader = ({
  firstName,
  lastName,
  externalPatientId,
  showLogoutButton,
}: Props) => {
  return (
    <nav
      className={classNames(
        styles.navContainer,
        styles.nav,
        "navbar-fixed-top",
        "fixed-top"
      )}
    >
      <div className={styles.navFlex}>
        <div className="">
          <h1
            className={classNames(
              styles.multiActorH1,
              "canopy-typography-heading-medium"
            )}
          >
            Order for {firstName} {lastName}
          </h1>
          <div className="canopy-typography-body-small">
            MRN: {externalPatientId}
          </div>
        </div>
        <div className={styles.signOutButton}>
          {showLogoutButton && <SignOutButton />}
          <Logo width="36"></Logo>
        </div>
      </div>
    </nav>
  )
}

export default DoctorPendingOrderWorkflowHeader
