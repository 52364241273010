import React from "react"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import {
  CarrierLineItemAuthorization,
  CarrierLineItemAuthorizationStatusEnum,
} from "graphql/__generated__/graphql"
import { dmeOrderUrl } from "../../../../../Workflow/urls"

interface LineItemStatusBannerProps {
  lineItem: CarrierLineItemAuthorization
  dmeOrderId: string
  className?: string
}

export const bannerStatuses = [
  CarrierLineItemAuthorizationStatusEnum.Approved,
  CarrierLineItemAuthorizationStatusEnum.PartiallyApproved,
  CarrierLineItemAuthorizationStatusEnum.Denied,
  CarrierLineItemAuthorizationStatusEnum.Cancelled,
  CarrierLineItemAuthorizationStatusEnum.NoAdmit,
]

const LineItemStatusBanner = ({
  lineItem,
  dmeOrderId,
  className,
}: LineItemStatusBannerProps) => {
  const variant = () => {
    switch (lineItem.decisionStatus) {
      case CarrierLineItemAuthorizationStatusEnum.Approved:
        return "success"
      case CarrierLineItemAuthorizationStatusEnum.PartiallyApproved:
        return "warning"
      case CarrierLineItemAuthorizationStatusEnum.Denied:
      case CarrierLineItemAuthorizationStatusEnum.Cancelled:
      case CarrierLineItemAuthorizationStatusEnum.NoAdmit:
        return "error"
    }
  }

  const title = () => {
    switch (lineItem.decisionStatus) {
      case CarrierLineItemAuthorizationStatusEnum.Approved:
      case CarrierLineItemAuthorizationStatusEnum.PartiallyApproved:
        return `Prior auth number: ${lineItem.priorAuthorizationNumber}`
      case CarrierLineItemAuthorizationStatusEnum.Denied:
      case CarrierLineItemAuthorizationStatusEnum.Cancelled:
      case CarrierLineItemAuthorizationStatusEnum.NoAdmit:
        return "For more information, chat with the payor in the order's activity feed"
      default:
        return ""
    }
  }

  const message = () => {
    if (
      lineItem.decisionStatus ===
      CarrierLineItemAuthorizationStatusEnum.PartiallyApproved
    ) {
      return "For more information about the partial approval, chat with the payor in the order's activity feed"
    }
    return ""
  }

  const actions = () => {
    if (
      lineItem.decisionStatus ===
      CarrierLineItemAuthorizationStatusEnum.Approved
    ) {
      return
    }
    return [
      {
        href: dmeOrderUrl("/review", dmeOrderId),
        target: "_blank",
        text: "Go to activity feed",
      },
    ]
  }

  return (
    <CanopyNotice
      title={title()}
      message={message()}
      actions={actions()}
      variant={variant()}
      className={className}
    />
  )
}

export default LineItemStatusBanner
