import classNames from "classnames"
import React from "react"
import partition from "lodash/partition"
import * as styles from "./supplier-list.module.scss"

export type Props = {
  suppliers: Supplier[]
  selectSupplier: (supplierId: string) => void
  selectedSupplierId?: string
  highlightedSupplierIds: string[]
}

interface Supplier {
  name: string
  id: string
}

const SupplierListItem = ({
  supplier,
  isSelected,
  selectSupplier,
  isHighlighted,
}: {
  supplier: Supplier
  selectSupplier: (supplierId: string) => void
  isSelected?: boolean
  isHighlighted?: boolean
}) => {
  const onSelect = () => {
    selectSupplier(supplier.id)
  }
  return (
    <li>
      <button
        onClick={onSelect}
        className={classNames(styles.supplierLink, {
          [styles.supplierLinkSelected]: isSelected,
        })}
      >
        {isHighlighted && (
          <p
            role="status"
            aria-label="new items indicator"
            className={styles.highlightIndicator}
          />
        )}
        <span>{supplier.name}</span>
      </button>
    </li>
  )
}

export const SupplierList = ({
  suppliers,
  selectedSupplierId,
  selectSupplier,
  highlightedSupplierIds,
}: Props) => {
  const isHighlighted = (supplier) =>
    highlightedSupplierIds.includes(supplier.id)
  const [highlightedSuppliers, otherSuppliers] = partition(
    suppliers,
    isHighlighted
  )
  return (
    <ul className="list-unstyled">
      {[...highlightedSuppliers, ...otherSuppliers].map((supplier) => (
        <SupplierListItem
          key={supplier.id}
          supplier={supplier}
          selectSupplier={selectSupplier}
          isSelected={supplier.id === selectedSupplierId}
          isHighlighted={isHighlighted(supplier)}
        />
      ))}
    </ul>
  )
}
