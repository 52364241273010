import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { ClinicalFacilityProfile } from "../index"
import { useFormikParachute } from "applications/shared/hooks/useFormikParachute/useFormikParachute"
import { updateFaxNumbers } from "../api"
import * as styles from "../index.module.scss"
import ClinicalFacilityProfileProgress from "./ClinicalFacilityProfileProgress"
import FaxNumbersFormGroup from "applications/ClinicalFacilityManageSettings/components/FaxNumbersFormGroup"

type Props = {
  clinicalFacility: ClinicalFacilityProfile
  onUpdate: (facility: ClinicalFacilityProfile) => void
  next: () => void
  back: () => void
}

const ClinicalFacilityProfileFaxNumbers = (props: Props) => {
  const { clinicalFacility, next, back } = props

  const formik = useFormikParachute({
    initialValues: {
      faxNumbers: clinicalFacility.faxNumbers || [],
    },
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  const onSubmit = async (values) => {
    try {
      const response = await updateFaxNumbers(values.faxNumbers)
      props.onUpdate(response)
      next()
    } catch (errors: any) {
      formik.setErrors(errors)
    }
  }

  return (
    <div className={styles.profileWorkflowStepContainer}>
      <ClinicalFacilityProfileProgress step={3} />
      <CanopyForm
        className={styles.formContainer}
        onSubmit={formik.handleSubmit}
        data-testid="facility-fax-numbers-form"
      >
        <h1 className={styles.header}>
          Which fax numbers do you use to receive supplier requests?
        </h1>
        <p>
          Add fax numbers suppliers use to send requests for clinical
          documentation so they can find you on Parachute.
        </p>
        <FaxNumbersFormGroup
          faxNumbers={formik.values.faxNumbers}
          onChange={(faxNumbers) => {
            formik.setFieldValue("faxNumbers", faxNumbers)
          }}
          limit={16}
        />
        <div className="canopy-mb-32x" />
        <div className={styles.buttonContainer}>
          <CanopyButton variant="secondary" onClick={back} type="button">
            Back
          </CanopyButton>
          <CanopyButton type="submit" loading={formik.isSubmitting}>
            Continue
          </CanopyButton>
        </div>
      </CanopyForm>
    </div>
  )
}

export default ClinicalFacilityProfileFaxNumbers
