import React from "react"
import { Employer, EmployerType } from "sharedTypes"
import ClinicalFacilityPatientPortal from "./ClinicalFacilityPatientPortal"
import SupplierPatientPortal from "./SupplierPatientPortal"
import { currentOrderId } from "applications/Workflow/urls"
import { build as buildEndpoint } from "services/patientPortal"
import {
  usePatientPortalQuery,
  useUpdatePatientPortalOrder,
  useResendFacilityInvite,
} from "applications/Workflow/api"

type Props = {
  employer: Employer
  globalFacilityEnabled: boolean
  globalSupplierEnabled: boolean
  orderStatus: string
  latestPatientPortalEventTimestamp: string
  deliveryTicketSignedAt: string
}

const PatientPortal: React.FC<Props> = (props) => {
  const {
    globalSupplierEnabled,
    globalFacilityEnabled,
    employer,
    orderStatus,
    latestPatientPortalEventTimestamp,
    deliveryTicketSignedAt,
  } = props

  const orderId = currentOrderId() as string
  const endpoint = buildEndpoint()

  const portalDataParams = {
    orderId,
    globalFacilityEnabled,
    globalSupplierEnabled,
    latestPatientPortalEventTimestamp,
  }

  const { data: portalData } = usePatientPortalQuery(portalDataParams)

  const { mutateAsync: updateFacilityEnabled } = useUpdatePatientPortalOrder(
    portalDataParams
  )
  const { mutate: resendFacilityInvite } = useResendFacilityInvite(
    portalDataParams
  )

  if (!portalData || !portalData.phoneNumber) {
    return null
  }

  const latestMessageScheduledAt = !!portalData.latestMessageScheduledAt
    ? new Date(portalData.latestMessageScheduledAt)
    : null

  const lastPatientLoginAt = !!portalData.lastPatientLoginAt
    ? new Date(portalData.lastPatientLoginAt)
    : null

  const lastFailedLoginAt = !!portalData.lastFailedLoginAt
    ? new Date(portalData.lastFailedLoginAt)
    : null

  if (
    employer.employerType === EmployerType.ClinicalFacility &&
    (latestMessageScheduledAt || globalFacilityEnabled)
  ) {
    return (
      <ClinicalFacilityPatientPortal
        facilityEnabled={portalData.facilityEnabled}
        phoneNumber={portalData.phoneNumber}
        phoneCanReceiveSms={portalData.phoneCanReceiveSms}
        latestMessageScheduledAt={latestMessageScheduledAt}
        globalFacilityEnabled={globalFacilityEnabled}
        lastAccessEventType={portalData.lastAccessEventType}
        orderStatus={orderStatus}
        updateFacilityEnabled={updateFacilityEnabled}
        resendFacilityInvite={resendFacilityInvite}
        confirmViewImgUrl={endpoint.confirmFacilityViewImgUrl(currentOrderId())}
        inviteSmsCopy={portalData.inviteSmsCopy}
        lastPatientLoginAt={lastPatientLoginAt}
        lastFailedLoginAt={lastFailedLoginAt}
        phoneNumberRejectedAt={portalData.phoneNumberRejectedAt}
        optedOutAt={portalData.optedOutAt}
        canForceInviteResend={portalData.canForceInviteResend}
        facilityInviteResentAt={portalData.facilityInviteResentAt}
        eSigningEnabled={portalData.eSigningEnabled}
        deliveryTicketSignedAt={deliveryTicketSignedAt}
      />
    )
  } else if (employer.employerType === EmployerType.Supplier) {
    return (
      <SupplierPatientPortal
        phoneNumber={portalData.phoneNumber}
        phoneCanReceiveSms={portalData.phoneCanReceiveSms}
        latestMessageScheduledAt={latestMessageScheduledAt}
        globalSupplierEnabled={globalSupplierEnabled}
        globalFacilityEnabled={globalFacilityEnabled}
        facilityEnabled={portalData.facilityEnabled}
        lastAccessEventType={portalData.lastAccessEventType}
        orderStatus={orderStatus}
        supplierControlPanelUrl={`${portalData.supplierControlPanelUrl}`}
        lastPatientLoginAt={lastPatientLoginAt}
        lastFailedLoginAt={lastFailedLoginAt}
        phoneNumberRejectedAt={portalData.phoneNumberRejectedAt}
        optedOutAt={portalData.optedOutAt}
        eSigningEnabled={portalData.eSigningEnabled}
        deliveryTicketSignedAt={deliveryTicketSignedAt}
        canForceInviteResend={portalData.canForceInviteResend}
        facilityInviteResentAt={portalData.facilityInviteResentAt}
        resendFacilityInvite={resendFacilityInvite}
      />
    )
  } else {
    return null
  }
}

export default PatientPortal
