import React, { FC } from "react"
import { Divider } from "@material-ui/core"
import { Follower, OrderStatus, Originator, SignatureStatus } from "sharedTypes"
import * as styles from "./ActionableOrderCard.module.scss"
import classNames from "classnames"
import SignatureAndDocumentStatus from "./SignatureAndDocumentStatus"
import ActionsDropdown from "./ActionsDropdown"
import { useFeatureFlags } from "components/FeatureFlagContext"

interface Props {
  signatureStatus: SignatureStatus
  orderStatus: OrderStatus
  unsatisfiedManualDocumentation: boolean
  stackChips: boolean
  actionsDropdownPermission: boolean
  modalFollowers: Follower[]
  dmeOrder: {
    externalId: string
    maxSnoozeDate: string | undefined
    minSnoozeDate: string | undefined
    originator: Originator
    patient: {
      firstName: string
      lastName: string
    }
    snooze?: {
      snoozedUntil: string
    }
  }
  syncFollowers: (params: { followerIds: string[] }) => Promise<void>
  snoozeDmeOrder: (params) => Promise<void>
  unsnoozeDmeOrder: () => Promise<void>
  cancelDmeOrder: (
    cancelReason?: string,
    cancelComment?: string
  ) => Promise<void>
  openDropdownId: string | null
  setOpenDropdownId: (id: string | null) => void
}

const BottomSection: FC<Props> = ({
  orderStatus,
  signatureStatus,
  unsatisfiedManualDocumentation,
  stackChips,
  actionsDropdownPermission,
  modalFollowers,
  dmeOrder,
  syncFollowers,
  snoozeDmeOrder,
  unsnoozeDmeOrder,
  cancelDmeOrder,
  openDropdownId,
  setOpenDropdownId,
}) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const actionsDropdownEnabled = isFeatureEnabled(
    "facilityDashboardOrderActions"
  )

  const showSignatureStatus =
    signatureStatus === SignatureStatus.Needed ||
    signatureStatus === SignatureStatus.Pending ||
    signatureStatus === SignatureStatus.Unknown

  const showStatuses =
    (showSignatureStatus || unsatisfiedManualDocumentation) &&
    orderStatus !== OrderStatus.FacilityInProgress

  const showActionsDropdown =
    orderStatus === OrderStatus.FacilityInProgress &&
    actionsDropdownEnabled &&
    actionsDropdownPermission

  if (!showStatuses && !showActionsDropdown) return null

  return (
    <>
      <Divider className="canopy-my-8x" />
      <div className={classNames(styles.bottomSection)}>
        {showStatuses && (
          <SignatureAndDocumentStatus
            signatureStatus={signatureStatus}
            unsatisfiedManualDocumentation={unsatisfiedManualDocumentation}
            stackChips={stackChips}
          />
        )}
        {showActionsDropdown && (
          <ActionsDropdown
            cancelDmeOrder={cancelDmeOrder}
            syncFollowers={syncFollowers}
            modalFollowers={modalFollowers}
            dmeOrder={dmeOrder}
            snoozeDmeOrder={snoozeDmeOrder}
            unsnoozeDmeOrder={unsnoozeDmeOrder}
            openDropdownId={openDropdownId}
            setOpenDropdownId={setOpenDropdownId}
          />
        )}
      </div>
    </>
  )
}

export default BottomSection
