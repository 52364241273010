import React, { useEffect, useState } from "react"
import { actionColumnPadding } from "../../index.module.scss"
import { capitalize } from "utilities/string"
import { Link } from "react-router-dom"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { usePatientQuery } from "../../queries"
import {
  searchOrders,
  DmeOrder,
  ExternalOrder,
  DocumentationOrder,
} from "./api"
import { handleError } from "utilities/error"
import { useCsrInboxState } from "../../csrInboxStateContext"
import { ROUTES } from "../../routes"
import { renderOrderCard } from "./OrderCard"
import IndeterminateLoader from "components/IndeterminateLoader"
import { hasCompleteDemographics } from "../../utilities"

export const OrderHistory = () => {
  const { data: patient } = usePatientQuery()
  const [dmeOrders, setDmeOrders] = useState<DmeOrder[]>([])
  const [externalOrders, setExternalOrders] = useState<ExternalOrder[]>([])
  const [documentationOrders, setDocumentationOrders] = useState<
    DocumentationOrder[]
  >([])
  const [isLoading, setIsLoading] = useState(true)

  const {
    supplierOrganization,
    supplier,
    id: csrInboxStateId,
  } = useCsrInboxState()

  useEffect(() => {
    if (!patient?.masterPatientId && !patient?.supplierSystemPatientId) {
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    searchOrders({
      supplierOrganizationId: supplierOrganization.id,
      supplierId: supplier.id,
      masterPatientId: patient.masterPatientId,
      supplierSystemPatientId: patient.supplierSystemPatientId,
    })
      .then((response) => {
        setDmeOrders(response.dmeOrders)
        setExternalOrders(response.externalOrders)
        setDocumentationOrders(response.documentationOrders)
      })
      .catch(handleError)
      .finally(() => setIsLoading(false))
  }, [
    patient?.masterPatientId,
    patient?.supplierSystemPatientId,
    supplierOrganization.id,
    supplier.id,
  ])

  const sortedOrders = [
    ...dmeOrders,
    ...externalOrders,
    ...documentationOrders,
  ].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  })

  const NUMBER_OF_EXTERNAL_ORDERS_TO_PRE_FETCH = 2
  let indexOfExternalOrder = 0
  const preFetchItems = (order) => {
    if ("supplierSystemId" in order) {
      indexOfExternalOrder++
    }
    return indexOfExternalOrder <= NUMBER_OF_EXTERNAL_ORDERS_TO_PRE_FETCH
  }

  if (!patient) return null
  return (
    <div className={actionColumnPadding}>
      <Link role="link" to={ROUTES.PATIENT_SEARCH}>
        <CanopyIcon name="arrow-left" />
        Back
      </Link>
      <div>
        <span className="canopy-typography-heading-medium">
          {patient.firstName} {patient.lastName}
        </span>{" "}
        {capitalize(patient.sex)}
      </div>
      <div>DOB: {patient.dateOfBirth}</div>

      <div className="canopy-mt-12x">
        <div className="float-right">
          <CanopyButton
            as={Link}
            to={
              hasCompleteDemographics(patient)
                ? ROUTES.NEW_ORDER
                : ROUTES.COMPLETE_PATIENT
            }
            variant="primary"
            iconStart="plus"
            size="small"
          >
            New Order
          </CanopyButton>
        </div>
        <h3 className="canopy-typography-heading-medium canopy-mb-12x">
          Order history
          {!isLoading &&
            ` (${
              dmeOrders.length +
              externalOrders.length +
              documentationOrders.length
            })`}
        </h3>
        <div role="list" aria-label="Patient search results">
          {sortedOrders.map((order) =>
            renderOrderCard({
              order,
              preFetchItems: preFetchItems(order),
              csrInboxStateId,
              supplierOrganizationId: supplierOrganization.id,
              supplierId: supplier.id,
            })
          )}
        </div>
        {isLoading && (
          <div className="canopy-mt-12x">
            <IndeterminateLoader />
          </div>
        )}
      </div>
    </div>
  )
}
