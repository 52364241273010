import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import React, { useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { getDiagnoses, updateDiagnoses } from "./api"
import { useCsrInboxState } from "../../../csrInboxStateContext"
import { useDebounce } from "hooks/useDebounce"
import { MAX_DIAGNOSES } from "applications/Workflow/containers/Icd10Codes/utilities/validation"
import { reportError } from "utilities/error"
import { Diagnosis } from "./api"
import { SectionHeader, SubSectionHeader } from "../SectionHeader"

type ExtendedOptionItem = OptionItem & {
  chipText?: string
  description: string
}

const formatDiagnosis = (diagnosis: Diagnosis): ExtendedOptionItem => {
  const maxDescriptionLength = 17
  const truncatedDescription =
    diagnosis.description.length > maxDescriptionLength
      ? diagnosis.description.slice(0, maxDescriptionLength) + "..."
      : diagnosis.description
  return {
    label: diagnosis.codeLabel,
    description: diagnosis.description,
    value: diagnosis.codeId,
    chipText: diagnosis.codeLabel + " " + truncatedDescription,
  }
}
export const Diagnoses = (props: { initialSelectedDiagnoses: Diagnosis[] }) => {
  const csrInboxState = useCsrInboxState()
  const [selectedDiagnoses, setSelectedDiagnoses] = useState<
    ExtendedOptionItem[]
  >(props.initialSelectedDiagnoses.map(formatDiagnosis))

  const [searchTerm, setSearchTerm] = useState<string>("")

  const debouncedGetDiagnoses = useDebounce(getDiagnoses, 200)
  const { data: searchResults } = useQuery({
    queryKey: [
      csrInboxState.id,
      csrInboxState.supplierOrganization.id,
      "diagnoses",
      searchTerm,
    ],
    queryFn: async () => {
      if (searchTerm.length < 1) {
        return []
      }
      return debouncedGetDiagnoses(
        csrInboxState.id,
        csrInboxState.supplierOrganization.id,
        searchTerm
      ).then((res) => {
        return res.map(formatDiagnosis)
      })
    },
  })
  const mutateDiagnoses = useMutation({
    mutationFn: async (selectedDiagnoses: OptionItem[]) => {
      return updateDiagnoses(
        csrInboxState.id,
        csrInboxState.supplierOrganization.id,
        selectedDiagnoses.map((s) => ({ codeId: s.value }))
      )
    },
  })

  const onChange = (selectedDiagnoses: OptionItem | OptionItem[] | null) => {
    const itIsNullWhichMeansAUserClearedTheirTypedTextAndTheComboboxSentNullWhichIsABugInComboboxAndWeShouldDoNothing =
      selectedDiagnoses === null
    if (
      itIsNullWhichMeansAUserClearedTheirTypedTextAndTheComboboxSentNullWhichIsABugInComboboxAndWeShouldDoNothing
    ) {
    } else if (!Array.isArray(selectedDiagnoses)) {
      reportError(
        new Error(
          "Somehow the value of selectedDiagnoses is not an array in Diagnoses component which shouldn't be possible because the combobox is multiple"
        )
      )
    } else {
      setSelectedDiagnoses(selectedDiagnoses as ExtendedOptionItem[])
      mutateDiagnoses.mutateAsync(selectedDiagnoses as ExtendedOptionItem[])
    }
  }

  const onInputChange = (newValue: string) => {
    setSearchTerm((oldValue) => {
      if (newValue === "") {
        return oldValue
      }
      return newValue
    })
  }

  const tooManyDiagnoses = selectedDiagnoses.length >= MAX_DIAGNOSES

  return (
    <>
      <SectionHeader
        headerText="Order details"
        subheaderText="Look over the clinician information and product details for this order."
      />
      <SubSectionHeader text="Clinicial information" />
      <CanopyComboboxField
        label="Diagnosis codes"
        options={(!tooManyDiagnoses && searchResults) || []}
        onInputChange={onInputChange}
        optionSelectedFormatFunction={(option: OptionItem | null) =>
          option ? (option as ExtendedOptionItem).chipText || option.value : ""
        }
        optionRenderFunction={({ option }) => (
          <div className="canopy-pis-2x">
            <strong>{option.label}</strong> {option.description}{" "}
          </div>
        )}
        onChange={onChange}
        multiple={true}
        value={selectedDiagnoses}
        noOptionsFoundMessage={
          tooManyDiagnoses
            ? `Maximum of ${MAX_DIAGNOSES} diagnoses allowed. Remove a code to add more.`
            : undefined
        }
        // TODO cleanup conditional placeholder when canopy fixes bug to not show in multiple Combobox
        placeholder={
          !selectedDiagnoses.length
            ? "Search and select by ICD-10 code or description"
            : ""
        }
      />
    </>
  )
}
