import {
  PackageConfiguration,
  PackageConfigurationStep,
  CustomAttributeWithOptions,
  ProductCustomAttributeOption,
  ProductWithCustomAttributes,
} from "../sharedTypes"
import { isRxQuantityIncomplete } from "./rxQuantity"

const allAttributesConfigured = (packageConfiguration) => {
  const {
    requiredProductCustomAttributes,
    optionalProductCustomAttributes,
    catalogCustomAttributeOptionIds,
    productCustomAttributeOptions: productCustomAttributeOptions,
    packageRelationshipsEnabled,
  } = packageConfiguration

  if (packageRelationshipsEnabled) {
    const totalRequiredLength = requiredProductCustomAttributes.reduce(
      (sum, obj) => sum + obj.customAttributes.length,
      0
    )

    const totalOptionalLength = optionalProductCustomAttributes.reduce(
      (sum, obj) => sum + obj.customAttributes.length,
      0
    )

    const numberOfProductAttributes = totalRequiredLength + totalOptionalLength
    return numberOfProductAttributes === productCustomAttributeOptions.length
  } else {
    const numberOfAttributes =
      requiredProductCustomAttributes.length +
      optionalProductCustomAttributes.length
    return numberOfAttributes === catalogCustomAttributeOptionIds.length
  }
}

// helper to check that for every required product custom attribute
// there is a selection existing matching by product id, attribute id and option id
const hasPcaoSelection = (
  productCustomAttributeOptions: ProductCustomAttributeOption[]
) => (productCustomAttribute: ProductWithCustomAttributes) => {
  const productId = productCustomAttribute.id
  return productCustomAttribute.customAttributes.every(({ id, options }) =>
    productCustomAttributeOptions.find(
      (pcao) =>
        pcao.catalogProductId === productId &&
        pcao.catalogCustomAttributeId === id &&
        options.some(({ id }) => pcao.catalogCustomAttributeOptionId === id)
    )
  )
}

const hasSelection = (selectedIds: number[]) => (
  customAttribute: CustomAttributeWithOptions
) => {
  return customAttribute.options.some(({ id }) => selectedIds.includes(id))
}
const requiredAttributesConfigured = (packageConfiguration) => {
  const {
    requiredProductCustomAttributes,
    catalogCustomAttributeOptionIds,
    productCustomAttributeOptions,
    packageRelationshipsEnabled,
  } = packageConfiguration

  if (packageRelationshipsEnabled) {
    return requiredProductCustomAttributes.every(
      hasPcaoSelection(productCustomAttributeOptions)
    )
  } else {
    return requiredProductCustomAttributes.every(
      hasSelection(catalogCustomAttributeOptionIds)
    )
  }
}

export const isSupplierIncomplete = (packageConfiguration): boolean => {
  const { supplier } = packageConfiguration
  return !supplier
}

export const isConfigurationIncomplete = (packageConfiguration) => {
  const { accessoryRequirementsSatisfied } = packageConfiguration
  return !configured(packageConfiguration) || !accessoryRequirementsSatisfied
}

const isProductRequirementsIncomplete = (
  packageConfiguration: PackageConfiguration
) => {
  const {
    requiresHeight,
    requiresWeight,
    requiresWoundCount,
    heightInches,
    weightLbs,
    numberOfWounds,
  } = packageConfiguration

  if (requiresHeight && !isNumber(heightInches)) {
    return true
  }
  if (requiresWeight && !isNumber(weightLbs)) {
    return true
  }
  if (requiresWoundCount && !isNumber(numberOfWounds)) {
    return true
  }
  return false
}

const isNumber = (value) => value !== null && isFinite(value)

const configured = (packageConfiguration) => {
  const { supplier, deliveryMethod, fulfillable } = packageConfiguration
  return (
    supplier &&
    deliveryMethod &&
    fulfillable &&
    allAttributesConfigured(packageConfiguration)
  )
}

export const showAccessories = (packageConfiguration): boolean => {
  const { supplier, deliveryMethod, fulfillable } = packageConfiguration
  return (
    supplier &&
    deliveryMethod &&
    fulfillable &&
    requiredAttributesConfigured(packageConfiguration)
  )
}

const isConfigurationStep = (packageConfiguration, cantSkip): boolean => {
  return isConfigurationIncomplete(packageConfiguration) || cantSkip
}

const isRxDetailsStep = (packageConfiguration, cantSkip): boolean => {
  return (
    !packageConfiguration.rxDetails.isComplete ||
    (packageConfiguration.rxDetails.hasQuestions && cantSkip)
  )
}

const isRxQuantityRequired = (packageConfiguration: PackageConfiguration) => {
  return packageConfiguration.dmeOrderLineItemGroup.lineItems.some(
    (lineItem) => lineItem.prescribedQuantityRequired
  )
}

const isRxQuantityStep = (
  packageConfiguration: PackageConfiguration
): boolean => {
  if (!isRxQuantityRequired(packageConfiguration)) return false

  return isRxQuantityIncomplete(packageConfiguration)
}

// All potential steps, in order, excluding the default/last SummaryStep
// `if` is a function that receives the packageConfiguration and a boolean
// indicating whether the step must be may be skipped
// and returns a boolean indicating whether the step should be shown
const stepConditions = [
  { step: PackageConfigurationStep.SupplierStep, if: isSupplierIncomplete },
  { step: PackageConfigurationStep.ConfigurationStep, if: isConfigurationStep },
  {
    step: PackageConfigurationStep.RequirementsStep,
    if: isProductRequirementsIncomplete,
  },
  { step: PackageConfigurationStep.RxQuantityStep, if: isRxQuantityStep },
  { step: PackageConfigurationStep.RxDetailsStep, if: isRxDetailsStep },
]

export const calculateStep = (
  packageConfiguration: PackageConfiguration,
  cantSkip: PackageConfigurationStep[]
) => {
  const currentStep = stepConditions.find(({ step, if: condition }) => {
    return condition(packageConfiguration, cantSkip.includes(step))
  })

  return currentStep?.step || PackageConfigurationStep.SummaryStep
}
