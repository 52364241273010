import React from "react"
import { pluralize } from "utilities/string"
import { Follower } from "sharedTypes"
import { CanopyButton } from "@parachutehealth/canopy-button"

type Props = {
  followers: Follower[]
  onClick(): void
}

export function followersText(count) {
  return `${count} ${pluralize("Follower", count)}`
}

function FollowersButton({ followers, onClick }: Props) {
  return (
    <CanopyButton fullWidth onClick={onClick} variant="secondary">
      {followersText(followers.length)}
    </CanopyButton>
  )
}

export default FollowersButton
