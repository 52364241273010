import React from "react"
import { ChartNote } from "sharedTypes"
import { EventCategory, trackEvent } from "utilities/tracking"
import { getTrackingStatus } from "./utilities"
import { Link } from "react-router-dom"
import { CanopyButton } from "@parachutehealth/canopy-button"

type Props = {
  chartNotes: ChartNote[]
  bounceToSection?: {
    shouldBounce: boolean
    triggerBounce: () => void
  }
  pathToFirstIncompleteDocumentation: string
}
const EVENT_PREFIX = "documentation-requirement-build"

const GenerateButton = ({
  chartNotes,
  bounceToSection,
  pathToFirstIncompleteDocumentation,
}: Props) => {
  const { shouldBounce, triggerBounce } = bounceToSection || {
    triggerBounce: () => {},
    shouldBounce: false,
  }

  const trackEventSuffix = getTrackingStatus(
    chartNotes.map((chartNote) => chartNote.status)
  )

  const clickTrackEvent = () => {
    trackEventSuffix &&
      trackEvent(
        EventCategory.Activation,
        `${EVENT_PREFIX}-${trackEventSuffix}`
      )
  }

  if (shouldBounce) {
    return (
      <CanopyButton
        onClick={() => triggerBounce()}
        style={{ inlineSize: "100%" }}
      >
        Build documents with Parachute
      </CanopyButton>
    )
  }
  return (
    <CanopyButton
      as={Link}
      onClick={() => clickTrackEvent()}
      style={{ inlineSize: "100%" }}
      to={pathToFirstIncompleteDocumentation}
    >
      Build documents with Parachute
    </CanopyButton>
  )
}

export default GenerateButton
