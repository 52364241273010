import { get, post, put } from "services/api"
import {
  reviewDataUrl,
  createSubmissionUrl,
  submissionStatusUrl,
  createAcceptanceUrl,
  acceptanceStatusUrl,
  duplicateOrderUrl,
  pullBackUrl,
  supplierEditUrl,
  supplierUnacceptUrl,
  signatureUrl,
  signatureRequestUrl,
  createSignedDeliveryTicketUrl,
  updateFulfillmentUrl,
  updateSupplierLegalEntityUrl,
  updateSupplierFulfillmentUrl,
  updateSupplierSystemClinicalFacilityIdUrl,
  updateClinicalFacilityUrl,
  createSupplierTransferUrl,
  archiveUrl,
  createReviewerEmploymentUrl,
  updateSignatureStatusUrl,
  updateSalesRepUrl,
  searchSalesRepsUrl,
} from "applications/Workflow/urls"
import {
  CreateSupplierTransferAttributes,
  UpdateClinicalFacilityAttributes,
} from "./sharedTypes"
import { SalesRep } from "sharedTypes"

export const getReviewData = () => get(reviewDataUrl())
export const createSubmission = () => put(createSubmissionUrl())
export const getSubmissionStatus = () => get(submissionStatusUrl())
export const createAcceptance = () => put(createAcceptanceUrl())
export const getAcceptanceStatus = () => get(acceptanceStatusUrl())
export const pullBack = () => post(pullBackUrl())
export const archive = () => post(archiveUrl())
export const createSignature = () => post(signatureUrl())
export const sendSignatureRequest = (params) =>
  post(signatureRequestUrl(), params)
export const updateSignatureStatus = () => put(updateSignatureStatusUrl())
export const supplierEdit = () => post(supplierEditUrl())
export const supplierUnaccept = () => post(supplierUnacceptUrl())
export const createSignedDeliveryTicket = (params) =>
  put(createSignedDeliveryTicketUrl(), params)
export const updateSupplierLegalEntity = (params) =>
  put(updateSupplierLegalEntityUrl(), params)
export const updateFulfillment = (fulfillmentId, values) => {
  return put(updateFulfillmentUrl(fulfillmentId), {
    dmeOrderFulfillment: values,
  })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
}
export const updateSupplierFulfillment = (fulfillmentId, supplierSystemId) => {
  return put(updateSupplierFulfillmentUrl(fulfillmentId), {
    supplierFulfillment: { supplierSystemId },
  }).then(({ data }) => data)
}
export const updateSupplierSystemClinicalFacilityId = (
  clinicalFacilityId: string,
  supplierSystemId: string
) => {
  return put(updateSupplierSystemClinicalFacilityIdUrl(clinicalFacilityId), {
    supplierSystemClinicalFacility: { supplierSystemId },
  }).catch(({ response }) => Promise.reject(response.data))
}

export const updateClinicalFacility = (
  data: UpdateClinicalFacilityAttributes
) => {
  return put(updateClinicalFacilityUrl(), data).then(({ data }) => data)
}

export const createSupplierTransfer = (
  data: CreateSupplierTransferAttributes
) => post(createSupplierTransferUrl(), data).then(({ data }) => data)

export const createReviewerEmployment = (doctorId: string) => {
  return post(createReviewerEmploymentUrl(), { doctorId: doctorId }).then(
    ({ data }) => data
  )
}

export const updateSalesRep = (params: { externalId: string }) => {
  return post(updateSalesRepUrl(), {
    salesRep: params,
  }).then(({ data }) => data)
}

export const searchSalesReps = (term: string): Promise<SalesRep[]> => {
  return get(searchSalesRepsUrl(), {
    term,
    allPotentialSalesReps: true,
  }).then(({ data }) => data)
}

export const duplicateOrder = (dmeOrderId: string) => {
  return post(duplicateOrderUrl(dmeOrderId))
}
