import React, { useCallback, useState, FC } from "react"
import Popover from "components/Popover"
import { CanopyButton } from "@parachutehealth/canopy-button"
import SupplierDetails from "./SupplierDetails"
import { DmeOrder } from "sharedTypes"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { primaryPolicyFor } from "applications/Workflow/utilities/payment"

interface ContactInfoProps {
  dmeOrder: DmeOrder
}

const ContactInfo: FC<ContactInfoProps> = ({ dmeOrder }) => {
  const [open, setOpen] = useState(false)

  const togglePopover = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])

  const { isFeatureEnabled } = useFeatureFlags()
  const primaryInsurancePolicy = primaryPolicyFor(dmeOrder)
  const { lineItemGroups } = dmeOrder
  if (lineItemGroups.length === 0) {
    return null
  }

  const buttonText = () => {
    if (
      isFeatureEnabled("payorOrderDetails") &&
      primaryInsurancePolicy?.payorEnhancedWorkflowEnabled
    ) {
      return "Supplier contact info"
    } else {
      return "Contact info"
    }
  }

  return (
    <Popover
      open={open}
      onClick={togglePopover}
      label={
        <CanopyButton
          variant="secondary"
          size="small"
          className="canopy-mbe-4x"
        >
          {buttonText()}
        </CanopyButton>
      }
    >
      {open && <SupplierDetails />}
    </Popover>
  )
}

export default ContactInfo
