import { ServerSideDataGridParams } from "../../../../../../hooks/useServerSideDataGrid"
import { get } from "../../../../../../../../services/api"

type BaseProductSkusDataGridRecord = {
  id: number
  externalId: string
  description: string
  manufacturerId: string | null
  hcpcs: string | null
  archivedAt: string | null
  status: "Live" | "Archived" // generated by backend SQL query
  quantity: number
  supplierCount: number
}

// allow a base set of known keys and types but allow arbitrary extension to account for synthetic column names
type Overwrite<Base, Overrides> = Omit<Base, keyof Overrides> & Overrides
export type ProductSkusDataGridRecord = Overwrite<
  Record<keyof BaseProductSkusDataGridRecord, string>,
  BaseProductSkusDataGridRecord
>

export type ProductSkusDataGridResponse = {
  records: ProductSkusDataGridRecord[]
  totalCount: number
}

export const getSkusForProduct = async (
  productId: string,
  params?: ServerSideDataGridParams
): Promise<ProductSkusDataGridResponse> => {
  return await get(`/cms/catalog/products/${productId}/skus.json`, params).then(
    (response) => response.data
  )
}
