import { EmploymentRolesEnum } from "graphql/__generated__/graphql"
import { EmployerClassEnum } from "sharedTypes"
import { isNullOrUndefined } from "utilities/isNullOrUndefined"

export const calculateAccessByEmployer = (
  employer: string | undefined,
  role: string | undefined,
  isAuthSubmitted: boolean,
  authEditsEnabled: boolean
): boolean => {
  switch (employer) {
    case EmployerClassEnum.Supplier:
      return authEditsEnabled ? true : !isAuthSubmitted
    case EmployerClassEnum.InsurancePayor:
      return (
        isAuthSubmitted &&
        role !== EmploymentRolesEnum.ViewOnly &&
        !isNullOrUndefined(role)
      )
    default:
      return false
  }
}
