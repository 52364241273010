import React from "react"
import { Form, Checkbox } from "components/form"
import Button from "components/form/Button"
import Alert from "components/Alert"
import Icon from "components/Icon"
import { handleError } from "utilities/error"
import Tooltip from "components/Tooltip"
import { EmploymentMention, Comment, CommentType } from "sharedTypes"
import CommentField from "./CommentField"
import CSSTransition from "react-transition-group/CSSTransition"

interface Props {
  onSubmit(values: Comment): Promise<void>
  onCancel(): void
  disabled?: boolean
  getEmploymentMentions(
    term: string,
    commentType: CommentType
  ): Promise<EmploymentMention[]>
  payorActivityFeedInclusion: boolean
}
export const INTERNAL_COMMENT_PLACEHOLDER_TEXT =
  "Type an internal comment, make a note to yourself, or use @ to mention someone"
function InternalCommentForm({
  onSubmit,
  onCancel,
  disabled,
  getEmploymentMentions,
  payorActivityFeedInclusion,
}: Props) {
  const submit = (values: Comment) => {
    return onSubmit(values).then(onCancel).catch(handleError)
  }

  return (
    <CSSTransition
      in
      appear
      classNames="internal-comment-form"
      timeout={{ appear: 250 }}
    >
      <Form initialValues={{ content: "" }} onSubmit={submit}>
        {({ isSubmitting, submitForm, values }) => (
          <>
            <CommentField
              autoFocus
              fetch={(term) =>
                getEmploymentMentions(term, CommentType.Internal)
              }
              placeholder={INTERNAL_COMMENT_PLACEHOLDER_TEXT}
              disabled={isSubmitting}
              submit={submitForm}
              alert={
                <Alert leftIcon="fa-eye-slash" status="textured">
                  Not visible to referral
                  {payorActivityFeedInclusion && " or payor"}. Do not post
                  credit card info.
                </Alert>
              }
            />

            <div className="clearfix">
              <Tooltip
                triggerElementClassName="d-inline-block float-right"
                trigger={disabled ? ["click", "hover"] : []}
                overlay="Internal comment cannot be posted when filtered out of the view below."
                placement={Tooltip.Placement.Top}
              >
                <Button
                  type="submit"
                  className="btn-info canopy-px-8x"
                  disabled={isSubmitting || !values.content || disabled}
                  analyticsTag="post-internal-comment"
                >
                  <Icon
                    className="canopy-mie-4x"
                    type={isSubmitting ? "spinner" : "book"}
                    spin={isSubmitting}
                  />
                  Post Internal Comment
                </Button>
              </Tooltip>
              <Button
                className="btn-link float-right"
                onClick={onCancel}
                disabled={isSubmitting}
                analyticsTag="cancel-internal-comment"
              >
                Cancel
              </Button>
            </div>
            <p className="text-right canopy-mbs-4x color-dark-gray font-notice">
              or press 'Ctrl+Enter' to post
            </p>
            {values.content && (
              <Checkbox
                label="Do not notify anyone"
                name="skipNotifications"
                type={0}
                primary
              />
            )}
          </>
        )}
      </Form>
    </CSSTransition>
  )
}

export default InternalCommentForm
