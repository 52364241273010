import React from "react"
import { useParams } from "react-router-dom"
import { useGetSurveyQuestions } from "../api"
import { QualificationStatus, WorkflowStep } from "../sharedTypes"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { LEGAL_WARNING } from "applications/Workflow/containers/ClinicalFacilityClinical/utilities"
import * as styles from "./MedicalNecessityStatus.module.scss"

const MedicalNecessityStatus: React.FC = () => {
  const { pendingOrderId, therapySelectionId } = useParams()

  const {
    data: {
      percentageComplete = 0,
      qualificationStatus = QualificationStatus.NotStarted,
      disqualifiedMessage = "",
    } = {
      percentageComplete: 0,
      qualificationStatus: QualificationStatus.NotStarted,
      disqualifiedMessage: "",
    },
  } = useGetSurveyQuestions(
    pendingOrderId,
    therapySelectionId,
    WorkflowStep.MedicalNecessity
  )

  if (
    percentageComplete !== 100 &&
    qualificationStatus !== QualificationStatus.Disqualified
  ) {
    return null
  }

  const renderStatus = () => {
    switch (qualificationStatus) {
      case QualificationStatus.Qualified:
        return (
          <CanopyNotice
            className={styles.statusContainer}
            title="Documentation completed."
            // @ts-ignore
            message={
              <span>
                <br />
                <em>{LEGAL_WARNING}</em>
              </span>
            }
            // @ts-ignore
            variant="success"
          />
        )
      case QualificationStatus.Disqualified:
        return (
          <CanopyNotice
            className={styles.statusContainer}
            title="Documentation not supported."
            // @ts-ignore
            message={
              <span>
                {disqualifiedMessage}
                <br />
                <br />
                <em>{LEGAL_WARNING}</em>
              </span>
            }
            // @ts-ignore
            variant="error"
          />
        )
      default:
        return null
    }
  }

  return renderStatus()
}

export default MedicalNecessityStatus
