import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import { CanopyForm } from "@parachutehealth/canopy-form"
import React from "react"
import { Formik, FormikValues } from "formik"
import {
  CarrierLineItemAuthorization,
  CarrierLineItemAuthorizationUpdateMutationVariables,
  CarrierLineItemAuthorizationStatusEnum,
} from "../../../../../graphql/__generated__/graphql"
import * as styles from "./PayorLineItemEditForm.module.scss"
import { capitalize } from "../../../../../utilities/string"
import classNames from "classnames"

interface LineItemEditFormProps {
  onCancel: () => void
  onSubmit: (
    updatedFields: CarrierLineItemAuthorizationUpdateMutationVariables
  ) => void
  lineItem: CarrierLineItemAuthorization
}

const STATUS_OPTIONS = [
  CarrierLineItemAuthorizationStatusEnum.PayorInReview,
  CarrierLineItemAuthorizationStatusEnum.Approved,
  CarrierLineItemAuthorizationStatusEnum.Cancelled,
  CarrierLineItemAuthorizationStatusEnum.Denied,
  CarrierLineItemAuthorizationStatusEnum.NoAdmit,
  CarrierLineItemAuthorizationStatusEnum.PartiallyApproved,
].reduce((arr: { label: string; value: string }[], currentValue) => {
  if (currentValue === CarrierLineItemAuthorizationStatusEnum.PayorInReview) {
    arr.unshift({
      label: "Pending payor review",
      value: currentValue,
    })
  } else {
    arr.push({
      label: capitalize(currentValue.split("_").join(" ")),
      value: currentValue,
    })
  }
  return arr
}, [])

export const PayorLineItemEditForm = ({
  onCancel,
  onSubmit,
  lineItem,
}: LineItemEditFormProps) => {
  const initialValues = {
    decisionStatus: lineItem.decisionStatus,
    priorAuthorizationNumber: lineItem.priorAuthorizationNumber || undefined,
  }

  const validate = (values) => {
    const errors = {}
    if (!values.decisionStatus) {
      errors["decisionStatus"] = "Decision status required"
    }

    if (
      (values.decisionStatus ===
        CarrierLineItemAuthorizationStatusEnum.Approved ||
        values.decisionStatus ===
          CarrierLineItemAuthorizationStatusEnum.PartiallyApproved) &&
      !values.priorAuthorizationNumber
    ) {
      errors["priorAuthorizationNumber"] = "Prior auth number required"
    }

    return errors
  }

  const handleOnSubmit = async (values: FormikValues) => {
    const valuesToSubmit = {
      id: lineItem.id,
      decisionStatus: values.decisionStatus,
      priorAuthorizationNumber: values.priorAuthorizationNumber,
    }

    onSubmit(valuesToSubmit)
  }

  return (
    <>
      <div className="canopy-typography-body-medium canopy-mb-12x">
        <span className={styles.redText}>*</span> Indicates a required field
      </div>
      <Formik
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validate={validate}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => {
          return (
            <CanopyForm id={lineItem.id} onSubmit={handleSubmit}>
              <CanopyRadioInputField
                label="Status"
                description="Visible to supplier, clinical facility, and patient"
                name="decisionStatus"
                options={STATUS_OPTIONS}
                value={
                  values.decisionStatus ? values.decisionStatus : undefined
                }
                onChange={handleChange}
                className={classNames(styles.radioInput, "canopy-mb-12x")}
                required
                feedbackMessage={
                  touched.decisionStatus
                    ? (errors.decisionStatus as string)
                    : undefined
                }
              />
              {(values.decisionStatus ===
                CarrierLineItemAuthorizationStatusEnum.Approved ||
                values.decisionStatus ===
                  CarrierLineItemAuthorizationStatusEnum.PartiallyApproved) && (
                <CanopyTextInputField
                  name="priorAuthorizationNumber"
                  label="Prior auth number"
                  required
                  value={values.priorAuthorizationNumber}
                  onChange={handleChange}
                  feedbackMessage={
                    touched.priorAuthorizationNumber
                      ? (errors.priorAuthorizationNumber as string)
                      : undefined
                  }
                />
              )}

              <div className={styles.formButtonSection}>
                <CanopyButton variant="primary" type="submit">
                  Save
                </CanopyButton>
                <CanopyButton variant="tertiary" onClick={() => onCancel()}>
                  Cancel
                </CanopyButton>
              </div>
            </CanopyForm>
          )
        }}
      </Formik>
    </>
  )
}
