// @team @cat-crew

import { get } from "../../../services/api"
import { packagingLabelsUrl } from "../urls/packagingLabels"
import { ServerSideDataGridParams } from "../hooks/useServerSideDataGrid"
import { CatalogProduct, CatalogPackagingLabel } from "../types/sharedTypes"
import { ApiResponse } from "./utilities"

type ProductsForPackagingLabelResponse = {
  products: CatalogProduct[]
}

export type CatalogPackagingLabelParams = ServerSideDataGridParams
export type PackagingLabelsResponse = {
  packagingLabels: CatalogPackagingLabel[]
  totalCount: number
}

export const getAllPackagingLabels = async (
  params?: CatalogPackagingLabelParams
): Promise<PackagingLabelsResponse> => {
  return await get(packagingLabelsUrl("json"), params)
    .then((response) => response.data)
    .catch((data) => data.response.data)
}

export const getProductsForPackagingLabel = async (
  packagingLabelId: string | number
): Promise<ApiResponse<ProductsForPackagingLabelResponse>> => {
  return await get(
    `/cms/catalog/packaging_labels/${packagingLabelId}/products.json`
  )
    .then((response) => response.data)
    .catch((error) => error.response.data)
}
