import React from "react"
import ParachuteMuiDataGridPro from "components/ParachuteMuiDataGrid/ParachuteMuiDataGrid"
import { GridColDef, GridRowData } from "@mui/x-data-grid-pro"
import {
  DmeOrderConnection,
  OrdersDashboard_GetPayorDmeOrdersQuery,
} from "../../../../../../graphql/__generated__/graphql"
import { isTest } from "../../../../../../utilities/environment"
import DataGridToolbar from "../../../../../Cms/components/DataGridToolbar"
import { useGraphqDataGrid } from "hooks/useGraphqlDataGrid/useGraphqlDataGrid"
import { ApolloQueryResult, FetchMoreOptions } from "@apollo/client/core"
import { DataGridOptions } from "sharedTypes"
import { makeStyles } from "@material-ui/styles"
import { DataGridColumnMenu } from "./DataGridColumnMenu/DataGridColumnMenu"

interface Props {
  initialColumnDefs: GridColDef[]
  pageSize: number
  dmeOrderData: DmeOrderConnection | undefined
  loading: boolean
  paginate: (args: FetchMoreOptions) => void
  refetch: (
    variables?
  ) => Promise<ApolloQueryResult<OrdersDashboard_GetPayorDmeOrdersQuery>>
  initialOptions: Partial<DataGridOptions>
}

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnSeparator": {
      display: "flex",
    },
  },
})

export const OrdersDashboardTable = ({
  dmeOrderData,
  refetch,
  paginate,
  initialColumnDefs,
  pageSize,
  loading,
  initialOptions,
}: Props) => {
  const { nodes, pageInfo, totalCount } = dmeOrderData || {}
  const dmeOrders: Readonly<GridRowData[]> = nodes
    ? (nodes as GridRowData[])
    : []

  const {
    handlePagination,
    handleSorting,
    handleColumnVisibilityChange,
    gridOptions,
    gridColumnDefs,
  } = useGraphqDataGrid(
    refetch,
    paginate,
    pageInfo,
    pageSize,
    initialOptions,
    initialColumnDefs
  )

  const classes = useStyles()

  return (
    <ParachuteMuiDataGridPro
      autoHeight
      columns={gridColumnDefs}
      onColumnVisibilityChange={handleColumnVisibilityChange}
      components={{
        Toolbar: DataGridToolbar,
        ColumnMenu: (props) => (
          <DataGridColumnMenu disableFilterAction={true} {...props} />
        ),
      }}
      className={classes.root}
      disableSelectionOnClick={true}
      disableVirtualization={isTest()}
      getRowId={(row) => row.id}
      loading={loading}
      onPageChange={async (page) => await handlePagination(page)}
      page={gridOptions.page}
      pageSize={pageSize}
      pagination
      paginationMode="server"
      rowCount={totalCount}
      rows={dmeOrders}
      rowsPerPageOptions={[pageSize]}
      sortingMode="server"
      sortModel={gridOptions.sort}
      onSortModelChange={async (sortModel) => await handleSorting(sortModel)}
    ></ParachuteMuiDataGridPro>
  )
}
