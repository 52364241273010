import { downloadALink } from "./downloadALink"
import { Failure, Success } from "components/AsyncButton"
import { useSessionBackedState } from "../../hooks/useSessionBackedState/useSessionBackedState"
import { getDownloadLink } from "./getDownloadLink"
import {
  CompleteResponse,
  FailedResponse,
  GetDownloadLinkResponse,
} from "./asyncDownload.types"
import { useEffect, useState } from "react"

function isComplete(res: GetDownloadLinkResponse): res is CompleteResponse {
  return res.status === "complete"
}
function isFailed(res: GetDownloadLinkResponse): res is FailedResponse {
  return res.status === "failed"
}

const waitForIt = (milliseconds: number): Promise<void> => {
  return new Promise((res) => {
    setTimeout(() => res(), milliseconds)
  })
}

const useEffectThatOnlyRunsOnMount = (func) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(func, [])
}
export const NOT_STARTED_STATE = "notStarted"
export const IN_PROGRESS_STATE = "inProgress"
export const COMPLETE_STATE = "complete"
export const FAILED_STATE = "failed"

type DownloadState =
  | typeof NOT_STARTED_STATE
  | typeof IN_PROGRESS_STATE
  | typeof COMPLETE_STATE
  | typeof FAILED_STATE
type UseAsyncDownloadResponse = {
  downloadState: DownloadState
  fetchDownload: () => Promise<Success | Failure>
}
export const useAsyncDownload = ({
  createDownload,
  key,
}: {
  createDownload: () => Promise<{
    links: { pollUrl: string }
  }>
  key: string
}): UseAsyncDownloadResponse => {
  const [cachedPollUrl, setCachedPollUrl] = useSessionBackedState<
    string | null
  >({
    defaultValue: null,
    expirationInSeconds: 400,
    key: key,
  })
  const [downloadState, setDownloadState] = useState<DownloadState>(
    cachedPollUrl ? IN_PROGRESS_STATE : NOT_STARTED_STATE
  )

  const fetchDownload = async () => {
    setDownloadState(IN_PROGRESS_STATE)
    const pollUrl: string = cachedPollUrl
      ? cachedPollUrl
      : await createDownload().then((res) => {
          setCachedPollUrl(res.links.pollUrl)
          return res.links.pollUrl
        })
    let attempts = 0
    while (attempts < 15) {
      const res = await getDownloadLink(pollUrl)
      if (isComplete(res)) {
        downloadALink({ link: res.link, filename: res.filename })
        setCachedPollUrl(null)
        setDownloadState(COMPLETE_STATE)
        return new Success()
      } else if (isFailed(res)) {
        alert("Failed to load document please try again")
        setDownloadState(FAILED_STATE)
        setCachedPollUrl(null)
        return new Failure(
          "Failed to load document. Please refresh and try again"
        )
      }
      attempts += 1
      const waitTime = 10000
      await waitForIt(waitTime)
    }
    return new Failure("Failed to load document. Please refresh and try again")
  }
  useEffectThatOnlyRunsOnMount(() => {
    if (downloadState === IN_PROGRESS_STATE) {
      fetchDownload()
    }
  })
  return { fetchDownload, downloadState }
}
