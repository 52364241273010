import { employerPrefix } from "utilities/url"

export const therapySelectionPath = (
  pendingOrderId: string,
  therapySelectionId: string
): string =>
  `/pending_orders/${pendingOrderId}/therapy_selections/${therapySelectionId}`

export const diagnosesPath = (
  pendingOrderId: string,
  therapySelectionId: string
): string =>
  `/pending_orders/${pendingOrderId}/therapy_selections/${therapySelectionId}/diagnoses`

export const medicalNecessityPath = (
  pendingOrderId: string,
  therapySelectionId: string
): string =>
  `/pending_orders/${pendingOrderId}/therapy_selections/${therapySelectionId}/medical_necessity`

export const therapySelectionSurveyQuestionsPath = (
  pendingOrderId: string,
  therapySelectionId: string
): string =>
  `${employerPrefix()}/pending_orders/${pendingOrderId}/therapy_selections/${therapySelectionId}/survey_questions`
