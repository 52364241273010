import React from "react"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { canopyColorTextSecondary } from "@parachutehealth/canopy-tokens-color"

export const StackedData = ({ label, value }) => (
  <CanopyFlex gap="2X" direction="column">
    <span
      className={`canopy-typography-body-small ${canopyColorTextSecondary}`}
    >
      {label}
    </span>
    <span className="canopy-typography-body-medium">{value}</span>
  </CanopyFlex>
)
