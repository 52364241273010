import { GridColDef } from "@mui/x-data-grid-pro"
import React from "react"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import * as styles from "./OrdersDashboardTable.module.scss"
import { format } from "../../../../../../utilities/date"
import {
  AuthStatusToSeverity,
  AuthStatusToTitle,
} from "../../../../CarrierAuthorizationRequest/utilities/statusFormatters"
import AuthStatusPill from "../../../../CarrierAuthorizationRequest/components/AuthStatusPill/AuthStatusPill"
import DeliveryStatus from "components/DeliveryStatus"
import Divider from "@material-ui/core/Divider"
import Link from "components/Link"
import { dmeOrderUrl, carrierAuthorizationUrl } from "../../../urls"
import { PayorDmeOrdersSortEnum } from "graphql/__generated__/graphql"

type columnDefinitionParams = {
  orderDetailsEnabled: boolean
  authDetailsEnabled: boolean
}

export enum PayorOrdersDashboardFields {
  Member = PayorDmeOrdersSortEnum.Member,
  Supplier = PayorDmeOrdersSortEnum.Supplier,
  Facility = PayorDmeOrdersSortEnum.Facility,
  AuthSubmitted = PayorDmeOrdersSortEnum.AuthSubmitted,
  AuthPriority = PayorDmeOrdersSortEnum.AuthPriority,
  AuthStatus = PayorDmeOrdersSortEnum.AuthStatus,
  RequestedDelivery = PayorDmeOrdersSortEnum.RequestedDelivery,
  FulfillmentStatus = PayorDmeOrdersSortEnum.FulfillmentStatus,
  Packages = "Packages",
  HCPCS = "HCPCS",
  Links = "Links",
}

export const ordersDashboardTableColumnDefinitions = ({
  orderDetailsEnabled,
  authDetailsEnabled,
}: columnDefinitionParams): GridColDef[] => [
  {
    field: PayorOrdersDashboardFields.Member,
    width: 150,
    headerName: "Member",
    renderCell: (params) => {
      const name = `${params.row.patient?.firstName} ${params.row.patient?.lastName}`
      const memberNumber = params.row.insurancePolicies?.find(
        (ip) => ip.rank === "primary"
      )?.memberNumber
      return (
        <>
          <CanopyFlex
            className={styles.memberColumn}
            direction="column"
            gap="0"
          >
            <p>{name}</p>
            <p className={styles.memberNumber}>{memberNumber}</p>
          </CanopyFlex>
        </>
      )
    },
  },
  {
    field: PayorOrdersDashboardFields.Supplier,
    width: 150,
    headerName: "Supplier",
    valueGetter: (params) => params.row.supplierLegalEntity?.name,
  },
  {
    field: PayorOrdersDashboardFields.Facility,
    width: 150,
    headerName: "Facility",
    valueGetter: (params) => params.row.clinicalFacility?.name,
  },
  {
    field: PayorOrdersDashboardFields.Packages,
    width: 150,
    headerName: "Packages",
    sortable: false,
    renderCell: (params) => {
      const bullet = "•"

      return params.row.lineItemGroups?.reduce(
        (packagesString, item, index) => {
          packagesString +=
            index === params.row.lineItemGroups.length - 1
              ? item.displayName
              : `${item.displayName} ${bullet} `
          return packagesString
        },
        ""
      )
    },
  },
  {
    field: PayorOrdersDashboardFields.AuthSubmitted,
    width: 175,
    headerName: "Auth submitted",
    renderCell: (params) => {
      const authRequestSubmit =
        params.row.carrierAuthorization?.requestSubmitDateTime
      return formatDate(authRequestSubmit)
    },
  },
  {
    field: PayorOrdersDashboardFields.AuthPriority,
    width: 150,
    headerName: "Auth priority",
    renderCell: (params) => {
      return params.row.carrierAuthorization?.priority === "urgent"
        ? "Urgent"
        : params.row.carrierAuthorization?.priority === "routine"
        ? "Routine"
        : "-"
    },
  },
  {
    field: PayorOrdersDashboardFields.AuthStatus,
    width: 175,
    headerName: "Auth status",
    renderCell: (params) => {
      const authStatus = params.row.carrierAuthorization?.authStatus
      return (
        !!authStatus && (
          <AuthStatusPill
            statusLevel={AuthStatusToSeverity[authStatus]}
            statusText={AuthStatusToTitle[authStatus]}
          />
        )
      )
    },
  },
  {
    field: PayorOrdersDashboardFields.HCPCS,
    width: 175,
    headerName: "HCPCS",
    hide: true,
    sortable: false,
    valueGetter: (params) =>
      params.row.lineItemGroups?.flatMap((lig) => lig.hcpcs).join(", ") || "",
  },
  {
    field: PayorOrdersDashboardFields.RequestedDelivery,
    width: 175,
    hide: true,
    headerName: "Requested delivery",
    renderCell: (params) => {
      const deliveryFulfillment = getDeliveryFulfillment(params.row)
      return formatDate(deliveryFulfillment?.requestedDeliveryDate)
    },
  },
  {
    field: PayorOrdersDashboardFields.FulfillmentStatus,
    width: 175,
    hide: true,
    headerName: "Fulfillment status",
    renderCell: (params) => {
      const deliveryFulfillment = getDeliveryFulfillment(params.row)
      return (
        !!deliveryFulfillment && (
          <DeliveryStatus
            status={deliveryFulfillment?.deliveryStatus || null}
          ></DeliveryStatus>
        )
      )
    },
  },
  {
    field: PayorOrdersDashboardFields.Links,
    headerName: "Order and auth links",
    width: 190,
    resizable: false,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <></>,
    renderCell: (params) => {
      const carrierAuthorization = params.row?.carrierAuthorization
      return (
        <>
          {orderDetailsEnabled && (
            <Link
              href={dmeOrderUrl(params.row.id)}
              target="_blank"
              rel="nofollow noreferrer"
            >
              View order
            </Link>
          )}
          {authDetailsEnabled && !!carrierAuthorization && (
            <>
              <Divider orientation="vertical" variant="middle" />
              <Link
                href={carrierAuthorizationUrl(
                  params.row.carrierAuthorization?.id
                )}
                target="_blank"
                rel="nofollow noreferrer"
              >
                View auth
              </Link>
            </>
          )}
        </>
      )
    },
  },
]

const formatDate = (date: Date) => {
  return !!date ? format(date.toLocaleString(), "MM/DD") : ""
}

const getDeliveryFulfillment = (row) => {
  return row.deliveryFulfillments?.length ? row.deliveryFulfillments[0] : null
}
