// @team @cat-crew

import { post } from "../../../services/api"
import { ApiResponse } from "./utilities"
import { CatalogPackage, CatalogPackageProduct } from "../types/sharedTypes"

export const createSimplePackage = async (
  productId: string | number,
  addToSuppliers?: boolean
): Promise<
  ApiResponse<{
    package: CatalogPackage
    packageProducts: CatalogPackageProduct[]
  }>
> => {
  return await post(`/cms/catalog/products/${productId}/simple_packages.json`, {
    addToSuppliers: addToSuppliers,
  }).then((data) => {
    return data.data
  })
}
