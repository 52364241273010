// @team @demand-ordering
// @ts-strict-ignore
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useHistory } from "react-router-dom"
import { datadogRum } from "@datadog/browser-rum"
import {
  initialCatalogDataForSearchByProduct,
  searchByProduct,
  initialCatalogDataForAllSuppliers,
  initialCatalogDataForSupplier,
  searchCatalog,
  searchPackagesWithSkus,
  searchPackagesWithSkuCompatibleWithSearchByProduct,
  selectDefaultConsignmentCloset as apiSelectDefaultConsignmentCloset,
  selectPackage as apiSelectPackage,
  selectPackageV2,
  selectPackageWithSku as apiSelectPackageWithSku,
} from "./api"
import {
  updateUiPreferences,
  getReferralApiCatalogPackage,
} from "applications/Workflow/api"
import Overlay from "components/Overlay"
import withInitialData from "components/withInitialData"
import Header from "./components/Header"
import Grid from "./components/Grid"
import Pagination from "./components/Pagination"
import ConsignmentClosetNudgeModal from "./components/ConsignmentClosetNudgeModal/ConsignmentClosetNudgeModal"
import * as routes from "applications/Workflow/routes"
import {
  CatalogSearchTab,
  ConsignmentCloset,
  Context,
  DmeOrder,
  NetworkCoverage,
  CatalogBrowseTab,
  SearchWorkflow,
  Supplier,
  Nullable,
  DmeOrderCreatedVia,
} from "sharedTypes"
import {
  CategoryWithSubcategories,
  Package,
  PackageSku,
  PackageWithCartData,
  SupplierContactDetails,
} from "./sharedTypes"
import { ALL_SUPPLIERS } from "applications/Workflow/containers/Product"
import GlobalContext from "context/Global"
import { scrollTop } from "utilities/scroll"
import { handleError } from "utilities/error"
import SidebarAwareContainer from "./components/SideBarAwareContainer"
import ProductFilters from "./components/ProductFilters"
import ProductSearch from "./components/ProductSearch"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { allowSearchByProduct } from "../../utilities/searchByProduct"
import { AxiosPromise, AxiosResponse } from "axios"
import debounce from "awesome-debounce-promise"
import NoSearchResults from "./components/NoSearchResults"
import {
  isProductSearchTab,
  isCombinedCatalogSearchType,
} from "../../utilities/catalogSearchTabs"

type CatalogResult = {
  packages: Package[]
  availableCategories: CategoryWithSubcategories[]
  currentPage: number
  totalPages: number
  categories?: CategoryWithSubcategories[]
  otherPackagesCount: Nullable<number>
}

type CatalogFilter = {
  supplierId?: number
  consignmentClosetOnly: boolean
  formularyOnly: boolean
  defaultServiceAreaStates: string[]
  yourOrganizationsSuppliersOnly: boolean
}

type SearchParams = {
  loading?: boolean
  categoryId?: number
  supplierId?: number
  query?: string
  consignmentClosetOnly?: boolean
  formularyOnly?: boolean
  yourOrganizationsSuppliersOnly?: boolean
  selectedServiceAreaState?: string
  page: number
}

type Props = {
  initialData: {
    categories: CategoryWithSubcategories[]
    canFilterByFormulary: boolean
    consignmentClosets: ConsignmentCloset[]
    defaultConsignmentClosetId: string
    initialCatalogResult: CatalogResult
    initialFilter: CatalogFilter
    networkCoverage: NetworkCoverage | undefined
    supplier: Supplier | undefined
    supplierContactDetails: SupplierContactDetails
  }
  dmeOrder: DmeOrder
  supplierId?: string
  currentCatalogBrowseTab: CatalogBrowseTab
  yourOrganizationsSuppliersOnly: boolean
  toggleYourOrganizationsSuppliersOnly: () => void
  setSelectedServiceAreaStateFilter: (state: string) => void
}

const Browsing: React.FC<Props> = ({
  initialData,
  dmeOrder,
  supplierId: supplierIdProp,
  currentCatalogBrowseTab,
  yourOrganizationsSuppliersOnly: initialYourOrganizationsSuppliersOnly,
  toggleYourOrganizationsSuppliersOnly,
  setSelectedServiceAreaStateFilter,
}: Props) => {
  const context = useContext(GlobalContext)
  const history = useHistory()
  const { isFeatureEnabled } = useFeatureFlags()
  const { consignmentClosets } = initialData

  const initialSelectedTab = isProductSearchTab(
    dmeOrder.clinicalFacility.catalogSearchType
  )
    ? CatalogSearchTab.PackageFilter
    : CatalogSearchTab.SkuQuickAdd

  const initialConsignmentClosetId =
    consignmentClosets.length === 1
      ? consignmentClosets[0].externalId
      : initialData.defaultConsignmentClosetId
  const PER_PAGE = 12

  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [categoryId, setCategoryId] = useState(null)
  const [subcategoryId, setSubcategoryId] = useState(null)
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab)
  const [defaultConsignmentClosetId, setDefaultConsignmentClosetId] = useState(
    initialConsignmentClosetId
  )
  const [data, setData] = useState(initialData.initialCatalogResult)
  const [categories, setCategories] = useState(initialData.categories)
  const [catalogPackageToSelect, setCatalogPackageToSelect] = useState(null)
  const [
    yourOrganizationsSuppliersOnly,
    setYourOrganizationsSuppliersOnly,
  ] = useState(initialYourOrganizationsSuppliersOnly)
  const [selectedServiceAreaState, setSelectedServiceAreaState] = useState(
    initialData.initialFilter.defaultServiceAreaStates[0]
  )
  const [consignmentClosetOnly, setConsignmentClosetOnly] = useState(
    initialData.initialFilter.consignmentClosetOnly
  )
  const [formularyOnly, setFormularyOnly] = useState(
    initialData.initialFilter.formularyOnly
  )
  const supplierId = initialData.initialFilter.supplierId
  const [searchText, setSearchText] = useState("")
  const prevSearchText = useRef(searchText)
  const shouldRunDebouncedQuery = useRef(false)
  const isInitialLoad = useRef(true)

  const search = useCallback(
    async (
      params: SearchParams,
      catalogSearch: (params: any) => AxiosPromise,
      isDebounced?: boolean
    ) => {
      // for debounced searches, we only run them if a more recent query hasn't run
      // this addresses the race condition where another value is changed in the form after a search is debounced,
      // but before it executes
      if (!isDebounced || shouldRunDebouncedQuery.current) {
        return catalogSearch(params)
      }
    },
    []
  )

  const debouncedSearch = useMemo(() => debounce(search, 250), [search])

  const supplierIdParam = () => supplierIdProp || ALL_SUPPLIERS

  const isConsignmentClosetSelectionSkipped = () => {
    return context.uiPreferences.skippedConsignmentClosetSelections?.find(
      (skipped) => {
        return (
          skipped.supplierId === initialData.supplier.externalId &&
          skipped.clinicalFacilityId === dmeOrder.clinicalFacility.externalId
        )
      }
    )
  }

  const viewPackageConfig = (
    packageConfigurationId: string,
    selecting = false
  ): Promise<void> => {
    history.push(
      routes.productsPackageConfigurationPath(
        supplierIdParam(),
        packageConfigurationId,
        selecting
      )
    )
    return Promise.resolve()
  }

  const selectPackage = (
    catalogPackage: PackageWithCartData
  ): Promise<void> => {
    if (catalogPackage.packageConfigurationId) {
      return viewPackageConfig(catalogPackage.packageConfigurationId)
    }

    const defaultCloset = consignmentClosets.find(
      ({ externalId }) => externalId === defaultConsignmentClosetId
    )

    if (
      consignmentClosets.length > 1 &&
      !defaultCloset &&
      !isConsignmentClosetSelectionSkipped()
    ) {
      setCatalogPackageToSelect(catalogPackage)
      return Promise.resolve()
    } else {
      return actuallySelectPackage(catalogPackage)
    }
  }

  const markAsSkipped = () => {
    const { uiPreferences } = context as Context
    uiPreferences.skippedConsignmentClosetSelections =
      uiPreferences.skippedConsignmentClosetSelections || []
    if (!isConsignmentClosetSelectionSkipped()) {
      uiPreferences.skippedConsignmentClosetSelections.push({
        supplierId: initialData.supplier.externalId,
        clinicalFacilityId: dmeOrder.clinicalFacility.externalId,
      })
      updateUiPreferences(uiPreferences)
    }
  }

  const cancelConsignmentClosetNudgeModal = () => {
    markAsSkipped()
    actuallySelectPackage()
  }

  const selectDefaultConsignmentCloset = (consignmentClosetId) => {
    return apiSelectDefaultConsignmentCloset({ consignmentClosetId }).then(
      () => {
        setDefaultConsignmentClosetId(consignmentClosetId)
        setPage(1)
        if (catalogPackageToSelect) {
          actuallySelectPackage(null, consignmentClosetId)
        }
      }
    )
  }

  const actuallySelectPackage = (
    catalogPackage?: PackageWithCartData,
    consignmentClosetId?: string
  ): Promise<void> => {
    const body = {
      dmeOrderPackageConfiguration: {
        catalogPackageId: (catalogPackage || catalogPackageToSelect).id,
        formularyOnly,
        consignmentClosetOnly,
        consignmentClosetId: consignmentClosetId || defaultConsignmentClosetId,
        supplierId,
        searchWorkflow: searchWorkflow(),
      },
    }

    const selectPackageAPI = isFeatureEnabled("marketplacePackageConfiguration")
      ? selectPackageV2
      : apiSelectPackage
    setLoading(true)
    return selectPackageAPI(body).then(({ data }) => {
      setLoading(false)
      setCatalogPackageToSelect(null)
      viewPackageConfig(data.id, true)
    }, handleError)
  }

  const apiSearchPackagesWithSku = (searchQuery: string) => {
    const params = {
      consignmentClosetOnly,
      formularyOnly,
      supplierId,
      query: searchQuery,
      consignmentClosetId: defaultConsignmentClosetId,
    }
    return searchPackagesWithSkus(params)
  }

  const apiSearchPackagesWithSkuCompatibleWithSearchByProduct = (
    searchQuery: string
  ) => {
    const params = {
      consignmentClosetOnly,
      formularyOnly,
      yourOrganizationsSuppliersOnly,
      supplierId,
      selectedServiceAreaState,
      query: searchQuery,
    }
    return searchPackagesWithSkuCompatibleWithSearchByProduct(params)
  }

  const isSearchByProduct = useMemo(
    () =>
      allowSearchByProduct(
        isFeatureEnabled("userActivationProductFirstSearchIncludeEnterprise"),
        dmeOrder.clinicalFacility.usesEnterpriseFeatures
      ) && currentCatalogBrowseTab === CatalogBrowseTab.Product,
    [
      currentCatalogBrowseTab,
      dmeOrder.clinicalFacility.usesEnterpriseFeatures,
      isFeatureEnabled,
    ]
  )

  const apiSearchBySku = () => {
    return isSearchByProduct
      ? apiSearchPackagesWithSkuCompatibleWithSearchByProduct
      : apiSearchPackagesWithSku
  }

  const selectPackageWithSku = (
    packageSku: PackageSku,
    consignmentClosetId?: string
  ) => {
    const { catalogPackageId, catalogProductVariationId } = packageSku

    const body = {
      dmeOrderPackageConfiguration: {
        catalogPackageId,
        catalogProductVariationId,
        formularyOnly,
        consignmentClosetOnly,
        consignmentClosetId: consignmentClosetId || defaultConsignmentClosetId,
        supplierId,
        searchWorkflow: searchWorkflow(),
      },
    }
    apiSelectPackageWithSku(body).then(
      ({ data }) => viewPackageConfig(data.id),
      handleError
    )
  }

  const queryParams = useMemo(() => {
    return {
      page,
      perPage: PER_PAGE,
      consignmentClosetOnly,
      consignmentClosetIds: defaultConsignmentClosetId
        ? [defaultConsignmentClosetId]
        : [],
      formularyOnly,
      yourOrganizationsSuppliersOnly,
      searchText,
      selectedServiceAreaState,
      supplierId,
      categoryId,
      query: searchText,
    }
  }, [
    categoryId,
    consignmentClosetOnly,
    defaultConsignmentClosetId,
    formularyOnly,
    page,
    searchText,
    selectedServiceAreaState,
    supplierId,
    yourOrganizationsSuppliersOnly,
  ])

  const goToPage = (page: number) => {
    scrollTop()
    setPage(page)
  }

  const toggleConsignmentClosetFilter = useCallback(() => {
    setConsignmentClosetOnly((prevValue) => !prevValue)
    setPage(1)
  }, [])

  const toggleFormularyFilter = useCallback(() => {
    setFormularyOnly((prevValue) => !prevValue)
    setPage(1)
  }, [])

  const toggleYourOrganizationsSuppliersFilter = useCallback(() => {
    setYourOrganizationsSuppliersOnly((prevValue) => !prevValue)
    setPage(1)
    toggleYourOrganizationsSuppliersOnly()
  }, [toggleYourOrganizationsSuppliersOnly])

  const selectCategory = useCallback(
    (selectedCategoryId: number, isSubcategory = false) => {
      if (isSubcategory) {
        setSubcategoryId(selectedCategoryId)
      } else {
        if (!selectedCategoryId) {
          setSubcategoryId(null)
        }
        setCategoryId(selectedCategoryId)
      }
      setPage(1)
    },
    []
  )

  const selectState = useCallback(
    (serviceAreaState: string) => {
      setSelectedServiceAreaState(serviceAreaState)
      // Note - the duplicative-looking state-setting
      // is due to the fact that we are tracking filter state in two places.
      // In addition to the current Browsing component, we also track the state
      // of the service area filter in the Product component
      // (app/javascript/applications/Workflow/containers/Product/index.tsx)
      // The Product component needs to track service area filter state
      // in order to render the PackageConfiguration component (and its children)
      // with an accurate filter value.
      setSelectedServiceAreaStateFilter(serviceAreaState)
      setPage(1)
    },
    [setSelectedServiceAreaStateFilter]
  )

  const searchWorkflow = () => {
    switch (currentCatalogBrowseTab) {
      case CatalogBrowseTab.Product:
        return SearchWorkflow.SearchByProduct
      case CatalogBrowseTab.Supplier:
        return SearchWorkflow.SupplierFirstSearch
    }
  }

  const updateData = useCallback((response: AxiosResponse) => {
    const { data } = response
    const { categories } = data
    if (categories) {
      setCategories(categories)
    }
    setData(data)
    setLoading(false)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (!isInitialLoad.current) {
        setLoading(true)
      } else {
        isInitialLoad.current = false
      }

      const catalogSearch = isSearchByProduct ? searchByProduct : searchCatalog

      const searchChanged = queryParams.searchText !== prevSearchText?.current

      const searchMethod = searchChanged ? debouncedSearch : search
      if (searchChanged) {
        shouldRunDebouncedQuery.current = true
        datadogRum.addAction("user triggers search", {
          searchText: queryParams.searchText,
        })
      } else {
        // cancel any pending debounced searches when we run a query here, since they would otherwise
        // have stale/wrong query params
        shouldRunDebouncedQuery.current = false
      }

      prevSearchText.current = queryParams.searchText

      try {
        const response = await searchMethod(
          queryParams,
          catalogSearch,
          searchChanged
        )
        if (response) {
          updateData(response)
        }
      } catch (e) {
        setLoading(false)
        handleError(e)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [queryParams, isSearchByProduct, updateData, search, debouncedSearch])

  const packagesWithCartData = useCallback(() => {
    const groupsById = dmeOrder.lineItemGroups.reduce(
      (accum, lig) => ({
        ...accum,
        [lig.packageId]: lig,
      }),
      {}
    )

    return data.packages.map((pkg) => {
      const lineItemGroup = groupsById[pkg.externalId]
      return {
        ...pkg,
        packageConfigurationId:
          lineItemGroup && lineItemGroup.packageConfigurationId,
        numberInCart: (lineItemGroup && lineItemGroup.quantity) || 0,
      }
    })
  }, [data, dmeOrder])

  const [packages, setPackages] = useState(packagesWithCartData())
  useEffect(() => {
    const packages = packagesWithCartData()
    setPackages(packages)
  }, [data, setData, dmeOrder, packagesWithCartData])

  const {
    canFilterByFormulary,
    supplier,
    networkCoverage,
    supplierContactDetails,
  } = initialData
  const { oneTimeOrderEnabled, catalogSearchType } = dmeOrder.clinicalFacility
  const combinedCatalogSearch = isCombinedCatalogSearchType(catalogSearchType)

  const canFilterByYourOrganizationsSuppliers =
    isSearchByProduct && oneTimeOrderEnabled

  const isReferralApiProductSelectionEnabled: boolean =
    isFeatureEnabled("referralApiProductSelectionEnabled") &&
    dmeOrder.createdVia === DmeOrderCreatedVia.referral_api

  const existingPackageSelection = async () => {
    if (
      dmeOrder.lineItemGroups.length !== 0 ||
      history.location.pathname.includes("package_configuration")
    ) {
      //If the user returns to the product selection page it will
      //open a new package without the return. We could look at opening the edit page
      //instead of escaping the package selection automation.
      //We also want to avoid this automation if the user is already configuring a package and refreshes the page.
      return
    }
    const catalogPackage = await getReferralApiCatalogPackage(dmeOrder.id)
    if (!("id" in catalogPackage) && !("external_id" in catalogPackage)) {
      console.log("Failed to retrieve catalog package from referral api")
      return
    }
    selectPackage({ ...catalogPackage, numberInCart: 0 })
  }

  useEffect(() => {
    if (isReferralApiProductSelectionEnabled) {
      void existingPackageSelection()
    }
    // We only want this to run once on the page load. Without skipping exhaustive-deps it requires dependencies
    // which causes it to run multiple times. Removing the dep array causes it to run multiple times..
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isSearchByProduct ? null : (
        <Header
          dmeOrder={dmeOrder}
          networkCoverage={networkCoverage}
          supplier={supplier}
          supplierContactDetails={supplierContactDetails}
        />
      )}

      <div className="row">
        <ProductFilters
          dmeOrder={dmeOrder}
          categories={categories}
          consignmentClosetOnly={consignmentClosetOnly}
          toggleConsignmentClosetFilter={toggleConsignmentClosetFilter}
          defaultConsignmentClosetId={defaultConsignmentClosetId}
          consignmentClosets={consignmentClosets}
          selectDefaultConsignmentCloset={selectDefaultConsignmentCloset}
          formularyOnly={formularyOnly}
          canFilterByFormulary={canFilterByFormulary}
          toggleFormularyFilter={toggleFormularyFilter}
          canFilterByYourOrganizationsSuppliers={
            canFilterByYourOrganizationsSuppliers
          }
          yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
          toggleYourOrganizationsSuppliersFilter={
            toggleYourOrganizationsSuppliersFilter
          }
          availableCategories={data.availableCategories}
          selectedCategory={categoryId}
          selectCategory={selectCategory}
          selectState={selectState}
          serviceAreaState={selectedServiceAreaState}
          currentCatalogBrowseTab={currentCatalogBrowseTab}
          combinedCatalogSearch={combinedCatalogSearch}
        ></ProductFilters>
        <SidebarAwareContainer role="productGrid">
          <ProductSearch
            availableCategories={data.availableCategories}
            canFilterByFormulary={canFilterByFormulary}
            canFilterByYourOrganizationsSuppliers={
              canFilterByYourOrganizationsSuppliers
            }
            catalogSearchType={catalogSearchType}
            categories={categories}
            categoryId={categoryId}
            combinedCatalogSearch={combinedCatalogSearch}
            consignmentClosetOnly={consignmentClosetOnly}
            consignmentClosets={consignmentClosets}
            currentCatalogBrowseTab={currentCatalogBrowseTab}
            defaultConsignmentClosetId={defaultConsignmentClosetId}
            defaultServiceAreaState={
              initialData.initialFilter.defaultServiceAreaStates[0]
            }
            dmeOrder={dmeOrder}
            filterQuery={searchText}
            formularyOnly={formularyOnly}
            formularyPriceEnabled={
              dmeOrder.clinicalFacility.formularyPriceEnabled
            }
            searchPackagesWithSku={apiSearchBySku()}
            selectCategory={selectCategory}
            selectDefaultConsignmentCloset={selectDefaultConsignmentCloset}
            selectedCategory={categoryId}
            selectedTab={selectedTab}
            selectPackageWithSku={selectPackageWithSku}
            selectState={selectState}
            serviceAreaState={selectedServiceAreaState}
            setSelectedTab={setSelectedTab}
            subcategoryId={subcategoryId}
            supplierIdParam={supplierIdParam()}
            toggleConsignmentClosetFilter={toggleConsignmentClosetFilter}
            toggleFormularyFilter={toggleFormularyFilter}
            toggleYourOrganizationsSuppliersFilter={
              toggleYourOrganizationsSuppliersFilter
            }
            updateFilterQuery={(updatedText) => {
              setSearchText(updatedText)
              setPage(1)
            }}
            yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
          />
          <Overlay showSpinner active={loading} position="top">
            <Grid
              currentCatalogBrowseTab={currentCatalogBrowseTab}
              dmeOrder={dmeOrder}
              onSelect={selectPackage}
              packages={packages}
              currentPage={data.currentPage}
              yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
              searchContextForRum={{
                searchText,
                categoryId,
                subcategoryId,
                formularyOnly,
                supplierId,
                selectedServiceAreaState,
              }}
            />
            <NoSearchResults
              otherPackagesCount={data.otherPackagesCount}
              packagesCount={packages.length}
              selectCategory={selectCategory}
            />
          </Overlay>
        </SidebarAwareContainer>
      </div>
      <div className="row">
        <SidebarAwareContainer role="paginationLink">
          {packages.length > 0 && (
            <Overlay active={loading} showSpinner={false}>
              <Pagination
                goToPage={goToPage}
                currentPage={data.currentPage}
                totalPages={data.totalPages}
              />
            </Overlay>
          )}
        </SidebarAwareContainer>
      </div>
      <ConsignmentClosetNudgeModal
        closets={consignmentClosets}
        onClose={cancelConsignmentClosetNudgeModal}
        onSubmit={selectDefaultConsignmentCloset}
        open={!!catalogPackageToSelect}
      />
    </>
  )
}

const fetchInitialData = ({
  supplierId,
  currentCatalogBrowseTab,
  yourOrganizationsSuppliersOnly,
}: Omit<Props, "initialData">): Promise<Props["initialData"]> => {
  if (supplierId && supplierId !== ALL_SUPPLIERS) {
    return initialCatalogDataForSupplier(supplierId).then(({ data }) => data)
  }

  if (currentCatalogBrowseTab === CatalogBrowseTab.Product) {
    return initialCatalogDataForSearchByProduct(
      yourOrganizationsSuppliersOnly
    ).then(({ data }) => data)
  }

  return initialCatalogDataForAllSuppliers().then(({ data }) => data)
}

export default withInitialData(fetchInitialData)(Browsing)
