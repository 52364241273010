import { SurveyAnswerValue, SurveyAnswerType } from "sharedTypes"

export type Values = {
  answerValue?: SurveyAnswerValue
  answerType?: SurveyAnswerType
  na?: boolean
  otherAnswer?: string
}

export type InitialValues = {
  answerValue?: SurveyAnswerValue | undefined
  na?: boolean
  otherAnswer?: string
}

export const INPUT_DATE_QUESTION_TYPE = "date"
export const INPUT_NUMBER_QUESTION_TYPE = "numeric"
export const INPUT_TEXT_QUESTION_TYPE = "text"
export const SELECT_MANY_QUESTION_TYPE = "select_multi"
export const SELECT_ONE_QUESTION_TYPE = "select_one"
export const SELECT_YES_NO_QUESTION_TYPE = "yes_no"
export const OPTIONAL_TYPE = "optional"
export const DOCTOR_RX_NOTE_TYPE = "doctor_rx_note"
export const OTHER_VALUE = "OTHER"
export const NA_VALUE = "na"
