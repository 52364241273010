import React from "react"
import ProductAttributeTable from "./ProductAttributeTable"
import { NoticeContext } from "../../../../../contexts/NoticeContext"
import { CatalogProduct } from "../../../../../types/sharedTypes"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getAttributesForProduct } from "./api"
import { CountContext } from "../../../../../contexts/CountContext"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CircularProgress } from "@material-ui/core"
import SkusDataGrid from "./SkusDataGrid"

type Props = {
  product: CatalogProduct
}

const SkusAndAttributesTab: React.FC<Props> = ({
  product,
}: Props): React.JSX.Element => {
  const queryClient = useQueryClient()
  const { changeCount } = React.useContext(CountContext)
  const { showNotice } = React.useContext(NoticeContext)

  const { isFetching = true, error: _error, data: attributeValues } = useQuery({
    queryKey: [
      "ProductDetailsPage",
      "SkusAndAttributesTab",
      "getAttributesForProduct",
      product.externalId,
    ],
    queryFn: () => getAttributesForProduct(product.externalId),
    refetchOnWindowFocus: false,
  })

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries({
      // using only this prefix should reset all the react-query instances for this page
      // other items like loaded SKUs will need to be re-queried if their attributes change
      queryKey: ["ProductDetailsPage"],
    })
  }

  React.useEffect(() => {
    changeCount(
      "attributes",
      attributeValues?.productCustomAttributes?.length || 0
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeValues?.productCustomAttributes])

  if (isFetching) {
    return (
      <CanopyFlex
        justifyContent="center"
        alignItems="center"
        className="canopy-p-4x"
      >
        <CircularProgress color="inherit" />
      </CanopyFlex>
    )
  }

  return (
    <>
      <ProductAttributeTable
        product={product}
        productCustomAttributes={attributeValues?.productCustomAttributes || []}
        usedAttributeValues={attributeValues?.usedAttributeValues || {}}
        onRemoveAttribute={async () => {
          showNotice("Attribute successfully removed", "success", [], true)
          await invalidateQueries()
        }}
        onAddAttribute={async () => {
          showNotice("Attribute successfully added", "success", [], true)
          await invalidateQueries()
        }}
      />
      <SkusDataGrid
        product={product}
        productCustomAttributes={attributeValues?.productCustomAttributes || []}
      />
    </>
  )
}

export default SkusAndAttributesTab
