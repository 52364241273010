// @team @cat-crew

export const productVariationsUrl = (): string => {
  return "/cms/catalog/skus/"
}

export const productVariationDetailUrl = (id: string): string => {
  return `/cms/catalog/skus/${id}`
}

export const editProductVariationUrl = (id: string): string => {
  return `${productVariationDetailUrl(id)}/edit`
}
