import React, { useState } from "react"
import { Doctor } from "sharedTypes"
import InternalAffiliatedDoctorManagement from "./components/InternalAffiliatedDoctorManagement"
import { create, update, search, remove } from "./api"
import { useDebounce } from "hooks/useDebounce"

type Props = {
  affiliatedDoctors: Doctor[]
}

const AffiliatedDoctorManagement = ({ affiliatedDoctors }: Props) => {
  const [stateAffiliatedDoctors, setAffiliatedDoctors] = useState(
    affiliatedDoctors
  )

  const updateAffiliatedDoctor = (doctor: Doctor, params) =>
    update(doctor.doctorId, params).then(setAffiliatedDoctors)
  const removeAffiliatedDoctor = (doctor: Doctor) =>
    remove(doctor.doctorId).then(setAffiliatedDoctors)
  const createDoctor = (doctor: Doctor, params) => {
    return create(doctor.doctorId, params).then(setAffiliatedDoctors)
  }
  const debouncedSearch = useDebounce(search)

  return (
    <InternalAffiliatedDoctorManagement
      affiliatedDoctors={stateAffiliatedDoctors}
      updateAffiliatedDoctor={updateAffiliatedDoctor}
      removeAffiliatedDoctor={removeAffiliatedDoctor}
      searchDoctors={debouncedSearch}
      selectDoctor={createDoctor}
    />
  )
}

export default AffiliatedDoctorManagement
