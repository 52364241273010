// @team @demand-ordering
import * as styles from "../GridItem/index.module.scss"
import React from "react"

type Props = {
  count: number
  supplierType: string
  shouldRenderPlaceholder: boolean
}

const SupplierCount = ({
  count,
  supplierType,
  shouldRenderPlaceholder,
}: Props) => {
  return (
    <p className={styles.supplierLabels}>
      {shouldRenderPlaceholder ? (
        <span aria-label={`${supplierType} (0)`}>---</span>
      ) : (
        <span>
          {supplierType} ({count || 0})
        </span>
      )}
    </p>
  )
}

export default SupplierCount
