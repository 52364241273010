// @ts-strict-ignore
import Patient from "./Patient"
import Payment from "./Payment"
import Cart from "./Cart"
import Diagnoses from "./Diagnoses"
import Documentation from "./Documentation"
import GlobalContext from "context/Global"
import React from "react"
import { DmeOrder, Document, Context } from "sharedTypes"
import * as styles from "./index.module.scss"
import classNames from "classnames"
import MedicalNecessityReview from "./MedicalNecessityReview"
import { useFeatureFlags } from "components/FeatureFlagContext"

const DmeOrderPreview = (props: {
  dmeOrder: DmeOrder
  downloadableDocuments: Document[]
  context: Context
  className?: string
}) => {
  const { dmeOrder, downloadableDocuments, context, className } = props
  const { isFeatureEnabled } = useFeatureFlags()

  return (
    <GlobalContext.Provider value={context}>
      <div
        aria-label="dme order preview"
        className={classNames(
          "right-sidebar-body",
          className,
          styles.dmeOrderDetailsContainer,
          styles.columns
        )}
      >
        <div className={styles.column}>
          <Patient dmeOrder={dmeOrder} />
          <Payment insurancePolicies={dmeOrder.insurancePolicies} />
        </div>
        <div className={styles.column}>
          <Cart lineItemGroups={dmeOrder.lineItemGroups} />
          <Diagnoses icd10Codes={dmeOrder.icd10Codes} />
          <Documentation
            dmeOrder={dmeOrder}
            downloadableDocuments={downloadableDocuments}
            doctorId={dmeOrder.doctor.doctorId}
          />
          {dmeOrder.payorEnhancedWorkflowEnabled &&
            isFeatureEnabled("payorEnhancedClinicalGuidelines") && (
              <MedicalNecessityReview
                questionnaireResponses={dmeOrder.questionnaireResponses}
              />
            )}
        </div>
      </div>
    </GlobalContext.Provider>
  )
}

export default DmeOrderPreview
