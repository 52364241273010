import React from "react"
import { CarrierMembership } from "graphql/__generated__/graphql"
import * as styles from "./PatientDetails.module.scss"
import { CanopyHeading } from "@parachutehealth/canopy-heading"

interface PatientDetailsProps {
  carrierMembership: CarrierMembership
}

export const PatientDetails = ({
  carrierMembership,
}: PatientDetailsProps): JSX.Element => {
  const {
    firstName,
    lastName,
    memberNumber,
    dateOfBirth,
    sex,
    insuranceProduct,
  } = carrierMembership

  const patientDetail = (label: string, value: string): JSX.Element => {
    return (
      <div className={styles.patientDetailItem}>
        <b className="canopy-typography-body-medium canopy-typography-font-weight-bold">{`${label}:`}</b>
        <b className="canopy-typography-body-medium canopy-pl-4x">{value}</b>
      </div>
    )
  }

  return (
    <div>
      <div className={styles.patientDetailCard}>
        <CanopyHeading
          level={2}
          size="large"
          className={styles.patientDetailHeader}
        >
          {firstName + " " + lastName}
        </CanopyHeading>
        <div
          role="grid"
          className={styles.container}
          data-testid="PatientDetails"
        >
          <div role="grid" className={styles.patient}>
            {patientDetail("Sex", sex)}
            {patientDetail("DOB", dateOfBirth)}
          </div>
          <div role="grid" className={styles.insurance}>
            {patientDetail("Member ID", memberNumber)}
            {patientDetail("Health Plan", insuranceProduct.name)}
          </div>
        </div>
      </div>
      <hr className={styles.separator} />
    </div>
  )
}
