import React from "react"
import { DocumentationRequirement } from "sharedTypes"
import Alert from "components/Alert"
import { Field } from "formik"
import {
  titleWithCategory,
  titleWithUsageContext,
} from "applications/Workflow/utilities/documentationRequirements"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { FillablePdfDetails } from "applications/Workflow/containers/ClinicalFacilityClinical/components/DocumentationRequirements/DocumentationRequirementRow/FillablePdfDetails"
import { CanopyFlex } from "@parachutehealth/canopy-flex"

type Props = {
  documentationRequirements: DocumentationRequirement[]
}

const DocumentationRequirementsSelection = ({
  documentationRequirements,
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const contextDrivenDocs = isFeatureEnabled("contextDrivenDocs")
  const fillablePdfFlag = isFeatureEnabled("enhancedFillableForms")
  const isFillablePdf = (docReq) => fillablePdfFlag && docReq.fillablePdf

  if (documentationRequirements.length < 1) {
    return <></>
  }

  const renderCheckbox = (docReq) => (
    <div key={docReq.externalId}>
      <CanopyFlex alignItems="flex-end" justifyContent="space-between">
        <Field name="requestedDocumentationRequirementIds">
          {({ form, field }) => (
            <label className="checkbox checkbox-brand basic">
              {contextDrivenDocs
                ? titleWithUsageContext(docReq)
                : titleWithCategory(docReq)}
              <input
                type="checkbox"
                name="requestedDocumentationRequirementIds"
                value={docReq.externalId}
                checked={field.value.includes(docReq.externalId)}
                onChange={() => {
                  if (field.value.includes(docReq.externalId)) {
                    const nextValue = field.value.filter(
                      (value) => value !== docReq.externalId
                    )
                    form.setFieldValue(
                      "requestedDocumentationRequirementIds",
                      nextValue
                    )
                  } else {
                    const nextValue = field.value.concat(docReq.externalId)
                    form.setFieldValue(
                      "requestedDocumentationRequirementIds",
                      nextValue
                    )
                  }
                }}
              />
              <span className="icon" />
            </label>
          )}
        </Field>
        <CanopyFlex>
          {isFillablePdf(docReq) && (
            <FillablePdfDetails
              employer="supplier"
              documentationRequirement={docReq}
            />
          )}
        </CanopyFlex>
      </CanopyFlex>
    </div>
  )

  return (
    <>
      <h3>Documentation Requirements</h3>
      <p className="color-dark-gray">
        The following documentation is required to fulfill this order. Select
        the documentation you need to collect or update in Parachute.
        {fillablePdfFlag
          ? " Only Medicaid forms can be edited by suppliers."
          : ""}
      </p>
      <div className="canopy-mbe-4x">
        {documentationRequirements.map((docReq) => renderCheckbox(docReq))}
      </div>
      {!documentationRequirements.find(
        (requirement) => requirement.generate
      ) && (
        <Alert leftIcon status="warning">
          <strong>No documents have been selected</strong>
          <div>
            Please select which documents you'd like to request from the
            facility to generate on Parachute.
          </div>
        </Alert>
      )}
    </>
  )
}

export default DocumentationRequirementsSelection
