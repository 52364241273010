import React from "react"
import { Form } from "components/form"
import Button from "components/form/Button"
import Alert from "components/Alert"
import { handleError } from "utilities/error"
import { CommentType, DmeOrder, EmploymentMention } from "sharedTypes"
import CommentField from "./CommentField"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { primaryPolicyFor } from "applications/Workflow/utilities/payment"

interface Values {
  content: string
}

interface Props {
  onSubmit(values: Values): Promise<void>
  dmeOrder: DmeOrder
  getEmploymentMentions(
    term: string,
    commentType: CommentType
  ): Promise<EmploymentMention[]>
}

function FacilityCommentForm({
  onSubmit,
  dmeOrder,
  getEmploymentMentions,
}: Props) {
  const submit = (values: Values) => {
    return onSubmit(values).catch(handleError)
  }

  const { isFeatureEnabled } = useFeatureFlags()
  const primaryInsurancePolicy = primaryPolicyFor(dmeOrder)
  const payorActivityFeedInclusion =
    isFeatureEnabled("payorOrderDetails") &&
    primaryInsurancePolicy?.payorEnhancedWorkflowEnabled

  const placeholderText = () =>
    payorActivityFeedInclusion
      ? "Type a global comment or use @ to mention someone"
      : "Type a comment to your supplier or use @ to mention someone"

  const alertText = () => {
    let prependString
    if (payorActivityFeedInclusion) {
      prependString =
        "Visible to supplier and payor. They will only see\
      comment after order is sent. "
    } else if (!dmeOrder.supplier) {
      prependString = "Supplier will only see comment after order is sent. "
    } else {
      prependString = ""
    }

    return `${prependString}Do not post credit card info.`
  }

  return (
    <Form
      initialValues={{ content: "" }}
      onSubmit={(values, actions) =>
        submit(values).then(() => {
          actions.resetForm()
        })
      }
    >
      {({ isSubmitting, submitForm, values }) => (
        <>
          <CommentField
            disabled={isSubmitting}
            fetch={(term) => getEmploymentMentions(term, CommentType.Global)}
            placeholder={placeholderText()}
            submit={submitForm}
            alert={
              <Alert status="default" className="bg-lightest-gray">
                {alertText()}
              </Alert>
            }
          />
          {values.content && (
            <>
              <div className="row">
                <div className="text-right col-xl-4 offset-xl-8 col-lg-6 offset-lg-6">
                  <Button
                    type="submit"
                    className="btn-brand btn-block"
                    disabled={isSubmitting}
                  >
                    Post Comment
                  </Button>
                </div>
              </div>
              <p className="text-right canopy-mbs-8x color-dark-gray font-notice">
                or press 'Ctrl+Enter' to post
              </p>
            </>
          )}
        </>
      )}
    </Form>
  )
}

export default FacilityCommentForm
