import React from "react"
import * as styles from "./FocusTiles.module.scss"
import Link from "components/Link"
import Sparkles from "sparkles.svg"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { completeFacilityProfileUrl } from "../../urls"
import ProgressDonut from "components/ProgressDonut"

interface Props {
  percent?: number
}

const ProfileCompletionTile: React.FC<Props> = ({ percent = 0 }) => {
  const url = completeFacilityProfileUrl()
  return (
    <Link className={styles.containerProfileCompletion} href={url}>
      <CanopyFlex direction="row">
        {percent === 0 ? (
          <img src={Sparkles} />
        ) : (
          <ProgressDonut
            className="progress-circle-s canopy-m-0 canopy-mr-1x canopy-pt-1x"
            strokeColor="#6100B3"
            percentage={percent}
          />
        )}
        <CanopyFlex direction="column">
          <div className={styles.innerContainer}>
            <h2 className={styles.title}>Get more done with Parachute</h2>
          </div>
          <h2 className={styles.subtitleProfileCompletion}>
            Complete your account
          </h2>
        </CanopyFlex>
      </CanopyFlex>
      <div
        data-testid="shine"
        className={[styles.slide, styles.outerShine].join(" ")}
      >
        <div className={styles.innerShine} />
      </div>
    </Link>
  )
}

export default ProfileCompletionTile
