import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import * as tokens from "@parachutehealth/canopy-tokens-color"

interface Props {
  onClick(): void
}

function ClearAllLink({ onClick }: Props) {
  return (
    <CanopyButton
      className="link link-sm nowrap"
      onClick={onClick}
      style={{
        textDecoration: "underline",
        color: tokens.canopyColorInteractiveLinkDefault,
        backgroundColor: "transparent",
        border: "none",
        margin: 0,
        padding: 0,
      }}
      size="small"
    >
      Clear all filters
    </CanopyButton>
  )
}

export default ClearAllLink
