import React from "react"
import * as styles from "./SalesCollaborationToolsLowEngagement.module.scss"
import SalesCollaborationToolsScreen from "../SalesCollaborationTools/components/SalesCollaborationToolsScreen"
import { Option } from "components/SupplierOrganizationDashboard/LeftSidebar"

export interface Props {
  supplierOrgExternalId: string
  salesTeamCollaborationToolsLowEngagementMainApp?: boolean
  isSsr: boolean
}

const SalesCollaborationToolsLowEngagement = (props: Props) => {
  return (
    <SalesCollaborationToolsScreen
      {...props}
      screen={Option.LowEngagement}
      headerContent={
        <>
          <h1>
            <span className={styles.headerText}>Low engagement</span>
          </h1>
          <div className={styles.subheaderText}>
            These facilities haven’t had any user logins in the last 60 days.
            Facilities with zero user logins for over six months have been
            excluded.
          </div>
        </>
      }
    />
  )
}

export default SalesCollaborationToolsLowEngagement
