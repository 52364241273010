import React from "react"
import * as styles from "./index.module.scss"

export const SubSectionHeader = ({ text }: { text: string }) => {
  return (
    <>
      <h3 className={styles.subSectionHeader}>{text}</h3>
    </>
  )
}

export const SectionHeader = ({
  headerText,
  subheaderText,
}: {
  headerText: string
  subheaderText: string
}) => {
  return (
    <>
      <h2 className={styles.header}>{headerText}</h2>
      <div className={styles.subheader}>{subheaderText}</div>
    </>
  )
}
