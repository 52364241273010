import React, { useState } from "react"
import CheckHand from "check-hand.svg"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { completeFacilityProfileUrl } from "../../urls"

const CompleteFacilityProfileModal = () => {
  const [open, setOpen] = useState(true)

  const onClose = () => {
    setOpen(false)
  }

  return (
    <CanopyDialogModal
      headerOverride={
        <div className="text-center canopy-ml-20x">
          <img
            src={CheckHand}
            alt="Hand in check"
            className="canopy-my-4x"
            style={{ width: "86px", height: "86px" }}
          />
          <h2 className="bold">Get more done with Parachute</h2>
        </div>
      }
      open={open}
      unclosable
      centeredFooter
      primaryFooterButton={
        <CanopyButton
          as="a"
          href={completeFacilityProfileUrl()}
          iconEnd="arrow-right"
        >
          Get started
        </CanopyButton>
      }
      secondaryFooterButton={
        <CanopyButton
          className="canopy-mr-6x"
          onClick={onClose}
          variant="secondary"
        >
          Remind me later
        </CanopyButton>
      }
      size="medium"
    >
      <div className="text-center">
        Verify your facility’s details to ensure orders go through without
        delays. Spend less time fixing issues and more time helping patients.
      </div>
      &nbsp;
      <div className="text-center">It will take less than 2 minutes.</div>
      &nbsp;
    </CanopyDialogModal>
  )
}

export default CompleteFacilityProfileModal
