import { gql } from "@apollo/client"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import {
  CarrierAuthorizationSection_GetCarrierAuthorizationByIdQuery,
  CarrierAuthorizationSection_GetCarrierAuthorizationByIdQueryVariables,
} from "graphql/__generated__/graphql"

export const CarrierAuthorizationSection_GetCarrierAuthorizationById: TypedDocumentNode<
  CarrierAuthorizationSection_GetCarrierAuthorizationByIdQuery,
  CarrierAuthorizationSection_GetCarrierAuthorizationByIdQueryVariables
> = gql`
  query CarrierAuthorizationSection_GetCarrierAuthorizationById($id: String!) {
    carrierAuthorization(id: $id) {
      id
      requestSubmitDateTime
    }
  }
`
