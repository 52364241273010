import React, { useCallback, useContext, useEffect, useState } from "react"
import { DocumentationRequirement } from "sharedTypes"
import { PdfViewer } from "./PdfViewer"
import {
  documentationRequirementPdfDataUrl,
  updateDocumentationRequirementPdfDataUrl,
} from "./urls"
import WorkflowPageContext from "context/WorkflowPage"
import { SidebarTab } from "applications/Workflow/containers/Navigation/sharedTypes"
import { PageHeader, PdfSavingState } from "./PageHeader"
import { markDocumentationRequirementAsComplete } from "./api"
import * as styles from "./styles.module.scss"
import axios from "axios"
import { getAuthorizationHeaders } from "services/api"
import { SaveDocumentCallback } from "./PdfViewer/viewSDKClient"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import {
  FACILITY_REVIEW_COPY,
  FACILITY_REVIEW_CTA,
  SUPPLIER_REVIEW_COPY,
  SUPPLIER_REVIEW_CTA,
} from "../../utilities"
import { useFeatureFlags } from "../../../../../../components/FeatureFlagContext"
import { canopySpace12X } from "@parachutehealth/canopy-tokens-space"

type Props = {
  refreshDmeOrder(): Promise<void>
  documentationRequirement: DocumentationRequirement
  nextPagePath: string | undefined
  supplierView: boolean
}

export const FillablePdfDocumentationRequirement = ({
  refreshDmeOrder,
  documentationRequirement,
  nextPagePath,
  supplierView,
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const fillablePdfFlag = isFeatureEnabled("enhancedFillableForms")

  const { setSidebarTab } = useContext(WorkflowPageContext)
  useEffect(() => {
    setSidebarTab(SidebarTab.Closed)
  }, [setSidebarTab])
  const [savingState, setSavingState] = useState<PdfSavingState>("unsaved")

  useEffect(() => {
    setSavingState("unsaved")
  }, [documentationRequirement.externalId])

  const saveDocument = useCallback<SaveDocumentCallback>(
    (data) => {
      setSavingState("saving")
      return axios({
        method: "put",
        url: updateDocumentationRequirementPdfDataUrl(
          documentationRequirement.externalId
        ),
        data: data,
        headers: {
          "Content-Type": "application/pdf",
          ...getAuthorizationHeaders(),
        },
      })
        .then(() => {
          setSavingState("saved")
        })
        .catch((e) => {
          setSavingState("unsaved")
          throw e
        })
    },
    [documentationRequirement.externalId]
  )
  return (
    <div className={styles.fullHeightContainer}>
      <PageHeader
        savingState={savingState}
        documentationRequirement={documentationRequirement}
        markComplete={() =>
          markDocumentationRequirementAsComplete(
            documentationRequirement.externalId
          )
        }
        refreshDmeOrder={refreshDmeOrder}
        nextPagePath={nextPagePath}
      />
      {fillablePdfFlag && (
        <CanopyNotice
          closable={true}
          title={supplierView ? SUPPLIER_REVIEW_CTA : FACILITY_REVIEW_CTA}
          message={supplierView ? SUPPLIER_REVIEW_COPY : FACILITY_REVIEW_COPY}
          variant="information"
          style={{
            marginBottom: canopySpace12X,
          }}
        />
      )}
      <PdfViewer
        documentUrl={documentationRequirementPdfDataUrl(
          documentationRequirement.externalId
        )}
        saveDocument={saveDocument}
      />
    </div>
  )
}
