// @ts-strict-ignore
import React, { useEffect, useRef, useState } from "react"
import { useEffectRunOnEveryRender } from "hooks"
import withInitialData from "components/withInitialData"
import {
  ClinicalFacility,
  ContextEmployer,
  DmeOrder,
  DmeOrderWarning,
  Event,
  EventAction,
  Comment,
  EmployerType,
} from "sharedTypes"
import Events from "components/Activity/components/Events"
import * as api from "./api"
import { unique } from "utilities/array"
import { handleError } from "utilities/error"
import { ActivityData } from "components/Activity/sharedTypes"
import ActivitySelector from "./components/ActivitySelector"
import CommentForm from "./components/CommentForm"
import { mergeEvents } from "components/Activity/utilities/events"
import { useWebSockets } from "hooks/useWebSockets"
import {
  DEFAULT_FILTER_OPTION,
  Label,
  FilterOption,
  FILTERS_BY_LABEL,
} from "./utilities/filters"
import TitleBar from "./components/TitleBar"
import EmptyMessage from "./components/EmptyMessage"
import NewCommentMessage from "./components/NewCommentMessage"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { useFeatureFlags } from "components/FeatureFlagContext"
import useClinicalFacilityHasSignedAgreements from "./hooks/useClinicalFacilityHasSignedAgreements"
import { primaryPolicyFor } from "applications/Workflow/utilities/payment"

type Props = {
  selectedOption: FilterOption
  setSelectedOption(option: FilterOption): void
  initialData: ActivityData
  dmeOrder: DmeOrder
  clinicalFacility: ClinicalFacility
  warnings: DmeOrderWarning[]
  employer: ContextEmployer
}

const POLLING_INTERVAL = 30000
const fetch = () => api.getActivityData().then(({ data }) => data)

const Activity: React.FC<Props> = ({
  selectedOption,
  setSelectedOption,
  initialData,
  dmeOrder,
  clinicalFacility,
  warnings,
  employer,
}) => {
  const dmeOrderId = dmeOrder.id
  const [warningMessages, setWarningMessages] = useState(initialData.warnings)
  const [events, setEvents] = useState(initialData.events)
  const [visibleEvents, setVisibleEvents] = useState(events)
  const [newEventAction, setNewEventAction] = useState(null)
  const [activeUsers, setActiveUsers] = useState(initialData.activeUsers)
  const { permissions } = dmeOrder

  const { isFeatureEnabled } = useFeatureFlags()

  useEffect((): void => {
    const selectedFilter = FILTERS_BY_LABEL[selectedOption.label]
    setVisibleEvents(events.filter(selectedFilter))
  }, [events, selectedOption])

  const selectedOptionRef = useRef(selectedOption)
  useEffectRunOnEveryRender(() => {
    selectedOptionRef.current = selectedOption
  })
  useEffect(() => setNewEventAction(null), [selectedOption])

  const onNewEvent = (event: Event) => {
    const selectedOptionLabel = selectedOptionRef.current.label

    if (
      event.action === EventAction.InternalComment &&
      selectedOptionLabel === Label.GlobalComments
    ) {
      setNewEventAction(EventAction.InternalComment)
    }
    if (
      event.action === EventAction.Comment &&
      selectedOptionLabel === Label.InternalComments
    ) {
      setNewEventAction(EventAction.Comment)
    }

    setActiveUsers((activeUsers) => unique([...activeUsers, event.userId]))
    setEvents((events: Event[]) => mergeEvents(events, event))
  }

  const onOnlineChange = (data) => {
    setActiveUsers((activeUsers) => unique([...activeUsers, data.userId]))
  }

  useWebSockets(
    { channel: "EventsChannel", id: dmeOrderId },
    { received: onNewEvent }
  )

  useWebSockets(
    {
      channel: "OnlineChannel",
      dmeOrderId: dmeOrderId,
      id: dmeOrder.supplier?.externalId,
    },
    { received: onOnlineChange, disabled: !dmeOrder.supplier }
  )

  useWebSockets(
    {
      channel: "OnlineChannel",
      dmeOrderId: dmeOrderId,
      id: dmeOrder.clinicalFacility?.externalId,
    },
    { received: onOnlineChange, disabled: !dmeOrder.clinicalFacility }
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      api.getActiveUsers().then(({ data }) => setActiveUsers(data))
    }, POLLING_INTERVAL)
    return () => clearInterval(intervalId)
  }, [])

  const createComment = (values: Comment): Promise<void> => {
    return api
      .createComment(values)
      .then(fetch)
      .then(({ warnings, events }) => {
        setWarningMessages(warnings)
        setEvents(events)
      })
      .catch(handleError)
  }

  const createInternalComment = (values: Comment): Promise<void> => {
    return api
      .createInternalComment(values)
      .then(fetch)
      .then(({ warnings, events }) => {
        setWarningMessages(warnings)
        setEvents(events)
      })
      .catch(handleError)
  }

  const createSupplierComment = (values: Comment): Promise<void> => {
    return api
      .createSupplierComment(values)
      .then(fetch)
      .then(({ warnings, events }) => {
        setWarningMessages(warnings)
        setEvents(events)
      })
      .catch(handleError)
  }

  // See WF-26969
  const clinicalFacilityHasSignedAgreements = useClinicalFacilityHasSignedAgreements(
    dmeOrder
  )

  function renderWarningWhenFacilityHasNoSignedAgreements() {
    if (!isFeatureEnabled("warnWhenFacilityHasNoSignedAgreements")) return null
    if (employer.employerType !== EmployerType.Supplier) return null
    if (clinicalFacilityHasSignedAgreements) return null

    return (
      <CanopyNotice
        title="Responses to comments in the activity feed are unlikely"
        message="This facility does not receive notifications for comments in the activity feed. "
      />
    )
  }

  const patientPayorDiscoveryNotice = () => {
    if (employer.employerType === EmployerType.InsurancePayor) return null

    const primaryInsurancePolicy = primaryPolicyFor(dmeOrder)
    if (
      isFeatureEnabled("payorOrderDetails") &&
      primaryInsurancePolicy?.payorEnhancedWorkflowEnabled
    ) {
      return (
        <CanopyNotice
          variant="discovery"
          closable
          title="Your patient's payor is on Parachute!"
          message="The payor can see this patient's orders and will be part of the chat conversation when relevant"
          className="canopy-mbe-8x"
        />
      )
    }
  }

  return (
    <div data-analytics-context="ActivityFeed">
      <TitleBar dmeOrder={dmeOrder} />
      {renderWarningWhenFacilityHasNoSignedAgreements()}
      {permissions.comment && (
        <CommentForm
          warnings={warnings}
          employer={employer}
          createComment={createComment}
          getEmploymentMentions={api.getEmploymentMentions}
          createInternalComment={createInternalComment}
          createSupplierComment={createSupplierComment}
          warningMessages={warningMessages}
          viewFilter={selectedOption.label}
          dmeOrder={dmeOrder}
        />
      )}
      <hr />
      {patientPayorDiscoveryNotice()}
      <div className="canopy-mbe-8x">
        <ActivitySelector
          employerType={employer.employerType}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        {newEventAction && (
          <NewCommentMessage
            onClick={() => setSelectedOption(DEFAULT_FILTER_OPTION)}
            action={newEventAction}
          />
        )}
      </div>
      <Events
        clinicalFacility={clinicalFacility}
        events={visibleEvents}
        activeUsers={activeUsers}
      />
      {visibleEvents.length === 0 && (
        <EmptyMessage viewFilter={selectedOption.label} />
      )}
    </div>
  )
}

export default withInitialData(fetch)(Activity)
