import React from "react"
import RequirementsForm from "./RequirementsForm"
import { validate } from "../utilities/validation"
import { toInches } from "utilities/conversion"
import { Employer, LineItemGroupCommonAttributes } from "sharedTypes"
import { FormikValues } from "formik"

type Props = {
  currentEmployer: Employer
  lineItemGroupCommonAttributes: LineItemGroupCommonAttributes
  onSkip(): void
  onContinue(
    params?: FormikValues
  ): Promise<LineItemGroupCommonAttributes> | Promise<void>
  useFeetForHeight: boolean
  changeHeightUnit(): void
}

function Requirements({
  currentEmployer,
  lineItemGroupCommonAttributes,
  useFeetForHeight,
  onSkip,
  onContinue,
  changeHeightUnit,
}: Props) {
  const {
    heightInches,
    weightLbs,
    numberOfWounds,
    requiresHeight,
    requiresWeight,
    requiresWoundCount,
  } = lineItemGroupCommonAttributes
  const convertHeightToFeet = requiresHeight && useFeetForHeight

  const onSubmit = (values, { setErrors }) => {
    const { heightFeet, ...newValues } = values
    if (convertHeightToFeet) {
      newValues.heightInches = toInches(heightFeet, values.heightInches)
    }
    onContinue(newValues).catch((errors) => {
      setErrors(errors)
    })
  }

  const getHeight = (heightInches) => {
    if (convertHeightToFeet) {
      const feet = Math.floor(heightInches / 12)
      const inches = heightInches % 12
      return {
        heightFeet: feet || "",
        heightInches: inches || (feet ? 0 : ""),
      }
    }
    return { heightFeet: "", heightInches: heightInches || "" }
  }

  const validateErrors = validate({
    requiresHeight,
    requiresWeight,
    requiresWoundCount,
    useFeetForHeight,
  })
  const initialValues = {
    weightLbs: weightLbs || "",
    numberOfWounds: numberOfWounds || "",
    ...getHeight(heightInches),
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <RequirementsForm
          enableReinitialize
          initialValues={initialValues}
          onSkip={onSkip}
          onSubmit={onSubmit}
          validate={validateErrors}
          requiresHeight={requiresHeight}
          requiresWeight={requiresWeight}
          requiresWoundCount={requiresWoundCount}
          useFeetForHeight={useFeetForHeight}
          changeHeightUnit={changeHeightUnit}
          currentEmployer={currentEmployer}
        />
      </div>
    </div>
  )
}

export default Requirements
