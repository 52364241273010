import { CanopyNotice } from "@parachutehealth/canopy-notice"
import React, { useEffect, useState } from "react"

import { EventCategory, trackEvent } from "utilities/tracking"
import SsrAppStoreBannerModal from "./SsrAppStoreBannerModal"
const CLOSED_SALES_REP_APP_BANNER_KEY = "closed_sales_rep_app_banner"
const SalesRepAppBanner = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    trackEvent(EventCategory.SalesCollaborationTools, "show_ssr_app_banner")
  }, [])

  const openSsrAppStoreBannerModal = () => {
    trackEvent(
      EventCategory.SalesCollaborationTools,
      "open_ssr_app_store_modal"
    )
    setOpen(true)
  }
  const closeSsrAppStoreBannerModal = () => {
    trackEvent(
      EventCategory.SalesCollaborationTools,
      "close_ssr_app_store_modal"
    )
    setOpen(false)
  }

  if (localStorage.getItem(CLOSED_SALES_REP_APP_BANNER_KEY) === "true") {
    return null
  }
  return (
    <>
      <SsrAppStoreBannerModal
        isOpen={open}
        onClose={closeSsrAppStoreBannerModal}
      />
      <CanopyNotice
        actions={[
          {
            onClick: openSsrAppStoreBannerModal,
            text: "View download instructions",
          },
        ]}
        message="Facilities you save can be also accessed in the Parachute SSR mobile app. Download in the Apple App Store or Google Play."
        title="Available in the Supplier Sales Rep mobile app!"
        variant="discovery"
        onClose={() => {
          localStorage.setItem(CLOSED_SALES_REP_APP_BANNER_KEY, "true")
          return trackEvent(
            EventCategory.SalesCollaborationTools,
            "close_ssr_app_store_notice"
          )
        }}
        closable
      />
    </>
  )
}

export default SalesRepAppBanner
