// @team @destroythefax
import React, { Dispatch, SetStateAction } from "react"
import { DmeOrder } from "sharedTypes"
import OrderActionsDropdownButton from "applications/Workflow/containers/Navigation/components/OrderActionsDropdown/OrderActionsDropdownButton"
import SnoozeModal from "components/ClinicalModals/SnoozeModal"
import { EventCategory, trackEvent } from "utilities/tracking"

type Props = {
  snoozeDmeOrder(params): Promise<void>
  dmeOrder: DmeOrder
  setOrderActionsDropdownOpen: Dispatch<SetStateAction<boolean>>
  removeClickOutsideDropdownMenuListener?(): void
}

const SnoozeButton = ({
  snoozeDmeOrder,
  dmeOrder,
  setOrderActionsDropdownOpen,
  removeClickOutsideDropdownMenuListener,
}: Props) => {
  const [showSnoozeModal, setShowSnoozeModal] = React.useState(false)
  const onClick = () => {
    removeClickOutsideDropdownMenuListener &&
      removeClickOutsideDropdownMenuListener()
    openSnoozeModal()
  }
  const openSnoozeModal = () => {
    trackEvent(EventCategory.InOrderActions, "in_order_actions_snooze_selected")
    setShowSnoozeModal(true)
  }
  const closeSnoozeModal = () => {
    setShowSnoozeModal(false)
    setOrderActionsDropdownOpen(false)
  }

  return (
    <>
      <OrderActionsDropdownButton
        iconName="clock-rotate-left"
        title="Snooze&nbsp;order"
        subtitle="Complete the order at a later date"
        onClick={onClick}
      />
      <SnoozeModal
        snoozeDmeOrder={snoozeDmeOrder}
        dmeOrder={dmeOrder}
        isOpen={showSnoozeModal}
        onClose={closeSnoozeModal}
      />
    </>
  )
}

export default SnoozeButton
