import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import ReassignSalesRepsTable, {
  SalesRepAssignment,
} from "./ReassignSalesRepsTable"
import { OptionItem } from "@parachutehealth/canopy-combobox-field"
import * as api from "applications/ReassignSalesReps/api"
import { SalesRepSearchResult } from "applications/ReassignSalesReps/api"
import BackToPageButton from "../../components/BackToPageButton"
import { GridRowId } from "@mui/x-data-grid-pro"
import { handleError } from "utilities/error"
import { Success } from "components/AsyncButton"
import ReassignSalesRepTabs, {
  isOnTab,
  SelectedTab,
} from "applications/ReassignSalesReps/ReassignSalesRepsTabs"
import ReassignSalesRepForm from "applications/ReassignSalesReps/ReassignSalesRepsForm"
import TransferSalesRepsForm from "applications/ReassignSalesReps/TransferSalesRepsForm"
import { AxiosResponse } from "axios"
import { Alert, Color } from "@material-ui/lab"
import { Snackbar, SnackbarOrigin } from "@material-ui/core"
import { iconMapping as toastIconMapping } from "components/Toaster"

import { getSalesReps } from "./api"
import { debounce } from "lodash"

type Props = {
  settingsPath: string
  salesRepsSearchPath: string
  territoriesPath: string
}

const ReassignSalesReps: React.FC<Props> = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    SelectedTab.Select
  )

  const [selectedSalesRep, setSelectedSalesRep] = useState<OptionItem>({
    label: "",
    value: "",
  })
  const { value: selectedSalesRepId } = selectedSalesRep || {}

  const [assignmentsLoading, setAssignmentsLoading] = useState(false)
  const [salesRepAssignments, setSalesRepAssignments] = useState(
    [] as SalesRepAssignment[]
  )
  const [userOptions, setUserOptions] = useState([] as OptionItem[])
  const [recipientUserOptions, setRecipientUserOptions] = useState(
    [] as OptionItem[]
  )
  const [userOptionsLoading, setUserOptionsLoading] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState<string>("success")
  const [severity, setSeverity] = React.useState<Color>("success")

  useEffect(() => {
    if (selectedSalesRepId !== "") {
      getAssignments(null).then().catch(handleError)
    }
  }, [selectedSalesRepId])

  const debouncedFetch = useMemo(
    () =>
      debounce(async (newValue, setUserOptionsCallback) => {
        const searchResults: SalesRepSearchResult[] = await getSalesReps(
          newValue,
          props.salesRepsSearchPath
        )

        const mappedUserOptions: OptionItem[] = searchResults.map((rep) => {
          return { label: rep.name, value: rep.externalId }
        })

        setUserOptionsCallback(mappedUserOptions)
        setUserOptionsLoading(false)
      }, 500),
    [props.salesRepsSearchPath]
  )

  const searchSalesReps = useCallback(
    async (newValue: string, setUserOptionsCallback: Function) => {
      setUserOptionsLoading(true)
      if (newValue.length > 1) {
        debouncedFetch(newValue, setUserOptionsCallback)
      } else {
        setUserOptionsCallback([])
        setUserOptionsLoading(false)
      }
    },
    [debouncedFetch]
  )

  const searchAndSetUserOptions = async (newValue: string) => {
    searchSalesReps(newValue, setUserOptions)
  }

  const searchAndSetRecipientUserOptions = async (newValue: string) => {
    searchSalesReps(newValue, setRecipientUserOptions)
  }

  const getAssignments = async (selectedSalesRepId: string | null) => {
    setAssignmentsLoading(true)
    return api.getAssignments(selectedSalesRepId).then((response) => {
      setSalesRepAssignments(response.data["salesRepAssignments"])
      setAssignmentsLoading(false)
    })
  }

  const DEFAULT_POSITION = { vertical: "bottom", horizontal: "left" }

  const setToast = (message: string, severity: Color) => {
    setMessage(message)
    setSeverity(severity)
    setOpen(true)
  }

  const closeToast = () => {
    setOpen(false)
  }

  const updateAssignments = async (
    selectedSalesRepId: string,
    initiallySelectedRows: GridRowId[],
    selectedRows: GridRowId[]
  ): Promise<void> => {
    try {
      await api.updateAssignments(
        selectedSalesRepId,
        initiallySelectedRows,
        selectedRows
      )

      // NOTE: passing in this id doesn't do anything
      await getAssignments(selectedSalesRepId)
      setToast("Facility assignments updated successfully.", "success")
    } catch {
      setToast("Assignment update failed. Please try again.", "error")
    }
  }

  const handleSuccess = async (
    result: AxiosResponse<{ updatedAgreements: string[] }>
  ): Promise<Success> => {
    await getAssignments(selectedSalesRepId)
    const numberUpdated = result.data.updatedAgreements.length
    setToast(
      `Facility assignments updated successfully. ${numberUpdated} agreements updated.`,
      "success"
    )
    return new Success()
  }

  return (
    <>
      <BackToPageButton
        path={props.settingsPath}
        labelText="Back to Settings"
      />
      <h1>Reassign Sales Reps</h1>
      <ReassignSalesRepTabs
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
      {isOnTab(selectedTab, SelectedTab.Select) && (
        <ReassignSalesRepForm
          onInputChange={searchAndSetUserOptions}
          selectedSalesRep={selectedSalesRep}
          setSelectedSalesRep={setSelectedSalesRep}
          userOptions={userOptions}
          userOptionsLoading={userOptionsLoading}
        />
      )}
      {isOnTab(selectedTab, SelectedTab.Transfer) && (
        <TransferSalesRepsForm
          givingSalesRep={selectedSalesRep}
          handleSuccess={handleSuccess}
          onInputChange={searchAndSetUserOptions}
          onRecipientInputChange={searchAndSetRecipientUserOptions}
          recipientUserOptions={recipientUserOptions}
          salesRepAssignments={salesRepAssignments}
          setGivingSalesRep={setSelectedSalesRep}
          setToast={setToast}
          userOptions={userOptions}
          userOptionsLoading={userOptionsLoading}
        />
      )}
      {selectedSalesRepId && isOnTab(selectedTab, SelectedTab.Select) && (
        <ReassignSalesRepsTable
          assignmentsLoading={assignmentsLoading}
          salesRepAssignments={salesRepAssignments}
          selectedSalesRep={selectedSalesRep}
          updateAssignments={updateAssignments}
          territoriesPath={props.territoriesPath}
        />
      )}
      <Snackbar
        open={open}
        anchorOrigin={DEFAULT_POSITION as SnackbarOrigin}
        autoHideDuration={10000}
        onClose={closeToast}
      >
        <Alert
          severity={severity}
          iconMapping={toastIconMapping}
          onClose={closeToast}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ReassignSalesReps
