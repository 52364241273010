// @team @demand-ordering

import React, { useState } from "react"
import Alert from "components/Alert"
import { EVENT_DATE_TIME_FORMAT, format as formatDate } from "utilities/date"
import { navigate } from "utilities/navigation"
import * as styles from "./PendingOrderAlert.module.scss"
import GetPendingOrder from "../graphql/pendingOrderQuery"
import PendingOrderMigrateToDmeOrder from "../graphql/pendingOrderMigrateToDmeOrderMutation"
import { GetPendingOrderQuery } from "graphql/__generated__/graphql"
import { useMutation, useQuery } from "@apollo/client"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyNotice } from "@parachutehealth/canopy-notice"

type Props = {
  patientExternalId: string
  showDmeOrderUrl(dmeOrderId: string): string
  onQueryCompleted?(data: GetPendingOrderQuery): void
}

const PendingOrderAlert: React.FC<Props> = ({
  patientExternalId,
  showDmeOrderUrl,
  onQueryCompleted,
}) => {
  const [hasError, setHasError] = useState(false)
  const { data } = useQuery(GetPendingOrder, {
    variables: { patientExternalId: patientExternalId },
    onCompleted: onQueryCompleted,
  })
  const pendingOrder = data?.pendingOrder

  const [completePendingOrder, { loading }] = useMutation(
    PendingOrderMigrateToDmeOrder,
    {
      onCompleted: ({ pendingOrderMigrateToDmeOrder }) => {
        const dmeOrderId =
          pendingOrderMigrateToDmeOrder?.pendingOrder?.dmeOrder?.id
        if (dmeOrderId !== undefined) {
          navigate(showDmeOrderUrl(dmeOrderId))
        } else {
          setHasError(true)
        }
      },
      onError: () => {
        setHasError(true)
      },
    }
  )

  const onClick = async () =>
    await completePendingOrder({
      variables: { pendingOrderId: pendingOrder!.id },
    })

  return pendingOrder ? (
    <>
      {hasError && (
        <CanopyNotice
          variant="error"
          title="Cannot convert pending order"
          actions={[
            {
              href: "mailto:support@parachutehealth.com",
              text: "Please contact support@parachutehealth.com",
            },
          ]}
        />
      )}
      <Alert
        status="accent"
        className={styles.pendingOrderAlert}
        leftIcon
        bordered
      >
        <span>
          An order signed{" "}
          {pendingOrder.creator &&
            `by ${pendingOrder.creator.firstName} ${pendingOrder.creator.lastName} `}
          on{" "}
          <time dateTime={pendingOrder.createdAt}>
            {formatDate(pendingOrder.createdAt, EVENT_DATE_TIME_FORMAT)}
          </time>{" "}
          is waiting to be completed.
        </span>
        <CanopyButton onClick={onClick} size="small" loading={loading}>
          Complete Order
        </CanopyButton>
      </Alert>
    </>
  ) : null
}

export default PendingOrderAlert
