import { gql } from "@apollo/client"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import {
  CarrierLineItemAuthorizationUpdateMutation,
  CarrierLineItemAuthorizationUpdateMutationVariables,
} from "../../../../graphql/__generated__/graphql"

export const CarrierLineItemAuthorizationUpdate: TypedDocumentNode<
  CarrierLineItemAuthorizationUpdateMutation,
  CarrierLineItemAuthorizationUpdateMutationVariables
> = gql`
  mutation CarrierLineItemAuthorizationUpdate(
    $id: ID!
    $applyDatesToAllLineItems: Boolean
    $hcpcs: String
    $hcpcsDetails: JSON
    $quantity: Int
    $unitsOfMeasure: String
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $previousAuthorizationNumber: String
    $priorAuthorizationNumber: String
    $priority: String
    $decisionStatus: CarrierLineItemAuthorizationStatusEnum
  ) {
    carrierLineItemAuthorizationUpdate(
      input: {
        carrierLineItemAuthorizationId: $id
        applyDatesToAllLineItems: $applyDatesToAllLineItems
        hcpcs: $hcpcs
        hcpcsDetails: $hcpcsDetails
        quantity: $quantity
        unitsOfMeasure: $unitsOfMeasure
        startDate: $startDate
        endDate: $endDate
        previousAuthorizationNumber: $previousAuthorizationNumber
        priorAuthorizationNumber: $priorAuthorizationNumber
        priority: $priority
        decisionStatus: $decisionStatus
      }
    ) {
      carrierLineItemAuthorization {
        id
        hcpcs
      }
    }
  }
`
