// @team @cat-crew

export const packagesUrl = (): string => {
  return "/cms/catalog/packages/"
}

export const packageDetailUrl = (id: string): string => {
  return `/cms/catalog/packages/${id}`
}

export const editPackageUrl = (id: string): string => {
  return `${packageDetailUrl(id)}/edit`
}
