import React from "react"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

type Props = {
  className?: string
  checked?: boolean
}

function NavigationIcon({ className, checked }: Props) {
  return (
    <CanopyIcon
      className={className}
      data-testid="navigation-icon"
      data-iconname={checked ? "circle-check" : "circle-outline"}
      name={checked ? "circle-check" : "circle-outline"}
      fill={
        checked ? "canopyColorPrimitivesGreen41" : "canopyColorPrimitivesGray74"
      }
      style={{ position: "relative", top: -1 }} // Accounting for Avenir misalignment
    />
  )
}

export default NavigationIcon
