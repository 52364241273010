// @team @destroythefax
import { CanopyHeading } from "@parachutehealth/canopy-heading"
import React from "react"
import ParachuteMuiDataGridContainer from "../../components/ParachuteMuiDataGrid/ParachuteMuiDataGridContainer"
import { isTest } from "../../utilities/environment"
import { myTeamsColumns, otherTeamsColumns } from "./teamGridColumns"
import { styled } from "@material-ui/core"
import ParachuteMuiDataGridPro from "../../components/ParachuteMuiDataGrid/ParachuteMuiDataGrid"

type Team = {
  id: string
  name: string
  members: string[]
  autoFollowScopes: string[]
}

interface Props {
  myTeams: Team[]
  otherTeams: Team[]
}

const TeamsDashboard = ({ myTeams, otherTeams }: Props) => {
  const noMyTeamsMessage = (
    <div style={{ textAlign: "center" }} className="canopy-mb-16x">
      <CanopyHeading level={2} size="large">
        You are not part of any teams
      </CanopyHeading>
      <span>Join existing teams below</span>
    </div>
  )

  const noOtherTeamsMessage = (
    <div style={{ textAlign: "center" }} className="canopy-mb-16x">
      <CanopyHeading level={2} size="large">
        There are no other teams at your facility
      </CanopyHeading>
    </div>
  )

  const Grid = styled(ParachuteMuiDataGridPro)(() => ({
    "&.MuiDataGrid-root": { border: "none" },
  }))

  return (
    <div style={{ maxWidth: "1088px", margin: "48px auto" }}>
      <CanopyHeading level={1} size="xLarge" className="canopy-mb-8x">
        My teams
      </CanopyHeading>
      {myTeams.length ? (
        <ParachuteMuiDataGridContainer>
          <Grid
            autoPageSize
            autoHeight
            columns={myTeamsColumns}
            disableColumnFilter
            disableColumnMenu
            disableColumnReorder
            disableMultipleSelection
            disableSelectionOnClick
            disableVirtualization={isTest()}
            getRowId={(row) => row.id}
            hideFooter
            rows={myTeams}
          />
        </ParachuteMuiDataGridContainer>
      ) : (
        noMyTeamsMessage
      )}
      <CanopyHeading
        level={1}
        size="xLarge"
        className="canopy-mt-16x canopy-mb-8x"
      >
        Other teams
      </CanopyHeading>
      {otherTeams.length ? (
        <ParachuteMuiDataGridContainer>
          <Grid
            autoPageSize
            autoHeight
            columns={otherTeamsColumns}
            disableColumnFilter
            disableColumnMenu
            disableColumnReorder
            disableMultipleSelection
            disableSelectionOnClick
            disableVirtualization={isTest()}
            getRowId={(row) => row.id}
            hideFooter
            rows={otherTeams}
          />
        </ParachuteMuiDataGridContainer>
      ) : (
        noOtherTeamsMessage
      )}
    </div>
  )
}

export default TeamsDashboard
