import React from "react"
import Alert from "components/Alert"
import LearnMore from "./LearnMore"
import PatientActivityStatus from "./PatientActivityStatus"
import LinkComponent from "components/Link"
import Header from "./Header"
import ResendInviteLink from "./ResendInviteLink"
import * as routes from "applications/Workflow/routes"
import { Link } from "react-router-dom"
import { Nullable } from "sharedTypes"
import { formatPhone, normalizePhone } from "utilities/phone"
import cx from "classnames"
import { conjugate } from "./utils"

type Props = {
  phoneNumber: string
  phoneCanReceiveSms: boolean
  latestMessageScheduledAt: Nullable<Date>
  globalSupplierEnabled: boolean
  globalFacilityEnabled: boolean
  facilityEnabled: boolean
  supplierControlPanelUrl: string
  orderStatus: string
  lastPatientLoginAt: Nullable<Date>
  lastFailedLoginAt: Nullable<Date>
  lastAccessEventType: Nullable<string>
  phoneNumberRejectedAt: Nullable<string>
  optedOutAt: Nullable<string>
  eSigningEnabled: boolean
  deliveryTicketSignedAt: string
  canForceInviteResend: boolean
  facilityInviteResentAt: Nullable<Date>
  resendFacilityInvite: () => void
}

const SupplierPatientPortal: React.FC<Props> = ({
  phoneNumber,
  phoneCanReceiveSms,
  latestMessageScheduledAt,
  globalSupplierEnabled,
  globalFacilityEnabled,
  facilityEnabled,
  supplierControlPanelUrl,
  orderStatus,
  lastPatientLoginAt,
  lastFailedLoginAt,
  lastAccessEventType,
  phoneNumberRejectedAt,
  optedOutAt,
  eSigningEnabled,
  deliveryTicketSignedAt,
  canForceInviteResend,
  facilityInviteResentAt,
  resendFacilityInvite,
}) => {
  const formattedPhoneNumber = formatPhone(normalizePhone(phoneNumber))

  const controlPanelLink = (
    <LinkComponent
      href={supplierControlPanelUrl}
      target="_blank"
      className="color-brand canopy-mie-12x"
    >
      View Control Panel
    </LinkComponent>
  )

  // invite already scheduled
  if (latestMessageScheduledAt) {
    const invited = latestMessageScheduledAt < new Date()
    // invite scheduled, supplier enabled
    if (globalSupplierEnabled) {
      return (
        <div className={cx("wide-fixed-row", "canopy-pbe-8x")}>
          <div className="card">
            <div className="flex">
              <Header
                invited={invited}
                eSigningEnabled={eSigningEnabled}
                latestMessageScheduledAt={latestMessageScheduledAt}
              />
            </div>
            <div className="canopy-mbs-8x">
              <p>
                An SMS with a link to the order tracker {conjugate(invited)}{" "}
                sent to <strong>{formattedPhoneNumber}</strong>{" "}
              </p>
              {invited && (
                <PatientActivityStatus
                  lastPatientLoginAt={lastPatientLoginAt}
                  lastFailedLoginAt={lastFailedLoginAt}
                  lastAccessEventType={lastAccessEventType}
                  phoneNumberRejectedAt={phoneNumberRejectedAt}
                  optedOutAt={optedOutAt}
                  eSigningEnabled={eSigningEnabled}
                  deliveryTicketSignedAt={deliveryTicketSignedAt}
                />
              )}
              {globalSupplierEnabled && controlPanelLink}
              <LearnMore />
              {eSigningEnabled && (
                <span className="canopy-mis-12x">
                  <ResendInviteLink
                    canForceInviteResend={canForceInviteResend}
                    facilityInviteResentAt={facilityInviteResentAt}
                    phoneNumber={formattedPhoneNumber!}
                    resendFacilityInvite={resendFacilityInvite}
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      )
    } else if (globalFacilityEnabled) {
      // invite scheduled, global facility enabled, supplier not enabled
      return (
        <div className={cx("wide-fixed-row", "canopy-pbe-8x")}>
          <div className="card">
            <div className="flex">
              <Header
                invited={invited}
                eSigningEnabled={eSigningEnabled}
                latestMessageScheduledAt={latestMessageScheduledAt}
              />
            </div>
            <div className="canopy-mbs-8x">
              <p>
                The facility has elected to keep the patient in the loop by
                enabling order tracking. An SMS with a link to the order tracker{" "}
                {conjugate(invited)} sent to{" "}
                <strong>{formattedPhoneNumber}</strong>. The patient can use the
                link to view their order details, and once accepted, supplier
                name.
              </p>
              {invited && (
                <PatientActivityStatus
                  lastPatientLoginAt={lastPatientLoginAt}
                  lastFailedLoginAt={lastFailedLoginAt}
                  lastAccessEventType={lastAccessEventType}
                  phoneNumberRejectedAt={phoneNumberRejectedAt}
                  optedOutAt={optedOutAt}
                  eSigningEnabled={eSigningEnabled}
                  deliveryTicketSignedAt={deliveryTicketSignedAt}
                />
              )}
              {globalSupplierEnabled && controlPanelLink}
              <LearnMore />
            </div>
          </div>
        </div>
      )
    } else {
      // invite scheduled, not global facility enabled, not supplier enabled
      // not sure if we ever end up in this state
      return null
    }
  } else if (globalFacilityEnabled && !facilityEnabled) {
    // no invite, global facility enabled, facility disabled
    return (
      <div className={cx("wide-fixed-row", "canopy-pbe-8x")}>
        <div className="card">
          <div className="flex">
            The facility has disabled the Order Tracker for this order.
          </div>
        </div>
      </div>
    )
  } else if (globalSupplierEnabled) {
    // no invite, NOT facility disabled, supplier enabled
    return (
      <div className={cx("wide-fixed-row", "canopy-pbe-8x")}>
        <div className="card">
          <div className="flex">
            <Header
              invited={false}
              eSigningEnabled={eSigningEnabled}
              latestMessageScheduledAt={latestMessageScheduledAt}
            />
          </div>
          <div className="canopy-mbs-8x">
            <p>
              Keep your patient in the loop with the Parachute Order Tracker.
              Use the link below to preview or share with your patient.
            </p>
            {controlPanelLink}
          </div>
          {!phoneCanReceiveSms && (
            <Alert leftIcon bordered status="info">
              <strong>Action Recommended!</strong> To enable this feature the
              patient&#39;s primary phone number should be mobile.{" "}
              {orderStatus === "supplier_review" && (
                <>
                  <Link to={routes.deliveryPath()}>Add a mobile number</Link> as
                  the patient&#39;s primary phone number.
                </>
              )}
            </Alert>
          )}
        </div>
      </div>
    )
  } else {
    // neither facility nor supplier enabled
    return null
  }
}

export default SupplierPatientPortal
