import React, { useEffect, useState } from "react"
import { SubSectionHeader } from "../SectionHeader"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import { searchSkus, updateCartItems } from "./api"
import type { CartItem, Sku } from "./api"
import { useCsrInboxState } from "../../../csrInboxStateContext"
import { useMutation, useQuery } from "@tanstack/react-query"
import { CanopyButton } from "@parachutehealth/canopy-button"
import {
  canopyColorTextDanger,
  canopyColorBackgroundPrimary,
  tableHead,
  skuTable,
  quantityColumn,
  actionColumn,
  itemColumn,
} from "./index.module.scss"
import { useDebounce } from "hooks/useDebounce"

const formatSku = (
  sku: Sku,
  alreadySelectedSkuIds: string[] = []
): OptionItem => ({
  value: sku.id,
  label: sku.description,
  disabled: alreadySelectedSkuIds.includes(sku.id),
})

const formatCartItem = (item: CartItem): OptionItem => ({
  value: item.skuId,
  label: item.description,
})

const useDebouncedValue = (value: string) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  const debouncedSetValue = useDebounce(
    (val) => {
      setDebouncedValue(val)
    },
    300,
    false
  )

  useEffect(() => {
    debouncedSetValue(value)
  }, [value, debouncedSetValue])

  return debouncedValue
}

const useSkuSearch = (
  searchTerm: string,
  supplierOrganizationId: string,
  csrInboxStateId: string
) => {
  const debouncedSearchTerm = useDebouncedValue(searchTerm)

  return useQuery({
    queryKey: [
      "skus",
      supplierOrganizationId,
      csrInboxStateId,
      debouncedSearchTerm,
    ],
    queryFn: () => {
      return searchSkus(
        supplierOrganizationId,
        csrInboxStateId,
        debouncedSearchTerm
      )
    },
    enabled: !!searchTerm,
  })
}

type Props = {
  initialCartItems: CartItem[]
}

export const OrderItems = ({ initialCartItems = [] }: Props): JSX.Element => {
  const csrInboxState = useCsrInboxState()
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedItems, setSelectedItems] = useState<OptionItem[]>(
    initialCartItems.map(formatCartItem)
  )
  const [feedbackMessage, setFeedbackMessage] = useState<string>()
  const [inputValue, setInputValue] = useState("")

  const { data: skus = [], isFetching: skuSearchPending } = useSkuSearch(
    searchTerm,
    csrInboxState.supplierOrganization.id,
    csrInboxState.id
  )

  const updateCartItemsMutation = useMutation({
    mutationFn: async (selectedSkus: OptionItem[]) => {
      return updateCartItems(
        csrInboxState.supplierOrganization.id,
        csrInboxState.id,
        selectedSkus.map((sku) => ({
          skuId: sku.value,
        }))
      )
    },
    onSettled: (resultOrUndefined) => {
      if (resultOrUndefined?.errors) {
        setFeedbackMessage(resultOrUndefined.errors.base.join(", "))
      } else {
        setFeedbackMessage(undefined)
      }
    },
  })

  const handleSelect = (selectedOption: OptionItem | OptionItem[] | null) => {
    if (!selectedOption) return
    if (Array.isArray(selectedOption)) {
      return
    }

    const updatedItems = [...selectedItems, selectedOption]
    const oldItems = [...selectedItems]
    setSelectedItems(updatedItems)
    updateCartItemsMutation.mutateAsync(updatedItems).then((result) => {
      if (result.errors) {
        setSelectedItems(oldItems)
      }
    })

    setInputValue("")
  }

  const handleRemove = (itemToRemove: OptionItem) => {
    const oldItems = [...selectedItems]
    const updatedItems = selectedItems.filter(
      (item) => item.value !== itemToRemove.value
    )
    setSelectedItems(updatedItems)
    updateCartItemsMutation.mutateAsync(updatedItems).then((result) => {
      if (result.errors) {
        setSelectedItems(oldItems)
      }
    })
  }

  const selectedSkuIds = selectedItems.map((item) => item.value)

  return (
    <div className="canopy-mbe-16x ">
      <SubSectionHeader text="Order items" />
      <div className={`canopy-p-8x ${canopyColorBackgroundPrimary}`}>
        <CanopyComboboxField
          label="Product search"
          placeholder="Search by description"
          options={skus.map((sku) => formatSku(sku, selectedSkuIds))}
          onInputChange={(value) => {
            setSearchTerm(value)
            setInputValue(value)
          }}
          onChange={handleSelect}
          feedbackMessage={feedbackMessage}
          loading={skuSearchPending}
          inputValue={inputValue}
        />

        {selectedItems.length > 0 && (
          <SelectedSkuTable
            selectedItems={selectedItems}
            onRemove={handleRemove}
            disableRemove={updateCartItemsMutation.isLoading}
          />
        )}
      </div>
    </div>
  )
}

const SelectedSkuTable = ({
  selectedItems,
  onRemove,
  disableRemove,
}: {
  selectedItems: OptionItem[]
  onRemove: (itemToRemove: OptionItem) => void
  disableRemove: boolean
}) => (
  <div className="canopy-mbs-8x">
    <table className={skuTable}>
      <thead className={tableHead}>
        <tr>
          <th className={itemColumn}>Item</th>
          <th className={quantityColumn}>Quantity</th>
          <th className={actionColumn}></th>
        </tr>
      </thead>
      <tbody className="canopy-typography-body-medium">
        {selectedItems.map((item) => (
          <tr key={item.value}>
            <td className={`canopy-pt-8x ${itemColumn}`}>{item.label}</td>
            <td className={`canopy-pt-8x ${quantityColumn}`}>1</td>
            <td className={`canopy-pt-8x ${actionColumn}`}>
              <CanopyButton
                className={canopyColorTextDanger}
                variant="tertiary"
                size="small"
                accessibleText={`Remove ${item.label}`}
                onClick={() => onRemove(item)}
                disabled={disableRemove}
              >
                Remove
              </CanopyButton>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)
