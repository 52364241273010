import React, { useEffect, useState, useRef } from "react"
import { FacilityData } from "../FacilityData"
import { ClinicianData, OrderingClinicianData } from "../ClinicianData"
import {
  useErrorContext,
  useMissingRequiredExternalSystemBranch,
  useMissingRequiredFacilityClinician,
} from "../../../error-context"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { useEffectThatWontRunOnMount } from "hooks/useEffectThatWontRunOnMount"
import { SectionHeader } from "../SectionHeader"
import { ExternalSystemBranchSelect } from "./ExternalSystemSelect"
import { useCsrInboxState } from "../../../csrInboxStateContext"

export const FacilityClinicianSection = ({
  clinician,
}: {
  clinician: OrderingClinicianData
}) => {
  const { submissionAttempts } = useErrorContext()
  const csrInboxState = useCsrInboxState()
  const isMissingRequiredFacilityClinician = useMissingRequiredFacilityClinician()
  const isMissingRequiredExternalSystemBranch = useMissingRequiredExternalSystemBranch(
    csrInboxState.supplier.brightreeAcceptance
  )
  const [displayErrorMessage, setDisplayErrorMessage] = useState<boolean>(
    !!submissionAttempts && isMissingRequiredFacilityClinician
  )
  const sectionRef = useRef<HTMLDivElement>(null)

  useEffectThatWontRunOnMount(() => {
    setDisplayErrorMessage(isMissingRequiredFacilityClinician)
  }, [isMissingRequiredFacilityClinician, submissionAttempts])
  useEffect(() => {
    if (
      !!submissionAttempts &&
      sectionRef.current &&
      (isMissingRequiredFacilityClinician ||
        isMissingRequiredExternalSystemBranch)
    ) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" })
    }
    // This effect should only run when the submissionAttempts changes
    // but needs to consider if the facility or clinician is missing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionAttempts])
  return (
    <>
      <div ref={sectionRef} />
      <SectionHeader
        headerText="Facility/clinician info"
        subheaderText="Select either a facility or clinician for this order."
      />
      {displayErrorMessage && (
        <CanopyNotice
          title="A facility or clinician is required to proceed."
          variant="error"
        />
      )}
      <ClinicianData clinicianInformation={clinician} />
      <FacilityData />
      {csrInboxState.supplier.brightreeAcceptance && (
        <ExternalSystemBranchSelect />
      )}
    </>
  )
}
