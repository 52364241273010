import React, { useContext } from "react"
import {
  attributeDetailUrl,
  attributesUrl,
} from "../../../../../urls/attributes"
import Breadcrumbs from "../../../../../components/Breadcrumbs"
import AttributeFormFields from "../AttributeFormFields"
import {
  getCatalogAttribute,
  updateCatalogAttribute,
} from "applications/Cms/api/attributes"
import { NoticeContext } from "../../../../../contexts/NoticeContext"
import { useHistory, useParams } from "react-router-dom"
import { Backdrop, CircularProgress } from "@material-ui/core"
import { useQuery, useQueryClient } from "@tanstack/react-query"

const EditAttributeFormPage: React.FC = () => {
  const { attributeId } = useParams()
  const queryClient = useQueryClient()
  const { showNotice } = useContext(NoticeContext)
  const history = useHistory()

  const { data: attribute, isLoading } = useQuery(
    ["EditAttributeFormPage", "catalogAttribute", attributeId],
    () => getCatalogAttribute(attributeId),
    {
      enabled: !!attributeId,
    }
  )

  const onSave = async (values, setErrors) => {
    await updateCatalogAttribute(attributeId, values).then((response) => {
      if (response.errors) {
        setErrors(response.errors)
      } else {
        queryClient.invalidateQueries(
          ["EditAttributeFormPage", "catalogAttribute", attributeId],
          { refetchType: "none" }
        )
        history.push(attributeDetailUrl(attributeId))
        showNotice(
          `${response.customAttribute.name} has been successfully updated`,
          "success",
          [],
          true
        )
      }
    })
  }

  return isLoading ? (
    <Backdrop style={{ color: "#fff", zIndex: 1_000 }} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <>
      <Breadcrumbs>
        {[
          {
            label: "All Attributes",
            to: attributesUrl(),
          },
        ]}
      </Breadcrumbs>
      <h1 className="canopy-typography-heading-2xlarge">{attribute?.name}</h1>
      <AttributeFormFields
        attribute={attribute}
        onSave={onSave}
        cancelUrl={attributeDetailUrl(attributeId)}
      />
    </>
  )
}

export default EditAttributeFormPage
