import React, { useState } from "react"
import DoctorSuggestion from "./Select/DoctorSuggestion"
import { DmeOrder } from "sharedTypes"
import { SuggestedDoctor } from "../sharedTypes"
import { CanopyComboboxField } from "@parachutehealth/canopy-combobox-field"
import { canSign } from "utilities/signature"
import { useQuery } from "@tanstack/react-query"
import { useDebounce } from "hooks/useDebounce"
import { EventCategory, trackEvent } from "utilities/tracking"

type Props = {
  dmeOrder: DmeOrder
  doctorSearch({ value }): Promise<SuggestedDoctor[]>
  selectDoctor(id: string): Promise<void>
  suggestedDoctors: SuggestedDoctor[]
}

const doctorName = (doctor) => {
  return `${doctor.firstName} ${doctor.lastName} - ${doctor.npi}`
}

const convertToOption = (doctor: SuggestedDoctor, dmeOrder: DmeOrder) => ({
  value: doctor.npi,
  label: doctorName(doctor),
  disabled: !canSign(doctor, dmeOrder),
  ...doctor,
})

const minSearchLength = 3

const ClinicianSelect: React.FC<Props> = ({
  dmeOrder,
  doctorSearch,
  selectDoctor,
  suggestedDoctors,
}) => {
  const [selectedDoctor, setSelectedDoctor] = useState(
    dmeOrder.doctor && convertToOption(dmeOrder.doctor, dmeOrder)
  )
  const [query, setQuery] = useState("")
  const debouncedSetQuery = useDebounce(setQuery)

  const suggestedForYou = [
    {
      label: "Suggested for you",
      options: suggestedDoctors.map((doctor) =>
        convertToOption(doctor, dmeOrder)
      ),
    },
  ]

  const { data: options, isFetching } = useQuery({
    queryKey: ["clinicianSearch", dmeOrder.id, query],
    enabled: !!query && query.length >= minSearchLength,
    queryFn: async () => {
      const res = await doctorSearch({ value: query })
      if (res.length === 0) {
        return []
      } else {
        return [
          ...res.map((doctor) => convertToOption(doctor, dmeOrder)),
          { value: null, disabled: true, isOther: true },
        ]
      }
    },
  })

  const displayOptions = !query
    ? suggestedForYou
    : query.length < minSearchLength
    ? []
    : options || []

  const noOptionsFoundMessage =
    query && query.length < minSearchLength
      ? `Type ${minSearchLength} or more characters to search`
      : "No clinicians found. Try again with the NPI number and/or full name."

  const onChange = (option: any) => {
    trackEvent(EventCategory.SigningClinician, "selected-clinician")
    selectDoctor(option?.npi)
    setSelectedDoctor(option)
  }

  return (
    <div className="wide-fixed-row">
      <CanopyComboboxField
        id="doctor"
        label="Who will be signing the order?"
        description="Search by clinician’s NPI or full name"
        // @ts-expect-error options type is too prescriptive
        options={displayOptions}
        // @ts-expect-error value type is too prescriptive
        value={selectedDoctor}
        loading={isFetching}
        noOptionsFoundMessage={noOptionsFoundMessage}
        onInputChange={debouncedSetQuery}
        onChange={onChange}
        optionRenderFunction={({ option }: { option: any }) => {
          if (option.isOther) {
            return (
              <span>
                Can’t find the clinician? Try searching by NPI or chat for help.
              </span>
            )
          }
          return (
            <DoctorSuggestion
              doctor={option}
              dmeOrder={dmeOrder}
              query={query}
            />
          )
        }}
      />
    </div>
  )
}

export default ClinicianSelect
