import { fetchExternalSystemBranchesUrl } from "applications/SupplierOrganizationInboxDocument/urls"
import { get, put } from "services/api"

export const fetchPreExistingExternalSystemBranch = async (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  facilityId: string
): Promise<{ id: string; name: string }> => {
  const path = `/u/r/${supplierOrganizationId}/inbox_documents/${csrInboxStateId}/facility/pre_existing_external_system_branch.json`
  return get(path, { facilityId }).then(({ data }) => data)
}

export const fetchExternalSystemBranches = async (
  supplierId: string,
  term: string
): Promise<{ name: string; id: string }[]> => {
  return get(fetchExternalSystemBranchesUrl(supplierId), { term }).then(
    ({ data }) => data.results
  )
}

export const updateBranch = (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  externalSystemBranchId: string | null
): Promise<{ success?: boolean; errors?: boolean }> => {
  const path = `/u/r/${supplierOrganizationId}/inbox_documents/${csrInboxStateId}/facility/update_external_system_branch.json`

  const requestBody = {
    supplier_system_branch_id: externalSystemBranchId,
  }
  return put(path, requestBody)
    .then(() => ({ success: true }))
    .catch(() => ({ errors: true }))
}
