// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-Insurance-CarrierAuthorizationRequest-components-PatientDetails-PatientDetails-module__container--xVXPe";
var _2 = "app-javascript-applications-Insurance-CarrierAuthorizationRequest-components-PatientDetails-PatientDetails-module__hr--U1Z97";
var _3 = "app-javascript-applications-Insurance-CarrierAuthorizationRequest-components-PatientDetails-PatientDetails-module__insurance--uHSwU";
var _4 = "app-javascript-applications-Insurance-CarrierAuthorizationRequest-components-PatientDetails-PatientDetails-module__patient--EirWV";
var _5 = "app-javascript-applications-Insurance-CarrierAuthorizationRequest-components-PatientDetails-PatientDetails-module__patientDetailCard--sR9Ax";
var _6 = "app-javascript-applications-Insurance-CarrierAuthorizationRequest-components-PatientDetails-PatientDetails-module__patientDetailHeader--E6jhq";
var _7 = "app-javascript-applications-Insurance-CarrierAuthorizationRequest-components-PatientDetails-PatientDetails-module__patientDetailItem--NYOSh";
var _8 = "app-javascript-applications-Insurance-CarrierAuthorizationRequest-components-PatientDetails-PatientDetails-module__separator--RNx58";
export { _1 as "container", _2 as "hr", _3 as "insurance", _4 as "patient", _5 as "patientDetailCard", _6 as "patientDetailHeader", _7 as "patientDetailItem", _8 as "separator" }
