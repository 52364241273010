import React from "react"
import { PackageConfiguration } from "../../sharedTypes"
import { Formik, FormikValues, useFormikContext } from "formik"
import {
  ApplicationError,
  LineItem,
  Nullable,
  PrescribedQuantity,
} from "sharedTypes"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyTextInput } from "@parachutehealth/canopy-text-input"
import { CircularProgress } from "@material-ui/core"
import { AxiosError } from "axios"
import { CanopyForm } from "@parachutehealth/canopy-form"
import * as styles from "./RxQuantity.module.scss"
import {
  CanopyFormFieldGroup,
  CanopyFormFieldGroupRenderMethodProps,
} from "@parachutehealth/canopy-form-field-group"
import { CanopySelect } from "@parachutehealth/canopy-select"
import { CanopyText } from "@parachutehealth/canopy-text"
import { isNullOrUndefined } from "../../../../../../utilities/isNullOrUndefined"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { handleError } from "../../../../../../utilities/error"

type Props = {
  lineItems: LineItem[]
  updateLineItemPrescribedQuantity(
    lineItemId: string,
    params: any
  ): Promise<PrescribedQuantity>
  onContinue(
    params?: FormikValues
  ): Promise<PackageConfiguration> | Promise<void>
}

/**
 * Until the UI supports them, we will need to backfill the visible "minimum" values
 * for some questions into the "maximum" as well (so that min === max)
 */
const backfillMaxValues = (values: RxQuantityFormFields) => {
  values.dosageMax = values.dosageMin
  values.frequencyTimesMax = values.frequencyTimesMin
  values.frequencyIntervalMax = values.frequencyIntervalMin
  // this is currently not configurable so it's hard-coded with the rest of the backfills
  values.durationUnit = "day"
}

/**
 * The main validation method used by each Formik instance. Generally-speaking, it enforces that
 * 1. Things we think should be numeric are non-zero numbers
 * 2. Everything is filled out
 */
const validate = (values: RxQuantityFormFields) => {
  backfillMaxValues(values)

  const invalidNumber = (input: any) => {
    const cast = Number(input)
    return isNaN(cast) || !(cast > 0)
  }

  return LINE_ITEM_RX_FIELD_NAMES.reduce((errors, current) => {
    if (NUMERIC_INPUTS.includes(current) && invalidNumber(values[current])) {
      errors[current] = "Must be a positive number"
    } else if (!values[current]) {
      errors[current] = "Required"
    }

    return errors
  }, {})
}

const LINE_ITEM_RX_FIELD_NAMES: string[] = [
  "dosageMin",
  "dosageMax",
  "dosageUnitId",
  "frequencyTimesMin",
  "frequencyTimesMax",
  "frequencyIntervalMin",
  "frequencyIntervalMax",
  "frequencyIntervalUnit",
  "durationValue",
  "durationUnit",
] as const

// n.b., `typeof LINE_ITEM_RX_FIELD_NAMES[number]` limits the key to known members of the array
type RxQuantityFormFields = Record<
  typeof LINE_ITEM_RX_FIELD_NAMES[number],
  Nullable<string>
>

/**
 * Certain inputs are expected to be numeric and >0
 * */
const NUMERIC_INPUTS: (keyof RxQuantityFormFields)[] = [
  "dosageMin",
  "dosageMax",
  "frequencyTimesMin",
  "frequencyTimesMax",
  "frequencyIntervalMin",
  "frequencyIntervalMax",
  "durationValue",
]

function InternalLineItemRxQuantityForm({
  item,
  onFormChange,
}: {
  item: LineItem
  onFormChange: (lineItemId: string, complete: boolean) => void
}) {
  const formik = useFormikContext<RxQuantityFormFields>()

  // keeps track of the form's state; memoized for use in
  // useEffect dependencies
  const validAndStable = React.useMemo(() => {
    return !formik.dirty && formik.isValid
  }, [formik.dirty, formik.isValid])

  React.useEffect(() => {
    onFormChange(item.externalId, validAndStable)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.submitCount, item.externalId, validAndStable])

  // We might have multiple fields feeding into the same feedback message; this arbitrarily finds the first
  // touched field that has an error.
  const feedbackMessage = (fields: (keyof RxQuantityFormFields)[]) => {
    const firstError = fields.find((f) => formik.touched[f] && formik.errors[f])
    return firstError && formik.errors[firstError]
  }

  /**
   * Conditionally render either a loading animation, a Save button, or
   * a calculated total.  This is wrapped in a useCallback because otherwise
   * it will re-render when a form field is blurred and can therefore "miss"
   * the first click.
   */
  const DispenseQuantity = React.useCallback(() => {
    if (formik.isSubmitting) {
      return (
        <span>
          <CircularProgress className="canopy-mie-2x" size={16} />
          <i>Calculating</i>
        </span>
      )
    } else if (formik.dirty || !validAndStable) {
      return (
        <CanopyButton
          onClick={formik.submitForm}
          size="small"
          variant="primary"
        >
          Save
        </CanopyButton>
      )
    } else if (item.prescribedQuantity?.calculatedTotal) {
      return <span>{item.prescribedQuantity?.calculatedTotal}</span>
    } else {
      // can we even get to this state?
      return <></>
    }
  }, [
    formik.dirty,
    formik.isSubmitting,
    formik.submitForm,
    item.prescribedQuantity?.calculatedTotal,
    validAndStable,
  ])

  return (
    <CanopyForm>
      <fieldset>
        <legend
          className={`${styles.productHeader} canopy-typography-heading-xlarge`}
        >
          {item.skuDescription}
        </legend>

        <div className={styles.form}>
          <CanopyFormFieldGroup
            className={styles.formFieldGroup}
            label="Application"
            feedbackMessage={feedbackMessage([
              "dosageMin",
              "dosageMax",
              "dosageUnitId",
            ])}
          >
            {({
              ariaDescribedbyIds,
              feedbackMessage,
              feedbackMessageStatus,
              primaryControlId,
            }: CanopyFormFieldGroupRenderMethodProps) => (
              <CanopyFlex alignItems="center">
                <span>Take</span>
                <CanopyFlex justifyContent="space-between" alignItems="center">
                  <CanopyTextInput
                    ariaLabel={`${item.skuDescription} Application Amount`}
                    size="small"
                    placeholder="0"
                    style={{ width: "100px" }}
                    disabled={formik.isSubmitting}
                    name="dosageMin"
                    defaultValue={formik.values.dosageMin || undefined}
                    id={primaryControlId}
                    ariaDescribedby={ariaDescribedbyIds}
                    invalid={
                      feedbackMessage !== undefined &&
                      feedbackMessageStatus === "error"
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  <CanopySelect
                    name="dosageUnitId"
                    ariaLabel={`${item.skuDescription} Application Unit`}
                    placeholder="Select an option"
                    ariaDescribedby={ariaDescribedbyIds}
                    defaultValue={formik.values.dosageUnitId || undefined}
                    disabled={formik.isSubmitting}
                    // CanopySelect won't take style, but it totally does
                    // @ts-ignore
                    style={{ maxWidth: "200px" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      feedbackMessage !== undefined &&
                      feedbackMessageStatus === "error"
                    }
                    options={
                      item.prescribedQuantity?.allowedDosageUnits?.map(
                        (unit) => ({
                          value: unit.id.toString(),
                          label: unit.label,
                        })
                      ) || []
                    }
                    size="small"
                  />
                </CanopyFlex>
              </CanopyFlex>
            )}
          </CanopyFormFieldGroup>

          <CanopyFormFieldGroup
            className={styles.formFieldGroup}
            label="Frequency"
            feedbackMessage={feedbackMessage([
              "frequencyIntervalMin",
              "frequencyTimesMin",
              "frequencyIntervalUnit",
            ])}
          >
            {({
              ariaDescribedbyIds,
              feedbackMessage,
              feedbackMessageStatus,
              primaryControlId,
            }: CanopyFormFieldGroupRenderMethodProps) => (
              <CanopyFlex alignItems="center">
                <CanopyTextInput
                  ariaLabel={`${item.skuDescription} Frequency times`}
                  size="small"
                  placeholder="0"
                  defaultValue={formik.values.frequencyTimesMin || undefined}
                  disabled={formik.isSubmitting}
                  style={{ width: "95px" }}
                  id={primaryControlId}
                  name="frequencyTimesMin"
                  ariaDescribedby={ariaDescribedbyIds}
                  invalid={
                    feedbackMessage !== undefined &&
                    feedbackMessageStatus === "error"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {/* don't let the browser make these words two lines */}
                <span>times&nbsp;every</span>
                <CanopyTextInput
                  ariaLabel={`${item.skuDescription} Frequency interval`}
                  size="small"
                  placeholder="0"
                  style={{ width: "75px" }}
                  defaultValue={formik.values.frequencyIntervalMin || undefined}
                  disabled={formik.isSubmitting}
                  name="frequencyIntervalMin"
                  ariaDescribedby={ariaDescribedbyIds}
                  invalid={
                    feedbackMessage !== undefined &&
                    feedbackMessageStatus === "error"
                  }
                  onChange={formik.handleChange}
                />
                <CanopySelect
                  ariaLabel={`${item.skuDescription} Frequency unit`}
                  placeholder="Select an option"
                  options={item.prescribedQuantity?.allowedFrequencyUnits || []}
                  size="small"
                  // @ts-ignore
                  style={{ maxWidth: "200px" }}
                  name="frequencyIntervalUnit"
                  defaultValue={
                    formik.values.frequencyIntervalUnit || undefined
                  }
                  disabled={formik.isSubmitting}
                  invalid={
                    feedbackMessage !== undefined &&
                    feedbackMessageStatus === "error"
                  }
                  ariaDescribedby={ariaDescribedbyIds}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </CanopyFlex>
            )}
          </CanopyFormFieldGroup>

          <CanopyFormFieldGroup
            className={styles.formFieldGroup}
            label="Supply period"
            feedbackMessage={feedbackMessage(["durationValue"])}
          >
            {({
              ariaDescribedbyIds,
              feedbackMessage,
              feedbackMessageStatus,
              primaryControlId,
            }: CanopyFormFieldGroupRenderMethodProps) => (
              <CanopyFlex alignItems="center">
                <span>For</span>
                <CanopyFlex justifyContent="space-between" alignItems="center">
                  <CanopyTextInput
                    ariaLabel={`${item.skuDescription} Supply period amount`}
                    size="small"
                    placeholder="0"
                    style={{ width: "75px" }}
                    name="durationValue"
                    defaultValue={formik.values.durationValue || undefined}
                    disabled={formik.isSubmitting}
                    id={primaryControlId}
                    ariaDescribedby={ariaDescribedbyIds}
                    invalid={
                      feedbackMessage !== undefined &&
                      feedbackMessageStatus === "error"
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span>days</span>
                </CanopyFlex>
              </CanopyFlex>
            )}
          </CanopyFormFieldGroup>

          <div className={styles.summary}>
            <CanopyText weight="bold">Dispense quantity</CanopyText>
            <CanopyText weight="bold">
              <DispenseQuantity />
            </CanopyText>
          </div>
        </div>
      </fieldset>
    </CanopyForm>
  )
}
function LineItemRxQuantityForm({
  item,
  updateLineItemPrescribedQuantity,
  onFormChange,
}: {
  item: LineItem
  updateLineItemPrescribedQuantity: (
    lineItemId: string,
    params: any
  ) => Promise<PrescribedQuantity>
  onFormChange: (lineItemId: string, complete: boolean) => void
}) {
  const initialValues: RxQuantityFormFields = LINE_ITEM_RX_FIELD_NAMES.reduce(
    (accumulator, field) => {
      const defaultValue = field === "durationUnit" ? "day" : null
      accumulator[field] =
        item.prescribedQuantity?.[field]?.toString() || defaultValue

      return accumulator
    },
    {} as RxQuantityFormFields
  )

  const initialErrors = LINE_ITEM_RX_FIELD_NAMES.reduce(
    (accumulator, current) => {
      if (!item.prescribedQuantity?.[current]) {
        accumulator[current] = "Required"
      }
      return accumulator
    },
    {}
  )

  const submit = (
    params: RxQuantityFormFields,
    { setErrors, setSubmitting, resetForm }
  ) => {
    updateLineItemPrescribedQuantity(item.externalId, params)
      .then((data) => {
        item.prescribedQuantity = data
        resetForm() // clear the dirty/etc state after it's saved
      })
      .catch((error: AxiosError) => {
        if (typeof error.response?.data === "object") {
          // meaningful JSON response (e.g., validation error)
          setErrors(error.response.data)
        } else {
          // failsafe (50x errors)
          handleError(error as ApplicationError)
        }
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Formik<RxQuantityFormFields>
      validateOnChange={true}
      enableReinitialize={true}
      initialErrors={initialErrors}
      initialValues={initialValues}
      validate={validate}
      onSubmit={submit}
    >
      {() => {
        return (
          <div className={styles.lineItemContainer}>
            <InternalLineItemRxQuantityForm
              item={item}
              onFormChange={onFormChange}
            />
          </div>
        )
      }}
    </Formik>
  )
}

function RxQuantity({
  lineItems,
  updateLineItemPrescribedQuantity,
  onContinue,
}: Props) {
  const lineItemsWithRxQuantity = lineItems.filter(
    (item) => item.prescribedQuantityRequired
  )

  const defaultFormCompletionState = lineItemsWithRxQuantity.reduce(
    (accumulator, current) => {
      accumulator[current.externalId] = !isNullOrUndefined(
        current.prescribedQuantity?.calculatedTotal
      )
      return accumulator
    },
    {}
  )

  const [formCompletionStates, setFormCompletionStates] = React.useState<
    Record<string, boolean>
  >(defaultFormCompletionState)

  const updateFormCompletionState = (lineItemId: string, complete: boolean) => {
    setFormCompletionStates({ ...formCompletionStates, [lineItemId]: complete })
  }

  const allComplete = () =>
    Object.values(formCompletionStates).every((value) => value)

  return (
    <>
      {/* this div wrapping the line items is required because of :last-child styling */}
      <div>
        {lineItemsWithRxQuantity.map((item) => (
          <LineItemRxQuantityForm
            key={item.id}
            item={item}
            updateLineItemPrescribedQuantity={updateLineItemPrescribedQuantity}
            onFormChange={updateFormCompletionState}
          />
        ))}
      </div>
      <div className="text-right">
        <button
          type="submit"
          onClick={() => onContinue()}
          className="btn btn-brand"
          disabled={!allComplete()}
        >
          Continue
        </button>
      </div>
    </>
  )
}

export default RxQuantity
