import React from "react"
import FocusTile from "./FocusTile"
import ProfileCompletionTile from "./ProfileCompletionTile"
import * as styles from "./FocusTiles.module.scss"
import {
  getIncomingOrdersCount,
  getSentBackForReviewOrdersCount,
} from "applications/ClinicalFacilityDashboard/api"
import { getUnreadEventsCount } from "applications/ClinicalFacilityDashboard/api"
import {
  createdBySupplierSubpageUrl,
  newMessagesSubpageUrl,
  sentBackForReviewSubpageUrl,
} from "./urls"
import { trackEvent, EventCategory } from "utilities/tracking"
import { ProfileCompletionProgress } from "../../sharedTypes"
import { QueryClientProvider } from "@tanstack/react-query"
import { useStableQueryClient } from "utilities/queryClient"

type FocusTileEvent =
  | "subpage_new_messages"
  | "subpage_incoming_orders"
  | "subpage_sent_back_for_review"

type Props = {
  clinicalFacilityId: string
  clinicalFacilityIsEnterprise: boolean
  profileCompletionProgress: ProfileCompletionProgress
}

const FocusTiles: React.FC<Props> = ({
  clinicalFacilityId,
  clinicalFacilityIsEnterprise,
  profileCompletionProgress,
}: Props) => {
  const queryClient = useStableQueryClient()

  if (clinicalFacilityIsEnterprise) return null

  const showProfileCompletionTile = profileCompletionProgress.show === "tile"

  const trackFocusTileEvent = (event: FocusTileEvent) => {
    trackEvent(EventCategory.FacilityDashboardFocusTiles, event)
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div className={styles.outerContainer}>
        <FocusTile
          getCount={getUnreadEventsCount}
          title="New messages"
          subtitle="Orders you follow that have new messages."
          href={newMessagesSubpageUrl(clinicalFacilityId)}
          onClick={() => trackFocusTileEvent("subpage_new_messages")}
        />
        <FocusTile
          getCount={getIncomingOrdersCount}
          title="Incoming orders"
          subtitle="Orders sent from suppliers that require review."
          href={createdBySupplierSubpageUrl(clinicalFacilityId)}
          onClick={() => trackFocusTileEvent("subpage_incoming_orders")}
        />
        <FocusTile
          getCount={getSentBackForReviewOrdersCount}
          title="Sent back for review"
          subtitle="Submitted orders sent back for revision."
          href={sentBackForReviewSubpageUrl(clinicalFacilityId)}
          onClick={() => trackFocusTileEvent("subpage_sent_back_for_review")}
        />
        {showProfileCompletionTile && (
          <ProfileCompletionTile percent={profileCompletionProgress.percent} />
        )}
      </div>
    </QueryClientProvider>
  )
}

export default FocusTiles
