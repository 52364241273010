import { Patient } from "./sharedTypes"

export const hasCompleteDemographics = (patient: Patient): boolean => {
  return !!(
    patient.firstName &&
    patient.lastName &&
    patient.dateOfBirth &&
    patient.sex
  )
}
