// @team @demand-ordering
import React, { useState } from "react"
import { Supplier } from "sharedTypes"
import LinkComponent from "components/Link"
import { RadioButtons } from "components/form"
import * as styles from "./OtherSuppliersOnParachute.module.scss"

type Props = {
  onSupplierSelect: (event: any) => void
  otherSuppliersOnParachute: Supplier[]
  initiallyShowOtherSuppliers: boolean
}

const OtherSuppliersOnParachute: React.FC<Props> = ({
  onSupplierSelect,
  otherSuppliersOnParachute,
  initiallyShowOtherSuppliers,
}) => {
  const [showOtherSuppliers, setShowOtherSuppliers] = useState<boolean>(
    initiallyShowOtherSuppliers
  )

  const supplierOption = (supplier) => {
    return {
      label: supplier.name,
      value: supplier.externalId,
    }
  }

  const otherSuppliersOnParachuteOptions = otherSuppliersOnParachute.map(
    (supplier) => supplierOption(supplier)
  )

  return showOtherSuppliers ? (
    <div className={styles.supplierGroup}>
      <h3>Other Suppliers on Parachute ({otherSuppliersOnParachute.length})</h3>
      <RadioButtons
        name="supplierId"
        options={otherSuppliersOnParachuteOptions}
        onChange={onSupplierSelect}
      />
    </div>
  ) : (
    <div className={styles.otherSuppliersLink}>
      <LinkComponent onClick={() => setShowOtherSuppliers(true)}>
        <span>Show Other Suppliers</span>
      </LinkComponent>
    </div>
  )
}

export default OtherSuppliersOnParachute
