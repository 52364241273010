import React, { useState } from "react"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyTextInputTypeValues } from "@parachutehealth/canopy-text-input"
import { useIsMutating } from "@tanstack/react-query"
import { updatePatient } from "./api"
import { onBlurHelper, useGenericUpdate } from "../helpers"
import { dataEntryLineContainer } from "../index.module.scss"
import { Insurance, Patient } from "../../../sharedTypes"
import { InsuranceInformation } from "../InsuranceInformation"
import { SectionHeader, SubSectionHeader } from "../SectionHeader"
import { usePatientQuery } from "../../../queries"

const updatePatientMutationKey = ["updatePatient"]

const PatientTextField = ({
  patient,
  label,
  fieldName,
  type,
  placeholder,
  contentEnd,
}: {
  patient: Patient
  label: string
  fieldName: keyof Pick<Patient, "mrn" | "weightLbs" | "heightInches">
  type?: CanopyTextInputTypeValues
  placeholder?: string
  contentEnd?: string
}) => {
  const numberOfMutations = useIsMutating({
    mutationKey: updatePatientMutationKey,
  })

  const [error, setError] = useState<string | undefined>(undefined)
  const [value, setValue] = useState(patient[fieldName])
  const mutatePatient = useGenericUpdate(
    fieldName,
    updatePatient,
    updatePatientMutationKey
  )

  const onBlur = onBlurHelper(
    fieldName,
    value,
    mutatePatient,
    setError,
    setValue
  )

  return (
    <CanopyTextInputField
      label={label}
      onBlur={onBlur}
      defaultValue={patient[fieldName]}
      placeholder={placeholder ? placeholder : ""}
      feedbackMessage={error}
      type={type}
      contentEnd={contentEnd ? contentEnd : ""}
      readOnly={mutatePatient.isLoading || numberOfMutations > 0}
    />
  )
}

export const PatientInformation = ({
  insurances,
}: {
  insurances: Insurance[]
}) => {
  const { data: patient } = usePatientQuery()
  if (!patient) return null
  return (
    <>
      <SectionHeader
        headerText="Patient details"
        subheaderText="Confirm the patient's basic information, insurance, and contact details."
      />
      <SubSectionHeader text="Profile" />
      <InsuranceInformation insurances={insurances} />
      <div className={dataEntryLineContainer}>
        <PatientTextField
          patient={patient}
          label="MRN"
          fieldName="mrn"
          type="text"
          placeholder="Enter patient MRN"
        />
        <div className={dataEntryLineContainer}>
          <PatientTextField
            patient={patient}
            label="Weight"
            fieldName="weightLbs"
            type="number"
            contentEnd="lbs"
          />
          <PatientTextField
            patient={patient}
            label="Height"
            fieldName="heightInches"
            type="number"
            contentEnd="in"
          />
        </div>
      </div>
    </>
  )
}
