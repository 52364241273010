import React from "react"
import { Header } from "./Header"
import LeftSidebar, {
  Option,
} from "components/SupplierOrganizationDashboard/LeftSidebar"
import { Redirect, Route } from "react-router-dom"
import { RoutePattern, RouteParams, downloadCsvTemplatePath } from "./routes"
import { withBrowserRouter } from "routers/BrowserRouter"
import DashboardTabPanel from "./tabpanels/DashboardTabPanel"
import UploadsTabPanel from "../Uploads/UploadsTabPanel"
import * as styles from "./index.module.scss"
import { FeatureFlagInput } from "../../sharedTypes"
import { Record } from "../../components/SelectFilter"
import * as api from "applications/DocumentationOrders/api"
import { uploadResultsUrl } from "../Uploads/urls"

interface Props {
  showUploadsTab?: boolean
  possibleCsvColumns: string[]
  requiredCsvColumns: string[]
  featureFlags: FeatureFlagInput
  notificationMethodOptions: Record[]
  uploadsIndex: ({ supplierOrganizationId, page }) => Promise<any>
}

const DocumentationOrderDashboard = (props: Props) => {
  return (
    <Route
      path={RoutePattern}
      render={({ match }) => {
        const supplierOrganizationId =
          match.params[RouteParams.SupplierOrganizationId]
        const activeTab = match.params[RouteParams.Tab] || "dashboard"

        return (
          <div className="position-relative">
            <LeftSidebar active={Option.SignatureRequests} />
            <div className="mt-n3">
              <div className={styles.content}>
                <Header
                  activeTab={activeTab}
                  supplierOrganizationId={supplierOrganizationId}
                  showUploadsTab={props.showUploadsTab}
                />
                {activeTab === "dashboard" && (
                  <DashboardTabPanel
                    supplierOrganizationId={supplierOrganizationId}
                    notificationMethodOptions={props.notificationMethodOptions}
                  />
                )}
                {activeTab === "uploads" &&
                  (props.showUploadsTab ? (
                    <UploadsTabPanel
                      supplierOrganizationId={supplierOrganizationId}
                      possibleCsvColumns={props.possibleCsvColumns}
                      requiredCsvColumns={props.requiredCsvColumns}
                      uploadsIndex={api.fetchUploads}
                      templatePath={downloadCsvTemplatePath(
                        supplierOrganizationId
                      )}
                      uploadGuide={{
                        label: "Resupply Guide",
                        link:
                          "https://ph-public-user-documentation.s3.amazonaws.com/Resupply+Bulk+Upload+Signature+Requests.pdf",
                      }}
                      resultsUrlBuilder={uploadResultsUrl}
                      uploadsCreate={api.createUpload}
                    />
                  ) : (
                    <Redirect to="dashboard" />
                  ))}
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default withBrowserRouter(DocumentationOrderDashboard, {})
