import React, { FC, MouseEventHandler } from "react"
import * as styles from "./ActionableOrderCard.module.scss"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { CanopyIconNames } from "@parachutehealth/canopy-icons/build/canopyIconGlyphNames"

interface Props {
  iconName: CanopyIconNames
  onClick: MouseEventHandler<HTMLButtonElement>
  iconFill?: "currentColor" | "canopyColorTextDanger"
}

const ActionsDropdownButton: FC<Props> = ({
  children,
  iconFill = "currentColor",
  iconName,
  onClick,
}) => {
  return (
    <button className={styles.orderAction} onClick={onClick}>
      <div className="d-flex align-items-center">
        <CanopyIcon fill={iconFill} name={iconName} className="canopy-mie-4x" />
        {children}
      </div>
    </button>
  )
}

export default ActionsDropdownButton
