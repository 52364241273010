import * as styles from "./index.module.scss"
import { Link } from "react-router-dom"
import LinkComponent from "components/Link"
import { productsPath } from "../../../routes"
import { ALL_SUPPLIERS } from "../../Product"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import React from "react"
import { fulfillmentAgreementsUrl } from "../InternalSupplierList/Header/urls"
import { allowSearchByProduct } from "../../../utilities/searchByProduct"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { CanopyHeading } from "@parachutehealth/canopy-heading"

export interface Props {
  suppliersCount: number
  hasActiveSuppliers: boolean
  manageFulfillmentAgreements: boolean
  usesEnterpriseFeatures: boolean
}

const YourOrganizationsSuppliersHeader = ({
  suppliersCount,
  hasActiveSuppliers,
  manageFulfillmentAgreements,
  usesEnterpriseFeatures,
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const searchByProductEnabled = allowSearchByProduct(
    isFeatureEnabled("userActivationProductFirstSearchIncludeEnterprise"),
    usesEnterpriseFeatures
  )
  return (
    <div className={styles.header}>
      <div className={styles.headerTopRow}>
        <div>
          <CanopyHeading size="xLarge" level={2} className="canopy-mbe-4x">
            {`Your Organization's Suppliers (${suppliersCount})`}
          </CanopyHeading>
          {!hasActiveSuppliers && (
            <h5 className={styles.subtitle}>
              Quick access to your facility's primary suppliers.
            </h5>
          )}
        </div>
        {manageFulfillmentAgreements && (
          <div className={`canopy-mbs-2x ${styles.manageSuppliersContainer}`}>
            <LinkComponent
              href={fulfillmentAgreementsUrl()}
              style={{ alignItems: "center", display: "flex" }}
              target="_blank"
            >
              <CanopyIcon className="canopy-mie-2x" name="gear" />
              <span className={styles.manageSuppliersLabel}>
                Manage Suppliers
              </span>
            </LinkComponent>
          </div>
        )}
      </div>
      {suppliersCount >= 2 && !searchByProductEnabled && (
        <div>
          <Link className="link font-s" to={productsPath(ALL_SUPPLIERS)}>
            <span>Browse your organization's full </span>
            <span className={styles.arrowWithWord}>
              <span className={styles.catalogWord}>catalog</span>
              <CanopyIcon
                name="arrow-right"
                size="medium"
                className={`canopy-mis-2x align-bottom ${styles.arrowIcon}`}
              />
            </span>
          </Link>
        </div>
      )}
    </div>
  )
}

export default YourOrganizationsSuppliersHeader
