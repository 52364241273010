// @team @destroythefax
import React, { FC, useEffect } from "react"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { useQuery } from "@tanstack/react-query"
import { workflowsUrl } from "../../../urls"
import { fullName } from "utilities/person"
import { EventCategory, trackEvent } from "utilities/tracking"
import { navigate } from "utilities/navigation"
import { createdBySupplierSubpageUrl } from "../../../../ClinicalFacilityDashboard/components/FocusTiles/urls"

interface Props {
  justSubmitted: boolean
  clinicalFacilityId: string
  currentOrderSigningClinicianExternalId?: string
  clinicalFacilityIsEnterprise: boolean
  getNextIncomingOrder: () => Promise<{
    nextIncomingOrder?: {
      externalId: string
      patient: { firstName: string; lastName: string }
      signingClinician: {
        firstName?: string
        lastName?: string
        externalId?: string
      }
    }
  }>
}

const IncomingOrderNotificationBanner: FC<Props> = ({
  justSubmitted,
  clinicalFacilityId,
  currentOrderSigningClinicianExternalId,
  clinicalFacilityIsEnterprise,
  getNextIncomingOrder,
}) => {
  const { getFlagValue } = useFeatureFlags()
  const featureFlag = getFlagValue("incomingOrderBannerFacilityReviewPage")
  const validFeatureFlagValues = [
    "signing_clinician",
    "next_incoming_order",
    "incoming_orders",
  ]
  const nextIncomingOrderBannerVariation = featureFlag === "next_incoming_order"
  const incomingOrdersBannerVariation = featureFlag === "incoming_orders"
  const signingClinicianBannerVariation = featureFlag === "signing_clinician"

  const { data: nextIncomingOrder } = useQuery({
    queryKey: ["incomingOrders", justSubmitted, featureFlag],
    queryFn: async () => {
      if (
        !justSubmitted ||
        !validFeatureFlagValues.includes(featureFlag as string)
      ) {
        return null
      }
      const results = await getNextIncomingOrder()
      if (!results?.nextIncomingOrder) return null
      return {
        externalId: results.nextIncomingOrder.externalId,
        patient: {
          firstName: results.nextIncomingOrder.patient.firstName,
          lastName: results.nextIncomingOrder.patient.lastName,
        },
        signingClinician: {
          firstName: results.nextIncomingOrder.signingClinician.firstName,
          lastName: results.nextIncomingOrder.signingClinician.lastName,
          externalId: results.nextIncomingOrder.signingClinician.externalId,
        },
      }
    },
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (nextIncomingOrder) {
      const signingClinicianMatches =
        nextIncomingOrder.signingClinician.externalId ===
        currentOrderSigningClinicianExternalId

      if (nextIncomingOrderBannerVariation) {
        void trackEvent(
          EventCategory.IncomingOrders,
          "next_incoming_order_banner_seen"
        )
      } else if (signingClinicianBannerVariation && signingClinicianMatches) {
        void trackEvent(
          EventCategory.IncomingOrders,
          "signing_clinician_banner_seen"
        )
      } else if (
        incomingOrdersBannerVariation &&
        !clinicalFacilityIsEnterprise
      ) {
        void trackEvent(
          EventCategory.IncomingOrders,
          "incoming_orders_banner_seen"
        )
      }
    }
  }, [
    nextIncomingOrder,
    nextIncomingOrderBannerVariation,
    incomingOrdersBannerVariation,
    signingClinicianBannerVariation,
    clinicalFacilityIsEnterprise,
    currentOrderSigningClinicianExternalId,
  ])

  if (
    !justSubmitted ||
    !validFeatureFlagValues.includes(featureFlag as string) ||
    !nextIncomingOrder
  ) {
    return null
  }

  const patientName = fullName(nextIncomingOrder.patient)
  const nextIncomingOrderLink = workflowsUrl(nextIncomingOrder.externalId)

  if (nextIncomingOrderBannerVariation) {
    const nextIncomingOrderBannerTitle = `An incoming order for patient ${patientName} needs attention`
    const nextIncomingOrderMessage = `A supplier sent an order to review and complete for patient ${patientName}`
    const nextIncomingOrderLinkText = "View\u00A0order"

    const redirectToNextIncomingOrder = () => {
      void trackEvent(
        EventCategory.IncomingOrders,
        "next_incoming_order_banner_selected"
      ).then(() => {
        navigate(nextIncomingOrderLink)
      })
    }

    return (
      <CanopyNotice
        className="canopy-mbe-16x"
        closable
        title={nextIncomingOrderBannerTitle}
        actions={[
          {
            as: "button",
            text: nextIncomingOrderLinkText,
            onClick: redirectToNextIncomingOrder,
          },
        ]}
        message={nextIncomingOrderMessage}
        variant="discovery"
      />
    )
  } else if (signingClinicianBannerVariation) {
    const signingClinicianName =
      nextIncomingOrder.signingClinician.firstName &&
      nextIncomingOrder.signingClinician.lastName
        ? fullName({
            firstName: nextIncomingOrder.signingClinician.firstName,
            lastName: nextIncomingOrder.signingClinician.lastName,
          })
        : undefined
    const signingClinicianMatches =
      nextIncomingOrder.signingClinician.externalId ===
      currentOrderSigningClinicianExternalId

    if (!signingClinicianName || !signingClinicianMatches) return null

    const signingClinicianBannerTitle = `Another order for signing clinician ${signingClinicianName} needs attention`
    const signingClinicianBannerMessage = `A supplier sent an order to review and complete for patient ${patientName}`
    const signingClinicianBannerLinkText = "View\u00A0order"
    const redirectToSigningClinician = () => {
      void trackEvent(
        EventCategory.IncomingOrders,
        "signing_clinician_banner_selected"
      ).then(() => {
        navigate(nextIncomingOrderLink)
      })
    }

    return (
      <CanopyNotice
        className="canopy-mbe-16x"
        closable
        title={signingClinicianBannerTitle}
        actions={[
          {
            as: "button",
            text: signingClinicianBannerLinkText,
            onClick: redirectToSigningClinician,
          },
        ]}
        message={signingClinicianBannerMessage}
        variant="discovery"
      />
    )
  } else if (
    incomingOrdersBannerVariation &&
    nextIncomingOrder &&
    !clinicalFacilityIsEnterprise
  ) {
    const incomingOrdersBannerTitle = "Incoming orders need attention"
    const incomingOrdersSubpageLink = createdBySupplierSubpageUrl(
      clinicalFacilityId
    )
    const incomingOrdersBannerMessage =
      "Suppliers have sent one or more orders to review and complete for your patients"
    const incomingOrdersBannerLinkText = "View\u00A0incoming\u00A0orders"
    const redirectToIncomingOrdersSubpage = () => {
      void trackEvent(
        EventCategory.IncomingOrders,
        "incoming_orders_banner_selected"
      ).then(() => {
        navigate(incomingOrdersSubpageLink)
      })
    }

    return (
      <CanopyNotice
        className="canopy-mbe-16x"
        closable
        title={incomingOrdersBannerTitle}
        actions={[
          {
            as: "button",
            text: incomingOrdersBannerLinkText,
            onClick: redirectToIncomingOrdersSubpage,
          },
        ]}
        message={incomingOrdersBannerMessage}
        variant="discovery"
      />
    )
  }
  return null
}

export default IncomingOrderNotificationBanner
