import React from "react"
import { Follower } from "sharedTypes"
import { pluralize } from "utilities/string"

type Props = {
  followers: Follower[]
}

const followerText = ({ followers }): string =>
  `${followers.length} supplier ${pluralize("follower", followers.length)}: `

const ReadOnlyFollowers = ({ followers }: Props) => {
  return (
    <p className="font-xs canopy-mt-8x">
      {followerText({ followers })}
      <span className="canopy-typography-font-weight-bold">
        {followers.map((follower) => follower.name).join(", ")}
      </span>
    </p>
  )
}

export default ReadOnlyFollowers
