import React, { useState } from "react"
import OrderCard from "./OrderCard"
import { ClinicalFacility } from "sharedTypes"
import { DashboardDmeOrder } from "../../sharedTypes"
import ActionableOrderCard from "./ActionableOrderCard/ActionableOrderCard"
import { useFeatureFlags } from "../../../../components/FeatureFlagContext"

type Props = {
  dmeOrders: DashboardDmeOrder[]
  limit: number
  clinicalFacility: ClinicalFacility
  showProgress?: boolean
  showSwatch?: boolean
  showMissingDocument?: boolean
  unreadEventCounts: object
  fetchData: () => void
  sidebarExpanded: boolean
}

function DashboardWarning({ count, limit }: { count: number; limit: number }) {
  if (count <= limit) {
    return null
  }
  return (
    <div className="alert alert-warning font-xs canopy-p-8x canopy-mbe-8x">
      Some orders hidden, use filters
    </div>
  )
}

function DashboardColumn({
  fetchData,
  dmeOrders,
  limit,
  clinicalFacility,
  unreadEventCounts,
  showSwatch,
  showMissingDocument,
  sidebarExpanded,
}: Props) {
  const { isFeatureEnabled } = useFeatureFlags()
  const showActionableCards = isFeatureEnabled(
    "facilityDashboardActionableCards"
  )

  const count = dmeOrders.length
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null)

  return (
    <>
      <DashboardWarning count={count} limit={limit} />
      {dmeOrders.length > 0 && (
        <>
          {dmeOrders.map((dmeOrder) => {
            return showActionableCards ? (
              <ActionableOrderCard
                fetchData={fetchData}
                key={dmeOrder.id}
                dmeOrder={dmeOrder}
                unreadEventCount={unreadEventCounts[dmeOrder.id]}
                sidebarExpanded={sidebarExpanded}
                openDropdownId={openDropdownId}
                setOpenDropdownId={setOpenDropdownId}
              />
            ) : (
              <OrderCard
                key={dmeOrder.id}
                clinicalFacility={clinicalFacility}
                dmeOrder={dmeOrder}
                unreadEventCount={unreadEventCounts[dmeOrder.id]}
                showSwatch={showSwatch}
                showMissingDocument={showMissingDocument}
              />
            )
          })}
        </>
      )}
      <DashboardWarning count={count} limit={limit} />
    </>
  )
}

export default DashboardColumn
