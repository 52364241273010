import React from "react"
import { ChartNote, ChartNoteStatus, QuestionnaireResponse } from "sharedTypes"
import { Link } from "react-router-dom"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { ExternalQuestionnaireSatisfyStatus } from "applications/Workflow/containers/Review/sharedTypes"
import * as routes from "applications/Workflow/routes"
import { slugify } from "utilities/string"

interface Props {
  questionnaireResponses?: QuestionnaireResponse[]
  chartNotes?: ChartNote[]
  buttonText?: string
}
const MedicalNecessityReviewButton = ({
  chartNotes,
  questionnaireResponses,
  buttonText = "Begin medical review",
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const demoClinicalGuidelines = isFeatureEnabled("demoClinicalGuidelines")

  if (demoClinicalGuidelines) {
    const firstNonQualifiedChartNote = chartNotes?.find(
      (cn) => cn.status !== ChartNoteStatus.Qualified
    )
    return firstNonQualifiedChartNote ? (
      <Link
        className="btn btn-block btn-brand"
        to={routes.chartNotePath(
          slugify(firstNonQualifiedChartNote.name),
          firstNonQualifiedChartNote.id
        )}
      >
        {buttonText}
      </Link>
    ) : (
      <></>
    )
  } else {
    const firstNonQualifiedQuestionnaireResponse = questionnaireResponses?.find(
      (qr) => {
        return (
          qr.status !== ExternalQuestionnaireSatisfyStatus.Recommended &&
          qr.status !== ExternalQuestionnaireSatisfyStatus.NotRecommended
        )
      }
    )
    return firstNonQualifiedQuestionnaireResponse ? (
      <Link
        className="btn btn-block btn-brand"
        to={routes.questionnaireResponsePath(
          firstNonQualifiedQuestionnaireResponse.id
        )}
      >
        {buttonText}
      </Link>
    ) : (
      <></>
    )
  }
}

export default MedicalNecessityReviewButton
