import React, { useState } from "react"
import { Box, Grid } from "@material-ui/core"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"

interface SearchBarProps {
  onSearch: (search: string) => void
  label?: string
  hiddenLabel?: boolean
  required?: boolean
  placeholder?: string
  searchButtonVariant?: "primary" | "secondary"
  showClearButton?: boolean
  onClear?: () => void
}

export const SearchBar = ({
  label = "search bar",
  hiddenLabel = false,
  placeholder,
  required = false,
  onSearch,
  searchButtonVariant = "primary",
  showClearButton = false,
  onClear = () => {
    return
  },
}: SearchBarProps): JSX.Element => {
  const [search, setSearch] = useState("")

  const handleClear = () => {
    onClear()
    setSearch("")
  }

  return (
    <Box mb={3} className="canopy-mbe-8x">
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item>
          <CanopyTextInputField
            label={label}
            hiddenLabel={hiddenLabel}
            placeholder={placeholder}
            type="search"
            required={required}
            data-testid="search-bar"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                void onSearch(search)
              }
            }}
          />
        </Grid>
        <Grid item className="canopy-pis-0">
          <CanopyButton
            variant={searchButtonVariant}
            onClick={() => void onSearch(search)}
          >
            Search
          </CanopyButton>
        </Grid>
        {showClearButton && (
          <Grid item className="canopy-pis-0">
            <CanopyButton variant="tertiary" onClick={handleClear}>
              Clear
            </CanopyButton>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
