import React from "react"
import { SaveStatusIndicatorComponents as Components } from "./SaveStatusIndicatorComponents"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { canopyColorTextDanger } from "@parachutehealth/canopy-tokens-color"

export type SaveStatusIndicatorState = "error" | "saved" | "saving"

const SaveStatusIndicator = ({
  saveState,
}: {
  saveState: SaveStatusIndicatorState
}) => {
  if (saveState === "error") {
    return (
      <Components.Container>
        <CanopyIcon name="circle-exclamation" fill="canopyColorTextDanger" />
        <Components.Text color={canopyColorTextDanger}>
          Failed to save
        </Components.Text>
      </Components.Container>
    )
  }

  if (saveState === "saving") {
    return (
      <Components.Container>
        <CanopyIcon name="spinner" className="spinner" />
        <Components.Text>Saving...</Components.Text>
      </Components.Container>
    )
  }

  return (
    <Components.Container>
      <CanopyIcon name="check" />
      <Components.Text>Saved</Components.Text>
    </Components.Container>
  )
}

export default SaveStatusIndicator
