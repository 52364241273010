import { InsuranceErrorType, InsuranceRank } from "sharedTypes"

type PathFn = {
  (...args: any[]): string
  matcher?: string | string[]
}

const assignMatcher = (pathFn: PathFn, matcher?: string | string[]): PathFn => {
  pathFn.matcher = matcher || pathFn()
  return pathFn
}

export const paymentPath = assignMatcher(
  (policyRank?: InsuranceRank, errorType?: InsuranceErrorType) => {
    const params = new URLSearchParams()
    if (policyRank) params.append("policyRank", policyRank)
    if (errorType) params.append("errorType", errorType)

    const query = params.toString()

    return query.length ? `/payment?${query}` : `/payment`
  }
)

export const productsPath = assignMatcher(
  (supplierId?: string) =>
    supplierId ? `/products/s/${supplierId}` : "/products",
  ["/products/s/:supplierId", "/products"]
)
export const productsQuickListPath = assignMatcher(
  (supplierId?: string) =>
    supplierId
      ? `/products/s/${supplierId}/quick_list`
      : "/products/quick_list",
  ["/products/s/:supplierId/quick_list", "/products/quick_list"]
)
export const productsPackageConfigurationPath = assignMatcher(
  (supplierId: string, id: string, selecting: boolean): string => {
    const path = supplierId
      ? `/products/s/${supplierId}/package_configuration/${id}`
      : `/products/package_configuration/${id}`
    return selecting ? `${path}?selecting=true` : path
  },
  [
    "/products/s/:supplierId/package_configuration/:packageConfigurationId",
    "/products/package_configuration/:packageConfigurationId",
  ]
)

export const deliveryPath = assignMatcher(() => "/delivery")
export const deliveryEditAddressesPath = assignMatcher(
  () => "/delivery/edit_delivery_addresses"
)

export const clinicalPath = assignMatcher(() => "/clinical")
export const chartNotePath = assignMatcher(
  (categorySlug: string, id: string | number) =>
    `/clinical/documentation/${categorySlug}/${id}`,
  "/clinical/documentation/:categorySlug/:faceToFaceId"
)

export const questionnaireResponsePath = assignMatcher(
  (id: string | number) => `/clinical/questionnaireResponse/${id}`,
  "/clinical/questionnaireResponse/:questionnaireResponseId"
)

export const signaturePath = assignMatcher(() => "/signature")

export const reviewPath = assignMatcher(() => "/review")
export const reviewGenerateInvitePath = assignMatcher(
  () => "/review/generate_invite"
)
export const reviewSignaturePath = assignMatcher(() => "/review/signature")
export const reviewSendBackPath = assignMatcher(() => "/review/send_back")
export const reviewRejectPath = assignMatcher(() => "/review/reject")

export const reviewSendBackFacilityWarningPath = assignMatcher(
  () => "/review/send_back_facility_warning"
)
export const supplierTransferPath = assignMatcher(
  () => "/review/supplier_transfer"
)
export const reviewUserEmailPath = assignMatcher(() => "/review/user_email")
export const reviewUpdateFacilityPath = assignMatcher(
  () => "/review/update_facility"
)
export const reviewProofOfDeliveryPath = assignMatcher(
  () => "/review/proof_of_delivery"
)
export const patientPreferenceConfigurationPath = assignMatcher(
  (id: string) => `/review/patient_preference_configuration/${id}`,
  "/review/patient_preference_configuration/:lineItemGroupId"
)
export const supplierLegalEntityModalPath = assignMatcher(
  () => "/review/supplier_legal_entity"
)
export const salesRepModalPath = assignMatcher(() => "/review/sales_rep")
export const orderLinkageModalPath = assignMatcher(
  (id: string) => `/review/order_linkage/${id}`,
  "/review/order_linkage/:fulfillmentId"
)

export const supplierSystemClinicalFacilityExternalSystemIdModalPath = assignMatcher(
  () => "/review/external_system_id"
)

export const quickOrderPath = assignMatcher(() => "/quick_orders")
