// @team @demand-ordering
import { CatalogSearchType } from "../../../sharedTypes"

export const isProductSearchTab = (
  catalogSearchType: CatalogSearchType
): boolean => {
  return (
    catalogSearchType === CatalogSearchType.PackageFilter ||
    catalogSearchType === CatalogSearchType.CombinedSearchDefaultToProductSearch
  )
}

export const isCombinedCatalogSearchType = (
  catalogSearchType: CatalogSearchType
): boolean => {
  if (
    catalogSearchType === CatalogSearchType.PackageFilter ||
    catalogSearchType === CatalogSearchType.SkuQuickAdd
  ) {
    return false
  }

  return true
}
