import classNames from "classnames"
import * as styles from "./index.module.scss"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import React from "react"
import { Tooltip } from "@material-ui/core"

type InternalNoteProps = {
  closed?: boolean
  className?: string
  note?: string
}

/**
 * This component is a reusable style component for displaying the common "internal notes"
 * pattern within the SidebarLayout component.
 */
const InternalNote: React.FC<InternalNoteProps> = ({
  closed = false,
  className,
  note,
}: InternalNoteProps) => {
  if (!note) {
    return <></>
  }

  if (closed) {
    return (
      <div className={styles.tooltipContainer}>
        <Tooltip arrow placement="right" title={note!}>
          {/* the wrapping div here is load-bearing; the Tooltip components needs a child that can accept a forwardref */}
          <div>
            <CanopyIcon
              fill="canopyColorPrimitivesBlue41"
              name="flag"
              title="More info"
              size="medium"
            />
          </div>
        </Tooltip>
      </div>
    )
  }

  return (
    <div
      role="note"
      aria-labelledby="internal-note-header"
      className={`${classNames(styles.internalNotesContainer, className)}`}
    >
      <div className="canopy-typography-heading-large">
        <CanopyIcon
          fill="canopyColorPrimitivesBlue41"
          name="flag"
          size="small"
        />
        <b
          id="internal-note-header"
          className="canopy-mis-2x canopy-typography-heading-small"
        >
          Internal note
        </b>
      </div>

      <div className="canopy-typography-body-small">{note}</div>
    </div>
  )
}

export default InternalNote
