import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import PayorDefaultLayout from "./layouts/PayorDefaultLayout/PayorDefaultLayout"
import MemberSearchDashboard from "./pages/MemberSearchDashboard/MemberSearchDashboard"
import { Employer, FeatureFlagInput } from "../../../sharedTypes"
import { FeatureFlagProvider } from "../../../components/FeatureFlagContext"
import { employerPrefix } from "../../../utilities/url"
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "../../../services/graphql/apolloClient"
import OrdersDashboard from "./pages/OrdersDashboard/OrdersDashboard"
import { PAYOR_BASE_ROUTES } from "./constants/payor_routes"
import { withBrowserRouter } from "routers/BrowserRouter"

interface PayorAppProps {
  featureFlags: FeatureFlagInput
  currentEmployer: Employer
}

const PayorApp = ({ featureFlags, currentEmployer }: PayorAppProps) => {
  return (
    <ApolloProvider client={apolloClient}>
      <FeatureFlagProvider flags={featureFlags}>
        <PayorDefaultLayout>
          <Switch>
            <Route path={PAYOR_BASE_ROUTES.MEMBER_SEARCH}>
              <MemberSearchDashboard />
            </Route>
            <Route path={PAYOR_BASE_ROUTES.ORDERS_DASHBOARD}>
              <OrdersDashboard payor={currentEmployer} />
            </Route>
            <Route
              exact
              path="/"
              render={() => (
                <Redirect to={PAYOR_BASE_ROUTES.ORDERS_DASHBOARD} />
              )}
            ></Route>
          </Switch>
        </PayorDefaultLayout>
      </FeatureFlagProvider>
    </ApolloProvider>
  )
}

export default withBrowserRouter(PayorApp, { basename: employerPrefix() })
