// @team @clinical-intelligence
import {
  ChartNote,
  DmeOrderWarning,
  DocumentationRequirement,
  DocumentationRequirementEntryMechanism,
  DocumentationRequirementSatisfyMethod,
  DocumentationRequirementSatisfyStatus,
  QuestionnaireResponse,
} from "../../../../sharedTypes"
import { fillablePdfPath } from "./utilities"
import * as routes from "../../routes"
import { slugify } from "../../../../utilities/string"
import { ExternalQuestionnaireSatisfyStatus } from "../Review/sharedTypes"
import { isStepValid } from "../../utilities/validation"
import { StepContext, StepId } from "../../utilities/steps"
import { PRODUCTS_STEP, DELIVERY_STEP } from "../../utilities/steps"

export const skipDeliveryStep = (
  stepId: StepId,
  warnings: DmeOrderWarning[],
  context: StepContext,
  skipDeliveryStepWhenComplete: boolean = false
) => {
  const currentlyOnProductsStep = stepId === PRODUCTS_STEP
  const deliveryStepIsComplete = isStepValid(
    DELIVERY_STEP,
    warnings,
    context,
    false
  )

  return (
    currentlyOnProductsStep &&
    deliveryStepIsComplete &&
    skipDeliveryStepWhenComplete
  )
}

const getCurrentChartNote = (
  chartNotes: ChartNote[],
  pathname: string
): ChartNote | undefined => {
  const match = pathname.match(
    /clinical\/documentation\/[a-z_]+\/([A-Za-z0-9\-]+)/
  )
  if (match) {
    return chartNotes.find((chartNote: ChartNote) => chartNote.id === match[1])
  }
}

const getNextUnsubmittableChartNote = (
  chartNotes: ChartNote[],
  currentChartNote: ChartNote | undefined
) => {
  const currentChartNoteIndex = chartNotes.findIndex(
    (chartNote: ChartNote) => chartNote === currentChartNote
  )
  const subsequentChartNotes = chartNotes.slice(currentChartNoteIndex + 1)
  return subsequentChartNotes.find(
    (chartNote: ChartNote) => !chartNote.submittable
  )
}

export const THERE_IS_NO_NEXT_PATH_THIS_MIGHT_BE_BAD_BEHAVIOR = false
export const getNextFillablePdfOrChartNotePagePathWhenOnClinicalStep = ({
  updateChartNotesPermission,
  documentationRequirements,
  currentPath,
  chartNotes,
}: {
  updateChartNotesPermission: boolean
  documentationRequirements: DocumentationRequirement[]
  currentPath: string
  chartNotes: ChartNote[]
}):
  | undefined
  | string
  | typeof THERE_IS_NO_NEXT_PATH_THIS_MIGHT_BE_BAD_BEHAVIOR => {
  const currentDocumentationRequirementExternalId = documentationRequirements.find(
    (d) => fillablePdfPath(d.externalId) === currentPath
  )?.externalId
  const currentChartNote = getCurrentChartNote(chartNotes, currentPath)

  if (currentChartNote && !currentChartNote.submittable) {
    return THERE_IS_NO_NEXT_PATH_THIS_MIGHT_BE_BAD_BEHAVIOR
  }
  const startIndexOfRequirementsAfterCurrentRequirement =
    documentationRequirements?.findIndex(
      (d) => d.externalId === currentDocumentationRequirementExternalId
    ) + 1
  const requirementsAfterCurrentRequirement = documentationRequirements.filter(
    (_, index) => index >= startIndexOfRequirementsAfterCurrentRequirement
  )
  const nextIncompleteRequirement = requirementsAfterCurrentRequirement?.find(
    (dr) =>
      dr.satisfyMethod === DocumentationRequirementSatisfyMethod.Generate &&
      dr.entryMechanism ===
        DocumentationRequirementEntryMechanism.FillablePdf &&
      dr.satisfyStatus !== DocumentationRequirementSatisfyStatus.Complete
  )

  if (
    updateChartNotesPermission &&
    !currentChartNote &&
    nextIncompleteRequirement
  ) {
    return fillablePdfPath(nextIncompleteRequirement.externalId)
  }

  const nextUnsubmittableChartNote = getNextUnsubmittableChartNote(
    chartNotes,
    currentChartNote
  )
  if (updateChartNotesPermission && nextUnsubmittableChartNote) {
    return routes.chartNotePath(
      slugify(nextUnsubmittableChartNote.name),
      nextUnsubmittableChartNote.id
    )
  }
}

export const getNextOptumQuestionnaireResponsePath = ({
  questionnaireResponses = [],
  currentPath,
}: {
  questionnaireResponses?: QuestionnaireResponse[]
  currentPath: string
}): undefined | string => {
  const currentQuestionnaireResponse = questionnaireResponses.find((qr) =>
    currentPath.includes(qr.id)
  )

  if (!currentQuestionnaireResponse) {
    return undefined
  }

  const currentQuestionnaireResponseIndex = questionnaireResponses.findIndex(
    (qr) => qr === currentQuestionnaireResponse
  )

  const nextIncompleteQuestionnaireResponse = questionnaireResponses.find(
    (qr, index) =>
      index !== currentQuestionnaireResponseIndex &&
      qr.status !== ExternalQuestionnaireSatisfyStatus.Recommended &&
      qr.status !== ExternalQuestionnaireSatisfyStatus.NotRecommended
  )

  if (nextIncompleteQuestionnaireResponse) {
    return routes.questionnaireResponsePath(
      nextIncompleteQuestionnaireResponse.id
    )
  }

  return undefined
}
