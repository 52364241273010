import { gql, TypedDocumentNode } from "@apollo/client"

export const CarrierAuthorizationUpdate: TypedDocumentNode<any, any> = gql`
  mutation CarrierAuthorizationUpdate(
    $carrierAuthorizationId: ID!
    $referralType: CarrierAuthorizationReferralTypesEnum
  ) {
    carrierAuthorizationUpdate(
      input: {
        carrierAuthorizationId: $carrierAuthorizationId
        referralType: $referralType
      }
    ) {
      carrierAuthorization {
        id
        authStatus
        requestSubmitDateTime
      }
    }
  }
`
