export const emrPatientStatusUrl = (
  emrContextIndex: string,
  emrPatientLoadJobId: string,
  cdsMode: boolean
) => {
  if (cdsMode) {
    return `/emr/${emrContextIndex}/cds_patient_loadings/${emrPatientLoadJobId}/status`
  }
  return `/emr/${emrContextIndex}/patient_loadings/${emrPatientLoadJobId}/status`
}
