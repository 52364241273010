import React from "react"
import { initials } from "utilities/person"
import { GroupedOptions } from "components/form/Select"
import { Follower, FollowerEmployerType } from "sharedTypes"
import ReadOnlyFollowers from "./ReadOnlyFollowers"
import {
  CanopyComboboxField,
  SelectOptions,
  SelectOption,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import { useDebounce } from "hooks/useDebounce"

type Props = {
  dmeOrder: { followers: Follower[]; externalId: string }
  editable: boolean
  getFollowers(term: string): Promise<GroupedOptions[]>
  values: { followers: SelectOption[] }
  setFieldValue: (
    field: string,
    value: OptionItem[] | OptionItem | null
  ) => void
}

const InternalFollowers: React.FC<Props> = ({
  dmeOrder,
  editable,
  getFollowers,
  values,
  setFieldValue,
}: Props) => {
  const currentUserEmployerType = window.parachute?.employerType
  const isFacility = currentUserEmployerType === "ClinicalFacility"
  const allFollowers = dmeOrder.followers
  const supplierFollowers = allFollowers.filter(
    (follower) => follower.employerType === FollowerEmployerType.Supplier
  )

  const [followerOptions, setFollowerOptions] = React.useState<SelectOptions>(
    []
  )

  const fetchFollowers = (term: string) => {
    getFollowers(term)
      .then((response) => {
        const options: SelectOptions = response.flatMap((group) => {
          const groups: SelectOption = {
            label: group.label as string,
            options: group.options.map((option) => ({
              label: option.label,
              value: option.value.toString(),
            })),
          }
          return groups
        })
        setFollowerOptions(options)
      })
      .catch((error) => {
        console.error("Failed to fetch followers:", error)
        setFollowerOptions([])
      })
  }

  const debounceFetch = useDebounce(fetchFollowers, 300, false)

  if (!editable) {
    if (allFollowers.length === 0) {
      return <div>This order does not have any followers yet</div>
    }
    return (
      <ul className="list-unstyled">
        {allFollowers.map((follower) => {
          return (
            <li
              className="canopy-mbe-8x"
              key={`${follower.name}-${follower.id}`}
            >
              <div className="gray-o square d-inline-block">
                {initials(follower.name)}
              </div>
              <strong className="canopy-mis-8x font-primary font-xs d-inline-block">
                {follower.name}
              </strong>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className="form-group">
      <CanopyComboboxField
        multiple
        options={followerOptions}
        value={values.followers}
        onInputChange={debounceFetch}
        onChange={(newValue) => {
          setFieldValue("followers", newValue)
        }}
        label="Followers"
        placeholder="Search"
      />
      {isFacility && supplierFollowers.length > 0 && (
        <ReadOnlyFollowers followers={supplierFollowers} />
      )}
    </div>
  )
}

export default InternalFollowers
