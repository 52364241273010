// @team @cat-crew
import sortBy from "lodash/sortBy"
import uniq from "lodash/uniq"

/**
 * Takes an array of values and returns a new array with unique values sorted numerically.
 * This is commonly used when we have multiple existing options with the same value but different
 * units/packaging, and we don't want multiple copies of the same option showing up.
 *
 * @param values - Array of values to be processed
 * @returns Array of unique values sorted numerically
 * @example
 * sortedUniqueValues(['2', '1', '2', '3']) // returns ['1', '2', '3']
 * sortedUniqueValues([2, 1, 2, 3]) // returns [1, 2, 3]
 */
export const sortedUniqNumbers = <T extends string | number>(
  values: T[]
): T[] => {
  return sortBy(uniq(values), Number)
}

export default sortedUniqNumbers
