import React, { useState } from "react"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { dataEntryLineContainer } from "../../index.module.scss"
import { useCsrInboxState } from "../../../../csrInboxStateContext"
import { useIsMutating, useMutation } from "@tanstack/react-query"
import {
  PatientContactInformationProps,
  updatePatientContactInformation,
} from "./api"
import { CanopyTextInputTypeValues } from "@parachutehealth/canopy-text-input"
import { ResourceUpdateResponse } from "../../api"

const updatePatientContactaInformationMutationKey = [
  "updatePatientContactInformation",
]
const useUpdatePatientContactInformation = (
  fieldName: keyof PatientContactInformationProps
) => {
  const csrInboxState = useCsrInboxState()
  return useMutation({
    mutationFn: (fieldValue: string) =>
      updatePatientContactInformation(
        csrInboxState.supplierOrganization.id,
        csrInboxState.id,
        { [fieldName]: fieldValue }
      ),
    mutationKey: updatePatientContactaInformationMutationKey,
  })
}

const isSuccess = (
  response: ResourceUpdateResponse<keyof PatientContactInformationProps>
): response is { success: boolean } => "success" in response

const PatientContactInformationTextField = ({
  patientContactInformation,
  label,
  fieldName,
  placeholder,
  type,
}: {
  patientContactInformation: PatientContactInformationProps
  fieldName: keyof PatientContactInformationProps
  label: string
  placeholder: string
  type?: CanopyTextInputTypeValues
}) => {
  const numberOfMutations = useIsMutating({
    mutationKey: updatePatientContactaInformationMutationKey,
  })

  const [error, setError] = useState<string | undefined>(undefined)
  const [value, setValue] = useState(patientContactInformation[fieldName])
  const mutatePatientContactInformation = useUpdatePatientContactInformation(
    fieldName
  )

  const onBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value !== value) {
      mutatePatientContactInformation
        .mutateAsync(e.target.value)
        .then((response) => {
          if (isSuccess(response)) {
            setError(undefined)
          } else {
            const errors = response.errors
            const error = errors[fieldName]

            setError(error ? error[0] : "Something went wrong")
          }
        })
      setValue(e.target.value)
    }
  }

  return (
    <CanopyTextInputField
      label={label}
      defaultValue={patientContactInformation[fieldName]}
      onBlur={onBlur}
      feedbackMessage={error}
      placeholder={placeholder}
      readOnly={
        mutatePatientContactInformation.isLoading || numberOfMutations > 0
      }
      type={type}
    />
  )
}

export const PatientContactInformation = ({
  contactInformation,
}: {
  contactInformation: PatientContactInformationProps
}) => {
  return (
    <>
      <div className={dataEntryLineContainer}>
        <PatientContactInformationTextField
          patientContactInformation={contactInformation}
          label="Email"
          fieldName="email"
          placeholder="example@domain.com"
          type="email"
        />
      </div>
    </>
  )
}
