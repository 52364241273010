// @team @kingston

import { EventCategory, trackEvent } from "utilities/tracking"
import { SidebarLink } from "sharedTypes"

const eventAnalyticsMap = {
  ["patientFeedback"]: {
    category: EventCategory.PatientPortalChat,
    analytics_event: "supplier_viewed_patient_feedback",
  },
}

export function trackSidebarAnalyticsEvent(link: SidebarLink): void {
  if (!link.analyticsEvent || !eventAnalyticsMap[link.analyticsEvent]) {
    return
  }

  const { category, analytics_event } = eventAnalyticsMap[link.analyticsEvent]

  trackEvent(category, analytics_event)
}
