import React from "react"
import { CanopyHeading } from "@parachutehealth/canopy-heading"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyText } from "@parachutehealth/canopy-text"

interface Props {
  title: string
  subtitle?: string
}

function WorkflowHeader({ title, subtitle }: Props) {
  return (
    <CanopyFlex direction="column" gap="6X" className="canopy-mbe-8x">
      <CanopyHeading size="2xLarge" level={1}>
        {title}
      </CanopyHeading>
      {subtitle && <CanopyText>{subtitle}</CanopyText>}
    </CanopyFlex>
  )
}

export default WorkflowHeader
