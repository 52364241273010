import {
  CatalogProduct,
  CatalogProductCustomAttribute,
  CatalogProductVariation,
} from "../../../../../../types/sharedTypes"
import { FieldSet } from "../../../../../../types/form/sharedTypes"
import { booleanRadioGroup } from "../../../../../../components/Formik/DynamicFormField"
import React, { useContext } from "react"
import { useQuery } from "@tanstack/react-query"
import { CircularProgress, Grid } from "@material-ui/core"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { generateInitialValues } from "../../../../../../utilities/form"
import {
  createProductVariation,
  CreateCatalogProductVariationResponse,
  getCatalogProductVariation,
  updateProductVariation,
} from "../../../../../../api/productVariations"
import get from "lodash/get"
import flatten from "lodash/flatten"
import isEmpty from "lodash/isEmpty"
import { canopySpace4X } from "@parachutehealth/canopy-tokens-space"
import ContentArea from "../../../../../../components/ContentArea"
import { Formik, useFormikContext } from "formik"
import FormSegment from "../../../../../../components/Formik/FormSegment"
import { getAttributesAndOptionsForProduct } from "./api"
import FreesoloCombobox from "../../../../../../components/FreesoloCombobox"
import { CanopyComboboxField } from "@parachutehealth/canopy-combobox-field"
import { canopyColorPrimitivesGray86 } from "@parachutehealth/canopy-tokens-color"
import { NoticeContext } from "../../../../../../contexts/NoticeContext"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { AxiosError } from "axios"
import { CanopyFormFieldGroup } from "@parachutehealth/canopy-form-field-group"
import sortedUniqNumbers from "utilities/array/sortedUniqNumbers"

export type ProductVariationFormProps = {
  productCustomAttributes: CatalogProductCustomAttribute[]
  product: CatalogProduct
  mode?: "create" | "edit" | "duplicate"
  skuId?: string
  onCancel: () => void
  onSuccess?: (sku: CatalogProductVariation) => void
}

const baseFields: FieldSet[] = [
  {
    label: "SKU Description",
    name: "description",
    required: true,
    type: "text",
  },
  [
    {
      label: "HCPCS",
      name: "hcpcs",
      type: "text",
      optional: true,
    },
    {
      label: "Quantity",
      name: "quantity",
      initialValue: "1",
      type: "text",
      required: true,
      inputProps: { type: "number", min: "1", step: "1" },
    },
  ],
  {
    label: "Manufacturer ID",
    name: "manufacturerId",
    type: "text",
    optional: true,
  },
  {
    label: "Internal notes",
    name: "internalNotes",
    type: "textarea",
    optional: true,
  },
]

/* These fields ONLY show up when we're editing a SKU, not when duplicating or creating */
const editModeFields: FieldSet[] = [
  {
    type: "text",
    label: "Utilization Unit Value",
    name: "utilizationUnitValue",
    optional: true,
    inputProps: { pattern: "\\d*\\.?\\d*" },
  },
  {
    type: "text",
    label: "Utilization Unit Label",
    name: "utilizationUnitLabel",
    optional: true,
  },
  {
    type: "text",
    label: "Calories",
    name: "calories",
    inputProps: { pattern: "\\d*\\.?\\d*" },
    optional: true,
  },
  {
    type: "text",
    label: "Milliliters",
    name: "milliliters",
    inputProps: { pattern: "\\d*\\.?\\d*" },
    optional: true,
  },
  {
    label: "Hide From Supplier Export",
    name: "hideFromSupplierExport",
    type: "radio",
    required: true,
    options: booleanRadioGroup(),
    initialValue: "false",
  },
]

const ProductVariationForm: React.FC<ProductVariationFormProps> = (
  props: ProductVariationFormProps
): React.JSX.Element => {
  const {
    product,
    mode = "create",
    productCustomAttributes,
    skuId,
    onCancel,
    onSuccess,
  } = props

  const { showNotice } = useContext(NoticeContext)

  /**
   * Fetching all relevant attributes + all their options + all the units and labels for each
   * can be expensive, so we only do it when we need to from this bespoke endpoint.
   * This query can/will/should be invalidated after saves, in case any new options were added;
   * invalidating here, however, will cause it to be requeried immediately, so the invalidation
   * will have to occur in a parent component that also controls the render state of this component
   * (i.e., close it, and then invalidate, so it doesn't requery unless it's opened again).
   */
  const {
    isFetching: optionsAreFetching = true,
    data: attributesAndOptions,
  } = useQuery({
    queryKey: [
      "ProductDetailsPage",
      "ProductVariationForm",
      "getAttributesAndOptionsForProduct",
      product.externalId,
    ],
    queryFn: () => getAttributesAndOptionsForProduct(product.externalId),
    refetchOnWindowFocus: false,
  })

  /**
   * If we're in either edit or duplicate mode, we have to fetch the SKU in order
   * to get its details.
   */
  const { isFetching: skuIsFetching = true, data: sku } = useQuery({
    queryKey: [
      "ProductDetailsPage",
      "ProductVariationForm",
      "getCatalogProductVariation",
      skuId,
    ],
    queryFn: () =>
      getCatalogProductVariation(skuId!).then((data) => data.productVariation),
    refetchOnWindowFocus: false,
    enabled: skuId !== undefined,
  })

  const handleCancel = () => {
    if (onCancel) onCancel()
  }

  /* Retrieve the static (non-attribute fields which vary depending on which mode we're in */
  const staticFields = React.useCallback((): FieldSet[] => {
    const fields: FieldSet[] = [...baseFields]

    if (mode === "edit") {
      fields.push(...editModeFields)
    }

    return fields
  }, [mode])

  const initialValues = React.useMemo(() => {
    // this pre-populates any "simple" attributes that we can pull directly from the SKU to edit or copy
    const values: Record<string, any> = generateInitialValues(
      staticFields(),
      sku || {}
    )

    productCustomAttributes.forEach((pca) => {
      const id = pca.id.toString()

      const skuAttribute = sku?.customAttributeValues?.find(
        (pvcav) => pvcav.catalogProductCustomAttributeId === pca.id
      )

      /**
       * This horrifying contraption sets up a data structure wherein the possible components of the SKU's
       * ProductVariationCustomAttributeValue are defined in keys constructed like `{productCustomAttributeId}-{thing}`:
       * @example
       *  12345-description: "Blue"
       *  67890-value: "10"
       *  67890-catalogPackagingLabelId: "1" (fk to the packaging label)
       *  67890-catalogUnitId: "2" (fk to the unit)
       * It would be nicer to have these be nested (`{12345: {description: "Blue"}}`), but that wasn't working so well
       * historically (could just be a limitation of our Formik setup); this would be a good candidate for refactor
       * whenever we have the time or inclination
       */
      if (pca.customAttribute.valueType === "number") {
        values[`${id}-value`] = get(skuAttribute, ["value"], "")
        values[`${id}-catalogUnitId`] =
          get(skuAttribute, ["unit", "id"])?.toString() || ""
        values[`${id}-catalogPackagingLabelId`] =
          get(skuAttribute, ["packagingLabel", "id"])?.toString() || ""
      } else {
        values[`${id}-description`] = get(skuAttribute, ["description"]) || ""
      }
    })

    return values
  }, [productCustomAttributes, sku, staticFields])

  /**
   * Depending on what mode we're in, this will either add a new SKU
   * or update an existing one; the return value is the same in either case.
   */
  const createOrUpdate = async (
    cleanedValues: Record<string, any>
  ): Promise<CreateCatalogProductVariationResponse> => {
    let response: CreateCatalogProductVariationResponse
    if (mode === "edit") {
      response = await updateProductVariation(sku!.externalId, cleanedValues)
    } else {
      response = await createProductVariation(product.externalId, cleanedValues)
    }
    return response
  }

  const handleSubmit = async (
    params: { [p: string]: any },
    { setErrors }: any
  ) => {
    const cleanedValues: Record<string, any> = {}

    flatten(staticFields()).forEach((field) => {
      cleanedValues[field.name] = params[field.name]
    })

    cleanedValues[
      "customAttributeValuesAttributes"
    ] = productCustomAttributes.reduce((acc, pca, index) => {
      let attributeData = {}
      if (pca.customAttribute.valueType === "number") {
        const attrValue = params[`${pca.id}-value`]
        const attrUnitId = params[`${pca.id}-catalogUnitId`]
        const attrPackagingLabelId = params[`${pca.id}-catalogPackagingLabelId`]
        attributeData = {
          value: attrValue?.text || attrValue,
          // standard autocomplete returns the id/text tuple
          catalogUnitId: attrUnitId?.id || attrUnitId,
          catalogPackagingLabelId:
            attrPackagingLabelId?.id || attrPackagingLabelId,
        }
      } else {
        const attrDescription = params[`${pca.id}-description`]
        attributeData = {
          description: attrDescription?.text || attrDescription,
        }
      }

      // This wrestles the data into the form that Rails expects, which is an object/hash
      // with array-like indexes as the keys and the data within -- e.g.,
      // "0": {
      //    catalogProductCustomAttributeId: 1,
      //    description: "Foo"
      // }
      acc[index] = {
        catalogProductCustomAttributeId: pca.id,
        ...attributeData,
      }

      // we only want to include the PVCAV's id if we're editing, not creating or duplicating
      if (sku && mode === "edit") {
        acc[index]["id"] = sku.customAttributeValues?.find(
          (pvcav) => pvcav.catalogProductCustomAttributeId === pca.id
        )?.id
      }

      return acc
    }, {})

    await createOrUpdate(cleanedValues)
      .then((response) => {
        showNotice(
          `Successfully ${mode === "edit" ? "edited" : "created"}   ${
            response.productVariation.description
          } `,
          "success",
          [{ text: "View SKU", href: response.productVariation.url }],
          true
        )
        if (onSuccess) onSuccess(response.productVariation)
      })
      .catch((error: AxiosError) => {
        const errors = get(error, ["response", "data", "errors"]) || {
          _: ["An error occurred"],
        }
        if (errors._) {
          showNotice(errors._.join("; "), "error")
        }
        setErrors(errors)
      })
  }

  /**
   * Convenience method to pluck certain properties (such as description) from the known set of options.
   * @param customAttributeId Required to pull the options for the correct attributes
   * @param property Which property of the option object to return; defaults to "description"
   */
  const optionsFor = (customAttributeId: number, property?: string) => {
    return (
      attributesAndOptions
        ?.find((ca) => ca.id === customAttributeId)
        ?.options?.map((o) => o[property || "description"]) || []
    )
  }

  /**
   * A convenience element that auto-switches the correct form field layout
   * to use depending on whether we're dealing with a numeric or text attribute
   */
  const AttributeSwitcher = ({
    productCustomAttribute,
  }: {
    productCustomAttribute: CatalogProductCustomAttribute
  }): React.JSX.Element => {
    switch (productCustomAttribute.customAttribute.valueType) {
      case "number":
        return (
          <NumericAttributeFields
            key={productCustomAttribute.id}
            productCustomAttribute={productCustomAttribute}
          />
        )
      default:
        return (
          <TextAttributeField
            key={productCustomAttribute.id}
            productCustomAttribute={productCustomAttribute}
          />
        )
    }
  }

  /**
   * Construct the input for a "text"-type attribute. This consists of a
   * single Combobox that allows user creation of new values.  It sets a single
   * string (the description) to the Formik context.
   */
  const TextAttributeField = ({
    productCustomAttribute,
  }: {
    productCustomAttribute: CatalogProductCustomAttribute
  }): React.JSX.Element => {
    const { setFieldValue, values, errors } = useFormikContext()
    const fieldKey = `${productCustomAttribute.id}-description`

    return (
      <FreesoloCombobox
        className="canopy-mbe-8x"
        defaultValue={get(values, `${productCustomAttribute.id}-description`)}
        key={productCustomAttribute.id}
        label={productCustomAttribute.customAttribute.name}
        required={true}
        feedbackMessage={errors[productCustomAttribute.id]}
        options={optionsFor(productCustomAttribute.customAttribute.id)}
        onChange={(newValue) =>
          setFieldValue(fieldKey, newValue?.value || newValue)
        }
      />
    )
  }

  /**
   * Construct the input for a "number"-type attribute. This consists of three separate
   * inputs for packaging, unit, and value.  The latter allows for user creation of values and well set
   * the text (and not an ID) to the Formik context.
   */
  const NumericAttributeFields = ({
    productCustomAttribute,
  }: {
    productCustomAttribute: CatalogProductCustomAttribute
  }): React.JSX.Element => {
    const { setFieldValue, errors, values } = useFormikContext()

    const valueKey = `${productCustomAttribute.id}-value`
    const catalogUnitKey = `${productCustomAttribute.id}-catalogUnitId`
    const catalogPackagingLabelKey = `${productCustomAttribute.id}-catalogPackagingLabelId`

    const optionValues = optionsFor(
      productCustomAttribute.customAttribute.id,
      "value"
    )
    // we can now have multiple existing options with the same value but different units/packaging, and we don't want
    // multiple copies of the same option showing up
    const uniqueValues: string[] = sortedUniqNumbers(optionValues)

    const packagingLabels =
      attributesAndOptions
        ?.find((ca) => ca.id === productCustomAttribute.customAttribute.id)
        ?.packagingLabels?.map((packaging) => ({
          value: packaging.id.toString(),
          label: packaging.label,
        })) || []

    const units =
      attributesAndOptions
        ?.find((ca) => ca.id === productCustomAttribute.customAttribute.id)
        ?.units?.map((unit) => ({
          value: unit.id.toString(),
          label: unit.label,
        })) || []

    return (
      <div className="canopy-mbe-8x">
        {/*
         * We've got two sets of labels: one for the attribute name, but then a label
         * for each subcomponent.
         * The backend gives us a set of validation messages _per PCA ID_; because there are three fields here,
         * we can't currently associate them individually (maybe a later PR); so we'll add the message to the wrapper
         * form field instead.
         */}
        <CanopyFormFieldGroup
          className="canopy-mbe-8x"
          feedbackMessage={errors[productCustomAttribute.id]}
          required
          label={productCustomAttribute.customAttribute.name}
        >
          {() => {
            return (
              <div
                className="canopy-pis-8x"
                style={{
                  width: "100%",
                  borderLeft: `6px solid ${canopyColorPrimitivesGray86}`,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <CanopyComboboxField
                      onChange={(newValue) =>
                        setFieldValue(
                          catalogPackagingLabelKey,
                          get(newValue, "value")
                        )
                      }
                      options={packagingLabels}
                      disabled={isEmpty(packagingLabels)}
                      multiple={false}
                      size="small"
                      placeholder={isEmpty(packagingLabels) ? "N/A" : undefined}
                      value={get(values, catalogPackagingLabelKey)}
                      label="Packaging label"
                      feedbackMessage={errors[catalogPackagingLabelKey]}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FreesoloCombobox
                      data-testid={valueKey}
                      label="Value"
                      required={true}
                      feedbackMessage={errors[valueKey]}
                      defaultValue={get(
                        values,
                        `${productCustomAttribute.id}-value`
                      )}
                      options={uniqueValues}
                      onChange={(newValue) =>
                        setFieldValue(valueKey, newValue?.value || newValue)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CanopyComboboxField
                      data-testid={catalogUnitKey}
                      onChange={(newValue) =>
                        setFieldValue(catalogUnitKey, get(newValue, "value"))
                      }
                      options={units}
                      disabled={isEmpty(units)}
                      multiple={false}
                      size="small"
                      value={get(values, catalogUnitKey)}
                      label="Unit of measure"
                      placeholder={isEmpty(units) ? "N/A" : undefined}
                      feedbackMessage={errors[catalogUnitKey]}
                    />
                  </Grid>
                </Grid>
              </div>
            )
          }}
        </CanopyFormFieldGroup>
      </div>
    )
  }

  if (skuIsFetching || optionsAreFetching) {
    return (
      <CanopyFlex className="canopy-m-4x canopy-p-4x" justifyContent="center">
        <CircularProgress size={32} color="inherit" />
      </CanopyFlex>
    )
  }

  return (
    <ContentArea compact data-testid="add-sku-form">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => {
          return (
            <>
              <h2
                style={{
                  borderRadius: `${canopySpace4X} ${canopySpace4X} 0 0`,
                }}
                className="canopy-p-8x bg-lightest-gray canopy-typography-heading-xlarge canopy-mbe-0"
              >
                {mode === "edit" ? "Edit" : "Add"} SKU
              </h2>

              <div className="canopy-p-8x canopy-pbe-0">
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <h3 className="color-dark-gray canopy-typography-heading-large">
                      SKU details
                    </h3>
                    <FormSegment>{staticFields()}</FormSegment>
                  </Grid>
                  <Grid item lg={6}>
                    <h3 className="color-dark-gray canopy-typography-heading-large">
                      SKU Attributes
                    </h3>
                    {isEmpty(productCustomAttributes) && (
                      <p>No attributes available</p>
                    )}
                    {productCustomAttributes.map(
                      (pca: CatalogProductCustomAttribute) => (
                        <AttributeSwitcher
                          key={pca.id}
                          productCustomAttribute={pca}
                        />
                      )
                    )}
                  </Grid>
                </Grid>
                <div className="">
                  <CanopyButton
                    size="small"
                    variant="primary"
                    type="submit"
                    onClick={formik.submitForm}
                    loading={formik.isSubmitting}
                  >
                    Save
                  </CanopyButton>
                  <CanopyButton
                    size="small"
                    variant="tertiary"
                    disabled={formik.isSubmitting}
                    onClick={handleCancel}
                  >
                    Cancel
                  </CanopyButton>
                </div>
              </div>
            </>
          )
        }}
      </Formik>
    </ContentArea>
  )
}

export default ProductVariationForm
