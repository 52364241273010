import React, { useState, useCallback } from "react"
import { Link, useHistory } from "react-router-dom"
import { canopyColorTextSecondary } from "../PatientSearchPage/index.module.scss"
import { actionColumnPadding } from "../../../index.module.scss"
import { usePatientQuery } from "../../../queries"
import { ROUTES } from "../../../routes"
import { PatientDemographicsForm } from "../PatientDemographicsForm"
import { usePatientMutation } from "../usePatientMutation"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

type NewPatientProps = {
  supplierOrganizationId: string
  csrInboxStateId: string
}

export const NewPatient = (props: NewPatientProps) => {
  const history = useHistory()
  const { data: patient } = usePatientQuery()

  const [formData, setFormData] = useState(
    patient || {
      dateOfBirth: "",
      firstName: "",
      lastName: "",
    }
  )

  const { mutate, isLoading, errors } = usePatientMutation({
    supplierOrganizationId: props.supplierOrganizationId,
    csrInboxStateId: props.csrInboxStateId,
    onSuccess: () => {
      history.push(ROUTES.NEW_ORDER)
    },
  })

  const handleInputChange = useCallback((name: string, value: string) => {
    setFormData((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleSubmit = () => {
    mutate({
      dateOfBirth: formData.dateOfBirth,
      firstName: formData.firstName,
      lastName: formData.lastName,
      sex: formData.sex,
    })
  }

  return (
    <div className={actionColumnPadding}>
      <Link role="link" to={ROUTES.PATIENT_SEARCH}>
        <CanopyIcon name="arrow-left" />
        Back to patient search
      </Link>
      <h2 className="canopy-typography-heading-xlarge">
        Create patient record
      </h2>
      <p
        className={`canopy-typography-body-medium ${canopyColorTextSecondary}`}
      >
        Please review and complete any missing or incomplete information
      </p>

      <PatientDemographicsForm
        formData={formData}
        onSubmit={handleSubmit}
        onInputChange={handleInputChange}
        isLoading={isLoading}
        submitButtonText="Review document info"
        submitButtonIconEnd="arrow-right"
        requireFields
        errors={errors}
      />
    </div>
  )
}
