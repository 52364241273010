import { CsrInboxDocument } from "sharedTypes"
import { employerPrefix } from "utilities/url"
import { isNewDocument } from "./utilities"

export const makeUrlProvider = (supplierId: string) => {
  const urlPrefix = employerPrefix("Supplier", supplierId)
  return {
    csrInboxDocumentsUrl: `${urlPrefix}/inbox.json`,
    csrInboxDocumentAssigneeUrl: (csrInboxDocumentId: string) =>
      `${urlPrefix}/inbox/${csrInboxDocumentId}/assignees.json`,
    searchClinicalFacilitiesUrl: `${urlPrefix}/clinical_facilities_search.json`,
    searchDocumentSourcesUrl: `${urlPrefix}/csr_inbox_state_source_search.json`,
    searchReceivingFaxNumbersUrl: `${urlPrefix}/csr_inbox_state_receiving_fax_numbers.json`,
    ordersUrl: urlPrefix,
    authorizationsUrl: `${urlPrefix}/authorizations`,
    getAssigneesUrl: `${urlPrefix}/assignees.json`,
    initialDashboardDataUrl: `${urlPrefix}/inbox/initial_dashboard_data.json`,
  }
}

export const getCsrInboxStateInternalStatusesUrl = (
  supplierId: string,
  csrInboxDocumentId: string
) => {
  const urlPrefix = employerPrefix("Supplier", supplierId)
  return `${urlPrefix}/dashboard/csr_inbox_states/${csrInboxDocumentId}/internal_status.json`
}

export const getDocumentFilesUrl = (
  supplierId: string,
  csrInboxDocumentId: string
) => {
  const urlPrefix = employerPrefix("Supplier", supplierId)
  return `${urlPrefix}/inbox/${csrInboxDocumentId}/files`
}

export const getCsrInboxDocumentUrl = ({
  supplierOrganizationId,
  supplierId,
  intakePatientSearchEnabled,
  csrInboxDocument,
}: {
  supplierOrganizationId: string
  supplierId: string
  intakePatientSearchEnabled: boolean
  csrInboxDocument: CsrInboxDocument
}) => {
  if (intakePatientSearchEnabled && isNewDocument(csrInboxDocument)) {
    const urlPrefix = employerPrefix(
      "SupplierOrganization",
      supplierOrganizationId
    )
    return `${urlPrefix}/inbox_documents/${csrInboxDocument.id}/patient_search`
  }
  const urlPrefix = employerPrefix("Supplier", supplierId)
  return `${urlPrefix}/inbox/${csrInboxDocument.id}`
}
