import {
  GridColumnMenuContainer,
  GridColumnsMenuItem,
  HideGridColMenuItem,
  SortGridMenuItems,
  GridColumnMenuProps,
  GridFilterMenuItem,
} from "@mui/x-data-grid-pro"
import React from "react"

/*
NOTE: this component cannot be independently tested from a MUI DataGrid
(it relies on the data grid context), thus there is no spec file
 */

export interface CustomColumnMenuProps {
  disableFilterAction?: boolean
  disableHideAction?: boolean
  disableShowAction?: boolean
  disableSortAction?: boolean
}

type DataGridColumnMenuProps = CustomColumnMenuProps & GridColumnMenuProps

export const DataGridColumnMenu = ({
  hideMenu,
  currentColumn,
  disableFilterAction = false,
  disableHideAction = false,
  disableShowAction = false,
  disableSortAction = false,
  ...props
}: DataGridColumnMenuProps) => {
  return (
    <GridColumnMenuContainer
      {...props}
      hideMenu={hideMenu}
      currentColumn={currentColumn}
    >
      {!disableSortAction && (
        <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
      )}
      {!disableFilterAction && (
        <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
      )}
      {!disableHideAction && (
        <HideGridColMenuItem onClick={hideMenu} column={currentColumn!} />
      )}
      {!disableShowAction && (
        <GridColumnsMenuItem onClick={hideMenu} column={currentColumn!} />
      )}
    </GridColumnMenuContainer>
  )
}
