// @team @demand-ordering
import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { RecentOrder, Employer, ApplicationError } from "sharedTypes"
import { handleError } from "utilities/error"
import { makeApi } from "../api"
import { employerTypeFromContext } from "utilities/url"
import { navigateToNewTab } from "utilities/navigation"

type Props = {
  dmeOrder: RecentOrder
  currentEmployer: Employer
}

const DuplicateOrderButton = ({ dmeOrder, currentEmployer }: Props) => {
  const [loading, setLoading] = useState(false)
  const employerType = employerTypeFromContext(currentEmployer.employerType)

  const duplicateOrder = async () => {
    setLoading(true)
    try {
      const response = await makeApi(
        employerType,
        currentEmployer.employerId
      ).duplicateOrder(dmeOrder.id)
      navigateToNewTab(response.data.redirectUrl)
    } catch (error) {
      handleError(error as ApplicationError)
    } finally {
      setLoading(false)
    }
  }

  const handleClick = (e) => {
    // We have to do this because this component is rendered within an anchor tag
    // and we don't want the anchor tag's click event listenter to fire
    // Seems problematic that PatientProfile/components/RecentOrderCard.tsx
    // wraps so much content within an anchor tag...
    e.preventDefault()
    duplicateOrder()
  }

  return (
    <CanopyButton className="btn" onClick={handleClick} loading={loading}>
      Duplicate order
    </CanopyButton>
  )
}

export default DuplicateOrderButton
