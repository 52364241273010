import React from "react"
import { CanopyForm } from "@parachutehealth/canopy-form"
import * as styles from "./index.module.scss"
import { Diagnoses } from "./Diagnoses"
import { Diagnosis as DiagnosisType } from "./Diagnoses/api"
import PatientData from "./PatientData"
import { Insurance, PatientEmergencyContact } from "../../sharedTypes"
import { PatientInformation } from "./PatientInformation"
import { ContactInformation } from "./ContactInformation"
import { PatientAddress } from "./ContactInformation/api"
import { EmergencyContactInformation } from "./EmergencyContactInformation"
import ActionsFooter from "./ActionsFooter"
import { actionColumnPadding } from "../../index.module.scss"
import { PatientContactInformation } from "./ContactInformation/PatientContactInformation"
import { PatientContactInformationProps } from "./ContactInformation/PatientContactInformation/api"
import { OrderingClinicianData, setClinicianQueryData } from "./ClinicianData"
import { FacilityClinicianSection } from "./FacilityClinicianSection"
import { OrderItems } from "./OrderItems"
import {
  ExternalSystemBranchData,
  setExternalSystemBranchQueryData,
} from "./FacilityClinicianSection/ExternalSystemSelect"
import {
  InboxStateClinicalFacility,
  setFacilityQueryData,
} from "./FacilityData"
import { useQueryClient } from "@tanstack/react-query"
import { CartItem } from "./OrderItems/api"

export const DataEntryColumn = ({
  clinician,
  diagnoses,
  patientAddress,
  patientContactInformation,
  patientEmergencyContact,
  insurances,
  facility,
  externalSystemBranch,
  skuSetterEnabled,
  cartItems,
}: {
  clinician: OrderingClinicianData
  externalSystemBranch: ExternalSystemBranchData
  diagnoses: DiagnosisType[]
  insurances: Insurance[]
  patientAddress: PatientAddress
  patientContactInformation: PatientContactInformationProps
  patientEmergencyContact: PatientEmergencyContact
  facility: InboxStateClinicalFacility
  skuSetterEnabled: boolean
  cartItems: CartItem[]
}) => {
  const queryClient = useQueryClient()
  setFacilityQueryData(queryClient, facility)
  setClinicianQueryData(queryClient, clinician)
  setExternalSystemBranchQueryData(queryClient, externalSystemBranch)

  return (
    <>
      <div className={actionColumnPadding}>
        <CanopyForm>
          <PatientData />
          <FacilityClinicianSection clinician={clinician} />
          <hr className={styles.divider} />
          <PatientInformation insurances={insurances} />
          <ContactInformation address={patientAddress} />
          <PatientContactInformation
            contactInformation={patientContactInformation}
          />
          <EmergencyContactInformation
            patientEmergencyContact={patientEmergencyContact}
          />
          <hr className={styles.divider} />
          <Diagnoses initialSelectedDiagnoses={diagnoses} />
          {skuSetterEnabled && <OrderItems initialCartItems={cartItems} />}
        </CanopyForm>
      </div>
      <ActionsFooter />
    </>
  )
}
