import React from "react"
import InternalFollowers from "./components/InternalFollowers"
import SpinIcon from "components/Icon/SpinIcon"
import { getFollowers } from "./api"
import { convertToGroupOption } from "./components/utilities/followers"
import { GroupedOptions } from "components/form/Select"
import { Follower } from "sharedTypes"
import {
  SelectOption,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"

type Props = {
  dmeOrder: { externalId: string; followers: Follower[] }
  editable: boolean
  loading: boolean
  values: { followers: SelectOption[] }
  setFieldValue: (
    field: string,
    value: OptionItem[] | OptionItem | null
  ) => void
}

const Followers: React.FC<Props> = ({
  dmeOrder,
  editable,
  loading,
  values,
  setFieldValue,
}) => {
  const searchFollowers = (term: string): Promise<GroupedOptions[]> => {
    return getFollowers(term, dmeOrder.externalId).then((response) =>
      response.map(convertToGroupOption)
    )
  }

  if (loading) {
    return (
      <div className="text-center">
        <SpinIcon />
      </div>
    )
  }

  return (
    <InternalFollowers
      editable={editable}
      dmeOrder={dmeOrder}
      getFollowers={searchFollowers}
      values={values}
      setFieldValue={setFieldValue}
    />
  )
}

export default Followers
