import React from "react"
import * as styles from "../index.module.scss"

type Props = {
  step: number
}

const ClinicalFacilityProfileProgress = ({ step }: Props) => {
  const TOTAL_STEPS = 5
  const progress = (step / TOTAL_STEPS) * 100

  return (
    <div className={styles.progressContainer}>
      <div
        className={styles.progressBar}
        style={{ width: `${progress}%` }}
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  )
}

export default ClinicalFacilityProfileProgress
