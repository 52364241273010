import { meta } from "utilities/meta"
import {
  CatalogProductVariation,
  CatalogProductVariationCustomAttributeValue,
  CatalogProductVariationStatus,
} from "../types/sharedTypes"
import { toRailsStyle } from "utilities/urlSearchParams"
import { ApiResponse } from "./utilities"
import { camelizeKeys, decamelizeKeys } from "humps"
import { ServerSideDataGridParams } from "../hooks/useServerSideDataGrid"
import { get, patch, post } from "../../../services/api"

export type CatalogProductVariationsResponse = {
  results: CatalogProductVariation[]
  totalCount: number
}

export type CatalogProductVariationsParams = ServerSideDataGridParams & {
  status?: CatalogProductVariationStatus | "All"
  searchKeywords?: string
  supplierId?: string
}

export type GetCatalogProductVariationsResponse = {
  results: CatalogProductVariation[]
  totalCount: number
}

export const getCatalogProductVariations = async (
  params: CatalogProductVariationsParams
): Promise<CatalogProductVariationsResponse> => {
  const urlParams = toRailsStyle(params)
  const response = await fetch(`/cms/catalog/skus.json?${urlParams}`)
  const jsonData = camelizeKeys(await response.json())
  return jsonData
}

export type GetCatalogProductVariationResponse = {
  productVariation: CatalogProductVariation
}

export const getCatalogProductVariation = async (
  productVariationId: number | string,
  packageId?: number | undefined
): Promise<GetCatalogProductVariationResponse> => {
  const params = packageId !== undefined ? { packageId: packageId } : undefined
  return await get(`/cms/catalog/skus/${productVariationId}.json`, params).then(
    async (axiosResponse) => {
      return await axiosResponse.data
    }
  )
}

export const exportAsyncCatalogProductVariations = async (
  params?: Partial<CatalogProductVariationsParams>
): Promise<boolean> => {
  return await post("/cms/catalog/skus/export_async.json", params)
    .then((_data) => {
      return true
    })
    .catch((_error) => {
      return false
    })
}

export type CreateCatalogProductVariationResponse = ApiResponse<{
  productVariation: CatalogProductVariation
}>

export const updateProductVariation = async (
  productVariationId: string,
  params: { [p: string]: any }
): Promise<CreateCatalogProductVariationResponse> => {
  return await patch(`/cms/catalog/skus/${productVariationId}.json`, {
    catalog_product_variation: params,
  }).then((response) => response.data)
}

/**
 * @deprecated Can be removed as part of legacy code removal once WF-25864 is done.
 */
export const updateCatalogProductVariation = async (
  productVariationId: string,
  params: { [p: string]: any }
): Promise<CreateCatalogProductVariationResponse> => {
  const response = await fetch(`/cms/catalog/skus/${productVariationId}.json`, {
    method: "PATCH",
    headers: {
      "X-CSRF-Token": meta("csrf-token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      catalog_product_variation: decamelizeKeys({ ...params }),
    }),
  })

  const jsonData = await response.json()
  return camelizeKeys(jsonData)
}

export const createProductVariation = async (
  productId: string,
  params: { [p: string]: any }
): Promise<CreateCatalogProductVariationResponse> => {
  return await post(`/cms/catalog/products/${productId}/skus.json`, {
    catalog_product_variation: params,
  }).then((response) => response.data)
}

/**
 * @deprecated Can be removed as part of legacy code removal once WF-25864 is done.
 */
export const createCatalogProductVariation = async (
  productId: string,
  params: { [p: string]: any }
): Promise<CreateCatalogProductVariationResponse> => {
  const response = await fetch(`/cms/catalog/products/${productId}/skus.json`, {
    method: "POST",
    headers: {
      "X-CSRF-Token": meta("csrf-token"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      catalog_product_variation: decamelizeKeys({ ...params }),
    }),
  })

  const jsonData = await response.json()
  return camelizeKeys(jsonData)
}

/**
 * @deprecated Can be removed as part of legacy code removal once WF-25864 is done.
 */
export type GetCatalogProductVariationDefaultCustomAttributeValuesResponse = ApiResponse<{
  results: CatalogProductVariationCustomAttributeValue[]
}>

/**
 * @deprecated Can be removed as part of legacy code removal once WF-25864 is done.
 */
export const getCatalogProductVariationDefaultCustomAttributeValues = async (
  productId: string,
  productVariationId?: string | null
): Promise<GetCatalogProductVariationDefaultCustomAttributeValuesResponse> => {
  let baseUrl = `/cms/catalog/products/${productId}/default_custom_attribute_values.json`
  if (productVariationId) {
    baseUrl = `${baseUrl}?product_variation_id=${productVariationId}`
  }
  const response = await fetch(baseUrl, {
    method: "GET",
    headers: {
      "X-CSRF-Token": meta("csrf-token"),
    },
  })

  const jsonData = await response.json()
  return camelizeKeys(jsonData)
}

export const destroyProductVariation = async (id: string): Promise<boolean> => {
  const response = await fetch(`/cms/catalog/skus/${id}.json`, {
    method: "DELETE",
    headers: {
      "X-CSRF-Token": meta("csrf-token"),
    },
  })

  await response.text()

  return response.ok
}

export const unarchiveProductVariation = async (
  id: string
): Promise<boolean> => {
  const response = await fetch(`/cms/catalog/skus/${id}/unarchive.json`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": meta("csrf-token"),
    },
  })

  await response.text()

  return response.ok
}

export type BulkArchiveProductVariationsResponse = ApiResponse<{
  successCount: number
  errorCount: number
}>
export const bulkArchiveProductVariations = async (
  ids: string[]
): Promise<BulkArchiveProductVariationsResponse> => {
  const response = await fetch(`/cms/catalog/skus/bulk_archive`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": meta("csrf-token"),
    },
    body: JSON.stringify({
      ids: ids,
    }),
  })
  const jsonData = camelizeKeys(await response.json())
  return jsonData
}
