// extracted by mini-css-extract-plugin
var _1 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__action-column-padding--iLmB9";
var _2 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__actionsFooter--nqtKi";
var _3 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__column-container--rcUnU";
var _4 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__data-entry-column--wqXtw";
var _5 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__document-browser-frame--Dsend";
var _6 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__document-browser-injected-style--mEsbl";
var _7 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__document-column--QX4pU";
var _8 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__json-code--o1OzF";
var _9 = "app-javascript-applications-SupplierOrganizationInboxDocument-index-module__json-code-wrapper--HjG5c";
export { _1 as "actionColumnPadding", _2 as "actionsFooter", _3 as "columnContainer", _4 as "dataEntryColumn", _5 as "documentBrowserFrame", _6 as "documentBrowserInjectedStyle", _7 as "documentColumn", _8 as "jsonCode", _9 as "jsonCodeWrapper" }
