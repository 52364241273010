import React from "react"
import { useFacilityQuery } from "./components/DataEntryColumn/FacilityData"
import { useClinicianQuery } from "./components/DataEntryColumn/ClinicianData"
import { useExternalSystemBranchQuery } from "./components/DataEntryColumn/FacilityClinicianSection/ExternalSystemSelect"

export const ErrorContext = React.createContext({
  submissionAttempts: 0,
  incrementSubmissionAttempts: () => {},
})

export const useMissingRequiredFacilityClinician = () => {
  const { data: facilityData } = useFacilityQuery()
  const { data: doctorData } = useClinicianQuery()
  return !facilityData?.facility?.id && !doctorData?.doctor?.id
}

export const useMissingRequiredExternalSystemBranch = (
  brightreeAcceptance: boolean
) => {
  const { data: externalSystemBranchData } = useExternalSystemBranchQuery()
  return brightreeAcceptance && !externalSystemBranchData?.id
}

export const useAllRequiredFieldsSetCheck = (brightreeAcceptance: boolean) => {
  const missingRequiredFacilityClinician = useMissingRequiredFacilityClinician()
  const missingRequiredExternalSystemBranch = useMissingRequiredExternalSystemBranch(
    brightreeAcceptance
  )
  return (
    !missingRequiredFacilityClinician && !missingRequiredExternalSystemBranch
  )
}

export const useErrorContext = () => React.useContext(ErrorContext)

export const ErrorContextProvider = ({ children }) => {
  const [submissionAttempts, setSubmissionAttempts] = React.useState(0)
  const incrementSubmissionAttempts = () =>
    setSubmissionAttempts((prevAttempts) => prevAttempts + 1)

  return (
    <ErrorContext.Provider
      value={{ submissionAttempts, incrementSubmissionAttempts }}
    >
      {children}
    </ErrorContext.Provider>
  )
}
