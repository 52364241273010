import React from "react"
import Button from "components/form/Button"
import * as styles from "./index.module.scss"
import Link from "../../../../components/Link"

interface Props {
  onClick(): void
}

function StartFirstOrderCard({ onClick }: Props) {
  return (
    <div className={styles.container}>
      <h4>Start your first order!</h4>
      <p className="font-subparagraph canopy-mbe-8x">
        Create a new order and your progress will save automatically.
      </p>
      <p className="font-subparagraph canopy-mbe-8x">
        Want tips and tricks on for placing orders?{" "}
        <Link href="https://www.parachutehealth.com/demo" target="_blank">
          Schedule a demo
        </Link>
      </p>
      <Button className="btn-brand btn-sm" onClick={onClick}>
        + New Order
      </Button>
    </div>
  )
}

export default StartFirstOrderCard
