import React, { useEffect, useState } from "react"
import { updateCsrInboxStateCategories } from "./api"
import { useEffectThatWontRunOnMount } from "hooks/useEffectThatWontRunOnMount"
import {
  CanopyComboboxField,
  OptionItem,
  SelectOption,
} from "@parachutehealth/canopy-combobox-field"
import { useDebounce } from "hooks/useDebounce"

interface Category {
  id: string
  name: string
}

export interface CategoryEditorProps {
  csrInboxStateId: string
  supplierOrganizationId: string
  initialSelectedCategories: Category[]
  categories: Category[]
}

const optionItemToCategory = (item: OptionItem): Category => ({
  id: item.value,
  name: item.label || "",
})

const categoryToOption = (category: Category): SelectOption => ({
  label: category.name,
  value: category.id,
})

export const DEBOUNCE_DELAY = 1000

export const CsrInboxStateCategoryEditor: React.FC<CategoryEditorProps> = ({
  csrInboxStateId,
  supplierOrganizationId,
  initialSelectedCategories,
  categories,
}) => {
  const [selectedCategories, setSelectedCategories] = useState<Category[]>(
    initialSelectedCategories
  )

  const options = categories.map(categoryToOption)

  const handleChange = (newValue: OptionItem | OptionItem[] | null) => {
    if (!newValue) {
      setSelectedCategories([])
    } else {
      const values = Array.isArray(newValue)
        ? newValue.map(optionItemToCategory)
        : [optionItemToCategory(newValue)]

      setSelectedCategories(values)
    }
  }

  const debouncedUpdate = useDebounce(
    updateCsrInboxStateCategories,
    DEBOUNCE_DELAY
  )

  useEffectThatWontRunOnMount(() => {
    debouncedUpdate(
      csrInboxStateId,
      supplierOrganizationId,
      selectedCategories.map((c) => c.id)
    ).catch(() => {
      alert("Error updating categories please try again")
    })
  }, [selectedCategories, csrInboxStateId, supplierOrganizationId])

  useEffect(() => {
    setSelectedCategories(initialSelectedCategories)
  }, [initialSelectedCategories])

  return (
    <CanopyComboboxField
      label="Order categories"
      multiple
      description="Assign one or more categories for this order"
      options={options}
      value={selectedCategories.map(categoryToOption)}
      onChange={handleChange}
    />
  )
}

export default CsrInboxStateCategoryEditor
