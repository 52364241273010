import React, { useCallback, useEffect, useRef, useState } from "react"
import { ClinicalFacility, Doctor, Follower } from "sharedTypes"
import {
  Filters as FiltersType,
  ProfileCompletionProgress,
} from "../../sharedTypes"
import { Supplier } from "components/ClinicalFilters/sharedTypes"
import { useDebounce } from "hooks/useDebounce"
import Filters from "../Filters"
import DashboardHeader from "../DashboardHeader"
import DashboardColumn from "../DashboardColumn"
import StartFirstOrderCard from "../StartFirstOrderCard"
import { navigate } from "utilities/navigation"
import { EventCategory, trackEvent } from "utilities/tracking"
import { newOrderUrl } from "../../urls"
import WelcomeModal from "../WelcomeModal"
import CompleteFacilityProfileModal from "../CompleteFacilityProfileModal"
import LeftSidebar from "components/ClinicalDashboard/LeftSidebar"
import RemovedLastReviewerEmploymentAlert from "../Alerts/RemovedLastReviewerEmploymentAlert"
import OrderCanceledAlert from "components/OrderCanceledAlert"
import { useFeatureFlags } from "components/FeatureFlagContext"
import {
  SidebarListDoctor,
  SidebarListFacility,
} from "components/ClinicalDashboard/sharedTypes"
import { useEffectThatWontRunOnMount } from "hooks/useEffectThatWontRunOnMount"
import { AxiosPromise } from "axios"
import FocusTiles from "applications/ClinicalFacilityDashboard/components/FocusTiles/FocusTiles"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyText } from "@parachutehealth/canopy-text"
import { isEmpty } from "../../../../utilities/object"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { CanopyHeading } from "@parachutehealth/canopy-heading"
import IncomingOrderTeamPushbackConfigurationsModal from ".././IncomingOrderTeamPushbackConfigurationsModal/IncomingOrderTeamPushbackConfigurationsModal"
import IncomingOrdersNotificationPreferenceAlert from "../../../../components/IncomingOrdersNotificationPreferenceAlert"

type Props = {
  clinicalFacility: ClinicalFacility
  limit: number
  unreadEventCounts: object
  getClinicalFacilityDashboardResults(values?): Promise<any>
  followers: Follower[]
  suppliers: Supplier[]
  doctors: { doctorId: string; firstName: string; lastName: string }[]
  searchFollowers(term: string): Promise<Follower[]>
  searchSuppliers(term: string): Promise<Supplier[]>
  searchDoctors(term: string): Promise<Doctor[]>
  initialValues: FiltersType
  showStartFirstOrderCard?: boolean
  showWelcomeModal: boolean
  profileCompletionProgress: ProfileCompletionProgress
  alerts: {
    removedLastReviewerEmploymentDoctor?: string
    canceledOrder?: { id: string; patientFullName?: string }
  }
  sidebarInformation: {
    incomingOrdersEnabled: boolean
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    doctors?: SidebarListDoctor[]
    facilities?: SidebarListFacility[]
    lastAccessedDoctorEmployerId?: string
  }
  sidebarExpanded: boolean
  updateSidebarExpandedPreference: (sidebarExpanded: boolean) => AxiosPromise
  currentUserEligibleForReviewerSignUp?: boolean
}

const InternalClinicalFacilityDashboard: React.FC<Props> = ({
  clinicalFacility,
  limit,
  followers,
  suppliers,
  doctors,
  getClinicalFacilityDashboardResults,
  searchFollowers,
  searchSuppliers,
  searchDoctors,
  initialValues,
  showStartFirstOrderCard,
  showWelcomeModal,
  profileCompletionProgress,
  alerts,
  sidebarInformation,
  sidebarExpanded,
  updateSidebarExpandedPreference,
  currentUserEligibleForReviewerSignUp,
}: Props) => {
  const [loading, setLoading] = useState(true)
  const [inProgressOrders, setInProgressOrders] = useState([])
  const [submittedOrders, setSubmittedOrders] = useState([])
  const [doneOrders, setDoneOrders] = useState([])
  const [unreadEventCounts, setUnreadEventCounts] = useState({})
  const [initialOriginator, setInitialOriginator] = useState<string>(() =>
    initialValues.originator ? initialValues.originator : ""
  )
  const [initialFollowerIds, setInitialFollowerIds] = useState<string[]>(
    followers.map((follower) => follower.id)
  )
  const [initialSupplierIds, setInitialSupplierIds] = useState<string[]>(() =>
    suppliers.map((supplier) => supplier.externalId)
  )
  const [initialDeliveryDateStart, setInitialDeliveryDateStart] = useState(
    initialValues.deliveryDateStart ? initialValues.deliveryDateStart : ""
  )
  const [initialDeliveryDateEnd, setInitialDeliveryDateEnd] = useState(
    initialValues.deliveryDateEnd ? initialValues.deliveryDateEnd : ""
  )
  const [initialSort, setInitialSort] = useState<string>(() =>
    initialValues.sort ? initialValues.sort : ""
  )
  const [initialQuery, setInitialQuery] = useState("")
  const [initialDoctorIds, setInitialDoctorIds] = useState<string[]>(() =>
    doctors.map((doctor) => doctor.doctorId)
  )
  const { isFeatureEnabled } = useFeatureFlags()
  const incomingOrdersEnabledSetting = isFeatureEnabled(
    "incomingOrdersEnabledSetting"
  )

  const [initialLeftSidebarExpanded, setInitialLeftSidebarExpanded] = useState<
    boolean
  >(sidebarExpanded)

  const [
    incomingOrdersNotificationPreferenceMessage,
    setIncomingOrdersNotificationPreferenceMessage,
  ] = useState("")
  const debouncedGetClinicalFacilityDashboardResults = useDebounce(
    getClinicalFacilityDashboardResults
  )
  const latestRequestId = useRef<number>(0)

  const fetchData = useCallback(() => {
    const currentRequestId = ++latestRequestId.current
    const values = {
      query: initialQuery,
      originator: initialOriginator,
      followers: initialFollowerIds,
      supplier_ids: initialSupplierIds,
      doctor_ids: initialDoctorIds,
      deliveryDateStart: initialDeliveryDateStart,
      deliveryDateEnd: initialDeliveryDateEnd,
      sort: initialSort,
    }
    setLoading(true)
    debouncedGetClinicalFacilityDashboardResults(values)
      .then((results) => {
        if (currentRequestId === latestRequestId.current) {
          setInProgressOrders(results.inProgressOrders)
          setSubmittedOrders(results.submittedOrders)
          setDoneOrders(results.doneOrders)
          setUnreadEventCounts(results.unreadEventCounts)
          setLoading(false)
        }
      })
      .catch(() => {
        if (currentRequestId === latestRequestId.current) {
          setLoading(false)
        }
      })
  }, [
    debouncedGetClinicalFacilityDashboardResults,
    initialQuery,
    initialOriginator,
    initialFollowerIds,
    initialSupplierIds,
    initialDoctorIds,
    initialDeliveryDateStart,
    initialDeliveryDateEnd,
    initialSort,
  ])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const noOrders =
    !loading &&
    !inProgressOrders.length &&
    !submittedOrders.length &&
    !doneOrders.length

  const isAnyFilterActive = !!(
    initialQuery ||
    initialOriginator ||
    initialDeliveryDateStart ||
    initialDeliveryDateEnd ||
    !isEmpty(initialFollowerIds) ||
    !isEmpty(initialSupplierIds) ||
    !isEmpty(initialDoctorIds)
  )

  const noOrdersWithRegularFilter = noOrders && isAnyFilterActive

  const EVENT_CATEGORY = EventCategory.Activation
  const EVENT_NAME = "click-activation-start-first-order-button"

  const onStartFirstOrderClick = () => {
    trackEvent(EVENT_CATEGORY, EVENT_NAME).then(() => {
      navigate(newOrderUrl())
    })
  }

  const onClear = () => {
    setInitialOriginator("")
    setInitialSupplierIds([])
    setInitialFollowerIds([])
    setInitialDeliveryDateStart("")
    setInitialDeliveryDateEnd("")
    setInitialQuery("")
    setInitialDoctorIds([])
  }

  const showSignatureRequestsDashboardNewPill = currentUserEligibleForReviewerSignUp
  const showCompleteFacilityProfileModal =
    profileCompletionProgress.show === "modal"
  const showIncomingOrderTeamPushbackConfigurationsModal = isFeatureEnabled(
    "facilityIncomingOrderTeams"
  )

  function onToggleExpand() {
    setInitialLeftSidebarExpanded(!initialLeftSidebarExpanded)
  }

  useEffectThatWontRunOnMount(() => {
    updateSidebarExpandedPreference(initialLeftSidebarExpanded)
  }, [initialLeftSidebarExpanded])

  return (
    <div className="position-relative">
      <LeftSidebar
        active={LeftSidebar.Options.Orders}
        sidebarInformation={sidebarInformation}
        showSignatureRequestsDashboardNewPill={
          showSignatureRequestsDashboardNewPill
        }
        featureFlags={{
          incomingOrdersEnabledSetting: !!incomingOrdersEnabledSetting,
        }}
        onToggleExpand={onToggleExpand}
        expandLeftSidebar={initialLeftSidebarExpanded}
      />
      <div className="row mt-n3">
        <div className="col-12 canopy-mbs-12x">
          {incomingOrdersNotificationPreferenceMessage && (
            <IncomingOrdersNotificationPreferenceAlert
              message={incomingOrdersNotificationPreferenceMessage}
            />
          )}
          {alerts.canceledOrder &&
            alerts.canceledOrder.id &&
            alerts.canceledOrder.patientFullName && (
              <OrderCanceledAlert
                orderId={alerts.canceledOrder.id}
                patientFullName={alerts.canceledOrder.patientFullName}
              />
            )}
          {alerts.removedLastReviewerEmploymentDoctor && (
            <RemovedLastReviewerEmploymentAlert
              doctorName={alerts.removedLastReviewerEmploymentDoctor}
              showSignupLink={currentUserEligibleForReviewerSignUp}
            />
          )}
          <p
            className="canopy-typography-heading-xlarge"
            aria-label="facility name"
          >
            {clinicalFacility.name}
          </p>
          <FocusTiles
            clinicalFacilityId={clinicalFacility.externalId}
            clinicalFacilityIsEnterprise={!!clinicalFacility.enterprise}
            profileCompletionProgress={profileCompletionProgress}
          />
          <Filters
            initialQuery={initialQuery}
            setInitialQuery={setInitialQuery}
            followers={followers}
            suppliers={suppliers}
            doctors={doctors}
            initialOriginator={initialOriginator}
            setInitialOriginator={setInitialOriginator}
            initialFollowerIds={initialFollowerIds}
            setInitialFollowerIds={setInitialFollowerIds}
            initialDeliveryDateStart={initialDeliveryDateStart}
            setInitialDeliveryDateStart={setInitialDeliveryDateStart}
            initialDeliveryDateEnd={initialDeliveryDateEnd}
            setInitialDeliveryDateEnd={setInitialDeliveryDateEnd}
            initialSupplierIds={initialSupplierIds}
            setInitialSupplierIds={setInitialSupplierIds}
            initialDoctorIds={initialDoctorIds}
            setInitialDoctorIds={setInitialDoctorIds}
            initialSort={initialSort}
            setInitialSort={setInitialSort}
            searchFollowers={searchFollowers}
            searchSuppliers={searchSuppliers}
            searchDoctors={searchDoctors}
            onClear={onClear}
            showClearAllLink={isAnyFilterActive}
          />
          {loading ? (
            <CanopyFlex
              direction="column"
              alignItems="center"
              paddingBlockStart="48X"
            >
              <CanopyIcon
                name="spinner"
                className="fa-spin"
                style={{ height: "4rem", width: "4rem" }}
              />
              <CanopyHeading level={3} size="medium">
                Loading your orders...
              </CanopyHeading>
            </CanopyFlex>
          ) : (
            <div className="js-order-columns">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Orders in progress"
                    limit={limit}
                    count={inProgressOrders.length}
                  />
                  {showStartFirstOrderCard && (
                    <StartFirstOrderCard onClick={onStartFirstOrderClick} />
                  )}
                  <DashboardColumn
                    fetchData={fetchData}
                    dmeOrders={inProgressOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    sidebarExpanded={initialLeftSidebarExpanded}
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Sent to supplier"
                    limit={limit}
                    count={submittedOrders.length}
                  />
                  <DashboardColumn
                    fetchData={fetchData}
                    dmeOrders={submittedOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    sidebarExpanded={initialLeftSidebarExpanded}
                    showSwatch
                    showMissingDocument
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Accepted by supplier"
                    limit={limit}
                    count={doneOrders.length}
                  />
                  <DashboardColumn
                    fetchData={fetchData}
                    dmeOrders={doneOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    sidebarExpanded={initialLeftSidebarExpanded}
                    showSwatch
                  />
                </div>
              </div>
            </div>
          )}
          {noOrdersWithRegularFilter && (
            <div className="row">
              <div className="col-12">
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{ marginTop: "76px" }}
                >
                  <h4 className="canopy-typography-heading-2xlarge canopy-mbe-8x">
                    No orders found
                  </h4>
                  <CanopyText className="canopy-mbe-8x">
                    Clear your filters to view all orders at your facility.
                  </CanopyText>
                  <CanopyButton
                    onClick={onClear}
                    variant="secondary"
                    data-testid="no-orders-clear-filter-button"
                  >
                    Clear all filters
                  </CanopyButton>
                </div>
              </div>
            </div>
          )}
          {showWelcomeModal && <WelcomeModal />}
          {showIncomingOrderTeamPushbackConfigurationsModal && (
            <IncomingOrderTeamPushbackConfigurationsModal
              setIncomingOrdersNotificationPreferenceMessage={
                setIncomingOrdersNotificationPreferenceMessage
              }
            />
          )}
          {showCompleteFacilityProfileModal && <CompleteFacilityProfileModal />}
        </div>
      </div>
    </div>
  )
}

InternalClinicalFacilityDashboard.defaultProps = {
  initialValues: {},
}

export default InternalClinicalFacilityDashboard
