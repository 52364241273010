import React, { FC, useEffect, useState } from "react"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import { Formik } from "formik"
import {
  searchDoctors,
  updateIncomingOrdersNotificationPreference,
} from "../../api"
import {
  CanopyComboboxField,
  SelectOptions,
} from "@parachutehealth/canopy-combobox-field"
import * as styles from "./styles.module.scss"
import { useDebounce } from "../../../../hooks/useDebounce"

interface Props {
  setIncomingOrdersNotificationPreferenceMessage: (message: string) => void
}

const IncomingOrderTeamPushbackConfigurationsModal: FC<Props> = ({
  setIncomingOrdersNotificationPreferenceMessage,
}) => {
  const [open, setOpen] = useState(true)
  const [clinicianOptionsLoading, setClinicianOptionsLoading] = useState(false)
  const [clinicianOptions, setClinicianOptions] = useState<SelectOptions>([])

  const searchClinicians: (newValue: string) => void = (newValue) => {
    setClinicianOptionsLoading(true)
    searchDoctors(newValue)
      .then((clinicians) => {
        return clinicians.map((doctor) => ({
          label: `${doctor.firstName} ${doctor.lastName}`,
          value: doctor.doctorId,
        }))
      })
      .then((clinicianSelectOptions) => {
        setClinicianOptions(clinicianSelectOptions)
      })
      .then(() => setClinicianOptionsLoading(false))
  }

  const debouncedSearchClinicians = useDebounce(searchClinicians, 300, false)

  useEffect(() => {
    searchClinicians("")
  }, [])

  const onClose = () => setOpen(false)
  const onSubmit = (values, actions) => {
    actions.setSubmitting(false)
    updateIncomingOrdersNotificationPreference(
      values.incomingOrdersNotificationPreference,
      values.clinicians
    ).then((res) => {
      if (res.status === 200) {
        if (values.incomingOrdersNotificationPreference === "notify") {
          setIncomingOrdersNotificationPreferenceMessage(
            "You've been added to a team to be notified for incoming orders"
          )
        } else if (
          values.incomingOrdersNotificationPreference === "do-not-notify"
        ) {
          setIncomingOrdersNotificationPreferenceMessage(
            "You've opted out of notifications for incoming orders"
          )
        }
      }
    })
    onClose()
  }

  return (
    <Formik
      initialValues={{
        incomingOrdersNotificationPreference: undefined,
        clinicians: undefined,
      }}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors: { incomingOrdersNotificationPreference?: string } = {}
        if (!values.incomingOrdersNotificationPreference) {
          errors.incomingOrdersNotificationPreference = "Select an option"
        }
        return errors
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
      }) => {
        return (
          <CanopyDialogModal
            className={styles.modalOverflowOverride}
            open={open}
            header="Never miss an order for your patients"
            headerOverline="Opt in to notifications for incoming orders from suppliers"
            onClose={onClose}
            primaryFooterButton={
              <CanopyButton
                variant="primary"
                type="submit"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  handleSubmit()
                }}
                loading={isSubmitting}
              >
                Confirm
              </CanopyButton>
            }
            secondaryFooterButton={
              <CanopyButton variant="secondary" onClick={onClose}>
                Close
              </CanopyButton>
            }
          >
            <CanopyRadioInputField
              label="Notify for incoming orders"
              hiddenLabel
              name="incomingOrdersNotificationPreference"
              value={values.incomingOrdersNotificationPreference}
              onChange={handleChange}
              feedbackMessage={
                errors.incomingOrdersNotificationPreference as string
              }
              options={[
                {
                  label: "Notify me for incoming orders from suppliers",
                  value: "notify",
                },
                {
                  label: "Don't notify me for incoming orders from suppliers",
                  value: "do-not-notify",
                },
              ]}
            />
            {values.incomingOrdersNotificationPreference === "notify" && (
              <>
                <br />
                <CanopyComboboxField
                  label="Select clinicians you handle incoming orders for"
                  description="Leave this field empty if you handle all incoming orders at this facility"
                  id="clinicians"
                  loading={clinicianOptionsLoading}
                  multiple
                  onChange={async (newValue) => {
                    await setFieldValue("clinicians", newValue)
                  }}
                  onInputChange={debouncedSearchClinicians}
                  optional
                  options={clinicianOptions}
                  placeholder="Select clinicians..."
                  value={values.clinicians || ""}
                />
              </>
            )}
          </CanopyDialogModal>
        )
      }}
    </Formik>
  )
}

export default IncomingOrderTeamPushbackConfigurationsModal
