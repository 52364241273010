import { useRef } from "react"

export interface TimerProps {
  duration: number
  onExpire: () => void
}

export const useTimer = ({ duration, onExpire }: TimerProps) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const startTimer = () => {
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(onExpire, duration)
    }
  }

  const clearTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }

  return { startTimer, clearTimer }
}
