import React from "react"
import Icon from "components/Icon"
import { eventClassName } from "./Event/utilities/classNames"
import classNames from "classnames"
import { Event } from "../sharedTypes"

type Props = {
  event: Event
  internal?: boolean
}

export const Timeline: React.FC<Props> = ({ event, internal, children }) => {
  const className = internal ? "internal" : eventClassName(event)

  return (
    <div className={classNames("timeline", className)}>
      <div className="timeline-container">
        <div className="timeline-icon text-center">
          <span>
            {className === "supplier" && <Icon prefix="far" type="comment" />}
            {className === "insurance-payor" && (
              <Icon prefix="far" type="comment" />
            )}
            {className === "internal" && <Icon prefix="fas" type="eye-slash" />}
            {className === "referral" && <Icon prefix="far" type="comment" />}
            {className === "patient" && <Icon prefix="far" type="comment" />}
          </span>
        </div>
        <div className="timeline-line" />
      </div>
      <div className="timeline-content">{children}</div>
    </div>
  )
}

export default Timeline
