import React, { useState } from "react"
import Confirm from "components/Confirm"
import Icon from "components/Icon"
import { CanopyButton } from "@parachutehealth/canopy-button"

type Props = {
  archive(): void
  newUi?: boolean
}

function ArchiveButton(props: Props) {
  const [disabled, setDisabled] = useState(false)
  const archive = () => {
    props.archive()
    setDisabled(true)
  }

  return (
    <Confirm
      title="Are you sure?"
      confirmText="Yes, remove"
      cancelText="No, cancel"
      render={(confirm) => {
        return props.newUi ? (
          <CanopyButton
            variant="danger"
            size="small"
            iconStart="trash-can"
            disabled={disabled}
            onClick={confirm(archive)}
          >
            Archive document
          </CanopyButton>
        ) : (
          <button
            className="btn btn-secondary btn-xs canopy-mis-4x color-danger"
            disabled={disabled}
            onClick={confirm(archive)}
          >
            <Icon type="trash" />{" "}
          </button>
        )
      }}
    />
  )
}

export default ArchiveButton
