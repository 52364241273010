// @team @nexus
import React, { useState } from "react"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { SalesRep } from "sharedTypes"
import { CanopyComboboxField } from "@parachutehealth/canopy-combobox-field"
import { useQuery } from "@tanstack/react-query"
import { useDebounce } from "hooks/useDebounce"
import { canopyDialogModalWithComboboxOrPicker } from "./index.module.scss"

type Props = {
  salesRep: SalesRep | null
  searchSalesReps: (term: string) => Promise<SalesRep[]>
  updateSalesRep: (params: { externalId: string | null }) => Promise<void>
  close: () => void
}

const SalesRepModal = (props: Props) => {
  const [selectedSalesRep, setSelectedSalesRep] = useState(
    props.salesRep && {
      value: props.salesRep.externalId,
      label: props.salesRep.name,
    }
  )

  const [query, setQuery] = useState("")
  const debouncedSetQuery = useDebounce(setQuery)
  const [loading, setLoading] = useState(false)

  const { data: options, isFetching } = useQuery({
    queryKey: ["salesRepSearch", query],
    queryFn: async () => {
      const res = await props.searchSalesReps(query)
      return res.map((salesRep) => ({
        value: salesRep.externalId,
        label: salesRep.name,
      }))
    },
  })

  const onChange = (option) => {
    setSelectedSalesRep(option)
  }

  const done = async () => {
    setLoading(true)
    await props.updateSalesRep({ externalId: selectedSalesRep?.value || null })
    props.close()
  }

  return (
    <CanopyDialogModal
      className={canopyDialogModalWithComboboxOrPicker}
      header="Sales Rep"
      primaryFooterButton={
        <CanopyButton
          loading={loading}
          loadingText="Updating..."
          onClick={done}
        >
          Done
        </CanopyButton>
      }
      open={true}
      onClose={props.close}
    >
      <CanopyComboboxField
        id="external_id"
        label="Sales Rep"
        options={options || []}
        onInputChange={debouncedSetQuery}
        value={selectedSalesRep}
        loading={isFetching}
        onChange={onChange}
      />
    </CanopyDialogModal>
  )
}

export default SalesRepModal
