import React from "react"
import { NetworkCoverage, OrderType, Supplier } from "sharedTypes"
import Icon from "components/Icon"
import * as styles from "./index.module.scss"
import { SupplierContactDetails } from "../../sharedTypes"
import Subhead from "./Subhead"
import Alert from "components/Alert"
import { CanopyHeading } from "@parachutehealth/canopy-heading"

interface Props {
  dmeOrder: {
    orderTypes: OrderType[]
  }
  networkCoverage: NetworkCoverage | undefined
  supplier: Supplier
  supplierContactDetails: SupplierContactDetails
}

const SupplierHeader: React.FC<Props> = ({
  dmeOrder,
  networkCoverage,
  supplier,
  supplierContactDetails,
}) => {
  const isOutOfNetwork = networkCoverage !== NetworkCoverage.InNetwork
  const isHospiceOrder = dmeOrder.orderTypes.includes(OrderType.HospiceOrder)
  const showInsuranceWarning = isOutOfNetwork && !isHospiceOrder

  const NotAccepted = () => (
    <span className={styles.coverage}>
      <Icon type="exclamation-triangle" className="color-alert" />
      <span className="canopy-mis-4x">
        The patient's insurance is not accepted by this supplier
      </span>
    </span>
  )

  const UnknownAcceptance = () => (
    <Alert leftIcon status="warning">
      <strong>Heads up!</strong> We don't know if the patient's insurance is
      accepted by this supplier
    </Alert>
  )

  return (
    <div className="canopy-mbe-12x">
      <CanopyHeading size="2xLarge" level={1} className="canopy-mbe-4x">
        {supplier.name}
      </CanopyHeading>
      <Subhead
        supplierPhoneNumber={supplierContactDetails.supplierPhoneNumber}
      />
      {showInsuranceWarning &&
        (networkCoverage === NetworkCoverage.Unknown ? (
          <UnknownAcceptance />
        ) : (
          <NotAccepted />
        ))}
    </div>
  )
}

export default SupplierHeader
