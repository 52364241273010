import React, { ReactNode, useContext } from "react"
import { SidebarLink } from "sharedTypes"
import { trackSidebarAnalyticsEvent } from "./utilities/analytics"
import WorkflowPageContext from "context/WorkflowPage"
import * as tokens from "@parachutehealth/canopy-tokens-color"

type Props = {
  links: SidebarLink[]
}

const linkColor = tokens.canopyColorInteractiveLinkDefault
const linkStyle = {
  color: linkColor,
  textDecoration: "underline",
}

const SidebarLinks: React.FC<Props> = ({ links }) => {
  const { setSidebarTab } = useContext(WorkflowPageContext)

  const renderLink = (link: SidebarLink): ReactNode => {
    return (
      <div
        key={`${link.tab}-${link.text}`}
        role="button"
        className="d-inline-block"
        style={linkStyle}
        onClick={() => {
          link.analyticsEvent && trackSidebarAnalyticsEvent(link)
          return setSidebarTab(link.tab)
        }}
      >
        {link.text}
      </div>
    )
  }

  return <div>{links.map(renderLink)}</div>
}

export default SidebarLinks
