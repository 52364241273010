import React, { useState } from "react"
import Modal from "components/Modal"
import Overlay from "components/Overlay"

type Props = {
  onClick(): Promise<void>
}

const SupplierEditButton = ({ onClick }: Props) => {
  const [showModal, toggleModal] = useState(false)
  const [isProcessing, setProcessing] = useState(false)
  const openModal = () => toggleModal(true)
  const closeModal = () => toggleModal(false)
  const clickProcessing = () => {
    setProcessing(true)
    return onClick()
  }

  return (
    <>
      <button
        type="submit"
        className="btn btn-brand-o btn-block"
        onClick={openModal}
      >
        Edit order
      </button>
      <Modal
        size="md"
        title="Are you sure you want to edit the order?"
        show={showModal}
        closeable={false}
      >
        <Overlay
          active={isProcessing}
          className="overlay-flex-height"
          showSpinner
        >
          <Modal.Body>
            <p className="canopy-mbe-12x">
              Taking over editing permissions will lock the order for the
              facility user.
            </p>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-secondary canopy-mie-4x"
                onClick={closeModal}
              >
                Never mind
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={clickProcessing}
              >
                Yes, edit
              </button>
            </div>
          </Modal.Body>
        </Overlay>
      </Modal>
    </>
  )
}

export default SupplierEditButton
