import { post } from "services/api"
import { employerPrefix } from "utilities/url"

export const attachToExternalOrder = (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  supplierSystemOrderId: string
): Promise<{ success: boolean }> => {
  return post(
    `${employerPrefix(
      "SupplierOrganization",
      supplierOrganizationId
    )}/inbox_documents/${csrInboxStateId}/attach_to_external_order`,
    { supplier_system_order_id: supplierSystemOrderId }
  )
    .then(() => ({ success: true }))
    .catch(() => ({ success: false }))
}
