import { EmployerClassEnum } from "../../../../sharedTypes"

export const useEmployer = () => {
  const employerType = window?.parachute?.employerType
  const employmentRole = window?.parachute?.currentEmploymentRole

  const isSupplier = employerType === EmployerClassEnum.Supplier
  const isPayor = employerType === EmployerClassEnum.InsurancePayor

  return {
    currentEmployer: employerType,
    role: employmentRole,
    isSupplier,
    isPayor,
  }
}
