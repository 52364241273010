import { employerPrefix, userPrefix } from "utilities/url"
import { stringify } from "utilities/querystring"

export const currentOrderId = () => {
  return window.parachute && window.parachute.currentOrderId
}

export const dmeOrderUrl = (path, id?) =>
  `${employerPrefix()}/orders/${id || currentOrderId()}${path}`

export function employerHomeUrl() {
  return employerPrefix()
}

export function workflowsUrl(orderId?) {
  return dmeOrderUrl("", orderId)
}

export function packageConfigurationUrl(id) {
  return dmeOrderUrl(`/dme_order_package_configurations/${id}.json`)
}

export function packageConfigurationErrorNotificationUrl(id) {
  return dmeOrderUrl(
    `/dme_order_package_configurations/${id}/package_configuration_error_notification`
  )
}

export function packageConfigurationV2Url(id) {
  return dmeOrderUrl(`/package_configurations/${id}.json`)
}

export function lineItemPrescribedQuantityUrl(id) {
  return dmeOrderUrl(`/line_items/${id}/prescribed_quantity.json`)
}

export function deliveryUrl(deliveryId) {
  return dmeOrderUrl(`/deliveries/${deliveryId}.json`)
}

export function updateDeliveryAddressUrl(deliveryId) {
  return dmeOrderUrl(`/deliveries/${deliveryId}/update_delivery_address.json`)
}

export function workflowReviewUrl() {
  return dmeOrderUrl("/review")
}

export function createDeliveryUrl() {
  return dmeOrderUrl("/deliveries.json")
}

export function catalog() {
  return dmeOrderUrl("/workflow/catalog.json")
}

export function searchByProductUrl() {
  return dmeOrderUrl("/workflow/catalog/search_by_product.json")
}

export function quickListUrl() {
  return dmeOrderUrl("/quick_list.json")
}

export function quickListBulkAddUrl() {
  return dmeOrderUrl("/quick_list/bulk_add.json")
}

export function searchBySkuUrl() {
  return dmeOrderUrl("/packages/search.json")
}

export function searchBySkuCompatibleWithSearchByProductUrl() {
  return dmeOrderUrl(
    "/packages/search_by_sku_compatible_with_search_by_product.json"
  )
}

export function initialCatalogDataUrl() {
  return dmeOrderUrl("/workflow/catalog/browse.json")
}

export function initialSearchByProductUrl() {
  return dmeOrderUrl("/workflow/catalog/initial_search_by_product.json")
}

export function initialCartData() {
  return dmeOrderUrl("/workflow/cart.json")
}

export function initialSignatureDataUrl() {
  return dmeOrderUrl("/workflow/signature.json")
}

export function reviewDataUrl() {
  return dmeOrderUrl("/workflow/review.json")
}

export function updateDeliveryUrl() {
  return dmeOrderUrl("/workflow/delivery.json")
}

export function supplierSendBackDataUrl() {
  return dmeOrderUrl("/supplier_send_back.json")
}

export function selectPackage() {
  return dmeOrderUrl("/dme_order_package_configurations.json")
}

export function selectPackageV2() {
  return dmeOrderUrl("/package_configurations.json")
}

export function selectPackageWithSkuUrl() {
  return dmeOrderUrl(
    "/dme_order_package_configurations/create_from_product_variation.json"
  )
}

export function availableSuppliersUrl() {
  return dmeOrderUrl("/package_configurations/available_suppliers.json")
}

export function dmeOrder() {
  return dmeOrderUrl("/dme_order.json")
}

export function dmeOrderWarningsUrl() {
  return dmeOrderUrl("/warnings.json")
}

export function dmeOrderReorderUrl(id) {
  return `${employerPrefix()}/orders/${id}/reorder`
}

export function updateLineItemGroupDeliveryAddressUrl(lineItemGroupId) {
  return dmeOrderUrl(`/groups/${lineItemGroupId}/update_delivery_address.json`)
}

export function lineItemGroupUrl(lineItemGroupId) {
  return dmeOrderUrl(`/groups/${lineItemGroupId}.json`)
}

export function lineItemGroupCustomAttributeConfigurationsUrl(lineItemGroupId) {
  return dmeOrderUrl(
    `/groups/${lineItemGroupId}/custom_attribute_configurations.json`
  )
}

export function updateLineItemGroupCustomAttributeConfigurationUrl(
  lineItemGroupId
) {
  return dmeOrderUrl(
    `/groups/${lineItemGroupId}/custom_attribute_configurations/select.json`
  )
}

export function getPreferredSuppliersUrl(insuranceProductId: string) {
  return `${employerPrefix()}/supplier_insurance_network/${insuranceProductId}/preferred_suppliers.json`
}

export function followersUrl(orderId?: string) {
  return dmeOrderUrl("/dme_order_followings.json", orderId)
}

export function unfollowOrderUrl(followingId: string) {
  return dmeOrderUrl(`/dme_order_followings/${followingId}.json`)
}

export function createSubmissionUrl() {
  return dmeOrderUrl("/submission.json")
}

export function submissionStatusUrl() {
  return dmeOrderUrl("/submission/status.json")
}

export function createAcceptanceUrl() {
  return dmeOrderUrl("/acceptance.json")
}

export function acceptanceStatusUrl() {
  return dmeOrderUrl("/acceptance/status.json")
}

export function pullBackUrl() {
  return dmeOrderUrl("/pull_backs.json")
}

export function archiveUrl() {
  return dmeOrderUrl("/archives.json")
}

export function supplierEditUrl() {
  return dmeOrderUrl("/supplier_edits.json")
}

export function supplierSendBackUrl() {
  return dmeOrderUrl("/supplier_send_backs.json")
}

export function trackRecommendationResultsUrl() {
  return employerPrefix() + "/recommendation_results.json"
}

export function employmentMentionsUrl() {
  return dmeOrderUrl("/employment_mentions.json")
}

export function supplierSendBackFollowablesUrl() {
  return dmeOrderUrl("/supplier_followables.json")
}

export function supplierSendBackRecipientsUrl() {
  return dmeOrderUrl("/facility_recipients.json")
}

export function facilityInfoUrl() {
  return dmeOrderUrl("/supplier_send_backs/facility_info.json")
}

export function supplierRejectUrl() {
  return dmeOrderUrl("/supplier_rejections.json")
}

export function supplierUnacceptUrl() {
  return dmeOrderUrl("/supplier_unaccepts.json")
}

export function cancelUrl(dmeOrderId?: string) {
  return dmeOrderUrl("/dme_order_cancellations.json", dmeOrderId)
}

export function patientUrl(id) {
  return dmeOrderUrl(`/patients/${id}.json`)
}

export function updateEmergencyContactUrl() {
  return dmeOrderUrl("/dme_order_emergency_contact.json")
}

export function deleteEmergencyContactUrl(id) {
  return dmeOrderUrl(`/dme_order_emergency_contacts/${id}.json`)
}

export function icd10CodesUrl() {
  return dmeOrderUrl("/icd10_codes.json")
}

export function searchIcd10CodesUrl() {
  return dmeOrderUrl("/icd10_codes/search.json")
}

export function createSignedDeliveryTicketUrl() {
  return dmeOrderUrl("/dme_order_delivery_ticket_signature.json")
}

export function signatureUrl() {
  return dmeOrderUrl("/signatures.json")
}

export function signatureRequestUrl() {
  return dmeOrderUrl("/signature_requests.json")
}

export function updateDischargeDateUrl() {
  return dmeOrderUrl("/discharge_date.json")
}

export function updateOrderDateUrl() {
  return dmeOrderUrl("/order_date.json")
}

export function updateSatisfyMethodUrl(id) {
  return dmeOrderUrl(`/documentation_requirements/${id}.json`)
}

export function updateDocumentationRequirementsRequestedUrl() {
  return dmeOrderUrl("/documentation_requirements/update_requested.json")
}

export function updateDocumentationRequirementsSatisfyStatusUrl() {
  return dmeOrderUrl("/documentation_requirements/update_satisfy_status.json")
}

export function dmeOrderDiagnosisUrl() {
  return dmeOrderUrl("/diagnosis.json")
}

export function searchInsuranceUrl() {
  return dmeOrderUrl("/insurance/search.json")
}

export function updateInsurancePolicyUrl() {
  return dmeOrderUrl("/dme_order_insurance_policy.json")
}

export function deleteInsurancePolicyUrl(id) {
  return dmeOrderUrl(`/dme_order_insurance_policies/${id}.json`)
}

export function insuranceCheckUrl() {
  return dmeOrderUrl("/insurance/check_completed.json")
}

export function insuranceCarrierPredictionsUrl() {
  return dmeOrderUrl("/insurance/carrier_predictions.json")
}

export function insuranceAutoUpdateCarrierUrl() {
  return dmeOrderUrl("/insurance/auto_update_carrier.json")
}

export function dmeOrderCarrierAuthorizationsUrl() {
  return dmeOrderUrl("/dme_order_carrier_authorization.json")
}

export function workflowCarrierAuthorizationsUrl() {
  return dmeOrderUrl("/workflow/carrier_authorizations.json")
}

export function confirmPrefilledAnswersUrl(id) {
  return dmeOrderUrl(`/chart_notes/${id}/confirm_prefilled_answers.json`)
}

export function startChartNoteUrl(id) {
  return dmeOrderUrl(`/chart_notes/${id}/start.json`)
}

export function startQuestionnaireResponseUrl(id) {
  return dmeOrderUrl(
    `/dme_order_cached_questionnaire_responses/${id}/start.json`
  )
}

export function questionnaireResponseAnswerUrl(
  questionnaireResponseId,
  questionId
) {
  return dmeOrderUrl(
    `/dme_order_cached_questionnaire_responses/${questionnaireResponseId}/answers/${questionId}.json`
  )
}

export function dmeOrderQuestionAnswerUrl(questionId) {
  return dmeOrderUrl(`/dme_order_question_answers/${questionId}.json`)
}

export function rxSurveyQuestionAnswerUrl(id) {
  return dmeOrderUrl(`/rx_survey_question_answers/${id}.json`)
}

export function chartNoteQuestionsUrl(chartNoteId) {
  return dmeOrderUrl(`/chart_notes/${chartNoteId}/questions.json`)
}

export function updateDoctorUrl() {
  return dmeOrderUrl("/doctors.json")
}

export function createDoctorContactUrl() {
  return dmeOrderUrl("/doctor_contacts.json")
}

export function updateSignatureTypeUrl() {
  return dmeOrderUrl("/signature_type.json")
}

export function updateSignatureChannelUrl() {
  return dmeOrderUrl("/signature_channels.json")
}

export function updateSignatureStatusUrl() {
  return dmeOrderUrl("/signature_statuses.json")
}

export function doctorSearchUrl() {
  return dmeOrderUrl("/doctors/search.json")
}

export function documentationUploadUrl(
  signed = true,
  attachmentGroupId = null
) {
  return dmeOrderUrl(
    `/documents.json?${stringify({
      signed_upload: signed,
      dme_order_attachment_group_id: attachmentGroupId,
    })}`
  )
}

export function createAttachmentGroupUrl() {
  return dmeOrderUrl("/attachment_groups")
}

export function dmeOrderSnapshotUrl() {
  return dmeOrderUrl("/workflow/snapshot.json")
}

export function sendDocumentsUrl() {
  return dmeOrderUrl("/send_documents.json")
}

export function quickOrderPath() {
  return dmeOrderUrl("/quick_orders")
}

export function workflowLineItemGroupPreferenceUrl() {
  return dmeOrderUrl("/workflow/line_item_group_preference.json")
}

export function dashboardPath() {
  return employerPrefix()
}

export function updateEmploymentPreferencesUrl() {
  return `${employerPrefix()}/employment_preferences.json`
}

export function carrierAuthorizationUrl(id) {
  return `${employerPrefix()}/authorizations/${id}`
}

// For carrier authorizations created within Parachute Health
export function carrierAuthorizationDetailsUrl(id) {
  return `${employerPrefix()}/insurance/authorizations/${id}`
}

export function sidebarActivityUrl() {
  return dmeOrderUrl("/workflow/activity.json")
}

export function sidebarDocumentsUrl() {
  return dmeOrderUrl("/workflow/documents.json")
}

export function sidebarDocumentsDownloadurl(id) {
  return dmeOrderUrl(`/workflow/documents/${id}`)
}

export function archiveAttachmentsUrl(id) {
  return dmeOrderUrl(`/dme_order_attachments/${id}/archive.json`)
}

export function commentsUrl() {
  return dmeOrderUrl("/comments.json")
}

export function internalCommentsUrl() {
  return `${employerPrefix()}/internal_comments.json?dme_order_id=${currentOrderId()}`
}

export function supplierCommentsUrl() {
  return `${employerPrefix()}/supplier_comments.json?dme_order_id=${currentOrderId()}`
}

export function transformImageUrl(id) {
  return dmeOrderUrl(`/documents/${id}/transform.json`)
}

export function updateFulfillmentUrl(id) {
  return `${employerPrefix()}/dashboard/dme_orders/${currentOrderId()}/delivery_statuses/${id}.json`
}

export function selectDefaultConsignmentClosetUrl() {
  return `${employerPrefix()}/default_consignment_closets.json`
}

export function getUserEmailUrl() {
  return dmeOrderUrl("/user_email.json")
}

export function updateUserEmailUrl() {
  return dmeOrderUrl("/user_email.json")
}

export function sendPushbackInviteUrl() {
  return dmeOrderUrl("/pushback_invitation.json")
}

export function updateUiPreferencesUrl() {
  return `${userPrefix()}/ui_preferences.json`
}

export function updateSupplierLegalEntityUrl() {
  return dmeOrderUrl("/supplier_legal_entity.json")
}

export function getSupplierLegalEntitiesUrl() {
  return `${employerPrefix()}/supplier_legal_entities.json`
}

export function createInvitationUrl() {
  return dmeOrderUrl("/dme_order_invitations.json")
}

export function patientContactUrl() {
  return dmeOrderUrl("/patient_contact.json")
}

export function updateSupplierFulfillmentUrl(fulfillment_id) {
  return dmeOrderUrl(`/supplier_fulfillments/${fulfillment_id}.json`)
}

export function fulfillmentAgreementsUrl() {
  return `${employerPrefix()}/fulfillment_agreements`
}

export function updateSupplierSystemClinicalFacilityIdUrl(
  clinicalFacilityId: string
) {
  return `${employerPrefix()}/clinical_facilities/${clinicalFacilityId}/supplier_system_clinical_facility.json`
}

export function updateClinicalFacilityUrl() {
  return dmeOrderUrl("/clinical_facility.json")
}

export function createSupplierTransferUrl() {
  return dmeOrderUrl("/supplier_transfer.json")
}

export function supplierTransferOriginalCartSnapshotUrl() {
  return dmeOrderUrl("/workflow/supplier_transfer_original_cart_snapshot.json")
}

export function faxCoverSheetUrl(
  documentationRequirementIds: string[],
  otherTitle: string
) {
  const queryParams =
    documentationRequirementIds.length > 0
      ? `?${stringify({
          documentation_requirement_ids: documentationRequirementIds,
          other_title: otherTitle,
        })}`
      : `?${stringify({ other_title: otherTitle })}`
  return dmeOrderUrl(`/fax_cover_sheet.pdf${queryParams}`)
}

export function activeUsersUrl() {
  return dmeOrderUrl("/active_users.json?skip_extend_session=true")
}

export function signOutUrl() {
  return "/users/sign_out.json"
}

export function signInUrl() {
  return "/users/sign_in"
}

export const supportUrl = "/jwt/zendesk?return_to=%2Fhc%2Fen-us"

export function facilitySupportPageUrl() {
  return `${employerPrefix()}/support`
}

export function createReviewerEmploymentUrl() {
  return `${employerPrefix()}/reviewers`
}

export const incomingOrdersUrl = `${userPrefix()}/incoming_orders`

export function facilityDashboardSubpageUrl(subpage: string) {
  return `${employerPrefix()}/${subpage}`
}

export function referralApiCatalogPackageUrl(dme_order_id) {
  return `/emr/dme_orders/${dme_order_id}/catalog_package.json`
}

export function supplierCommentTemplatesUrl() {
  return `${employerPrefix()}/supplier_comment_templates.json`
}

export function employerHomeUrlWithCanceledOrderId(canceledOrderId?: string) {
  return `${employerPrefix()}?canceled_order_id=${canceledOrderId}`
}

export const updateSalesRepUrl = () =>
  `${employerPrefix()}/dashboard/dme_orders/${currentOrderId()}/sales_reps.json`
export const searchSalesRepsUrl = () => `${employerPrefix()}/sales_reps.json`

export function duplicateOrderUrl(dmeOrderId: string) {
  return `${employerPrefix()}/orders/${dmeOrderId}/duplicate_order`
}
