// @team @demand-ordering
// @ts-strict-ignore
import React, { Component } from "react"
import { Form, RadioButtons, Checkboxes } from "components/form"
import Overlay from "components/Overlay"
import { arrayEquals } from "utilities/array"
import {
  PackageConfiguration,
  CustomAttributeWithOptions,
} from "../sharedTypes"
import {
  ApplicationError,
  FeatureFlagInput,
  OfferingType,
  Supplier,
} from "sharedTypes"
import { FormikValues } from "formik"
import DeliveryMethodSelector from "./DeliveryMethodSelector"
import { createPackageConfigurationErrorNotification } from "applications/Workflow/api"
import { handleError } from "utilities/error"
import {
  canopyColorPrimitivesGray86,
  canopyColorTextSecondary,
} from "@parachutehealth/canopy-tokens-color"
import SelectedSupplierSummary from "./SupplierSelector/SelectedSupplierSummary"
import debounce from "awesome-debounce-promise"
import isEqual from "lodash.isequal"

const nameFor = ({ id }) => `attribute${id}`

const productAttributeNameFor = (productId, attributeId): string =>
  `product${productId}attribute${attributeId}`

const notFulfillableWarning = (id) => {
  try {
    createPackageConfigurationErrorNotification(id)
  } catch (error) {
    handleError(error as ApplicationError)
  }
  return (
    <div className="well well-danger well-expand canopy-mbs-8x">
      Package cannot be configured. Parachute Health has been notified, and
      we're working on resolving the issue. Check back again soon!
    </div>
  )
}

type ProductConfigurationValues = {
  selectedOptionalProductIds: string[] | string
  supplierId: string
  deliveryMethod: string
  productCustomAttributeOptions?: string[][]
  catalogCustomAttributeOptionIds?: string[]
}

type Props = {
  isConfigurationIncomplete: boolean
  showAccessories: boolean
  packageConfiguration: PackageConfiguration
  preferredSuppliers: Supplier[]
  onContinue(
    params?: FormikValues
  ): Promise<PackageConfiguration> | Promise<void>
  save(params: FormikValues): Promise<PackageConfiguration> | Promise<void>
  featureFlags: FeatureFlagInput
  showSupplierSelection(): void
}

type State = {
  loading: boolean
}

const saveValues = (
  values: ProductConfigurationValues,
  save: (params: FormikValues) => Promise<PackageConfiguration | void>,
  setLoading: (loading: boolean) => void,
  launchLoadingSpinner: boolean
) => {
  if (launchLoadingSpinner) {
    setLoading(true)
  }
  return save(values)
}

const debouncedSave = debounce(saveValues, 250)

class ConfigurationDeprecated extends Component<Props, State> {
  outstandingRequest?: Promise<void>
  outstandingValues?: { [key: string]: string }
  pendingValues?: { [key: string]: string }
  continueClicked: boolean
  previousValues?: { [key: string]: string }

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }
    this.outstandingRequest = null
    this.pendingValues = null
    this.previousValues = null
  }

  allProductCustomAttributes = () => {
    const {
      packageConfiguration: {
        requiredProductCustomAttributes,
        optionalProductCustomAttributes,
      },
    } = this.props
    return requiredProductCustomAttributes.concat(
      optionalProductCustomAttributes
    )
  }

  actuallySubmit = (values, setErrors, resetForm) => {
    this.outstandingValues = values
    const { save } = this.props
    const valuesToSubmit = this.getValuesToSubmit(values)

    this.outstandingRequest = save(valuesToSubmit)
      .then(() => {
        this.outstandingRequest = null
        this.outstandingValues = null
        if (this.pendingValues) {
          this.actuallySubmit(this.pendingValues, setErrors, resetForm)
          this.pendingValues = null
        } else if (this.continueClicked) {
          this.props.onContinue().then(() => {
            this.setState({ loading: false })
          })
        } else {
          this.setState({ loading: false })
          resetForm()
        }
      })
      .catch((errors) => {
        this.outstandingRequest = null
        this.outstandingValues = null
        this.pendingValues = null
        resetForm()
        if (errors.supplierId || errors.catalogCustomAttributeOptionIds) {
          setErrors(errors)
        } else {
          setErrors({ base: "Something went wrong!" })
        }
      })
  }

  submitValues: (
    values: { [key: string]: string },
    setErrors: (errors: { [key: string]: string }) => void,
    resetForm: () => void
  ) => void = async (values, setErrors, resetForm) => {
    const { save } = this.props
    const valuesToSubmit: ProductConfigurationValues = this.getValuesToSubmit(
      values
    )
    const shouldLaunchLoadingSpinner: boolean = this.requiredOptionsChanged(
      values,
      this.previousValues
    )
    this.previousValues = values

    try {
      await debouncedSave(
        valuesToSubmit,
        save,
        this.setLoading,
        shouldLaunchLoadingSpinner
      )

      if (this.continueClicked) {
        await this.props.onContinue()
      }

      this.setState({ loading: false })
      resetForm()
    } catch (error) {
      resetForm()
      if (error.supplierId || error.catalogCustomAttributeOptionIds) {
        setErrors(error)
      } else {
        setErrors({ base: "Something went wrong!" })
      }
    }
  }

  requiredOptionsChanged: (
    values: { [key: string]: string },
    previousValues: { [key: string]: string }
  ) => boolean = (values, previousValues) => {
    const valuesChanged: boolean = !isEqual(previousValues, values)
    const optionalValuesChanged: boolean = previousValues
      ? !arrayEquals(
          previousValues.selectedOptionalProductIds,
          values.selectedOptionalProductIds
        )
      : false
    return valuesChanged && !optionalValuesChanged
  }

  getValuesToSubmit: (values: {
    [key: string]: string
  }) => ProductConfigurationValues = (values) => {
    const { packageConfiguration } = this.props
    let catalogCustomAttributeOptionIds: string[]
    let productCustomAttributeOptions: string[][]

    const relationshipsEnabled: boolean =
      packageConfiguration.packageRelationshipsEnabled

    const {
      deliveryMethod,
      selectedOptionalProductIds,
      supplierId,
      ...selectedOptions
    } = values
    if (relationshipsEnabled) {
      // on backend takes in product/attribute/attribute option/package config ids
      // find or creates PCAOs based on ids sent
      // deletes 'unselected' PCAOs based on lack of ids present in the data sent

      const selectedValues: string[] = Object.values(selectedOptions)
      productCustomAttributeOptions = selectedValues.map((value) =>
        JSON.parse(value)
      )
    } else {
      catalogCustomAttributeOptionIds = this.allProductCustomAttributes()
        .map((attr) => selectedOptions[nameFor(attr)])
        .filter((a) => a)
    }

    return relationshipsEnabled
      ? {
          productCustomAttributeOptions: productCustomAttributeOptions,
          selectedOptionalProductIds: selectedOptionalProductIds,
          deliveryMethod: deliveryMethod,
          supplierId: supplierId,
        }
      : {
          catalogCustomAttributeOptionIds: catalogCustomAttributeOptionIds,
          selectedOptionalProductIds: selectedOptionalProductIds,
          deliveryMethod: deliveryMethod,
          supplierId: supplierId,
        }
  }

  selectedOptionalProductIdsChanged = (values) => {
    const current =
      this.pendingValues ||
      this.outstandingValues ||
      this.props.packageConfiguration
    return !arrayEquals(
      current.selectedOptionalProductIds,
      values.selectedOptionalProductIds
    )
  }

  setLoading = (loading) => {
    this.setState({
      loading: loading,
    })
  }

  onSubmit = (values, { setErrors, resetForm }) => {
    // Values for legacy
    // attribute<id>: Option id
    // Values for Product Compatibility
    // product<id>attribute<id> : JSON stringified { option id, product id, attribute id, package config id }
    const optionalProductIdsChanged = this.selectedOptionalProductIdsChanged(
      values
    )
    if (!optionalProductIdsChanged) {
      this.setState({ loading: true })
    }
    if (this.props.featureFlags.useFunctionalConfigurationComponent) {
      this.submitValues(values, setErrors, resetForm)
    } else if (this.outstandingRequest) {
      this.pendingValues = values
    } else {
      this.actuallySubmit(values, setErrors, resetForm)
    }
  }

  onContinue = () => {
    if (this.outstandingRequest) {
      this.setState({ loading: true })
      this.continueClicked = true
    } else {
      this.props.onContinue().then(() => {
        this.setState({ loading: false })
      })
    }
  }

  render() {
    const { loading } = this.state
    const {
      packageConfiguration,
      isConfigurationIncomplete,
      showAccessories,
      featureFlags,
    } = this.props
    const {
      availableDeliveryMethods,
      requiredProductCustomAttributes,
      optionalProductCustomAttributes,
      catalogCustomAttributeOptionIds,
      productCustomAttributeOptions,
      optionalProducts,
      selectedOptionalProductIds,
      fulfillable,
      offeringType,
      consignmentCloset,
      packageRelationshipsEnabled,
    } = packageConfiguration

    let initialValues = {
      selectedOptionalProductIds,
      supplierId: packageConfiguration.supplier?.externalId,
      deliveryMethod: packageConfiguration.deliveryMethod,
    }

    if (packageRelationshipsEnabled) {
      const pcaoNamesToValues: Record<
        string,
        string
      > = productCustomAttributeOptions.reduce(
        (acc, pcao) => ({
          ...acc,
          [productAttributeNameFor(
            pcao["catalogProductId"],
            pcao["catalogCustomAttributeId"]
          )]: JSON.stringify({
            catalog_custom_attribute_option_id:
              pcao["catalogCustomAttributeOptionId"],
            catalog_product_id: pcao["catalogProductId"],
            catalog_custom_attribute_id: pcao["catalogCustomAttributeId"],
            dme_order_package_configuration_id:
              pcao["dmeOrderPackageConfigurationId"],
          }),
        }),
        {}
      )
      initialValues = {
        ...initialValues,
        ...pcaoNamesToValues,
      }
    } else {
      initialValues = {
        ...initialValues,
        ...this.allProductCustomAttributes().reduce(
          (acc, attribute: CustomAttributeWithOptions) => ({
            ...acc,
            [nameFor(attribute)]: catalogCustomAttributeOptionIds.find((id) =>
              attribute.options.some((o) => o.id === id)
            ),
          }),
          {}
        ),
      }
    }

    const getAccessoriesLabel = (offeringType: OfferingType): string => {
      switch (offeringType) {
        case OfferingType.Service:
          return "Other Services"
        case OfferingType.O2Recert:
          return featureFlags.renameRecertificationToRenewal
            ? "Equipment to renew"
            : "Equipment to recertify"
        default:
          return "Accessories"
      }
    }

    const displayCustomAttributes = (product) => {
      return (
        <div
          style={{
            borderBottom: `2px solid ${canopyColorPrimitivesGray86}`,
          }}
          className="canopy-pt-16x canopy-pb-4x"
          key={product.id}
          data-testid={product.id}
        >
          <div
            className="canopy-typography-heading-xlarge canopy-pb-12x"
            style={{
              color: canopyColorTextSecondary,
            }}
          >
            {product.name}
          </div>
          {product.customAttributes.map(
            (customAttribute: CustomAttributeWithOptions) => (
              <RadioButtons
                labelClassName="canopy-typography-heading-small"
                label={customAttribute.name}
                name={productAttributeNameFor(product.id, customAttribute.id)}
                key={customAttribute.id}
                options={customAttribute.options.map((o) => ({
                  label: o.description,
                  value: JSON.stringify({
                    catalog_custom_attribute_option_id: o.id,
                    catalog_product_id: product.id,
                    catalog_custom_attribute_id: customAttribute.id,
                    dme_order_package_configuration_id: packageConfiguration.id,
                  }), // this json stringify is so that the name for the option selection is generated with a unique name (a stringified value object)
                }))}
              />
            )
          )}
        </div>
      )
    }

    return (
      <Overlay active={loading} showSpinner>
        <div className="row">
          <div className="col-md-12">
            <Form
              onSubmit={this.onSubmit}
              initialValues={initialValues}
              submitOnChange
            >
              <SelectedSupplierSummary
                selectedSupplier={packageConfiguration.supplier}
                availableSuppliers={packageConfiguration.availableSuppliers}
                preferredSuppliers={this.props.preferredSuppliers}
                showSupplierSelect={this.props.showSupplierSelection}
              />
              <DeliveryMethodSelector
                availableDeliveryMethods={availableDeliveryMethods}
                consignmentCloset={consignmentCloset}
              />
              {packageConfiguration.packageRelationshipsEnabled &&
                requiredProductCustomAttributes.map(displayCustomAttributes)}

              {packageConfiguration.packageRelationshipsEnabled &&
                showAccessories &&
                optionalProducts.length > 0 && (
                  <div
                    style={{
                      borderBottom: `2px solid ${canopyColorPrimitivesGray86}`,
                    }}
                    className="canopy-pt-16x canopy-pb-8x"
                  >
                    <Checkboxes
                      name="selectedOptionalProductIds"
                      labelClassName="canopy-typography-heading-xlarge canopy-pb-8x"
                      labelStyle={{
                        color: canopyColorTextSecondary,
                      }}
                      label={getAccessoriesLabel(offeringType)}
                      options={optionalProducts.map((pp) => ({
                        value: pp.productId,
                        label: pp.productName,
                      }))}
                    />
                  </div>
                )}

              {packageConfiguration.packageRelationshipsEnabled &&
                optionalProductCustomAttributes.map(displayCustomAttributes)}

              {!packageConfiguration.packageRelationshipsEnabled &&
                requiredProductCustomAttributes.map((customAttribute) => (
                  <RadioButtons
                    label={customAttribute.name}
                    name={nameFor(customAttribute)}
                    key={customAttribute.id}
                    options={customAttribute.options.map((o) => ({
                      label: o.description,
                      value: o.id,
                    }))}
                  />
                ))}
              {!packageConfiguration.packageRelationshipsEnabled &&
                showAccessories &&
                optionalProducts.length > 0 && (
                  <Checkboxes
                    name="selectedOptionalProductIds"
                    label={getAccessoriesLabel(offeringType)}
                    options={optionalProducts.map((pp) => ({
                      value: pp.productId,
                      label: pp.productName,
                    }))}
                  />
                )}
              {!packageConfiguration.packageRelationshipsEnabled &&
                optionalProductCustomAttributes.map((customAttribute) => (
                  <RadioButtons
                    label={customAttribute.name}
                    name={nameFor(customAttribute)}
                    key={customAttribute.id}
                    deselectable={true}
                    options={customAttribute.options.map((o) => ({
                      label: o.description,
                      value: o.id,
                    }))}
                  />
                ))}
              <div className="form-actions text-right">
                {isConfigurationIncomplete || (
                  <button
                    type="button"
                    onClick={this.onContinue}
                    className="btn btn-brand float-right"
                  >
                    Continue
                  </button>
                )}
              </div>
              {fulfillable || notFulfillableWarning(packageConfiguration.id)}
            </Form>
          </div>
        </div>
      </Overlay>
    )
  }
}

export default ConfigurationDeprecated
