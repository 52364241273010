import React, { useState } from "react"
import { Formik, FormikErrors, FormikProps, Field as FormikField } from "formik"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyLink } from "@parachutehealth/canopy-link"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyForm } from "@parachutehealth/canopy-form"
import * as urls from "../urls"
import LoginAlert from "./LoginAlert"
import FlashAlerts from "./FlashAlerts"
import { isSamlDomain } from "../utilities"
import { ErrorType, Flash } from "../sharedTypes"
import { isEmailValid } from "utilities/email"
import { EMAIL_PLACEHOLDER } from "utilities/placeholder"
import { blurOnEnter } from "utilities/browser/event"
import * as styles from "./LoginForm.module.scss"
import epicLogo from "epic-logo.png"
import pointClickCareLogo from "point-click-care-logo.png"
import { CanopyHeading } from "@parachutehealth/canopy-heading"

interface Props {
  flash?: Flash
  login(params: { login: string; password: string }): Promise<void>
  samlDomains: string[]
}

interface LoginFormValues {
  login?: string
  password?: string
}

interface FormLevelErrors {
  error?: string
  type?: ErrorType
}

const LoginForm: React.FC<Props> = ({ flash, login, samlDomains }) => {
  const [isPasswordDisabled, setIsPasswordDisabled] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [formLevelErrors, setFormLevelErrors] = useState<FormLevelErrors>({})

  const onSubmit = (values: { login: string; password: string }) => {
    setIsProcessing(true) // Using custom isProcessing state, because Formik's build-in isSubmitting state sets to false as soon as the promise resolves, but it's a better UX to have the form appear to be submitting until the redirect occurs
    return login(values).catch(({ error, type }) => {
      setIsProcessing(false)
      setFormLevelErrors({ error, type })
    })
  }

  const validate = ({ login, password }: LoginFormValues) => {
    const errors: FormikErrors<LoginFormValues> = {}
    setIsPasswordDisabled(false)

    if (!login) {
      errors.login = "Enter email"
    } else if (!isEmailValid(login) && !isSamlDomain(login, samlDomains)) {
      errors.login = "Enter a valid email address"
    } else if (isSamlDomain(login, samlDomains)) {
      setIsPasswordDisabled(true)
    }

    if (!password && !isPasswordDisabled) {
      errors.password = "Enter password"
    }
    return errors
  }

  return (
    <>
      <CanopyHeading level={1} size="2xLarge" className="canopy-mbe-24x">
        Log In to Parachute Health
      </CanopyHeading>
      <Formik
        initialValues={{ login: "", password: "" }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({
          errors,
          touched,
          handleSubmit,
          submitCount,
        }: FormikProps<LoginFormValues>) => {
          return (
            <CanopyForm onSubmit={handleSubmit}>
              <FlashAlerts flash={flash} />
              {formLevelErrors &&
                formLevelErrors.error &&
                formLevelErrors.type && (
                  <LoginAlert
                    error={formLevelErrors.error}
                    type={formLevelErrors.type}
                  />
                )}
              <FormikField
                as={CanopyTextInputField}
                name="login"
                label="Email"
                type="email"
                feedbackMessage={
                  touched.login && submitCount > 0 ? errors.login : undefined
                }
                inputProps={{ autoFocus: true, onKeyUp: blurOnEnter }}
                placeholder={EMAIL_PLACEHOLDER}
              />
              <FormikField
                as={CanopyTextInputField}
                name="password"
                label="Password"
                type="password"
                feedbackMessage={
                  !isPasswordDisabled && touched.password && submitCount > 0
                    ? errors.password
                    : undefined
                }
                disabled={isPasswordDisabled}
              />
              {!isPasswordDisabled && (
                <CanopyLink
                  className="canopy-mbe-6x"
                  href={urls.forgotPasswordUrl()}
                  size="small"
                >
                  Forgot password?
                </CanopyLink>
              )}
              <p className="canopy-typography-body-small color-dark-gray canopy-mbe-12x">
                By using this portal, you acknowledge and verify that you are
                acting within the scope of your assigned duties as an Authorized
                User of a facility/supplier that has an agreement with Parachute
                Health and that you agree to be bound by the terms of that
                agreement.
              </p>
              <CanopyButton
                fullWidth
                type="submit"
                loading={isProcessing}
                loadingText="Logging in..."
              >
                Log In
              </CanopyButton>
            </CanopyForm>
          )
        }}
      </Formik>
      <div className={styles.thirdPartyLoginContainer}>
        <p className="canopy-my-6x">or</p>
        <CanopyButton
          as="a"
          role="link"
          className={styles.thirdPartyLoginButton}
          size="small"
          variant="tertiary"
          href={urls.pointClickCareLoginUrl()}
        >
          {
            ((
              <>
                <img
                  aria-hidden="true"
                  src={pointClickCareLogo}
                  alt="PointClickCare logo"
                />
                <span>Sign in with PointClickCare</span>
              </>
            ) as unknown) as string
          }
        </CanopyButton>
        <CanopyButton
          as="a"
          role="link"
          className={styles.thirdPartyLoginButton}
          size="small"
          variant="tertiary"
          href={urls.epicLoginUrl()}
        >
          {
            ((
              <>
                <img aria-hidden="true" src={epicLogo} alt="Epic logo" />
                <span>Sign in with Epic</span>
              </>
            ) as unknown) as string
          }
        </CanopyButton>
      </div>
    </>
  )
}

export default LoginForm
