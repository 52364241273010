import React from "react"
import { useFormikParachute } from "../../../../../shared/hooks/useFormikParachute/useFormikParachute"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"

// Component API
type OrderLinkageModalProps = {
  /**
   * Function called when the modal is closed
   */
  onClose: () => void
  /**
   * Function called when the form is submitted (and all fields are valid)
   */
  onSubmit: (supplierSystemId) => Promise<void>
  /**
   * When true, the modal is visible
   */
  open?: boolean
  /**
   * Initial value that populates the single text input on the form
   */
  supplierSystemId?: string
  notBrightree?: boolean
}

// Form field interface
interface OrderLinkageFields {
  supplierSystemId?: string
}

/**
 * Modal form used to link Brightree orders to Parachute orders
 */
const OrderLinkageModal = (props: OrderLinkageModalProps): JSX.Element => {
  const { onClose, onSubmit, open = true, supplierSystemId } = props
  const headerText = props.notBrightree
    ? "Link external order to Parachute"
    : "Link Parachute order to Brightree"
  const labelText = props.notBrightree
    ? "External system ID"
    : "Brightree sales ID"

  const formik = useFormikParachute<OrderLinkageFields>({
    initialValues: {
      supplierSystemId: supplierSystemId,
    },
    onSubmit: (values) => {
      return onSubmit(values.supplierSystemId).then(() => onClose())
    },
    // No form validation  needed here since the field is optional and submitting it blank is a means for "clearing out" the supplierSystemId
  })

  return (
    <CanopyDialogModal
      header={headerText}
      onClose={onClose}
      open={open}
      primaryFooterButton={
        <CanopyButton
          type="submit"
          form="salesIdLinkageForm"
          loading={formik.isSubmitting}
          loadingText="Linking Order..."
        >
          Save
        </CanopyButton>
      }
    >
      <CanopyForm id="salesIdLinkageForm" onSubmit={formik.handleSubmit}>
        <CanopyTextInputField
          feedbackMessage={formik.errors.supplierSystemId}
          label={labelText}
          name="supplierSystemId"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.supplierSystemId}
        />
      </CanopyForm>
    </CanopyDialogModal>
  )
}

export default OrderLinkageModal
