import React from "react"
import { ChartNoteQuestion } from "sharedTypes"

type Props = {
  questions: ChartNoteQuestion[]
}

const ReadOnlyQuestionAnswerList = ({ questions }: Props) => {
  const hasMultipleAnswers = (count: number): string =>
    count === 1 ? "list-unstyled" : "list-aligned list-disc"

  return (
    <ul className="list-unstyled">
      {questions.map((question) => (
        <div
          key={question.text || question.title}
          className="well well-expand canopy-mbe-4x"
        >
          <li>
            {question.questionId && <h3>{question.questionId}</h3>}
            {question.text && <h3 className="h4 bold">{question.text}</h3>}
          </li>
          <hr />
          <li>
            {question.answers.length ? (
              question.answers.map((answer) => (
                <ul
                  key={answer.value}
                  className={hasMultipleAnswers(question.answers.length)}
                >
                  <li>{answer.value}</li>
                </ul>
              ))
            ) : (
              <span>{question.answer.answerValue}</span>
            )}
          </li>
        </div>
      ))}
    </ul>
  )
}

export default ReadOnlyQuestionAnswerList
