// @team @cat-crew

import { PackageConfiguration } from "../sharedTypes"

export const isRxQuantityIncomplete = (
  packageConfiguration: PackageConfiguration
) => {
  return !packageConfiguration.dmeOrderLineItemGroup.lineItems
    .filter((li) => li.prescribedQuantityRequired)
    .every((li) => li.prescribedQuantity?.calculatedTotal)
}
