// @team @cat-crew

import { get } from "../../../services/api"
import { ServerSideDataGridParams } from "../hooks/useServerSideDataGrid"
import { CatalogProduct, CatalogUnit } from "../types/sharedTypes"
import { unitsUrl } from "../urls/units"
import { ApiResponse } from "./utilities"

type ProductsForUnitResponse = {
  products: CatalogProduct[]
}

export type CatalogUnitParams = ServerSideDataGridParams
export type UnitsResponse = {
  units: CatalogUnit[]
  totalCount: number
}

export const getAllUnits = async (
  params?: CatalogUnitParams
): Promise<UnitsResponse> => {
  return await get(unitsUrl("json"), params)
    .then((response) => response.data)
    .catch((data) => data.response.data)
}

export const getProductsForUnit = async (
  unitId: string | number
): Promise<ApiResponse<ProductsForUnitResponse>> => {
  return await get(`/cms/catalog/units/${unitId}/products.json`)
    .then((response) => response.data)
    .catch((error) => error.response.data)
}
