// @team @destroythefax

import React, { Dispatch, ReactElement, SetStateAction } from "react"
import OrderActionsDropdownButton from "applications/Workflow/containers/Navigation/components/OrderActionsDropdown/OrderActionsDropdownButton"
import { handleError } from "utilities/error"
import { EventCategory, trackEvent } from "utilities/tracking"

type Props = {
  followDmeOrder(): Promise<void>
  setOrderActionsDropdownOpen: Dispatch<SetStateAction<boolean>>
}

const FollowButton = ({
  followDmeOrder,
  setOrderActionsDropdownOpen,
}: Props): ReactElement => {
  const followOrderHandler = () => {
    trackEvent(EventCategory.InOrderActions, "in_order_actions_follow_selected")
    return followDmeOrder()
      .then(() => {
        setOrderActionsDropdownOpen(false)
      })
      .catch((error) => {
        handleError(error)
      })
  }

  return (
    <OrderActionsDropdownButton
      iconName="user"
      title="Follow&nbsp;order"
      subtitle="Receive updates on this order"
      onClick={followOrderHandler}
    />
  )
}

export default FollowButton
