import React from "react"
import { CsrInboxDocument, InternalStatus, SortDirection } from "sharedTypes"
import Tooltip from "components/Tooltip"
import { EVENT_DATE_TIME_FORMAT, format } from "utilities/date"
import SortableTableHeaders from "components/SortableTableHeaders"
import { formatPhone } from "utilities/phone"
import InternalStatusEditor from "applications/InternalStatusEditor"
import {
  getCsrInboxStateInternalStatusesUrl,
  getCsrInboxDocumentUrl,
  getDocumentFilesUrl,
} from "./urls"
import CsrInboxStateAssigneeEditor from "applications/CsrInboxStateAssigneeEditor"
import Overlay from "components/Overlay"
import { initials } from "utilities/person"
import { titleize } from "utilities/string"
import { get } from "services/api"
import { FilePreviewer } from "components/FilePreviewer"
import * as styles from "./index.module.scss"
import { CanopyLink } from "@parachutehealth/canopy-link"
import { isNewDocument } from "./utilities"

type Props = {
  csrInboxDocuments: CsrInboxDocument[]
  sortColumn?: string
  sortDirection?: SortDirection
  onSortChange(values: { [key: string]: string }): Promise<void>
  internalStatuses: InternalStatus[]
  onChange(document: CsrInboxDocument): void
  loading?: boolean
  aiIntakeEnabled: boolean
  intakePatientSearchEnabled: boolean
  supplierIntakeEmailsEnabled: boolean
  supplierId: string
  supplierOrganizationId: string
}
export const fetchUrlForFirstPageImage = (
  csrInboxDocumentId: string,
  supplierId: string
) => {
  return get(getDocumentFilesUrl(supplierId, csrInboxDocumentId)).then((d) => {
    const getFirstPage = (min, curr) => {
      return curr.pageNumber &&
        (!min.pageNumber || curr.pageNumber < min.pageNumber)
        ? curr
        : min
    }
    return d.data.data.reduce(getFirstPage).url
  })
}

const Table = ({
  csrInboxDocuments,
  sortColumn,
  sortDirection,
  onSortChange,
  internalStatuses,
  onChange,
  loading,
  supplierId,
  supplierOrganizationId,
  aiIntakeEnabled,
  intakePatientSearchEnabled,
  supplierIntakeEmailsEnabled,
}: Props) => {
  const tableColumns = [
    { title: "Document", className: "col-w250" },
    ...(aiIntakeEnabled ? [{ title: "Patient" }] : []),
    ...(supplierIntakeEmailsEnabled ? [{ title: "Inbox" }] : []),
    { title: "Status" },
    ...(aiIntakeEnabled
      ? [{ title: "Category", className: styles.categoryColumn }]
      : []),
    { title: "Facility", value: "facility_name" },
    { title: "From", value: "from" },
    { title: "To" },
    { title: "Received", value: "created_at" },
    { title: "Pages", value: "page_count", className: "col-w80" },
    { title: "Internal Status", value: "internal_status" },
    { title: "Assigned To", value: "assignee" },
  ]
  const fromFax = (document) => document.sourceType === "fax"

  const renderDocumentRow = (document: CsrInboxDocument) => {
    const fetchFile = () => fetchUrlForFirstPageImage(document.id, supplierId)
    return (
      <tr key={document.id} data-csr-inbox-state-id={document.id}>
        <td className="col-w250">
          <CanopyLink
            href={getCsrInboxDocumentUrl({
              supplierId,
              supplierOrganizationId,
              intakePatientSearchEnabled,
              csrInboxDocument: document,
            })}
            className="canopy-pie-4x"
          >
            {document.id}
          </CanopyLink>
          <FilePreviewer fetchFileUrl={fetchFile} />
          {document.lastAccessUserName && (
            <Tooltip
              placement={Tooltip.Placement.Right}
              trigger={["click", "hover"]}
              overlay={document.lastAccessUserName}
            >
              <div className="circle green-o canopy-mis-4x">
                {initials(document.lastAccessUserName)}
              </div>
            </Tooltip>
          )}
        </td>
        {aiIntakeEnabled ? <td>{document.patientName}</td> : null}
        {supplierIntakeEmailsEnabled ? (
          <td>{document.supplierIntakeEmailName}</td>
        ) : null}
        <td>{titleize(document.state)}</td>
        {aiIntakeEnabled && (
          <td className={styles.categoryColumn}>
            {document.categories?.map((c) => c.name).join(", ")}
          </td>
        )}
        <td>{document.clinicalFacilityName}</td>
        <td>
          {fromFax(document) ? formatPhone(document.source) : document.source}
        </td>
        <td>{formatPhone(document.receivingFaxNumber)}</td>
        <td>{format(document.createdAt, EVENT_DATE_TIME_FORMAT)}</td>
        <td className="col-w80">{document.pageCount}</td>
        <td>
          <InternalStatusEditor
            updateUrl={getCsrInboxStateInternalStatusesUrl(
              supplierId,
              document.id
            )}
            internalStatus={document.internalStatus}
            internalStatuses={internalStatuses}
            readOnly={!isNewDocument(document)}
          />
        </td>
        <td className="assignee">
          <CsrInboxStateAssigneeEditor
            document={document}
            onChange={onChange}
            readOnly={!isNewDocument(document)}
            supplierId={supplierId}
          />
        </td>
      </tr>
    )
  }

  return (
    <Overlay active={!!loading} showSpinner position="top">
      <div
        className="table-container"
        style={{ maxHeight: "calc(100vh - 300px)" }}
      >
        <table className="table table-striped table-hover table-sortable table-expanded table-dashboard table-flex">
          <SortableTableHeaders
            onSortChange={onSortChange}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            columns={tableColumns}
          />
          <tbody>{csrInboxDocuments.map(renderDocumentRow)}</tbody>
        </table>
      </div>
    </Overlay>
  )
}

export default Table
