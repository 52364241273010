import { get } from "services/api"
import { emrPatientStatusUrl } from "./urls"
import { pollPromise } from "utilities/poll"

function getEmrPatientStatus(emrContextIndex, emrPatientLoadJobId, cdsMode) {
  return get(emrPatientStatusUrl(emrContextIndex, emrPatientLoadJobId, cdsMode))
}

export const pollForPatientStatus = (
  emrContextIndex,
  emrPatientLoadJobId,
  cdsMode
): Promise<{ successPath: string }> => {
  return pollPromise(() =>
    getEmrPatientStatus(emrContextIndex, emrPatientLoadJobId, cdsMode)
  )
}
