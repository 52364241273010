import React, { useContext } from "react"
import GlobalContext from "context/Global"
import Overlay from "components/Overlay"
import ProductSummary from "./ProductSummary/ProductSummary"
import Requirements from "../../LineItemGroupCommonAttributes/components/Requirements"
import RxDetails from "./RxDetails"
import RxQuantity from "./RxQuantity/RxQuantity"
import Details from "../../LineItemGroupCommonAttributes/components/Details"
import {
  isConfigurationIncomplete,
  showAccessories,
  isSupplierIncomplete,
} from "../utilities"
import {
  DmeOrder,
  Employer,
  Supplier,
  SurveyQuestion,
  SurveyAnswerType,
  LineItemGroupCommonAttributes,
  PrescribedQuantity,
} from "sharedTypes"
import { PackageConfiguration, PackageConfigurationStep } from "../sharedTypes"
import { FormikValues } from "formik"
import ConfigurationDeprecated from "./ConfigurationDeprecated"
import Configuration from "./Configuration"
import SelectSupplierStep from "./SelectSupplierStep"
import { PrescribedQuantityParams } from "../../../api"

const packageConfigurationToLineItemGroupCommonAttributes = (
  packageConfiguration: PackageConfiguration
): LineItemGroupCommonAttributes => {
  const {
    quantity,
    lengthOfNeedMonths,
    paymentMethod,
    supportedPaymentMethods,
    requiresLengthOfNeed,
    allowsQuantity,
    requiresHeight,
    requiresWeight,
    requiresWoundCount,
    heightInches,
    weightLbs,
    numberOfWounds,
  } = packageConfiguration
  return {
    quantity,
    lengthOfNeedMonths,
    paymentMethod,
    supportedPaymentMethods,
    requiresLengthOfNeed,
    allowsQuantity,
    requiresHeight,
    requiresWeight,
    requiresWoundCount,
    heightInches,
    weightLbs,
    numberOfWounds,
  }
}

type Props = {
  answerRxSurveyQuestion(
    question: SurveyQuestion,
    answerType: SurveyAnswerType,
    answerValue: string
  ): Promise<void>
  currentEmployer: Employer
  dmeOrder: DmeOrder
  packageConfiguration: PackageConfiguration
  useFeetForHeight: boolean
  loading: boolean
  remove(): void
  returnToBrowse(): void
  update(params: FormikValues): Promise<PackageConfiguration> | Promise<void>
  updateLineItemPrescribedQuantity(
    lineItemId: string,
    params: PrescribedQuantityParams
  ): Promise<PrescribedQuantity>
  save(params: FormikValues): Promise<PackageConfiguration> | Promise<void>
  step: string | PackageConfigurationStep
  changeStep(step: PackageConfigurationStep): void
  recalculateStep(): void
  changeHeightUnit(): void
  goToNextPage(params: FormikValues): void
  showServiceTier: boolean
  preferredSuppliers: Supplier[]
  redirectToPreferredSupplier?: boolean
  yourOrganizationsSuppliersOnly: boolean
  selectedServiceAreaState: string
}

const InternalPackageConfiguration = (props: Props): React.ReactElement => {
  const {
    answerRxSurveyQuestion,
    changeHeightUnit,
    changeStep,
    currentEmployer,
    dmeOrder,
    goToNextPage,
    loading,
    packageConfiguration,
    preferredSuppliers,
    recalculateStep,
    remove,
    returnToBrowse,
    save,
    showServiceTier,
    step,
    update,
    updateLineItemPrescribedQuantity,
    useFeetForHeight,
    redirectToPreferredSupplier = false,
    yourOrganizationsSuppliersOnly,
    selectedServiceAreaState,
  } = props
  const { featureFlags } = useContext(GlobalContext)

  const formularyPriceEnabled = !!(
    dmeOrder.clinicalFacility.formularyPriceEnabled &&
    featureFlags.formularyPricing
  )

  const onContinue = (params: FormikValues) => {
    if (params) {
      return update(params)
    } else {
      recalculateStep()
      return Promise.resolve(packageConfiguration)
    }
  }

  const onEditSupplier = () => {
    changeStep(PackageConfigurationStep.SupplierStep)
  }

  const onEditRequirements = () => {
    changeStep(PackageConfigurationStep.RequirementsStep)
  }

  const onEditRxDetails = () => {
    changeStep(PackageConfigurationStep.RxDetailsStep)
  }

  const onEditDetails = () => {
    changeStep(PackageConfigurationStep.DetailsStep)
  }

  const onEditConfiguration = () => {
    changeStep(PackageConfigurationStep.ConfigurationStep)
  }

  const currentStep = () => {
    switch (step) {
      case PackageConfigurationStep.SupplierStep:
        return (
          <SelectSupplierStep
            redirectToPreferredSupplier={redirectToPreferredSupplier}
            dmeOrder={dmeOrder}
            showServiceTier={showServiceTier}
            isSupplierIncomplete={isSupplierIncomplete(packageConfiguration)}
            packageConfiguration={packageConfiguration}
            onContinue={onContinue}
            save={save}
            yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
            selectedServiceAreaState={selectedServiceAreaState}
          />
        )
      case PackageConfigurationStep.ConfigurationStep:
        return featureFlags.useFunctionalConfigurationComponent ? (
          <Configuration
            showAccessories={showAccessories(packageConfiguration)}
            isConfigurationIncomplete={isConfigurationIncomplete(
              packageConfiguration
            )}
            packageConfiguration={packageConfiguration}
            preferredSuppliers={preferredSuppliers}
            onContinue={onContinue}
            save={save}
            showSupplierSelection={onEditSupplier}
          />
        ) : (
          <ConfigurationDeprecated
            showAccessories={showAccessories(packageConfiguration)}
            isConfigurationIncomplete={isConfigurationIncomplete(
              packageConfiguration
            )}
            packageConfiguration={packageConfiguration}
            preferredSuppliers={preferredSuppliers}
            onContinue={onContinue}
            save={save}
            featureFlags={featureFlags}
            showSupplierSelection={onEditSupplier}
          />
        )

      case PackageConfigurationStep.RequirementsStep:
        return (
          <Requirements
            currentEmployer={currentEmployer}
            lineItemGroupCommonAttributes={packageConfigurationToLineItemGroupCommonAttributes(
              packageConfiguration
            )}
            useFeetForHeight={useFeetForHeight}
            onSkip={returnToBrowse}
            onContinue={onContinue}
            changeHeightUnit={changeHeightUnit}
          />
        )
      case PackageConfigurationStep.RxQuantityStep:
        return (
          <RxQuantity
            lineItems={
              packageConfiguration.dmeOrderLineItemGroup?.lineItems || []
            }
            updateLineItemPrescribedQuantity={updateLineItemPrescribedQuantity}
            onContinue={onContinue}
          />
        )
      case PackageConfigurationStep.RxDetailsStep:
        return (
          <RxDetails
            answerQuestion={answerRxSurveyQuestion}
            rxDetails={packageConfiguration.rxDetails}
            onContinue={onContinue}
          />
        )
      case PackageConfigurationStep.DetailsStep:
        return (
          <Details
            lineItemGroupCommonAttributes={packageConfigurationToLineItemGroupCommonAttributes(
              packageConfiguration
            )}
            onContinue={onContinue}
          />
        )
      default:
        return (
          <ProductSummary
            packageConfiguration={packageConfiguration}
            formularyPriceEnabled={formularyPriceEnabled}
            onEditSupplier={onEditSupplier}
            onEditDetails={onEditDetails}
            onEditRequirements={onEditRequirements}
            onEditRxDetails={onEditRxDetails}
            onEditConfiguration={onEditConfiguration}
            returnToBrowse={returnToBrowse}
            remove={remove}
            update={update}
            useFeetForHeight={useFeetForHeight}
            goToNextPage={goToNextPage}
          />
        )
    }
  }

  return (
    <Overlay active={loading} showSpinner>
      <div>{currentStep()}</div>
    </Overlay>
  )
}

export default InternalPackageConfiguration
