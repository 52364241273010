import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import Confirm from "components/Confirm"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import { discardDocument } from "./api"
import { navigate, reload } from "utilities/navigation"

interface Props {
  documentTitle: string | undefined
  dashboardUrl: string
}

export const Discard = ({ documentTitle, dashboardUrl }: Props) => {
  const csrInboxState = useCsrInboxState()
  const documentReference = documentTitle || "this document"

  const handleDiscardClick = async () => {
    discardDocument({
      supplierOrganizationId: csrInboxState.supplierOrganization.id,
      csrInboxStateId: csrInboxState.id,
    })
      .then(() => {
        navigate(dashboardUrl)
      })
      .catch(() => {
        reload()
      })
  }

  return (
    <Confirm
      title="Discard document(s)?"
      description={
        <>
          Are you sure you want to discard <b>{documentReference}</b>? You can
          recover it later by reopening the document and clicking 'Restore.'
        </>
      }
      confirmText="Discard"
      cancelText="Cancel"
      render={(confirm) => (
        <CanopyButton
          iconStart="trash-can"
          size="small"
          variant="tertiary"
          onClick={confirm(handleDiscardClick)}
        >
          Discard
        </CanopyButton>
      )}
    />
  )
}
