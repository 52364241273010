import React from "react"
import Thumbnail from "components/Thumbnail"
import classNames from "classnames"
import { LineItemGroup } from "sharedTypes"

type Props = {
  lineItemGroup: Pick<
    LineItemGroup,
    "displayName" | "imageUrl" | "isConsignmentCloset"
  >
  thumbnailClassName?: string
}

function LineItemGroupThumbnail({ lineItemGroup, thumbnailClassName }: Props) {
  return (
    <div className="line-item-group-thumbnail">
      <Thumbnail
        className={classNames("float-left canopy-mie-8x", thumbnailClassName)}
        src={lineItemGroup.imageUrl}
        size="small"
      />
      <div className="line-item-group-thumbnail-name">
        {lineItemGroup.isConsignmentCloset && "Consignment - "}
        {lineItemGroup.displayName}
      </div>
    </div>
  )
}

export default LineItemGroupThumbnail
