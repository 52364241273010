import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import React, { useState } from "react"
import { getCatalogSupplierData, SuppliersDataGridResponse } from "./api"
import useServerSideDataGrid, {
  ServerSideDataGridOptions,
  ServerSideDataGridParams,
} from "../../../hooks/useServerSideDataGrid"
import { isTest } from "../../../../../utilities/environment"
import DataGridToolbar from "../../../components/DataGridToolbar"
import ContentArea from "../../../components/ContentArea"
import { supplierDetailUrl } from "../../../urls/suppliers"
import { Link } from "react-router-dom"
import SearchField from "../../../components/SearchField"
import { Grid } from "@material-ui/core"
import { consignmentClosetsUrl } from "../../../urls/consignmentClosets"
import { isEmpty } from "lodash"
import InfoTooltip from "../../../components/InfoTooltip"
import { CanopyLink } from "@parachutehealth/canopy-link"
import { CanopyText } from "@parachutehealth/canopy-text"

const columns: GridColDef[] = [
  {
    field: "externalId",
    flex: 1,
    headerName: "ID",
    hide: true,
  },
  {
    field: "test",
    flex: 0.5,
    type: "boolean",
    headerName: "Test",
    hide: true,
  },
  {
    field: "name",
    flex: 2,
    headerName: "Name",
    minWidth: 300,
    renderCell: (params) => {
      return (
        <>
          <CanopyLink as={Link} to={supplierDetailUrl(params.row.externalId)}>
            {params.row.name}
          </CanopyLink>
          {params.row.unconfigurablePackageCount > 0 && (
            <InfoTooltip
              title="This Supplier has unconfigurable Packages"
              icon="triangle-exclamation"
              className="color-danger canopy-mis-6x"
            />
          )}
        </>
      )
    },
  },
  {
    field: "supplierOrganizationName",
    flex: 2,
    headerName: "Supplier Org",
    minWidth: 300,
    renderCell: (params) => {
      return (
        <CanopyLink
          href={`/cms/supplier_organizations/${params.row.supplierOrganizationExternalId}`}
        >
          {params.row.supplierOrganizationName}
        </CanopyLink>
      )
    },
  },
  {
    field: "packageCount",
    flex: 0.5,
    headerName: "Packages",
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "unconfigurablePackageCount",
    flex: 0.5,
    headerName: "Unconfigurable Packages",
    type: "number",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => {
      return params.value || "-"
    },
  },
  {
    field: "skuCount",
    flex: 0.5,
    headerName: "SKUs",
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "brightree",
    flex: 0.5,
    type: "boolean",
    headerName: "Brightree",
    filterable: false,
    renderCell: (params) => {
      if (!params.value) return ""

      return (
        <a
          href={`${supplierDetailUrl(
            params.row.externalId
          )}/supplier_catalog_brightrees`}
        >
          Brightree
        </a>
      )
    },
  },
  {
    field: "consignmentClosetCount",
    headerName: "Closets",
    flex: 0.5,
    type: "number",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => {
      if (params.value === 0) {
        return "-"
      } else {
        return (
          <a href={`${consignmentClosetsUrl()}?supplier_id=${params.row.id}`}>
            {params.value}
          </a>
        )
      }
    },
  },
]

type SuppliersPageGridOptions = ServerSideDataGridOptions & {
  searchKeywords?: string
}

const defaultOptions: SuppliersPageGridOptions = {
  page: 1,
  // todo: make this be driven by the backend? right now it's set in two different places
  sort: [{ field: "name", sort: "asc" }],
}

const SuppliersPage: React.FC = (): React.JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [gridData, setGridData] = React.useState<SuppliersDataGridResponse>({
    records: [],
    totalCount: 0,
  })

  const beforeFetch = () => {
    setLoading(true)
  }
  const afterFetch = () => {
    setLoading(false)
  }

  const fetchFunction = async (
    params: ServerSideDataGridParams
  ): Promise<void> => {
    const data = await getCatalogSupplierData(params)
    setGridData((prev) => ({
      ...prev,
      records: data.records,
      totalCount: data.totalCount,
    }))
  }

  const {
    filterModel,
    options,
    handleFilterChange,
    handlePageChange,
    handleFilterModelChange,
    handleSortModelChange,
  } = useServerSideDataGrid<SuppliersPageGridOptions>({
    trackHistory: true,
    defaultOptions,
    columnDefinitions: columns,
    fetchFunction,
    beforeFetch,
    afterFetch,
  })

  return (
    <div>
      <h2 className="canopy-typography-heading-2xlarge">Suppliers</h2>
      <CanopyText className="canopy-mbe-8x">
        Suppliers with catalogs in Parachute.
      </CanopyText>
      <ContentArea>
        <Grid className="canopy-mbe-12x" container spacing={2}>
          <Grid xs={12} sm={3} lg={3} item>
            <SearchField
              onSearch={(searchText) => {
                const newValue = isEmpty(searchText) ? null : searchText
                handleFilterChange("searchKeywords", newValue)
              }}
              disabled={loading}
              dirtyTooltip={true}
              data-testid="search-keywords-filter"
              label="Search Suppliers"
              defaultValue={options?.searchKeywords}
            />
          </Grid>
        </Grid>
        <DataGridPro
          className="borderless"
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          loading={loading}
          columns={columns}
          rows={gridData.records}
          rowCount={gridData.totalCount}
          autoHeight
          getRowId={(row) => row.externalId}
          disableVirtualization={isTest()}
          hideFooterRowCount
          hideFooterSelectedRowCount
          filterMode="server"
          paginationMode="server"
          sortingMode="server"
          sortModel={options.sort}
          onSortModelChange={handleSortModelChange}
          components={{
            Toolbar: DataGridToolbar,
          }}
          componentsProps={{
            toolbar: { filter: true },
          }}
          pageSize={50}
          onPageChange={(page) => {
            handlePageChange(page + 1)
          }} // account for DataGrid's zero-based indexing
          //onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[50]}
          pagination={true}
          density="compact"
        />
      </ContentArea>
    </div>
  )
}

export default SuppliersPage
