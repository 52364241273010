import React, { useState } from "react"
import * as styles from "../index.module.scss"
import { Insurance, InsuranceRank } from "../../../sharedTypes"
import { capitalize } from "utilities/string"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import { useDebounce } from "hooks/useDebounce"
import { searchInsuranceProducts } from "./api"
import { useCsrInboxState } from "../../../csrInboxStateContext"
import { useQuery } from "@tanstack/react-query"
import { useEffectThatWontRunOnMount } from "hooks/useEffectThatWontRunOnMount"
import { useInsuranceMutation } from "./hooks/useInsuranceMutation"

const findInsurance = (insurances: Insurance[], rank: InsuranceRank) =>
  insurances.find((insurance) => insurance.rank === rank)

const InsuranceProductSelect = ({
  insurances,
  rank,
}: {
  insurances: Insurance[]
  rank: InsuranceRank
}) => {
  const csrInboxState = useCsrInboxState()
  const insurance: Insurance | undefined = findInsurance(insurances, rank)
  const description = `Extracted text: ${insurance?.extractedName || ""}`

  const initialValue =
    insurance && insurance.insuranceProductId && insurance.insuranceProductName
      ? {
          value: insurance.insuranceProductId,
          label: insurance.insuranceProductName,
        }
      : null
  const [value, setValue] = useState<OptionItem | null>(initialValue)
  const [query, setQuery] = useState<string>("")
  const { data: options } = useQuery({
    queryKey: [
      csrInboxState.supplierOrganization.id,
      csrInboxState.id,
      "insuranceProductSearch",
      query,
    ],
    enabled: !!query,
    queryFn: async () => {
      return searchInsuranceProducts({
        supplierOrganizationId: csrInboxState.supplierOrganization.id,
        csrInboxStateId: csrInboxState.id,
        query,
      }).then((res) => {
        return res.map(({ name, id }) => ({
          value: id,
          label: name,
        }))
      })
    },
  })
  const [rankMutation, rankMutationCount] = useInsuranceMutation(
    "insuranceProductId",
    rank
  )
  useEffectThatWontRunOnMount(() => {
    const fieldValue = value?.value || ""
    rankMutation.mutateAsync(fieldValue)
  }, [value?.value])

  const label = `${capitalize(rank)} insurance carrier`

  const onChange = (item: OptionItem | OptionItem[] | null) => {
    const selectedOption = item as OptionItem | null
    setValue(selectedOption)
  }
  const onInputChange = useDebounce(setQuery, 200)

  return (
    <CanopyComboboxField
      description={description}
      disabled={rankMutation.isLoading || rankMutationCount > 0}
      label={label}
      value={value}
      options={options || []}
      onChange={onChange}
      onInputChange={onInputChange}
      placeholder="Search insurance carrier"
    />
  )
}

const InsuranceMemberIdTextField = ({
  insurances,
  rank,
}: {
  insurances: Insurance[]
  rank: InsuranceRank
}) => {
  const insurance: Insurance | undefined = findInsurance(insurances, rank)
  const [value, setValue] = useState<string>(insurance?.memberId || "")

  const [rankMutation, rankMutationCount] = useInsuranceMutation(
    "memberId",
    rank
  )
  useEffectThatWontRunOnMount(() => {
    rankMutation.mutateAsync(value)
  }, [value])

  const onBlur = (e) => setValue(e.target.value)

  const label = `${capitalize(rank)} insurance member ID`
  const readOnly: boolean = rankMutation.isLoading || rankMutationCount > 0
  return (
    <CanopyTextInputField
      label={label}
      defaultValue={value}
      onBlur={onBlur}
      readOnly={readOnly}
      placeholder="Enter patient's member ID"
    />
  )
}

export const InsuranceInformation = ({
  insurances,
}: {
  insurances: Insurance[]
}) => {
  return (
    <>
      <div className={styles.dataEntryLineContainer}>
        <InsuranceMemberIdTextField insurances={insurances} rank="primary" />
        <InsuranceMemberIdTextField insurances={insurances} rank="secondary" />
      </div>
      <div className={styles.dataEntryLineContainer}>
        <InsuranceProductSelect insurances={insurances} rank="primary" />
        <InsuranceProductSelect insurances={insurances} rank="secondary" />
      </div>
    </>
  )
}
