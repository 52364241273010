// @ts-strict-ignore
import React from "react"
import { orderUrl } from "../urls"
import { formatOrderType } from "utilities/orderType"
import FulfillmentEditor from "../../FulfillmentEditor"
import UpdatesEditor from "./UpdatesEditor"
import InternalStatusEditor from "../../InternalStatusEditor"
import { employerPrefix } from "../../../utilities/url"
import { updateDmeOrderInternalStatusUrl } from "../../InternalStatusEditor/api"
import {
  ColumnSettings,
  SupplierOrganizationDashboardDmeOrder,
} from "../sharedTypes"
import FollowUpOwnerEditor from "./FollowUpOwnerEditor"
import ExternalSystemIds from "./ExternalSystemIds"
import AssignedToEditor from "../../../components/AssignedToEditor"
import * as api from "../api"
import { ExternalSystemBranch } from "./ExternalSystemBranch"
import Lock from "@material-ui/icons/Lock"
import SalesRepEditor from "./SalesRepEditor"
import { getSalesReps, updateSalesRep } from "./SalesRepTableCell"
import FacilityCell from "applications/SupplierOrganizationDashboard/components/FacilityCell"
import { CanopyLink } from "@parachutehealth/canopy-link"

export interface TableColumnDefinition<T> {
  title: string
  attr: string
  value?: string // key for sorting columns
  render?: (order: T) => JSX.Element | string | null
  defaultVisible?: boolean
  alwaysVisible?: boolean
}

const tableColumnDefinitions: TableColumnDefinition<
  SupplierOrganizationDashboardDmeOrder
>[] = [
  {
    title: "Patient",
    attr: "patientName",
    value: "patient",
    render: (order) => (
      <CanopyLink href={orderUrl(order.id)} rel="noreferrer" target="_blank">
        {order.patientName}
      </CanopyLink>
    ),
    alwaysVisible: true,
  },
  {
    title: "Supplier",
    attr: "supplierName",
    value: "supplier",
    defaultVisible: true,
  },
  {
    title: "Facility",
    attr: "facilityName",
    value: "clinical_facility",
    defaultVisible: true,
    render: (order) => <FacilityCell order={order} />,
  },
  { title: "Delivery Method", attr: "deliveryMethod" },
  { title: "MRN", attr: "mrn" },
  {
    title: "Order Type",
    attr: "orderTypes",
    render: (order) => order.orderTypes.map(formatOrderType).join(", "),
    defaultVisible: true,
  },
  { title: "Payer Type", attr: "payerType" },
  {
    title: "Legal Entity",
    attr: "legalEntity",
    value: "legal_entity",
  },
  { title: "Categories", attr: "categories", defaultVisible: true },
  { title: "Packages", attr: "packages" },
  {
    title: "Clinician",
    attr: "clinician",
    value: "clinician",
  },
  { title: "Supplier Followers", attr: "vendorFollowers" },
  {
    title: "Facility Level",
    attr: "facilityLevel",
  },
  {
    title: "Date Received",
    attr: "dateReceived",
    value: "submitted_at",
    defaultVisible: true,
  },
  {
    title: "Date Signed",
    attr: "dateSigned",
    value: "signed_at",
    defaultVisible: true,
  },
  {
    title: "Discharge Date",
    attr: "dischargeDate",
    value: "discharge_date",
  },
  {
    title: "Requested Delivery Date",
    attr: "requestedDeliveryDate",
    value: "delivery_date",
    defaultVisible: true,
  },
  {
    title: "Due Date",
    attr: "dueDate",
    value: "due_date",
  },
  { title: "Room", attr: "room" },
  {
    title: "Signature Status",
    attr: "signatureStatus",
    value: "signature_status",
    defaultVisible: true,
  },
  {
    title: "Order Stage",
    attr: "orderStage",
    defaultVisible: true,

    render: (order) => {
      return (
        <>
          {order.transferredOrderLocked && (
            <Lock
              aria-label="Order transferred indicator"
              className="color-black canopy-mie-4x"
            />
          )}
          {order.orderStage}
        </>
      )
    },
  },
  {
    title: "Fulfillment Status",
    attr: "fulfillmentStatus",
    render: (order) => {
      if (order.permissions.canUpdateDeliveryData && order.packages) {
        return (
          <FulfillmentEditor
            dmeOrderId={order.id}
            initialStatusText={order.fulfillmentStatus}
            employerPrefix={`/u/s/${order.supplierId}`}
          />
        )
      }
      if (order.permissions.canShowDeliveryData) {
        return order.fulfillmentStatus
      }
    },
    defaultVisible: true,
  },
  {
    title: "Internal Status",
    attr: "internalStatus",
    value: "internal_status",
    render: (order) => {
      return (
        <InternalStatusEditor
          internalStatus={order.internalStatus}
          internalStatuses={order.internalStatuses}
          readOnly={!order.permissions.canUpdateInternalStatus}
          updateUrl={updateDmeOrderInternalStatusUrl(
            order.id,
            employerPrefix("Supplier", order.supplierId)
          )}
        />
      )
    },
  },
  {
    title: "Order ID",
    attr: "externalSystemIds",
    value: "supplier_system_id",
    render: ({ externalSystemIds, externalSystemError }) => {
      return (
        <ExternalSystemIds
          externalSystemIds={externalSystemIds}
          externalSystemError={externalSystemError}
        />
      )
    },
    defaultVisible: true,
  },
  {
    title: "External System Branch",
    attr: "externalSystemBranch",
    render: ({ externalSystemBranchId, supplierId }) => (
      <ExternalSystemBranch
        externalSystemBranchId={externalSystemBranchId}
        supplierId={supplierId}
      />
    ),
  },
  {
    title: "Follow-up Owner",
    attr: "followUpOwner",
    render: (order) => {
      if (order.permissions.canEditFollowUpOwner) {
        return <FollowUpOwnerEditor order={order} />
      }
    },
  },
  {
    title: "Assigned To",
    attr: "assignedTo",
    render: (order) => {
      return (
        <AssignedToEditor
          id={order.id}
          assignee={order.assignee}
          canAssign={order.permissions.canUpdateAssignee}
          updateAssignee={api.updateAssignee(order.supplierId)}
          deleteAssignee={api.deleteAssignee(order.supplierId)}
          fetchAssignees={api.fetchAssignees(order.supplierId)}
        />
      )
    },
    defaultVisible: true,
  },
  {
    title: "Sales Reps",
    attr: "salesReps",
    value: "sales_reps",
    render: (order) => {
      return (
        <SalesRepEditor
          id={order.id}
          salesRep={order.salesReps}
          canUpdateSalesRep={order.permissions.canUpdateSalesRep}
          updateSalesRep={updateSalesRep({ supplierId: order.supplierId })}
          getSalesReps={getSalesReps({ supplierId: order.supplierId })}
        />
      )
    },
  },
  {
    title: "Updates",
    attr: "update",
    value: "update",
    render: (order) => {
      return <UpdatesEditor dmeOrder={order} supplierId={order.supplierId} />
    },
    defaultVisible: true,
  },
]

export default tableColumnDefinitions

export const defaultColumnSettings: ColumnSettings = tableColumnDefinitions.reduce(
  (acc, col) => {
    acc[col.attr] = col.alwaysVisible || col.defaultVisible
    return acc
  },
  {}
)

export const filterTableColumnDefinitions = (
  columnSettings: ColumnSettings
): TableColumnDefinition<
  SupplierOrganizationDashboardDmeOrder & { key: string }
>[] => tableColumnDefinitions.filter((tc) => columnSettings[tc.attr])
