// @team @demand-ordering
import React, { useState } from "react"
import { Form } from "components/form"
import Overlay from "components/Overlay"
import { PackageConfiguration } from "../sharedTypes"
import { DmeOrder, SearchWorkflow } from "sharedTypes"
import { FormikValues } from "formik"
import SearchByProductSupplierSelector from "./SearchByProductSupplierSelector/SearchByProductSupplierSelector"
import SupplierSelector from "./SupplierSelector/SupplierSelector"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { allowSearchByProduct } from "applications/Workflow/utilities/searchByProduct"

type Props = {
  dmeOrder: DmeOrder
  isSupplierIncomplete: boolean
  showServiceTier: boolean
  packageConfiguration: PackageConfiguration
  onContinue(
    params?: FormikValues
  ): Promise<PackageConfiguration> | Promise<void>
  save(params: FormikValues): Promise<PackageConfiguration> | Promise<void>
  redirectToPreferredSupplier: boolean
  yourOrganizationsSuppliersOnly: boolean
  selectedServiceAreaState: string
}

type FormErrors = {
  supplierId?: string
  base?: string
}

const SelectSupplierStep: React.FC<Props> = ({
  dmeOrder,
  isSupplierIncomplete,
  showServiceTier,
  packageConfiguration,
  onContinue,
  save,
  redirectToPreferredSupplier,
  yourOrganizationsSuppliersOnly,
  selectedServiceAreaState,
}) => {
  let continueButtonClicked = false

  const [loading, setLoading] = useState<boolean>(false)
  const { isFeatureEnabled } = useFeatureFlags()
  const userActivationProductFirstSearchIncludeEnterprise = isFeatureEnabled(
    "userActivationProductFirstSearchIncludeEnterprise"
  )

  const isSearchByProductEnabled = allowSearchByProduct(
    userActivationProductFirstSearchIncludeEnterprise,
    dmeOrder.clinicalFacility.usesEnterpriseFeatures
  )

  const packageConfigFromSearchByProductWorkflow =
    packageConfiguration.searchWorkflow &&
    packageConfiguration.searchWorkflow === SearchWorkflow.SearchByProduct

  const onContinueButtonClicked = () => {
    continueButtonClicked = true
    onContinue().then(() => {
      setLoading(false)
    })
  }

  const onSubmit = async (values, { setErrors, resetForm }) => {
    try {
      setLoading(true)
      await save({ supplierId: values.supplierId })
      if (continueButtonClicked) {
        onContinue().then(() => {
          setLoading(false)
        })
      } else {
        setLoading(false)
        resetForm()
      }
    } catch (e) {
      resetForm()
      // Need this otherwise TS strict check complains about e as unknown type
      const errors = e as FormErrors
      if (errors.supplierId) {
        setErrors(errors)
      } else {
        setErrors({ base: "Something went wrong!" })
      }
    }
  }

  const initialValues = {
    supplierId: packageConfiguration.supplier?.externalId,
  }

  const supplierSelector =
    isSearchByProductEnabled && packageConfigFromSearchByProductWorkflow ? (
      <SearchByProductSupplierSelector
        redirectToPreferredSupplier={redirectToPreferredSupplier}
        packageConfiguration={packageConfiguration}
        yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
        selectedServiceAreaState={selectedServiceAreaState}
        flexibleSupplierAccess={dmeOrder.clinicalFacility.oneTimeOrderEnabled}
        manageFulfillmentAgreements={
          dmeOrder.permissions.manageFulfillmentAgreements
        }
        loading={loading}
        setLoading={setLoading}
        showServiceTier={showServiceTier}
      />
    ) : (
      <SupplierSelector
        packageConfiguration={packageConfiguration}
        showServiceTier={showServiceTier}
      />
    )

  return (
    <Overlay active={loading} showSpinner>
      <div className="row">
        <div className="col-md-12">
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            submitOnChange
          >
            {supplierSelector}
            <div className="form-actions text-right">
              {isSupplierIncomplete || (
                <CanopyButton
                  onClick={onContinueButtonClicked}
                  className="btn btn-brand float-right"
                >
                  Continue
                </CanopyButton>
              )}
            </div>
          </Form>
        </div>
      </div>
    </Overlay>
  )
}

export default SelectSupplierStep
