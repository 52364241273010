import React from "react"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import * as styles from "./index.module.scss"
import Card from "components/Card"
import { Severity } from "sharedTypes"
import classNames from "classnames"
import { SummaryComponent } from "./SummaryComponent"

type Props = {
  title?: string
  summary?: string
  qualificationStatus?: "qualified" | "undetermined"
  isOnDeprecatedPage: boolean
}

const QualificationStatusIndicator = ({ name, style, text }) => {
  return (
    <div className={classNames(styles.indicator, styles[style])}>
      <CanopyIcon name={name} className="canopy-mie-2x" />
      {text}
    </div>
  )
}

export const InternalCsrInboxStateAiDetails = ({
  title,
  summary,
  qualificationStatus,
  isOnDeprecatedPage,
}: Props) => {
  if (!title || !summary) {
    return null
  }
  const severity =
    qualificationStatus === "qualified" ? Severity.Success : Severity.Warning
  return (
    <div className={isOnDeprecatedPage ? "canopy-my-12x" : "canopy-mb-16x"}>
      {isOnDeprecatedPage && (
        <>
          <div className="canopy-mbe-2x">
            <h3 className="d-inline canopy-typography-heading-large">
              Order Summary
            </h3>
            <span className={styles.betaBadge}>Beta</span>
          </div>
          <p> The information is AI-generated. Please confirm the details.</p>
        </>
      )}
      <Card
        className={styles.summary}
        status={severity}
        border="top"
        badge={() =>
          qualificationStatus === "qualified" ? (
            <QualificationStatusIndicator
              name="circle-check"
              style="successIndicator"
              text="Appears to qualify"
            />
          ) : (
            <QualificationStatusIndicator
              name="circle-exclamation"
              style="warningIndicator"
              text="Qualification undetermined"
            />
          )
        }
      >
        <h3 className="canopy-typography-heading-large">
          {title}
          {!isOnDeprecatedPage && (
            <span className={styles.newFlowBetaBadge}>Beta</span>
          )}
        </h3>
        <SummaryComponent summary={summary} />
      </Card>
    </div>
  )
}
