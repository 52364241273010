import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import { forwardDocument } from "./api"
import { navigate } from "utilities/navigation"
import { CanopyForm } from "@parachutehealth/canopy-form"

interface Props {
  dashboardUrl: string
}

function isSuccess(
  response:
    | { success: boolean }
    | { errors: { forwardedToEmails: string[] } | {} }
): response is { success: boolean } {
  return "success" in response
}

export const ForwardDocument = ({ dashboardUrl }: Props) => {
  const [open, setOpen] = React.useState(false)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const csrInboxState = useCsrInboxState()
  const [forwardedToEmails, setForwardedToEmails] = useState<string>("")
  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const handleOnChange = (e) => {
    setError(undefined)
    setForwardedToEmails(e.target.value)
  }

  const handleForwardDocumentClicked = (e, forwardedToEmails) => {
    e.preventDefault()
    setLoading(true)
    setError(undefined)
    forwardDocument({
      supplierOrganizationId: csrInboxState.supplierOrganization.id,
      csrInboxStateId: csrInboxState.id,
      forwardedToEmails: forwardedToEmails,
    }).then((response) => {
      if (isSuccess(response)) {
        navigate(dashboardUrl)
      } else {
        const errors = response.errors["forwardedToEmails"]
        setError(
          errors
            ? errors.join(", ")
            : "Something went wrong. Please refresh the page and try again."
        )
      }
      setLoading(false)
    })
  }

  return (
    <>
      <CanopyButton
        onClick={openModal}
        iconStart="envelope"
        size="small"
        variant="tertiary"
      >
        Forward via Email
      </CanopyButton>

      <CanopyDialogModal
        header="Send document(s) via email"
        open={open}
        onClose={closeModal}
        primaryFooterButton={
          <CanopyButton
            variant="primary"
            type="submit"
            form="forwardDocumentModalForm"
            loading={loading}
          >
            Send
          </CanopyButton>
        }
        secondaryFooterButton={
          <CanopyButton variant="secondary" onClick={closeModal}>
            Cancel
          </CanopyButton>
        }
      >
        Enter one or more email addresses (comma-separated) to route documents
        to the appropriate department or person.
        <CanopyForm
          id="forwardDocumentModalForm"
          onSubmit={(e) => handleForwardDocumentClicked(e, forwardedToEmails)}
        >
          <CanopyTextInputField
            id="forwardedToEmails"
            className="canopy-pt-8x"
            label="Recipient email address(es)"
            onChange={(e) => handleOnChange(e)}
            feedbackMessage={error}
            feedbackMessageStatus={error?.length ? "error" : "success"}
            placeholder="Enter email address"
            type="email"
          />
        </CanopyForm>
      </CanopyDialogModal>
    </>
  )
}
