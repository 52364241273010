import { put } from "services/api"
import { ApiResponse } from "applications/Cms/api/utilities"
import { updateInternalStatusUrl } from "applications/SupplierOrganizationInboxDocument/urls"

export const updateInternalStatus = ({
  supplierOrganizationId,
  csrInboxStateId,
  internalStatusId,
}: {
  supplierOrganizationId: string
  csrInboxStateId: string
  internalStatusId: string | undefined
}): Promise<ApiResponse<any>> => {
  return put(updateInternalStatusUrl(supplierOrganizationId, csrInboxStateId), {
    internal_status_id: internalStatusId,
  })
}
