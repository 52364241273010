import { gql } from "@apollo/client"
import { PageInfoFieldsFragment } from "../../../../../graphql/fragments/fragments"
import {
  MemberSearch_GetPayorDmeOrdersQuery,
  MemberSearch_GetPayorDmeOrdersQueryVariables,
} from "../../../../../graphql/__generated__/graphql"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import { PatientNameFields } from "./fragments/patient"
import {
  DeliveryAddressFields,
  SupplierLegalEntityAddressFields,
} from "./fragments/address"

export const MemberSearch_GetPayorDmeOrders: TypedDocumentNode<
  MemberSearch_GetPayorDmeOrdersQuery,
  MemberSearch_GetPayorDmeOrdersQueryVariables
> = gql`
  ${PageInfoFieldsFragment}
  ${PatientNameFields}
  ${DeliveryAddressFields}
  ${SupplierLegalEntityAddressFields}
  query MemberSearch_GetPayorDmeOrders(
    $memberId: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: PayorDmeOrdersSortEnum
    $sortDirection: SortDirectionEnum
  ) {
    payorDmeOrders(
      memberId: $memberId
      after: $after
      before: $before
      first: $first
      last: $last
      sort: $sort
      sortDirection: $sortDirection
    ) {
      totalCount
      pageInfo {
        ...PageInfoFields
      }
      nodes {
        id
        orderStatus
        signatureStatus
        missingDocuments
        clinicalFacility {
          name
        }
        creator {
          firstName
          lastName
        }
        patient {
          ...PatientNameFields
          dateOfBirth
        }
        deliveryFulfillments {
          id
          requestedDeliveryDate
          deliveryStatus
          ...DeliveryAddressFields
        }
        insurancePolicies {
          id
          memberNumber
          verifiedMemberId
          rank
        }
        lineItemGroups {
          id
          displayName
        }
        supplierLegalEntity {
          id
          name
          ...SupplierLegalEntityAddressFields
          phoneNumber
        }
        carrierAuthorization {
          id
        }
        orderDate
        orderingDoctor {
          name
        }
        originatorType
      }
    }
  }
`
