import { get, put } from "services/api"
import * as urls from "applications/Workflow/urls"
import { workflowLineItemGroupPreferenceUrl } from "applications/Workflow/urls"
import { LineItemGroup } from "./sharedTypes"
import { AxiosPromise } from "axios"

export function fetchInitialData() {
  return get(workflowLineItemGroupPreferenceUrl()).then(({ data }) => data)
}

export const getLineItemGroup = (
  lineItemGroupId: string
): AxiosPromise<LineItemGroup> => {
  return get(`${urls.lineItemGroupUrl(lineItemGroupId)}`) as AxiosPromise<
    LineItemGroup
  >
}

export function updateLineItemGroup(
  lineItemGroupId,
  params
): AxiosPromise<LineItemGroup> {
  return put(`${urls.lineItemGroupUrl(lineItemGroupId)}`, {
    dmeOrderLineItemGroup: params,
  }) as AxiosPromise<LineItemGroup>
}

export const updateEmploymentPreferences = (params) =>
  put(urls.updateEmploymentPreferencesUrl(), params)
