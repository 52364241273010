import React from "react"
import classNames from "classnames"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import Pages from "../Pages"
import { fullName } from "utilities/person"
import {
  DmeOrder,
  DmeOrderPermissions,
  Follower,
  Originator,
  Page,
} from "sharedTypes"
import * as styles from "./index.module.scss"
import OrderActionsDropdown from "applications/Workflow/containers/Navigation/components/OrderActionsDropdown/OrderActionsDropdown"
import FollowersButton from "applications/Workflow/containers/Navigation/components/FollowersButton"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyText } from "@parachutehealth/canopy-text"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

type Props = {
  open?: boolean
  originator: Originator
  buttons: React.ReactElement[]
  pages: Page[]
  nextPagePath?: string
  dmeOrder: DmeOrder
  patientLink: React.ReactElement
  toggle(): void
  followDmeOrder(): Promise<void>
  unfollowDmeOrder(followingId: string): Promise<void>
  snoozeDmeOrder(params): Promise<void>
  unsnoozeDmeOrder(): Promise<void>
  permissions: DmeOrderPermissions
  followers: Follower[]
  onFollowersClick: () => void
}

function BottomNavigation({
  open,
  buttons,
  dmeOrder,
  pages,
  patientLink,
  nextPagePath,
  toggle,
  followDmeOrder,
  unfollowDmeOrder,
  snoozeDmeOrder,
  unsnoozeDmeOrder,
  originator,
  permissions,
  followers,
  onFollowersClick,
}: Props) {
  const currentIndex = pages.findIndex((page) => page.active)
  const currentPage = pages[currentIndex]
  const hasMultiplePages = pages.length > 1

  const currentPageToggle = () => {
    if (!currentPage) {
      return fullName(dmeOrder.patient)
    }

    return (
      <CanopyFlex alignItems="center" gap="8X" className="canopy-mis-8x">
        <CanopyIcon
          data-testid="status-icon"
          data-iconname={
            currentPage.checked ? "circle-check" : "circle-outline"
          }
          size="medium"
          name={currentPage.checked ? "circle-check" : "circle-outline"}
          fill={
            currentPage.checked
              ? "canopyColorPrimitivesGreen41"
              : "canopyColorPrimitivesGray74"
          }
        />
        <CanopyFlex direction="column" gap="0" className="canopy-mbs-2x">
          {currentIndex >= 0 && hasMultiplePages && (
            <CanopyText
              size="small"
              weight="bold"
              style={{ textTransform: "uppercase", lineHeight: 1 }}
            >
              Step {currentIndex + 1}
            </CanopyText>
          )}
          <CanopyText weight="bold">{currentPage.title}</CanopyText>
        </CanopyFlex>
      </CanopyFlex>
    )
  }

  return (
    <div
      className={classNames({ [styles.overlay]: open })}
      data-testid="nav-overlay"
      data-active={open}
    >
      <div
        className={styles.space}
        onClick={toggle}
        data-testid="nav-overlay-space"
      />
      <div className={styles.mobile}>
        {open && (
          <div className={styles.body}>
            {patientLink}
            {!permissions.snooze && (
              <CanopyFlex
                gap="8X"
                padding="8X"
                className={styles.buttonContainer}
              >
                {buttons.map((button, index) => {
                  return (
                    <React.Fragment key={`button-${index}`}>
                      {button}
                    </React.Fragment>
                  )
                })}
              </CanopyFlex>
            )}
            {permissions.snooze && (
              <CanopyFlex
                gap="8X"
                padding="8X"
                className={styles.buttonContainer}
              >
                <OrderActionsDropdown
                  originator={originator}
                  dmeOrder={dmeOrder}
                  followDmeOrder={followDmeOrder}
                  unfollowDmeOrder={unfollowDmeOrder}
                  snoozeDmeOrder={snoozeDmeOrder}
                  unsnoozeDmeOrder={unsnoozeDmeOrder}
                />
                <FollowersButton
                  followers={followers}
                  onClick={onFollowersClick}
                />
              </CanopyFlex>
            )}
            <Pages pages={pages} removeBottomBorder={false} />
          </div>
        )}
        <CanopyFlex
          alignItems="center"
          gap="8X"
          className={styles.bar}
          onClick={toggle}
          data-testid="bar"
        >
          <CanopyFlex
            alignItems="center"
            justifyContent="space-between"
            style={{ flexGrow: 1 }}
          >
            <div>{currentPageToggle()}</div>
            <CanopyIcon
              data-testid="open-indicator-icon"
              data-iconname={open ? "caret-down" : "caret-up"}
              name={open ? "caret-down" : "caret-up"}
              size="medium"
            />
          </CanopyFlex>
          {nextPagePath && (
            <div className={styles.button}>
              <ContinueButton
                isButtonOutlined={!currentPage.checked}
                path={nextPagePath}
                // prevents the bottom nav from toggling open and closed
                shouldStopPropagation
              />
            </div>
          )}
        </CanopyFlex>
      </div>
    </div>
  )
}

export default BottomNavigation
