import React from "react"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyTextareaField } from "@parachutehealth/canopy-textarea-field"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { underscore } from "utilities/string"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { useFormikParachute } from "applications/shared/hooks/useFormikParachute/useFormikParachute"
import { FormikErrors } from "formik"

interface Props {
  cancel(): void
  onSubmit(values: FeedbackModalValues): void
  show: boolean
  topics?: string[]
}

interface FeedbackModalValues {
  feedback: string
  topic?: string
}

const FeedbackModal: React.FC<Props> = ({ cancel, onSubmit, show, topics }) => {
  const Topics = () =>
    topics ? (
      <CanopyRadioInputField
        label="Please select a topic you’d like to share feedback on:"
        name="topic"
        className="plain"
        onChange={formik.handleChange}
        value={formik.values.topic}
        options={topics.map((topic) => ({
          label: topic,
          value: underscore(topic),
        }))}
      />
    ) : (
      <></>
    )

  const formik = useFormikParachute<FeedbackModalValues>({
    initialValues: {
      feedback: "",
      topic: "",
    },
    onSubmit: (values) => {
      onSubmit(values)
    },
    validate: (values) => {
      const errors: FormikErrors<FeedbackModalValues> = {}
      if (!values.feedback) {
        errors.feedback = "Enter feedback"
      }
      return errors
    },
  })

  return (
    <CanopyDialogModal
      open={show}
      onClose={cancel}
      header="Share feedback with the Parachute team"
      primaryFooterButton={
        <CanopyButton
          form="feedbackForm"
          className="btn-brand"
          type="submit"
          disabled={false}
        >
          Share feedback
        </CanopyButton>
      }
      secondaryFooterButton={
        <CanopyButton variant="secondary" onClick={cancel}>
          Cancel
        </CanopyButton>
      }
    >
      <>
        <CanopyNotice
          title="Need a response?"
          message="Use Chat for support, or the Activity Feed to contact the supplier."
          variant="warning"
        />
        <CanopyForm
          id="feedbackForm"
          className="canopy-pt-8x"
          onSubmit={formik.handleSubmit}
        >
          <Topics />
          <CanopyTextareaField
            feedbackMessage={formik.errors.feedback}
            label="Suggest new features or improvements we can make "
            required
            name="feedback"
            data-testid="feedback"
            criticalGuidance="Do not share sensitive patient information"
            onChange={formik.handleChange}
            value={formik.values.feedback}
          />
        </CanopyForm>
      </>
    </CanopyDialogModal>
  )
}

export default FeedbackModal
