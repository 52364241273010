// @ts-strict-ignore
import React from "react"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import Icd10Codes from "applications/Workflow/containers/Icd10Codes"
import { DiagnosisHook } from "applications/Workflow/containers/Icd10Codes/hooks/useDiagnosis"
import DischargeDateForm from "applications/Workflow/containers/DischargeDateForm"
import OrderDateForm from "./OrderDateForm"
import SupplierDocumentationRequirements from "./SupplierDocumentationRequirements"
import { ChartNoteQuestion, DmeOrder } from "sharedTypes"
import cx from "classnames"

interface Props {
  dmeOrder: DmeOrder
  diagnosisHook: DiagnosisHook
  nextPagePath?: string
  isPageChecked?: boolean
  updateOrderDate(date: string): Promise<void>
  fetchChartNoteQuestions({ id }: { id: string }): Promise<ChartNoteQuestion[]>
  updateDocumentationRequirementsRequested(
    generatedIds: string[]
  ): Promise<void>
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
}

const InternalClinical = (props: Props) => {
  const {
    dmeOrder,
    updateOrderDate,
    updateDocumentationRequirementsRequested,
    diagnosisHook,
    fetchChartNoteQuestions,
    nextPagePath,
    isPageChecked,
    setDmeOrder,
  } = props
  const OrderDatesWell = () => {
    const columnClass = dmeOrder.discharge ? "col-auto" : "col-md-12"

    return (
      <div className="well canopy-mbs-8x">
        <h3>Dates</h3>
        <div className="row align-content-start">
          <div className={columnClass}>
            <OrderDateForm
              orderDate={dmeOrder.orderDate}
              updateOrderDate={updateOrderDate}
            />
          </div>
          {dmeOrder.discharge && (
            <div className={columnClass}>
              <DischargeDateForm
                dischargeDate={dmeOrder.dischargeDate}
                setDmeOrder={setDmeOrder}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="wide-fixed-row">
      <Icd10Codes
        header="Diagnoses"
        className="well"
        diagnosisHook={diagnosisHook}
        label="Diagnosis Codes"
        subheader="Add diagnosis codes for products in your order"
      />
      <OrderDatesWell />
      <SupplierDocumentationRequirements
        className={cx("wide-fixed-row", "canopy-mbs-8x")}
        dmeOrder={dmeOrder}
        documentationRequirements={dmeOrder.documentationRequirements}
        questionnaireResponses={dmeOrder.questionnaireResponses}
        chartNotes={dmeOrder.chartNotes}
        updateDocumentationRequirementsRequested={
          updateDocumentationRequirementsRequested
        }
        fetchChartNoteQuestions={fetchChartNoteQuestions}
      />
      {nextPagePath && (
        <div className={cx("text-right", "wide-fixed-row")}>
          <ContinueButton
            isButtonOutlined={!isPageChecked}
            path={nextPagePath}
          />
        </div>
      )}
    </div>
  )
}

export default InternalClinical
