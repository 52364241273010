import React from "react"

import * as styles from "./AuthCardDetail.module.scss"
import { isNullOrUndefined } from "utilities/isNullOrUndefined"
import classNames from "classnames"

interface AuthCardDetailProps {
  requiredField: boolean
  title: string
  displayValue: string | string[] | undefined
}

export const AuthCardDetail = ({
  title,
  requiredField,
  displayValue,
}: AuthCardDetailProps) => {
  const isRequiredField = () => {
    return requiredField ? (
      <p
        role="gridcell"
        className={classNames(styles.requiredField, styles.cardDetailValue)}
      >
        Required
      </p>
    ) : (
      <p role="gridcell" className={styles.cardDetailValue}>
        {" "}
        -{" "}
      </p>
    )
  }

  const displayValuesList = (displayValue) => (
    <div className={classNames(styles.cardDetailValue, "canopy-mb-8x")}>
      {displayValue.map((value, index) => {
        return (
          <p className="canopy-mb-0" key={index} role="gridcell">
            {value}
          </p>
        )
      })}
    </div>
  )

  const handleDisplayValue = () => {
    if (!isNullOrUndefined(displayValue) && Array.isArray(displayValue)) {
      return displayValuesList(displayValue)
    } else if (!isNullOrUndefined(displayValue)) {
      return (
        <p role="gridcell" className={styles.cardDetailValue}>
          {displayValue}
        </p>
      )
    } else {
      return isRequiredField()
    }
  }

  return (
    <div role="row" className={styles.cardDetail}>
      <div role="gridcell" className={styles.cardDetailTitle}>
        <h5 className="canopy-typography-font-weight-bold">{title}</h5>
      </div>
      {handleDisplayValue()}
    </div>
  )
}
