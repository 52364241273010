import React from "react"
import { InternalCsrInboxStateAiDetails } from "applications/CsrInboxStateAiDetails/components/InternalCsrInboxStateAiDetails"

type Props = {
  aiIntakeEnabled: boolean
  documentation?: {
    title?: string
    summary?: string
    qualificationStatus?: "qualified" | "undetermined"
  }
  isOnDeprecatedPage: boolean
}
const CsrInboxStateAiDetails = ({
  aiIntakeEnabled,
  documentation,
  isOnDeprecatedPage,
}: Props) => {
  if (!aiIntakeEnabled) {
    return null
  }

  return (
    <InternalCsrInboxStateAiDetails
      {...documentation}
      isOnDeprecatedPage={isOnDeprecatedPage}
    />
  )
}

export default CsrInboxStateAiDetails
