import { gql } from "@apollo/client"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import {
  CarrierLineItemAuthorizationCreateMutation,
  CarrierLineItemAuthorizationCreateMutationVariables,
} from "../../../../graphql/__generated__/graphql"

export const CarrierLineItemAuthorizationCreate: TypedDocumentNode<
  CarrierLineItemAuthorizationCreateMutation,
  CarrierLineItemAuthorizationCreateMutationVariables
> = gql`
  mutation CarrierLineItemAuthorizationCreate(
    $carrierAuthorizationId: ID!
    $hcpcs: String!
    $hcpcsDetails: JSON!
    $isMiscHcpcs: Boolean!
    $quantity: Int!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $priority: String!
    $previousAuthorizationNumber: String
  ) {
    carrierLineItemAuthorizationCreate(
      input: {
        carrierAuthorizationId: $carrierAuthorizationId
        hcpcs: $hcpcs
        hcpcsDetails: $hcpcsDetails
        isMiscHcpcs: $isMiscHcpcs
        quantity: $quantity
        startDate: $startDate
        endDate: $endDate
        priority: $priority
        previousAuthorizationNumber: $previousAuthorizationNumber
      }
    ) {
      carrierLineItemAuthorization {
        id
        hcpcs
      }
    }
  }
`
