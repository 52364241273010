// @team @clinical-intelligence
import React from "react"
import Card from "components/Card"
import { Severity } from "sharedTypes"
import { CanopyHeading } from "@parachutehealth/canopy-heading"

type Props = {
  title: string
  expanded?: boolean
  status?: Severity
  summary?: React.ReactNode
  children: React.ReactNode
  dropdown?: boolean
  badge?: ({ open }) => React.ReactNode
  className?: string
  id?: string
}

function ReviewCard({
  title,
  status,
  summary,
  expanded,
  dropdown,
  badge,
  children,
  className,
  id,
}: Props) {
  return (
    <Card
      id={id}
      className={className}
      status={status}
      border="top"
      badge={badge}
      dropdown={dropdown}
      expanded={expanded}
      render={({ open, toggle }) => {
        return (
          <>
            <CanopyHeading
              level={3}
              size="large"
              className="pointer canopy-mbe-6x"
              onClick={toggle}
            >
              {title}
            </CanopyHeading>
            {!open && summary && <div className="canopy-mbs-8x">{summary}</div>}
            {(open || !dropdown) && <>{children}</>}
          </>
        )
      }}
    />
  )
}

ReviewCard.defaultProps = {
  dropdown: true,
}

export default ReviewCard
