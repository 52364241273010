// @team @demand-ordering

export const allowSearchByProduct = (
  userActivationProductFirstSearchIncludeEnterpriseFlag: boolean,
  usesEnterpriseFeatures: boolean
): boolean => {
  if (userActivationProductFirstSearchIncludeEnterpriseFlag) return true

  // Search by product for enterprise facilities is false,
  // so we only enable search by product if the facility is not using enterprise features
  return !usesEnterpriseFeatures
}
