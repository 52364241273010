import { get, put } from "services/api"
import {
  clinicalFacilityDashboardResultsUrl,
  clinicalFacilityFocusTileResultsUrl,
  incomingOrdersCountUrl,
  ordersWithUnreadEventsCountUrl,
  searchDoctorsUrl,
  searchFollowersUrl,
  searchSuppliersUrl,
  sentBackForReviewCountUrl,
  updateUiPreferencesUrl,
  nextIncomingOrderUrl,
  updateTeamPushbackConfigurationsUrl,
} from "./urls"
import { Doctor, Follower, Supplier } from "sharedTypes"
import { AxiosPromise } from "axios"
import { SelectOptions } from "@parachutehealth/canopy-combobox-field"

export function getClinicalFacilityDashboardResults(values = {}) {
  return get(clinicalFacilityDashboardResultsUrl(), values).then(
    ({ data }) => data
  )
}

export function getClinicalFacilityDashboardNewMessagesFocusTileResults(
  values = {}
) {
  return get(clinicalFacilityFocusTileResultsUrl("new_messages"), values).then(
    ({ data }) => data
  )
}

export function getClinicalFacilityDashboardCreatedBySupplierFocusTileResults(
  values = {}
) {
  return get(
    clinicalFacilityFocusTileResultsUrl("created_by_supplier"),
    values
  ).then(({ data }) => data)
}

export function getClinicalFacilityDashboardSentBackForReviewFocusTileResults(
  values = {}
) {
  return get(
    clinicalFacilityFocusTileResultsUrl("sent_back_for_review"),
    values
  ).then(({ data }) => data)
}

export function searchFollowers(term: string): Promise<Follower[]> {
  return get(searchFollowersUrl(), { term }).then(({ data }) => data)
}

export function searchSuppliers(term: string): Promise<Supplier[]> {
  return get(searchSuppliersUrl(), { term }).then(({ data }) => data)
}

export function searchDoctors(term: string): Promise<Doctor[]> {
  return get(searchDoctorsUrl(), { term }).then(({ data }) => data)
}

export function updateSidebarExpandedPreference(
  sidebarExpanded: boolean
): AxiosPromise {
  return put(updateUiPreferencesUrl(), {
    uiPreferences: {
      clinicalFacilityLeftSidebarExpanded: sidebarExpanded,
    },
  })
}

export function updateIncomingOrdersNotificationPreference(
  incomingOrdersNotificationPreference: string,
  clinicians: SelectOptions
): AxiosPromise {
  return put(updateTeamPushbackConfigurationsUrl(), {
    incomingOrdersNotificationPreference: incomingOrdersNotificationPreference,
    clinicians: clinicians,
  })
}

export function getIncomingOrdersCount(): Promise<any> {
  return get(incomingOrdersCountUrl()).then(({ data }) => data)
}

export function getNextIncomingOrder(): Promise<any> {
  return get(nextIncomingOrderUrl()).then(({ data }) => data)
}

export function getSentBackForReviewOrdersCount(): Promise<any> {
  return get(sentBackForReviewCountUrl()).then(({ data }) => data)
}

export function getUnreadEventsCount(): Promise<any> {
  return get(ordersWithUnreadEventsCountUrl()).then(({ data }) => data)
}
