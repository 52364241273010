import React, { ReactElement } from "react"
import ExternalQuestionnaireModalButton from "../ExternalQuestionnaireModalButton/ExternalQuestionnaireModalButton"
import * as styles from "./MedicalNecessitySummaryRow.module.scss"
import { ChartNoteQuestion } from "../../../../../../../../../../sharedTypes"

type Props = {
  title: string
  questionsWithSelectedAnswers?: ChartNoteQuestion[]
  statusPill: ReactElement
}
function MedicalNecessitySummaryRow({
  title,
  questionsWithSelectedAnswers = [],
  statusPill,
}: Props) {
  return (
    <span className={`${styles.rowWrapper}`}>
      <span className="flex-1">{title}</span>
      {!!questionsWithSelectedAnswers.length && (
        <span className={styles.floatRight}>
          <ExternalQuestionnaireModalButton
            title={title}
            questionsWithSelectedAnswers={questionsWithSelectedAnswers}
          />
        </span>
      )}
      {statusPill}
    </span>
  )
}

export default MedicalNecessitySummaryRow
