import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import * as styles from "./index.module.scss"
import { MarkdownText } from "./MarkdownText"
import { truncateText } from "./utilities"

type Props = {
  summary: string | undefined
}

const TRUNCATE_LENGTH = 180
const MAX_LINES = 2

type ToggleButtonProps = {
  showMore: boolean
  onToggle: () => void
}

const ToggleButton = ({ showMore, onToggle }: ToggleButtonProps) => (
  <CanopyButton
    className={styles.seeMoreOrLessButton}
    variant="tertiary"
    size="small"
    onClick={onToggle}
  >
    {showMore ? "See less" : "See more"}
  </CanopyButton>
)

export const SummaryComponent = ({ summary }: Props) => {
  const [showMore, setShowMore] = useState(false)
  if (!summary) {
    return null
  }

  const truncatedText = truncateText(summary, TRUNCATE_LENGTH, MAX_LINES)
  const needsTruncating = truncatedText.length < summary.length
  const isTruncated = needsTruncating && !showMore
  const displayedSummaryText = showMore
    ? summary
    : truncatedText + (isTruncated ? "..." : "")

  return (
    <div>
      <div className={styles.markdownContainer}>
        <MarkdownText text={displayedSummaryText} />
      </div>
      {needsTruncating && (
        <div>
          <ToggleButton
            showMore={showMore}
            onToggle={() => setShowMore(!showMore)}
          />
        </div>
      )}
    </div>
  )
}
