import React from "react"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { DoneAll, Warning, Info } from "@material-ui/icons"
import { format } from "utilities/date"

const PatientActivityStatus = ({
  lastPatientLoginAt,
  lastFailedLoginAt,
  lastAccessEventType,
  phoneNumberRejectedAt,
  optedOutAt,
  eSigningEnabled,
  deliveryTicketSignedAt,
}) => {
  if (eSigningEnabled && deliveryTicketSignedAt) {
    return (
      <div className="d-flex align-items-center canopy-mbe-8x">
        <CanopyIcon name="marker" size="medium" fill="canopyColorTextSuccess" />
        <span className="canopy-pis-4x">
          Patient signed proof of delivery on{" "}
          {format(deliveryTicketSignedAt, "MMM D, YYYY")}
        </span>
      </div>
    )
  }
  if (phoneNumberRejectedAt) {
    return (
      <p className="d-flex align-items-center">
        <Warning htmlColor="#E0A84C" />
        <span className="canopy-pis-4x">
          Order tracker access locked because recipient indicated wrong number
        </span>
      </p>
    )
  } else if (optedOutAt) {
    return (
      <div className="d-flex align-items-center canopy-mbe-8x">
        <CanopyIcon
          name="ban"
          size="medium"
          fill="canopyColorPrimitivesGray48"
        />
        <span className="canopy-pis-4x">
          Patient opted out from Order Tracker SMS notifications on{" "}
          {format(optedOutAt, "MMM D, YYYY")}
        </span>
      </div>
    )
  } else if (lastAccessEventType !== "locked") {
    if (lastPatientLoginAt) {
      // logged in successfully at some point
      return (
        <p className="d-flex align-items-center">
          <DoneAll htmlColor="#16BAAA" />
          <span className="canopy-pis-4x">
            Patient last logged in on{" "}
            {format(lastPatientLoginAt, "MMM D, YYYY")}
          </span>
        </p>
      )
    } else if (lastFailedLoginAt) {
      // never logged in successfully, but tried and failed at least once
      return (
        <p className="d-flex align-items-center">
          <Info htmlColor="#2E7EC9" />
          <span className="canopy-pis-4x">
            Patient tried to log in on{" "}
            {format(lastFailedLoginAt, "MMM D, YYYY")}
          </span>
        </p>
      )
    } else {
      // never tried to log in
      return null
    }
  } else {
    // currently locked out
    return (
      <p className="d-flex align-items-center">
        <Warning htmlColor="#E0A84C" />
        <span className="canopy-pis-4x">
          Patient locked out for 24 hours on{" "}
          {format(lastFailedLoginAt, "MMM D, YYYY")} due to multiple failed
          attempts
        </span>
      </p>
    )
  }
}

export default PatientActivityStatus
