import React from "react"
import CmsDrawer from "applications/Cms/components/CmsDrawer"
import { CatalogUnit, CatalogProduct } from "applications/Cms/types/sharedTypes"
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core"
import StyledTable from "applications/Cms/components/StyledTable"
import { CanopyFlex } from "@parachutehealth/canopy-flex"

export type UnitProductsDrawerProps = {
  catalogUnit: CatalogUnit
  products: CatalogProduct[]
  open: boolean
  onClose(): void
}

const UnitProductsDrawer = ({
  open,
  onClose,
  catalogUnit,
  products,
}: UnitProductsDrawerProps) => {
  return (
    <CmsDrawer
      title={`Products using "${catalogUnit.label}" unit of measure`}
      onClose={onClose}
      open={open}
    >
      <>
        <CanopyFlex
          justifyContent="space-between"
          className="canopy-mbs-4x canopy-mbe-8x"
        >
          <div>
            <div className="canopy-typography-heading-large canopy-mbe-4x">
              {`${catalogUnit.label}`}
            </div>
            <div>
              This unit is used in {"  "}
              <span className="font-weight-bold">
                {`${products.length} Products`}
              </span>
            </div>
          </div>
        </CanopyFlex>
        {products && (
          <StyledTable>
            <Table data-testid="unit-products-table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="col">
                    Products
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>
                      <a href={product.url}>{product.name}</a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTable>
        )}
      </>
    </CmsDrawer>
  )
}
export default UnitProductsDrawer
