import React, { useState } from "react"
import Followers from "./Followers"
import { Follower, FollowerEmployerType } from "sharedTypes"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { handleError } from "utilities/error"
import { Form } from "components/form"
import { UpdateFollowersParams } from "./sharedTypes"
import { OptionItem } from "@parachutehealth/canopy-combobox-field"
import * as styles from "./index.module.scss"

type Props = {
  show: boolean
  closeModal(): void
  editable: boolean
  updateFollowers(params: UpdateFollowersParams, orderId: string): Promise<void>
  dmeOrder: {
    externalId: string
    patient: { firstName: string; lastName: string }
    followers: Follower[]
  }
  activatorElementRef?: React.RefObject<HTMLElement>
}

function FollowersModal({
  show,
  closeModal,
  editable,
  dmeOrder,
  updateFollowers,
  activatorElementRef,
}: Props) {
  const [loading, setLoading] = useState(false)

  const windowEmployerType = window.parachute?.employerType
  const isFacility = windowEmployerType === "ClinicalFacility"
  const allFollowers = dmeOrder.followers
  const supplierFollowers = allFollowers.filter(
    (follower) => follower.employerType === FollowerEmployerType.Supplier
  )

  const isAvailableToRemove = (record) =>
    !(record.employerType === FollowerEmployerType.Supplier && isFacility)
  const followers = dmeOrder.followers.filter(isAvailableToRemove)

  const initialFollowerOptions: OptionItem[] = followers.map((follower) => ({
    label: follower.name,
    value: follower.id.toString(),
  }))

  const headerOverline: string = `Order for ${dmeOrder.patient.firstName} ${dmeOrder.patient.lastName}`

  const onSubmit = (values) => {
    if (!editable) closeModal()
    const valueFollowerIds = values.followers.map((follower) => follower.value)
    setLoading(true)
    const readonlyFollowerIds = isFacility
      ? supplierFollowers.map((follower) => follower.id)
      : []
    updateFollowers(
      {
        followerIds: valueFollowerIds.concat(readonlyFollowerIds),
      },
      dmeOrder.externalId
    )
      .then(() => {
        setLoading(false)
        closeModal()
      })
      .catch((e) => {
        setLoading(false)
        handleError(e)
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ followers: initialFollowerOptions }}
    >
      {({ handleSubmit, values, dirty, setFieldValue }) => {
        return (
          <CanopyDialogModal
            unclosable
            activatorElementRef={activatorElementRef}
            header={editable ? "View and edit followers" : "Followers"}
            open={show}
            className={styles.overflow}
            primaryFooterButton={
              <CanopyButton
                onClick={() => (dirty ? handleSubmit(values) : closeModal())}
                loading={loading}
              >
                Confirm
              </CanopyButton>
            }
            secondaryFooterButton={
              <CanopyButton onClick={() => closeModal()} variant="secondary">
                Cancel
              </CanopyButton>
            }
            size={editable ? "medium" : "small"}
            headerOverline={headerOverline}
          >
            <Followers
              editable={editable}
              dmeOrder={dmeOrder}
              loading={loading}
              values={values}
              setFieldValue={setFieldValue}
            />
          </CanopyDialogModal>
        )
      }}
    </Form>
  )
}

export default FollowersModal
