import { gql } from "@apollo/client"
import { PageInfoFieldsFragment } from "../../../../../graphql/fragments/fragments"
import {
  OrdersDashboard_GetPayorDmeOrdersQuery,
  OrdersDashboard_GetPayorDmeOrdersQueryVariables,
} from "../../../../../graphql/__generated__/graphql"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"
import { PatientNameFields } from "./fragments/patient"

export const OrdersDashboard_GetPayorDmeOrders: TypedDocumentNode<
  OrdersDashboard_GetPayorDmeOrdersQuery,
  OrdersDashboard_GetPayorDmeOrdersQueryVariables
> = gql`
  ${PageInfoFieldsFragment}
  ${PatientNameFields}
  query OrdersDashboard_GetPayorDmeOrders(
    $memberId: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: PayorDmeOrdersSortEnum
    $sortDirection: SortDirectionEnum
  ) {
    payorDmeOrders(
      memberId: $memberId
      after: $after
      before: $before
      first: $first
      last: $last
      sort: $sort
      sortDirection: $sortDirection
    ) {
      totalCount
      pageInfo {
        ...PageInfoFields
      }
      nodes {
        id
        clinicalFacility {
          name
        }
        patient {
          ...PatientNameFields
        }
        insurancePolicies {
          id
          memberNumber
          verifiedMemberId
          rank
        }
        deliveryFulfillments {
          id
          requestedDeliveryDate
          deliveryStatus
        }

        lineItemGroups {
          id
          displayName
          hcpcs
        }

        supplierLegalEntity {
          name
        }
        carrierAuthorization {
          id
          requestSubmitDateTime
          authStatus
          priority
        }
      }
    }
  }
`
