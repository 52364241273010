import React, { useContext } from "react"
import GlobalContext from "context/Global"
import Overlay from "components/Overlay"
import ProductSummary from "./ProductSummary/ProductSummary"
import Details from "../../LineItemGroupCommonAttributes/components/Details"
import Requirements from "../../LineItemGroupCommonAttributes/components/Requirements"
import { DmeOrder, Employer, LineItemGroupCommonAttributes } from "sharedTypes"
import { LineItemGroup, LineItemGroupStep } from "../sharedTypes"
import { FormikValues } from "formik"

const lineItemGroupToLineItemGroupCommonAttributes = (
  lineItemGroup: LineItemGroup
): LineItemGroupCommonAttributes => {
  const {
    quantity,
    lengthOfNeedMonths,
    paymentMethod,
    supportedPaymentMethods,
    requiresLengthOfNeed,
    requiresHeight,
    requiresWeight,
    requiresWoundCount,
    heightInches,
    weightLbs,
    numberOfWounds,
  } = lineItemGroup
  return {
    quantity,
    lengthOfNeedMonths,
    paymentMethod,
    supportedPaymentMethods,
    requiresLengthOfNeed,
    requiresHeight,
    requiresWeight,
    requiresWoundCount,
    heightInches,
    weightLbs,
    numberOfWounds,
    allowsQuantity: true,
  }
}

type Props = {
  changeStep: (step: LineItemGroupStep) => void
  changeHeightUnit(): void
  currentEmployer: Employer
  goToNextPage(): Promise<void>
  dmeOrder: DmeOrder
  lineItemGroup: LineItemGroup
  step: string | LineItemGroupStep
  recalculateStep(): void
  remove(): void
  returnToBrowse(): void
  update(params: FormikValues): Promise<LineItemGroup | undefined>
  useFeetForHeight: boolean
}

const InternalLineItemGroup = (props: Props): React.ReactElement => {
  const {
    changeHeightUnit,
    changeStep,
    currentEmployer,
    dmeOrder,
    goToNextPage,
    lineItemGroup,
    recalculateStep,
    remove,
    returnToBrowse,
    step,
    update,
    useFeetForHeight,
  } = props
  const { featureFlags } = useContext(GlobalContext)

  const formularyPriceEnabled = !!(
    dmeOrder.clinicalFacility.formularyPriceEnabled &&
    featureFlags.formularyPricing
  )

  const onContinue = (
    params: FormikValues
  ): Promise<LineItemGroupCommonAttributes> => {
    if (params) {
      return new Promise((resolve) => {
        update(params).then((updatedLineItemGroup) => {
          if (updatedLineItemGroup) {
            resolve(
              lineItemGroupToLineItemGroupCommonAttributes(updatedLineItemGroup)
            )
          } else {
            resolve(lineItemGroupToLineItemGroupCommonAttributes(lineItemGroup))
          }
        })
      })
    } else {
      recalculateStep()
      return Promise.resolve(
        lineItemGroupToLineItemGroupCommonAttributes(lineItemGroup)
      )
    }
  }

  const onEditDetails = () => {
    changeStep(LineItemGroupStep.DetailsStep)
  }

  const onEditRequirements = () => {
    changeStep(LineItemGroupStep.RequirementsStep)
  }

  const currentStep = () => {
    switch (step) {
      case LineItemGroupStep.RequirementsStep:
        return (
          <Requirements
            changeHeightUnit={changeHeightUnit}
            currentEmployer={currentEmployer}
            lineItemGroupCommonAttributes={lineItemGroupToLineItemGroupCommonAttributes(
              lineItemGroup
            )}
            onContinue={onContinue}
            onSkip={returnToBrowse}
            useFeetForHeight={useFeetForHeight}
          />
        )
      case LineItemGroupStep.DetailsStep:
        return (
          <Details
            lineItemGroupCommonAttributes={lineItemGroupToLineItemGroupCommonAttributes(
              lineItemGroup
            )}
            onContinue={onContinue}
          />
        )
      default:
        return (
          <ProductSummary
            lineItemGroup={lineItemGroup}
            formularyPriceEnabled={formularyPriceEnabled}
            onEditDetails={onEditDetails}
            onEditRequirements={onEditRequirements}
            returnToBrowse={returnToBrowse}
            remove={remove}
            useFeetForHeight={useFeetForHeight}
            goToNextPage={goToNextPage}
          />
        )
    }
  }

  return (
    <Overlay active={false} showSpinner>
      <div>{currentStep()}</div>
    </Overlay>
  )
}

export default InternalLineItemGroup
